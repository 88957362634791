define("apollo/pods/components/transport/products-section/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service('store'),
    action: 'confirm',
    actions: {
      addProduct: function addProduct() {
        var product = this.get('store').createRecord('product', {});
        this.get('transport.products').pushObject(product);
      },
      removeProduct: function removeProduct(product) {
        this.get('transport.products').removeObject(product);
      }
    }
  });

  _exports.default = _default;
});