define("apollo/pods/transport-type/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    intl: Ember.inject.service(),
    TRANSPORT_TYPES: {
      TRANSPORT: 'TRANSPORT',
      SERVICE: 'SERVICE',
      DELIVERY: 'DELIVERY',
      COLLECTION: 'COLLECTION'
    },
    roleGroups: _emberData.default.hasMany('roleGroup', {
      async: true
    }),
    tableColumns: _emberData.default.hasMany('tableColumn', {
      async: true
    }),
    workflow: _emberData.default.belongsTo('workflow'),
    name: _emberData.default.attr('string'),
    typeGroup: _emberData.default.attr('string'),
    canBeCreatedByRoleGroup: _emberData.default.attr('boolean'),
    canBeSeenByRoleGroup: _emberData.default.attr('boolean'),
    canHaveAdviceAddedByRoleGroup: _emberData.default.attr('boolean'),
    showCreateButtonInTopMenu: _emberData.default.attr('boolean'),
    tasks: Ember.computed('workflow.tasks', function () {
      return this.get('workflow.tasks');
    }),
    label: Ember.computed('name', function () {
      return this.get('intl').t("transport.type.".concat(this.get('name')));
    }),
    tableColumnNames: Ember.computed('tableColumns.@each.id', function () {
      return this.get('tableColumns').map(function (c) {
        return c.get("name");
      });
    })
  });

  _exports.default = _default;
});