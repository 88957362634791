define("apollo/helpers/is-in-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isInList = isInList;
  _exports.default = void 0;

  function isInList(params) {
    var element = params[0];
    var list = params[1];
    return list.indexOf(element) >= 0;
  }

  var _default = Ember.Helper.helper(isInList);

  _exports.default = _default;
});