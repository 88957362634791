define("apollo/services/session-account", ["exports", "jquery", "apollo/config/environment", "apollo/mixins/persistence-mixin"], function (_exports, _jquery, _environment, _persistenceMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend(_persistenceMixin.default, {
    session: Ember.inject.service('session'),
    store: Ember.inject.service(),
    errors: [],
    cookies: Ember.inject.service(),
    intl: Ember.inject.service(),
    resourceDateFormSettings: {},
    language: Ember.computed(function () {
      return this.get('intl').locale[0];
    }),
    loadCurrentUser: function loadCurrentUser() {
      console.log("loadCurrentUser");
      var self = this;
      var username = this.get('session.data.authenticated.username');
      var token = this.get('session.data.authenticated.token');

      if (Ember.isEmpty(username)) {
        return;
      }

      self.set("token", token);
      return new Ember.RSVP.Promise(function (resolve, reject) {
        _jquery.default.ajax({
          url: _environment.default.serverURL + '/users/account/' + username,
          type: 'GET',
          beforeSend: function beforeSend(xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
          }
        }).then(function (response) {
          if (Ember.isEmpty(response.user)) {
            reject('No user provided in response.');
          }

          Ember.run.next(function () {
            if (response.meta) {
              self.set('backendVersion', response.meta.appVersion);
            }

            self.set('resourceDateFormSettings', response.resourceDateFormSettings);
            self.set('paymentOptions', response.paymentOptions);

            if (!self.get('store').hasRecordForId('user', response.user.id)) {
              self.get('store').pushPayload(response);
            }

            var user = self.get('store').peekRecord('user', response.user.id);
            var company = self.get('store').peekRecord('company', response.user.company);

            if (!user.get('company.id')) {
              user.set('company', company);
            }

            self.set("resetPasswordIsDisabled", response.resetPasswordIsDisabled);
            self.set("currentUser", user);
            self.removeDuplicateRecords(user.get('roleGroup'), 'notifications');
            self.removeDuplicateRecords(user.get('roleGroup'), 'notifications');
            self.removeDuplicateRecords(user, 'notifications');
            var columnNames = user.get('tableColumns').map(function (c) {
              return c.get("name");
            });
            self.set("columnNames", columnNames);

            if (response.company.isPrincipal) {
              self.get('cookies').write('principalId', response.company.id);
              self.get('currentUser.company').set('partnershipType', null);
            } else {
              if (!self.get('cookies').read('principalId')) {
                self.get('cookies').write('principalId', response.user.principals.get('firstObject'));
              }

              if (self.get('currentUser.company.partnerships')) {
                self.get('currentUser.company.partnerships').then(function (partnerships) {
                  partnerships.map(function (p) {
                    if (p.get('principalCompany.id') === self.get('cookies').read('principalId')) {
                      self.get('currentUser.company').set('partnershipType', p.get('partnershipType'));
                    }
                  });
                });
              }
            }

            resolve(user);
          });
        }, function () {
          self.get('session').invalidate();
        });
      });
    },
    isPrincipal: Ember.computed('currentUser.isPrincipal', function () {
      return this.get('currentUser').get('isPrincipal');
    }),
    hasRoleTransportTable: Ember.computed('currentUser.roles', function () {
      return this.hasRole('ROLE_TRANSPORT_TABLE');
    }),
    hasAnyOfTheRoles: function hasAnyOfTheRoles(roles) {
      var self = this;
      var hasAnyOfTheRoles = false;
      roles.forEach(function (role) {
        if (self.hasRole(role)) {
          hasAnyOfTheRoles = true;
          return false;
        }
      });
      return hasAnyOfTheRoles;
    },
    hasRole: function hasRole(name) {
      if (!this.get('session').get('isAuthenticated')) {
        return false;
      }

      return this.get('currentUser').get('roleNames').includes(name);
    },
    getSettingValue: function getSettingValue(name) {
      return this.get('store').peekAll('setting').filterBy('name', name).get('firstObject.value');
    }
  });

  _exports.default = _default;
});