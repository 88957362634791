define("apollo/pods/components/render-notification/component", ["exports", "jquery", "apollo/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    actions: {
      sendNotification: function sendNotification(transportNotification) {
        if (transportNotification.get('sent')) {
          return;
        }

        var header = this.get('intl').t("notification.label.".concat(transportNotification.get('notification.type')));
        var content = this.get('intl').t("notification.".concat(transportNotification.get('notification.name'), ".desc"));
        var footer = this.get('intl').t("notification.confirmation");

        if (!confirm("".concat(header, ": ").concat(content, "\n\n").concat(footer))) {
          return;
        }

        transportNotification.save().then(function (n) {
          console.log("transportNotification :: ".concat(n.id));
        }, function (response) {
          return alert(response.errors[0]);
        });
      },
      showSmsStatus: function showSmsStatus(transportNotification) {
        var _this = this;

        var element = this.element;
        var self = this;
        var token = this.get('sessionAccount').get('token');
        console.log(_environment.default.serverURL + '/notifications/' + self.get('transportNotification.id') + '/status');

        _jquery.default.ajax({
          url: _environment.default.serverURL + '/notifications/' + self.get('transportNotification.id') + '/status',
          type: 'GET',
          beforeSend: function beforeSend(xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
          }
        }).then(function (response) {
          if (response.errors) {
            alert(_this.get('intl').t('notification.errors.' + response.errors));
            return;
          }

          self.showModal('transports.modals.show-sms-statuses', response.statuses);
        });
      }
    }
  });

  _exports.default = _default;
});