define("apollo/pods/components/choose-wash-button/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    actions: {
      openChooseWashModal: function openChooseWashModal(transport) {
        var store = this.get('store');
        var self = this;
        var washStep;
        washStep = store.createRecord('step', {
          transport: transport,
          stepTypeName: 'wash',
          orderInTransport: transport.get('steps.length')
        });
        var filteredStepsInTransport = transport.get('steps').filter(function (s) {
          return s.get('stepTypeName') !== 'wash';
        });
        filteredStepsInTransport.pushObject(washStep);
        transport.set('steps', filteredStepsInTransport);
        transport.get('transaction').then(function (transaction) {
          var model = {
            transport: transport,
            washStep: washStep,
            transaction: transaction
          };
          self.showModal('wash.modals.choose-wash', model);
        });
      }
    }
  });

  _exports.default = _default;
});