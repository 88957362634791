define("apollo/helpers/format-resource-date", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    sessionAccount: Ember.inject.service('session-account'),
    compute: function compute(params) {
      var resource = params[0];
      var dateName = params[1];
      var resourceDate = resource.get('resourceDates').find(function (date) {
        return date.get('controlledDateType.name') === dateName;
      });

      if (!resourceDate || !resourceDate.get('date')) {
        return null;
      }

      return moment(resourceDate.get('date')).format("DD.MM.YYYY");
    }
  });

  _exports.default = _default;
});