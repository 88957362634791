define("apollo/pods/components/show-notes/component", ["exports", "jquery", "apollo/pods/components/show-notes/template"], function (_exports, _jquery, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    sessionAccount: Ember.inject.service('session-account'),
    layout: _template.default,
    canAddNote: Ember.computed('', function () {
      return this.get('sessionAccount').hasRole('ROLE_ADD_NOTES');
    }),
    setFocus: Ember.observer('transport.notes', function () {
      this.prepareView(this.element);
    }),
    actions: {
      onShow: function onShow() {
        var transport = this.get('transport');
        this.markNotesAsReadOff(transport);
      },
      addNote: function addNote() {
        var $el = this.findNoteInput(this.element);
        var noteText = $el.val();

        if (!noteText) {
          return;
        }

        var transport = this.get('transport');
        var id = transport.get('id');
        this.addNote(noteText, id);
        this.prepareView(this.element);
      }
    },
    prepareView: function prepareView(el) {
      Ember.run.later(this, function () {
        var $noteInput = this.findNoteInput(el);
        $noteInput.val('');
        $noteInput.focus();
        var $notesDiv = $noteInput.closest('.tooltip').find('.scrollable-body');
        $notesDiv.animate({
          scrollTop: $notesDiv.prop('scrollHeight')
        }, 1000);
      }, 250);
    },
    findNoteInput: function findNoteInput(el) {
      return (0, _jquery.default)(el).closest('.transport-row').find('.note-input');
    }
  });

  _exports.default = _default;
});