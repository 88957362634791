define("apollo/mixins/transports-mixin", ["exports", "jquery", "apollo/config/environment", "apollo/mixins/persistence-mixin"], function (_exports, _jquery, _environment, _persistenceMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_persistenceMixin.default, {
    sessionAccount: Ember.inject.service('session-account'),
    cookies: Ember.inject.service(),
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    createTransportController: Ember.inject.controller('transports/modals/create-transport'),
    getQueryMap: function getQueryMap() {
      var self = this;
      return {
        archived: self.get('archive'),
        transportTypesIds: self.get('transportTypesIds'),
        query: self.get('archiveQuery'),
        start: self.get('filterStart') ? moment(self.get('filterStart')).format() : '',
        stop: self.get('filterStop') ? moment(self.get('filterStop')).format() : '',
        dateFilterMode: self.get('dateFilterMode')
      };
    },
    transportTypesIds: Ember.computed('transportTypes.@each', function () {
      return this.get('transportTypes').map(function (type) {
        return type.get('id');
      });
    }),
    handleUpdate: function handleUpdate(transport) {
      var _this = this;

      var transportId = transport.get('id'); // TODO: Być może da się to kiedyś jakoś zautomatyzować.

      ['products', 'steps', 'checkpoints', 'additionalData'].forEach(function (property) {
        _this.removeDuplicateRecords(transport, property);
      });
      var stepsCopy = transport.get('steps').slice(0);
      stepsCopy.forEach(function (s) {
        if (s !== undefined && s.get('address') !== undefined && s.get('address.content')) {
          if (!s.get('address.id') && s.get('address').unloadRecord) {
            console.debug("Unloading redundant advice for newly saved transport ".concat(transportId, ".."));
            s.get('address').unloadRecord();
          }
        }

        if (s !== undefined && !s.get('id') && s.unloadRecord) {
          console.debug("Unloading redundant step for newly saved transport ".concat(transportId, ".."));
          transport.get('steps').removeObject(s);
          s.unloadRecord();
        } else if (s.get('deliveryDateFrom')._isAMomentObject) {
          s.set('deliveryDateFrom', s.get('deliveryDateFrom').toDate());
        }
      });
      ['products', 'externalCosts', 'additionalData'].forEach(function (property) {
        _this.unloadRedundantRecords(transport, property);
      });
      transport.set('operationsInProgress', false);
      transport.refresh();
    },
    unloadRedundantRecords: function unloadRedundantRecords(transport, propertyName) {
      transport.get(propertyName).then(function (property) {
        var propertyCopy = property.slice();
        propertyCopy.forEach(function (p) {
          if (p !== undefined && !p.get('id')) {
            property.removeObject(p);
            p.unloadRecord();
          }
        });
      });
    },
    externalCostOrderSortFunction: function externalCostOrderSortFunction(a, b) {
      a = a.get('executionDate') ? a.get('executionDate') : a.get('plannedDate');
      b = b.get('executionDate') ? b.get('executionDate') : b.get('plannedDate');
      return a < b ? -1 : a > b ? 1 : 0;
    },
    actions: {
      confirm: function confirm(checkpoint) {
        checkpoint.set('isBeingProcessed', true);
        var checkpointId = checkpoint.get('id');
        (0, _jquery.default)('.confirm-checkpoint-button').button('loading');
        var executionDateString = checkpoint.get('executionDate') ? moment(checkpoint.get('executionDate')).format() : "";
        console.debug("Confirming checkpoint ".concat(checkpointId, " on ").concat(executionDateString, ".."));
        var token = this.get('sessionAccount').get('token');
        var self = this;

        _jquery.default.ajax({
          url: _environment.default.serverURL + '/checkpoints/' + checkpointId + '/confirm',
          data: JSON.stringify({
            executionDate: executionDateString,
            reasonForTheDelayMustBeGiven: checkpoint.get('reasonForTheDelayMustBeGiven'),
            delayReason: checkpoint.get('delayReason'),
            isEdition: checkpoint.get('isEdition')
          }),
          type: 'PUT',
          complete: function complete() {
            checkpoint.set('isBeingProcessed', false);
          },
          beforeSend: function beforeSend(xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
          }
        }).then(function (response) {
          (0, _jquery.default)('.confirm-checkpoint-button').button('reset');

          if (response.error) {
            alert(self.get('intl').t('checkpoints.errors.' + response.error));

            if (response.error === 'CheckpointsSynchronizationError' || response.error === 'CheckpointNotFoundOrDeletedByAnotherTransactionError') {
              (0, _jquery.default)('.modal').modal('hide');
            }
          } else {
            self.store.findRecord('checkpoint', checkpointId).then(function (data) {
              data.get('transport').then(function (t) {
                t.reload();
                console.log("Checkpoint ".concat(checkpointId, " has been confirmed."));
              });
            });
            (0, _jquery.default)('.modal').modal('hide');
          }
        }, function (response) {
          var errorMsg = JSON.parse(response.responseText).errors;
          console.error(errorMsg);
          (0, _jquery.default)('.modal').modal('hide');
          alert(errorMsg);
        });
      },
      revoke: function revoke(checkpoint) {
        var checkpointId = checkpoint.get('id');
        var token = this.get('sessionAccount').get('token');
        console.log("revoke confirmation of checkpoint " + checkpointId);
        var self = this;

        _jquery.default.ajax({
          url: _environment.default.serverURL + '/checkpoints/' + checkpointId + '/revoke',
          type: 'PUT',
          beforeSend: function beforeSend(xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
          }
        }).then(function () {
          self.store.findRecord('checkpoint', checkpointId).then(function (data) {
            data.reload();
          });
          console.log("confirmed checkpoint " + checkpointId);
        });
      },
      addNote: function addNote(noteContent, transportId) {
        console.log("addNote: " + noteContent + ", transportId: " + transportId);
        var self = this;
        var transport = this.store.peekRecord("transport", transportId);
        var note = self.store.createRecord("note", {
          transport: transport,
          note: noteContent
        });
        note.save().then(function (n) {
          transport.get('notes').pushObject(n);
        });
      },
      markNotesAsReadOff: function markNotesAsReadOff(transport) {
        var token = this.get('sessionAccount').get('token');
        transport.set('numberOfUnreadNotes', 0);

        _jquery.default.ajax({
          url: _environment.default.serverURL + '/transports/' + transport.id + '/markNotesAsReadOff',
          type: 'PUT',
          beforeSend: function beforeSend(xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
          }
        }).then(function () {
          transport.get("notes").then(function (notes) {
            notes.reload().then(function () {
              transport.notifyPropertyChange('notes');
            });
          });
        });
      },
      filter: function filter() {
        var self = this;
        this.set('loadingMore', true);
        var queryMap = this.getQueryMap();
        this.get('store').query('transport', queryMap).then(function (transports) {
          self.setProperties({
            model: transports,
            loadingMore: false,
            filterActive: queryMap.query || queryMap.start || queryMap.stop,
            page: 1,
            totalPages: transports.get('meta.totalPages'),
            count: transports.get('meta.count')
          });
        }, function () {
          self.set('loadingMore', false);
          self.set('filterActive', false);
        });
      },
      clearFilter: function clearFilter() {
        this.setProperties({
          archiveQuery: '',
          filterStop: null,
          filterStart: null,
          filterActive: false
        });
        this.send('filter');
      },
      scrollPage: function scrollPage(transportTypeId) {
        (0, _jquery.default)(window).scrollTop($('#' + transportTypeId).offset().top);
      },
      fileUploadSuccess: function fileUploadSuccess(transportId) {
        console.log('fileUploadSuccess: ' + transportId);
        this.store.findRecord("transport", transportId).then(function (data) {
          data.reload();
        });
      },
      deleteFile: function deleteFile(file) {
        console.log('deleteFile: ' + file.id);
        var transport = file.get('transport');
        file.destroyRecord().then(function () {
          transport.then(function (t) {
            return t.reload();
          });
        });
      }
    }
  });

  _exports.default = _default;
});