define("apollo/pods/transports/index/controller", ["exports", "apollo/mixins/transports-mixin", "apollo/mixins/modal-mixin"], function (_exports, _transportsMixin, _modalMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_transportsMixin.default, _modalMixin.default, {
    sessionAccount: Ember.inject.service('session-account'),
    searchParams: Ember.computed('model', function () {
      var currentUser = this.get('sessionAccount').get('currentUser');

      if (currentUser.get('roleGroup.name') === 'TRADESMAN') {
        return {
          filterMode: 'user',
          query: '',
          filters: Ember.A(),
          transportTypesIds: this.get('allAvailableTransportTypes').getEach('id')
        };
      }

      if (currentUser.get('roleGroup.name') === 'FORWARDER') {
        return {
          filterMode: 'group',
          personsGroupId: currentUser.get('userGroup.id'),
          query: '',
          filters: Ember.A(),
          transportTypesIds: this.get('allAvailableTransportTypes').getEach('id')
        };
      }

      return {
        query: '',
        filters: Ember.A(),
        transportTypesIds: this.get('allAvailableTransportTypes').getEach('id')
      };
    }),
    allAvailableTransportTypes: Ember.computed('model', {
      get: function get() {
        if (this._allAvailableTransportTypes) {
          return this._allAvailableTransportTypes;
        }

        return [];
      },
      set: function set(key, value) {
        return this._allAvailableTransportTypes = value;
      }
    })
  });

  _exports.default = _default;
});