define("apollo/mixins/checkpoints-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    store: Ember.inject.service(),
    canBeConfirmedInRemoteTransport: Ember.computed('transport.firstUnconfirmedCheckpoint', function () {
      return !(this.get('transport.isFromSap') && !this.get('transport.firstUnconfirmedCheckpoint.task.canBeConfirmedInRemoteTransport'));
    }),
    checkpointCanBeApproved: Ember.computed({
      get: function get() {
        if (this._checkpointCanBeApproved) {
          return this._checkpointCanBeApproved;
        }
      },
      set: function set(key, value) {
        return this._checkpointCanBeApproved = value;
      }
    }),
    checkpointNeedsAdviceError: Ember.computed(function () {
      return this.get('transport.firstUnconfirmedCheckpoint.task.shouldBeApprovedWithAdvice') && !this.get('transport.advice.id');
    }),
    washCheckpointSetting: Ember.computed(function () {
      return this.get('store').peekAll('setting').filterBy('name', 'CHECKPOINT_CAN_BE_APPROVED_AFTER_SELECTING_WASH').get('firstObject');
    }),
    mechanicCheckpointSetting: Ember.computed(function () {
      return this.get('store').peekAll('setting').filterBy('name', 'CHECKPOINT_CAN_BE_APPROVED_AFTER_SELECTING_MECHANIC').get('firstObject');
    }),
    servicePlaceCheckpointSetting: Ember.computed(function () {
      return this.get('store').peekAll('setting').filterBy('name', 'CHECKPOINT_CAN_BE_APPROVED_AFTER_SELECTING_SERVICE_PLACE').get('firstObject');
    }),
    setCheckpointCanBeApproved: function setCheckpointCanBeApproved() {
      var self = this;
      var checkpoint = self.get('transport.firstUnconfirmedCheckpoint');
      var checkpointName = self.get('transport.firstUnconfirmedCheckpoint.name');
      self.set('checkpointCanBeApproved', false);

      if (!checkpointName) {
        return;
      }

      var washCheckpointSetting = this.get('washCheckpointSetting');
      var mechanicCheckpointSetting = this.get('mechanicCheckpointSetting');
      var servicePlaceCheckpointSetting = this.get('servicePlaceCheckpointSetting');

      if (checkpointName === washCheckpointSetting.get('value') && !self.get('transport.washStep.id')) {
        console.debug("Pierwszy niepotwierdzony status (".concat(checkpointName, ") wymaga dodania myjni!"));
        self.set('checkpointCanBeApproved', false);
      } else if (checkpointName === mechanicCheckpointSetting.get('value')) {
        var transportTypeName = self.get('transport.transportType.name');

        if (transportTypeName !== 'SERVICE') {
          self.set('checkpointCanBeApproved', true);
          return;
        }

        var isExternal = self.get('transport.steps.firstObject.company.isExternal');
        var mechanicId = self.get('transport.steps.firstObject.service.mechanic.id');

        if (isExternal || !isExternal && mechanicId) {
          self.set('checkpointCanBeApproved', true);
        } else if (!isExternal && !mechanicId) {
          console.debug("Pierwszy niepotwierdzony status (".concat(checkpointName, ") wymaga dodania mechanika!"));
          self.set('checkpointCanBeApproved', false);
        }
      } else if (checkpointName === servicePlaceCheckpointSetting.get('value')) {
        var _transportTypeName = self.get('transport.transportType.name');

        var canBeApproved = _transportTypeName !== 'SERVICE' || self.get('transport.steps.firstObject.company.id');
        self.set('checkpointCanBeApproved', canBeApproved);
      } else {
        self.set('checkpointCanBeApproved', true);
      }

      if (checkpoint && checkpoint.get('task.shouldBeApprovedWithAdvice') && !this.get('transport.advice.id')) {
        self.set('checkpointCanBeApproved', false);
      }
    }
  });

  _exports.default = _default;
});