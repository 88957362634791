define("apollo/pods/invitations/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "j06OSKo1",
    "block": "{\"symbols\":[\"invitation\",\"invitation\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"container container-tight\"],[8],[0,\"\\n    \"],[7,\"h1\",true],[8],[0,\"\\n        \"],[1,[28,\"t\",[\"invitations.label\"],null],false],[0,\"\\n\\n        \"],[7,\"span\",true],[10,\"class\",\"pull-right\"],[8],[0,\"\\n\"],[4,\"link-to\",null,[[\"class\",\"route\"],[\"btn btn-info\",\"invitations.create\"]],{\"statements\":[[0,\"                \"],[7,\"i\",true],[10,\"class\",\"fa fa-plus\"],[8],[9],[0,\"\\n                \"],[1,[28,\"t\",[\"invitation.invite\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"        \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n        \"],[7,\"h3\",true],[8],[0,\"\\n            \"],[1,[28,\"t\",[\"invitations.received\"],null],false],[0,\"\\n        \"],[9],[0,\"\\n\\n\"],[4,\"each\",[[24,[\"receivedInvitations\"]]],null,{\"statements\":[[0,\"            \"],[1,[28,\"invitation-section\",null,[[\"invitation\",\"received\"],[[23,2,[]],true]]],false],[0,\"\\n\"]],\"parameters\":[2]},{\"statements\":[[0,\"            \"],[1,[28,\"t\",[\"invitations.emptyReceived\"],null],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n        \"],[7,\"h3\",true],[8],[0,\"\\n            \"],[1,[28,\"t\",[\"invitations.sent\"],null],false],[0,\"\\n        \"],[9],[0,\"\\n\\n\"],[4,\"each\",[[24,[\"sentInvitations\"]]],null,{\"statements\":[[0,\"            \"],[1,[28,\"invitation-section\",null,[[\"invitation\"],[[23,1,[]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[0,\"            \"],[1,[28,\"t\",[\"invitations.emptySent\"],null],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/invitations/index/template.hbs"
    }
  });

  _exports.default = _default;
});