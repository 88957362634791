define("apollo/pods/components/transport/checkpoints-section/component", ["exports", "apollo/mixins/checkpoints-mixin"], function (_exports, _checkpointsMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_checkpointsMixin.default, {
    sessionAccount: Ember.inject.service('session-account'),
    store: Ember.inject.service(),
    cookies: Ember.inject.service(),
    canBeConfirmedInRemoteTransport: Ember.computed('transport.firstUnconfirmedCheckpoint', function () {
      return !(this.get('transport.remoteId') && this.get('transport.isFromSap'));
    }),
    checkpointCanBeApproved: Ember.computed(function () {}),
    actions: {
      confirm: function confirm(checkpoint) {
        this.showModal('transports.modals.confirm-checkpoint', checkpoint);
      }
    },
    init: function init() {
      this._super.apply(this, arguments);

      this.setCheckpointCanBeApproved();
    }
  });

  _exports.default = _default;
});