define("apollo/pods/register/after-register/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2afBy/JK",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"after-registration-div\"],[8],[0,\"\\n    \"],[7,\"h1\",true],[8],[1,[28,\"t\",[\"afterRegistration.label\"],null],false],[9],[0,\"\\n\\n    \"],[7,\"div\",true],[8],[0,\"\\n        \"],[7,\"h3\",true],[8],[0,\"\\n            \"],[1,[28,\"t\",[\"afterRegistration.title\"],null],false],[0,\"\\n        \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\\n    \"],[7,\"p\",true],[10,\"class\",\"pull-right padding-top-big\"],[8],[0,\"\\n        \"],[4,\"link-to\",null,[[\"class\",\"route\"],[\"btn btn-default\",\"invitations\"]],{\"statements\":[[1,[28,\"t\",[\"afterRegistration.invitationButton\"],null],false]],\"parameters\":[]},null],[0,\"\\n    \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/register/after-register/template.hbs"
    }
  });

  _exports.default = _default;
});