define("apollo/helpers/group-components", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function groupComponentsHelper(options) {
    var data = options.data,
        fn = options.fn,
        view = data.view,
        childView;
    childView = view.createChildView(Ember._MetamorphView, {
      context: Ember.get(view, 'context'),
      template: function template(context, options) {
        options.data.insideGroup = true;
        return fn(context, options);
      }
    });
    view.appendChild(childView);
  }

  var _default = groupComponentsHelper;
  _exports.default = _default;
});