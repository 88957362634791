define("apollo/pods/forbidden/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    redirectToWrongUrlPage: function redirectToWrongUrlPage() {
      this.transitionTo('forbidden');
    }
  });

  _exports.default = _default;
});