define("apollo/pods/users/edit/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    sessionAccount: Ember.inject.service('session-account'),
    access: ['ROLE_EDIT_USER'],
    model: function model(params, transition) {
      var currentUser = this.get('sessionAccount').get('currentUser');
      var hasAnyOfTheRoles = this.get('sessionAccount').hasAnyOfTheRoles(this.get('access'));

      if (params.user_id === currentUser.get('id')) {
        return this.store.peekRecord('user', params.user_id);
      } else if (hasAnyOfTheRoles) {
        return this.store.findRecord('user', params.user_id);
      } else {
        transition.abort();
        this.transitionTo('forbidden');
      }
    },
    renderTemplate: function renderTemplate() {
      this.render('users.create');
    },
    setupController: function setupController(controller, model) {
      this.controllerFor('users.create').setProperties({
        model: model
      });
    },
    actions: {
      willTransition: function willTransition() {
        this.controllerFor('users.create').setProperties({
          'successMessage': null,
          'model.changePassword': false,
          'model.password': '',
          'model.newPassword': '',
          'model.repeatedNewPassword': ''
        });
        return true;
      }
    }
  });

  _exports.default = _default;
});