define("apollo/pods/invitations/create/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    sessionAccount: Ember.inject.service('session-account'),
    store: Ember.inject.service(),
    roleGroups: Ember.computed('model.invitation.partnershipType', 'sessionAccount.currentUser.company.groups.@each', function () {
      var self = this;
      var partnershipType = this.get('model.invitation').get('partnershipType');
      var roleGroups;

      if (partnershipType.get('id')) {
        roleGroups = partnershipType.get('availableRoleGroups');
      } else {
        roleGroups = this.get('sessionAccount.currentUser.company.groups');
      }

      if (roleGroups.length === 1) {
        var roleGroupId = roleGroups.get('firstObject.id');
        self.send('selectRoleGroup', roleGroupId);
      }

      return roleGroups;
    }),
    actions: {
      selectPartnershipType: function selectPartnershipType(id) {
        var self = this;
        var type = id ? this.store.peekRecord("partnershipType", id) : null;
        this.get('model.invitation').set('partnershipType', type);
      },
      selectRoleGroup: function selectRoleGroup(id) {
        this.get('model.invitation').set('roleGroup', this.store.peekRecord("roleGroup", id));
      },
      sendAnInvitation: function sendAnInvitation() {
        var self = this;
        this.set('invitationSuccess', false);
        this.get('model.invitation').save().then(function () {
          self.set('invitationSuccess', true);
        }).catch(function (response) {
          self.set('invitationError', response.errors[0]);
        });
      },
      cancel: function cancel() {
        this.get('model.invitation').rollbackAttributes();
        this.get('model.invitation').unloadRecord();
        this.transitionToRoute('invitations');
      }
    }
  });

  _exports.default = _default;
});