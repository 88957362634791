define("apollo/pods/product/model", ["exports", "ember-data-copyable", "ember-data", "apollo/mixins/model-validator"], function (_exports, _emberDataCopyable, _emberData, _modelValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend(_modelValidator.default, _emberDataCopyable.default, {
    name: _emberData.default.attr('string'),
    symbol: _emberData.default.attr('string'),
    totalGrossTonnage: _emberData.default.attr('string'),
    totalNetTonnage: _emberData.default.attr('string'),
    validations: {
      symbol: {
        presence: {
          message: 'blank'
        }
      },
      totalGrossTonnage: {
        presence: {
          message: 'blank'
        }
      }
    }
  });

  _exports.default = _default;
});