define("apollo/pods/users/show/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    sessionAccount: Ember.inject.service('session-account'),
    access: ['ROLE_EDIT_USER'],
    model: function model(params, transition) {
      var currentUser = this.get('sessionAccount').get('currentUser');
      var hasAnyOfTheRoles = this.get('sessionAccount').hasAnyOfTheRoles(this.get('access'));

      if (params.user_id === currentUser.get('id') || hasAnyOfTheRoles) {
        return this.store.findRecord('user', params.user_id);
      } else {
        transition.abort();
        this.transitionTo('forbidden');
      }
    }
  });

  _exports.default = _default;
});