define("apollo/mixins/autocomplete-utils-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    store: Ember.inject.service(),
    setItem: function setItem(itemName, className, itemId) {
      var _this = this;

      this.get('store').findRecord(className, itemId).then(function (item) {
        _this.set(itemName, item);
      });
    },
    clearItem: function clearItem(itemName) {
      this.set(itemName, {});
    }
  });

  _exports.default = _default;
});