define("apollo/pods/components/log-change/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kIjVH8t5",
    "block": "{\"symbols\":[],\"statements\":[[0,\"Zmiana pola\\n\\n\"],[7,\"span\",true],[10,\"class\",\"bold\"],[8],[0,\"\\n    \"],[1,[22,\"label\"],false],[0,\"\\n\"],[9],[0,\"\\n\\n::\\n\\n\"],[7,\"span\",true],[10,\"class\",\"old-value\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"log\",\"oldValue\"]]],null,{\"statements\":[[0,\"        \"],[1,[24,[\"log\",\"oldValueFormat\"]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        --\\n\"]],\"parameters\":[]}],[9],[0,\"\\n\\n\"],[7,\"i\",true],[10,\"class\",\"fa fa-long-arrow-right\"],[8],[9],[0,\"\\n\\n\"],[7,\"span\",true],[10,\"class\",\"new-value\"],[8],[0,\"\\n    \"],[1,[24,[\"log\",\"newValueFormat\"]],false],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/log-change/template.hbs"
    }
  });

  _exports.default = _default;
});