define("apollo/controllers/application", ["exports", "apollo/mixins/modal-mixin"], function (_exports, _modalMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_modalMixin.default, {
    session: Ember.inject.service('session'),
    sessionAccount: Ember.inject.service('session-account'),
    cookies: Ember.inject.service(),
    partnershipTypes: Ember.computed(function () {
      var currentUserRoleGroupName = this.get('sessionAccount').get('currentUser.roleGroup.name');

      if (currentUserRoleGroupName === 'TECHNICAL_EMPLOYEE') {
        return this.store.peekAll('partnershipType').filter(function (p) {
          return p.get('name') === 'SERVICE' || p.get('name') === 'CARRIER';
        });
      } else {
        return this.store.peekAll('partnershipType');
      }
    }).readOnly(),
    partnerships: Ember.computed(function () {
      return this.store.peekAll('partnership');
    }).readOnly(),
    setVisibilityButtons: Ember.observer('init', 'principal.transportTypes@each', function () {
      var self = this;
      this.get('principal').then(function (p) {
        p.get('transportTypes').filterBy('canBeSeenByRoleGroup').map(function (tt) {
          if (tt.get('typeGroup') === 'TRANSPORT') {
            self.set('showTransportListButton', true);
            self.set('transportType', tt);
          }

          if (tt.get('typeGroup') === 'SERVICE') {
            self.set('showServiceListButton', true);
          }
        });
      });
    }),
    principal: Ember.computed(function () {
      return this.get('store').findRecord('company', this.get('cookies').read('principalId'));
    }),
    backgroundImage: Ember.computed(function () {
      var numberOfBackgrounds = 4;
      var rand = Math.floor(Math.random() * numberOfBackgrounds) + 1;
      return '/assets/images/backgrounds/' + rand + '.jpeg';
    }),
    canPlanTransports: Ember.computed('principal.transportTypes.@each', function () {
      var transportTypes = this.get('principal').get('transportTypes');
      return transportTypes ? transportTypes.findBy('canBeCreatedByRoleGroup', true) : false;
    }),
    today: Ember.computed(function () {
      return new Date();
    }),
    externalCostOrdersFeatureEnabled: Ember.computed('', function () {
      return this.get('sessionAccount').getSettingValue('EXTERNAL_COST_ORDERS_FEATURE_ENABLED') === "true";
    }),
    actions: {
      invalidateSession: function invalidateSession() {
        var _this = this;

        console.log("User logged out.");
        this.get('session').invalidate(this.get('sessionAccount')).then(function () {
          return _this.transitionToRoute('auth.login');
        });
      },
      setPrincipalAndRefreshCurrentRoute: function setPrincipalAndRefreshCurrentRoute(principal) {
        this.get('cookies').write('principalId', principal.get('id'));
        this.set('principal', principal);
        this.get('target.router').refresh();
      }
    }
  });

  _exports.default = _default;
});