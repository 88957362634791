define("apollo/helpers/find-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.findGroup = findGroup;
  _exports.default = void 0;

  function findGroup(params) {
    var key = params[0];
    var param1 = params[1];

    if (!param1) {
      return null;
    }

    return param1.keys().next().value === key;
  }

  var _default = Ember.Helper.helper(findGroup);

  _exports.default = _default;
});