define("apollo/pods/components/external-costs/component", ["exports", "jquery", "apollo/config/environment", "ember-data"], function (_exports, _jquery, _environment, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    intl: Ember.inject.service(),
    externalCostTypeMap: {
      AUTOMATIC_WASHING_COST: 'AUTOMATIC_WASHING',
      FREIGHT_COSTS: 'FREIGHT'
    },
    costTypeName: Ember.computed('category', function () {
      return this.get('externalCostTypeMap')[this.get('category')];
    }),
    externalCost: Ember.computed({
      get: function get() {
        if (this._externalCost) {
          return this._externalCost;
        }

        return this.get('store').createRecord('external-cost', {});
      },
      set: function set(key, value) {
        return this._externalCost = value;
      }
    }),
    externalCosts: Ember.computed('transport.id', function () {
      var _this = this;

      if (!this.get('transport.id')) {
        return [];
      }

      return this.get('transport.externalCosts').filter(function (ec) {
        return ec.get('externalCostType.category') === _this.get('category');
      });
    }),
    isFreightOrAutomaticCategory: Ember.computed('transport.id', function () {
      var category = this.get('category');
      return category === 'AUTOMATIC_WASHING_COST' || category === 'FREIGHT_COSTS';
    }),
    showGenerateCostButton: Ember.computed('category', 'transport.wash.id', 'externalCosts.[]', function () {
      return this.get('externalCosts.length') === 0 && (this.get('category') === 'FREIGHT_COSTS' || this.get('category') === 'AUTOMATIC_WASHING_COST' && this.get('transport.wash.id'));
    }),
    actions: {
      createExternalCost: function createExternalCost() {
        this.set('isCreationExternalCost', true);
      },
      editCost: function editCost(costModel) {
        this.send('createExternalCost');
        this.set('externalCost', costModel);
      },
      removeCost: function removeCost(costModel) {
        var _this2 = this;

        if (!costModel.get('isExternalOrder') || confirm(this.get('intl').t('external-cost.order.sureToDeleteCost'))) {
          costModel.destroyRecord().then(function () {
            _this2.notifyPropertyChange('transport.id');
          }).catch(function (response) {
            alert(response.errors.join("\n"));
          });
        }
      },
      closeCreateExternalCostArea: function closeCreateExternalCostArea() {
        this.notifyPropertyChange('transport.id');
        this.set('externalCost', this.get('store').createRecord('external-cost', {}));
        this.set('isCreationExternalCost', false);
      },
      generateCost: function generateCost(costTypeName) {
        var _this3 = this;

        var transportId = this.get('transport.id');
        var token = this.get('sessionAccount').get('token');

        _jquery.default.ajax({
          url: _environment.default.serverURL + "/external-costs/".concat(transportId, "/generateAutomaticCost"),
          type: 'PUT',
          data: JSON.stringify({
            costTypeName: costTypeName
          }),
          beforeSend: function beforeSend(xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
          }
        }).then(function (json) {
          if (!json.externalCostId) {
            return;
          }

          _this3.get('store').findRecord('externalCost', json.externalCostId, {
            reload: true
          }).then(function (externalCost) {
            return _this3.get('externalCosts').pushObject(externalCost);
          });
        }, function (response) {
          if (response.errors) {
            console.log(response.errors);
          }
        });
      }
    }
  });

  _exports.default = _default;
});