define("apollo/pods/containers/create/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    sessionAccount: Ember.inject.service('session-account'),
    access: ['ROLE_CREATE_VEHICLE'],
    beforeModel: function beforeModel(transition) {
      if (!this.get('sessionAccount').hasAnyOfTheRoles(this.get('access'))) {
        transition.abort();
        this.transitionTo('forbidden');
      }
    },
    model: function model(params) {
      var container = this.store.createRecord('container', {
        enabled: true
      });
      var carrierId = params.carrierId || this.get('sessionAccount.currentUser.company.id');
      return Ember.RSVP.hash({
        vehicle: container,
        carrierId: carrierId
      });
    },
    renderTemplate: function renderTemplate() {
      this.render('containers.create', {
        controller: 'trailers.create'
      });
    },
    setupController: function setupController(controller, model) {
      var _this = this;

      this.get('store').findRecord('company', model.carrierId).then(function (company) {
        console.log("Przygotowujemy formularz pojazdu dla przewo\u017Anika ".concat(company.get('name'), ".."));
        model.vehicle.set('owner', company);

        _this.controllerFor('trailers.create').setProperties({
          model: model,
          isEditing: true
        });
      });
    },
    actions: {
      willTransition: function willTransition() {
        this.controllerFor('trailers.create').setProperties({
          successMessage: null,
          errors: []
        });
        this.controllerFor('trailers.create').get('model.vehicle').unloadRecord();
      }
    }
  });

  _exports.default = _default;
});