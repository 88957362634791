define("apollo/pods/components/transport/documents-section/component", ["exports", "jquery", "apollo/mixins/attachment-mixin"], function (_exports, _jquery, _attachmentMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_attachmentMixin.default, {
    actions: {
      addFiles: function addFiles(files) {
        var attachments = this.get('attachments');
        files.forEach(function (file) {
          if (!attachments.findBy('name', file.name)) {
            attachments.pushObject(file);
          }
        });
      },
      removeFile: function removeFile(file) {
        var attachments = this.get('attachments');
        this.set('attachments', attachments.rejectBy('name', file.name));
      },
      removeExistingFile: function removeExistingFile(file) {
        console.log("removeExistingFile..");
        this.get('existingFilesToRemove').push(file.get('id'));
        (0, _jquery.default)("#file-" + file.get('id')).css("text-decoration", "line-through");
      }
    }
  });

  _exports.default = _default;
});