define("apollo/pods/components/advice-form-warnings/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    classNames: ['col-xs-12'],
    showMore: false,
    MIN_WARNINGS_DISPLAYED: 2,
    showMoreLabel: Ember.computed('showMore', function () {
      var labelName = this.get('showMore') ? 'less' : 'more';
      return this.get('intl').t("common.".concat(labelName));
    }),
    displayedWarnings: Ember.computed('warnings', 'showMore', function () {
      var warnings = this.get('warnings');

      if (this.get('showMore')) {
        return warnings;
      } else if (warnings) {
        return warnings.slice(0, this.get('MIN_WARNINGS_DISPLAYED'));
      } else {
        return [];
      }
    }),
    actions: {
      toggleShowMore: function toggleShowMore() {
        this.toggleProperty('showMore');
      }
    }
  });

  _exports.default = _default;
});