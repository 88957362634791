define("apollo/pods/carrier/modals/choose-carrier/controller", ["exports", "apollo/config/environment", "jquery", "apollo/mixins/advice-utils-mixin", "apollo/mixins/persistence-mixin", "apollo/mixins/autocomplete-utils-mixin", "apollo/mixins/input-utils-mixin", "apollo/mixins/modal-mixin"], function (_exports, _environment, _jquery, _adviceUtilsMixin, _persistenceMixin, _autocompleteUtilsMixin, _inputUtilsMixin, _modalMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_adviceUtilsMixin.default, _persistenceMixin.default, _autocompleteUtilsMixin.default, _inputUtilsMixin.default, _modalMixin.default, {
    store: Ember.inject.service(),
    cookies: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    errors: [],
    isAcceptedLowerThanMinimalMargin: false,
    setup: Ember.observer('model.id', function () {
      if (!this.get('model')) {
        return;
      }

      this.setCarrierCompanyCopy();
      this.setCurrencies();
      this.setValuesForService();
    }),
    setCarrierCompanyCopy: function setCarrierCompanyCopy() {
      this.set('carrierCompanyCopy', this.get('store').peekRecord('company', this.get('cookies').read('principalId')));
    },
    setCurrencies: function setCurrencies() {
      var self = this;
      var currencies = this.get('store').peekAll('currency').sortBy('idx');
      self.set('currencies', currencies.sortBy('idx'));

      if (!self.get('model').get('transaction').get('currencyOfFreight.id')) {
        self.get('model').get('transaction').set('currencyOfFreight', currencies.get('firstObject'));
      }
    },
    setValuesForService: function setValuesForService() {
      var _this = this;

      var c = this.get('carrierCompanyCopy');

      if (c && c.get('isPrincipal') && this.get('model.transaction.priceOfService')) {
        this.set('model.transaction.priceOfFreight', this.get('model.transaction.priceOfService'));
      } else {
        this.set('model.transaction.priceOfFreight', null);
      }

      if (c && c.get('isPrincipal') && this.get('model.transaction.currency.id')) {
        this.set('model.transaction.currencyOfFreight', this.get('model.transaction.currency'));
      } else {
        var currency = this.get('store').peekAll('currency').filter(function (c) {
          return c.get("symbol") === _this.get('model.transaction.currency.symbol');
        }).get('firstObject');
        this.set('model.transaction.currencyOfFreight', currency);
      }
    },
    profitMarginTooLow: Ember.computed('model.transaction.{profitMargin,minimalProfitMargin}', 'carrierCompanyCopy.isPrincipal', function () {
      if (this.get('carrierCompanyCopy.isPrincipal') || this.get('model.transaction.profitMargin') === null) {
        return false;
      }

      return parseFloat(this.get('model.transaction.profitMargin')) < parseFloat(this.get('model.transaction.minimalProfitMargin'));
    }),
    resourceDateWarnings: Ember.computed('date', 'carrierCompanyCopy.resourceDates.@each.date', function () {
      var _this2 = this;

      var resourceDates = this.get('carrierCompanyCopy.resourceDates');

      if (!resourceDates) {
        return;
      }

      return resourceDates.filter(function (rd) {
        return rd.get('date');
      }).map(function (rd) {
        var date = moment(rd.get('date'));
        var today = moment(new Date().setHours(0, 0, 0, 0));
        var difference = date.diff(today, 'days');
        var adviceWarning = rd.get('controlledDateType.daysBeforeFirstWarning');

        if (difference > adviceWarning) {
          return null;
        } else {
          var params = {
            resource: "".concat(_this2.get('intl').t('resources.of.DOCUMENT'), " ").concat(rd.get('identifier') ? " ".concat(rd.get('identifier')) : ""),
            document: rd.get('controlledDateType.label'),
            date: date.format('DD.MM.YYYY')
          };

          if (difference < 0) {
            return {
              className: 'text-danger',
              text: _this2.get('intl').t('documentsExpirationDate.expiredOn', params),
              date: date
            };
          }

          if (difference < adviceWarning) {
            return {
              className: 'text-info',
              text: _this2.get('intl').t('documentsExpirationDate.willExpireOn', params),
              date: date
            };
          }
        }
      }).filter(function (warning) {
        return warning;
      });
    }),
    actions: {
      chooseCarrier: function chooseCarrier(transportModel) {
        var self = this;
        transportModel.set('carrierCompany', this.get('carrierCompanyCopy'));

        if (!self.customValidate(transportModel)) {
          transportModel.set('carrierCompany', null);
          return;
        }

        if (transportModel.get('transaction.profitMarginTooLow') && !this.get('isAcceptedLowerThanMinimalMargin')) {
          return;
        }

        transportModel.save().then(function () {
          self.set('errors', []);
          transportModel.get('transaction._internalModel').clearErrorMessages();
          self.set('isAcceptedLowerThanMinimalMargin', false);

          _jquery.default.ajax({
            type: "POST",
            url: _environment.default.serverURL + '/checkpoints/confirmSelectCarrier',
            data: {
              id: transportModel.get('id')
            },
            beforeSend: function beforeSend(xhr) {
              xhr.setRequestHeader("Authorization", "Bearer " + self.get('sessionAccount').get("token"));
            }
          }).then(function () {
            transportModel.refresh();
          });
        }).catch(function (response) {
          self.set('errors', response.errors);
        });
        (0, _jquery.default)('.modal').modal('hide');
      },
      setCarrierAndClearErrors: function setCarrierAndClearErrors(carrierCompany) {
        var _this3 = this;

        this.get('store').findRecord('company', carrierCompany.id).then(function (carrierCompany) {
          _this3.set('carrierCompanyCopy', carrierCompany);

          _this3.get('model.transaction._internalModel').clearErrorMessages();

          _this3.set('isAcceptedLowerThanMinimalMargin', false);

          _this3.setValuesForService();
        });
      },
      removeCarrier: function removeCarrier() {
        this.clearItem('carrierCompanyCopy');
      },
      cancel: function cancel() {
        this.set('carrierCompanyCopy', null);
        this.get('model.transaction._internalModel').clearErrorMessages();
        this.set('isAcceptedLowerThanMinimalMargin', false);
        this.set('carriers', []);
        this.set('model', null);
        (0, _jquery.default)('div.fc-scroller').scrollTop('0px');
        (0, _jquery.default)('.modal').modal('hide');
      }
    },
    customValidate: function customValidate(transportModel) {
      var validates = true;

      if (!this.get('isPrincipalCompany')) {
        //get('errors').add() nie działa gdy errors jest puste dlatego najpierw musimy dodać jakikolwiek błąd
        var priceOfFreight = transportModel.get('transaction.priceOfFreight');

        if (!priceOfFreight || !this.isCorrectPrice(priceOfFreight) || !transportModel.get('transaction.currencyOfFreight.symbol')) {
          var error = 'transactionHasSomeErrors';
          transportModel.get('transaction').get('errors').pushObject(error);
          validates = false;
        }

        if (!priceOfFreight || !this.isCorrectPrice(priceOfFreight)) {
          transportModel.get('transaction').get('errors').add('priceOfFreight', 'incorrectNumber');
        }

        if (!transportModel.get('transaction.currencyOfFreight.symbol')) {
          transportModel.get('transaction').get('errors').add('currencyOfFreight', 'blank');
        }

        if (!transportModel.get('carrierCompany.id')) {
          transportModel.get('transaction').get('errors').add('carrierCompany', 'blank');
        }
      }

      transportModel.get('steps').forEach(function (s) {
        if (!s.validate()) {
          validates = false;
        }
      });
      return validates;
    }
  });

  _exports.default = _default;
});