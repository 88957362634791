define("apollo/mixins/vehicle-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    store: Ember.inject.service(),
    cookies: Ember.inject.service(),
    isOwnerExternalCompany: Ember.computed('vehicle.{isExternalResource,owner.isExternal}', function () {
      return this.get('vehicle.isExternalResource') ? this.get('vehicle.isExternalResource') : this.get('vehicle.owner.isExternal');
    }),
    disabledOwnerSelect: Ember.computed(function () {
      return false;
    }),
    disabledDriverSelect: Ember.computed('vehicle.vehicleType', function () {
      return this.get('vehicle.vehicleType') === "TRAILER";
    }),
    actions: {
      setCarTypeGroup: function setCarTypeGroup() {
        var _this = this;

        this.get('store').findAll('carTypeGroup').then(function (c) {
          return _this.set('carTypeGroups', c);
        });
      },
      showModal: function showModal(template, transport) {
        transport.set('carrierCompany', this.get('vehicle.owner'));

        if (this.get('vehicle.vehicleType') === "TRUCK") {
          transport.set('advice.truck', this.get('vehicle'));
        } else {
          transport.set('advice.trailer', this.get('vehicle'));
        }

        this.showModal(template, transport);
      },
      changeResourceDate: function changeResourceDate(vehicle, dateField, newDate) {
        var resourceDate = vehicle.get('resourceDates').find(function (rd) {
          return rd.get('controlledDateType.name') === dateField;
        });

        if (!resourceDate) {
          var type = this.get('store').peekAll('controlledDateType').find(function (rdt) {
            return rdt.get('name') === dateField;
          });
          resourceDate = this.get('store').createRecord('resourceDate', {
            controlledDateType: type,
            vehicle: vehicle
          });
        }

        resourceDate.set('date', newDate);
      },
      toggleProperty: function toggleProperty(propertyName) {
        this.get('vehicle').toggleProperty(propertyName);
      }
    }
  });

  _exports.default = _default;
});