define("apollo/pods/companies/edit/route", ["exports", "jquery", "ember-simple-auth/mixins/authenticated-route-mixin", "apollo/config/environment"], function (_exports, _jquery, _authenticatedRouteMixin, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    sessionAccount: Ember.inject.service('session-account'),
    session: Ember.inject.service('session'),
    access: ['ROLE_EDIT_COMPANY', 'ROLE_CREATE_RESOURCES', 'ROLE_COMPANY_CARD_TAB'],
    beforeModel: function beforeModel(transition) {
      if (!this.get('sessionAccount').hasAnyOfTheRoles(this.get('access'))) {
        transition.abort();
        this.transitionTo('forbidden');
      }
    },
    model: function model(params) {
      var _this = this;

      var self = this;
      var countries = this.store.peekAll('country');
      var partnerships = this.store.peekAll('pods.partnership');
      return this.store.findRecord('company', params.id, {
        reload: true
      }).then(function (c) {
        var queryParams = {
          archived: false,
          carrierId: params.id,
          noPagination: true
        };

        var token = _this.get('session.data.authenticated.token');

        return _jquery.default.ajax({
          url: _environment.default.serverURL + '/transports/index',
          data: JSON.stringify(queryParams),
          type: 'POST',
          contentType: 'application/json',
          beforeSend: function beforeSend(xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
          }
        }).then(function (response) {
          // Dodanie do store całego payload z /transports/index powoduje nadpisanie listy pojazdów firmy - na
          // liście zasobów przewoźnika wyświetlą się tylko pojazdy, które zostały przysłane razem z transportami.
          // Chcemy tylko dane zleceń, które są przypisane do kierowców i pojazdów
          self.get('store').pushPayload({
            transports: response.transports,
            advices: response.advices,
            steps: response.steps,
            addresses: response.addresses,
            products: response.products,
            checkpoints: response.checkpoints
          });
          self.set('transports', []);
          response.transports.forEach(function (t) {
            var transport = self.get('store').peekRecord('transport', t.id);

            if (transport.get('advice.id')) {
              self.get('transports').pushObject(transport);
            }
          });

          if (c.get('addresses.length') === 0) {
            var address = self.store.createRecord('address', {
              country: c.get('country'),
              isHeadquarter: true
            });
            c.get('addresses').pushObject(address);
          }

          return Ember.RSVP.hash({
            countries: countries,
            partnerships: partnerships,
            company: c,
            transports: self.get('transports')
          });
        });
      });
    },
    afterModel: function afterModel(model) {
      var company = model.company;

      if (company.get('addresses.length') === 0) {
        var address = this.store.createRecord('address', {
          country: company.get('country'),
          isHeadquarter: true
        });
        company.get('addresses').pushObject(address);
      }
    },
    renderTemplate: function renderTemplate() {
      this.render('companies.edit', {
        controller: 'companies.create'
      });
    },
    setupController: function setupController(controller, model) {
      this.controllerFor('companies.create').setProperties({
        model: model,
        isEditing: true
      });
    },
    actions: {
      willTransition: function willTransition() {
        this.controllerFor('companies.create').setProperties({
          errors: [],
          attachments: [],
          successMessage: null,
          showOrderHistory: false,
          showResourcesHistory: false,
          historyLogs: []
        });
      }
    }
  });

  _exports.default = _default;
});