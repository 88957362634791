define("apollo/pods/company-email/model", ["exports", "ember-data", "apollo/mixins/model-validator"], function (_exports, _emberData, _modelValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend(_modelValidator.default, {
    company: _emberData.default.belongsTo('company'),
    email: _emberData.default.attr('string'),
    validations: {
      email: {
        email: {
          message: 'notValidEmail'
        }
      }
    }
  });

  _exports.default = _default;
});