define("apollo/pods/components/file-upload/component", ["exports", "jquery", "apollo/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    sessionAccount: Ember.inject.service('session-account'),
    intl: Ember.inject.service(),
    didInsertElement: function didInsertElement() {
      var self = this;
      var token = this.get('sessionAccount').get('token');
      var transportId = this.get('transport.id');
      (0, _jquery.default)('.file-upload-input').attr('data-url', _environment.default.serverURL + '/files/').fileupload({
        autoUpload: true,
        formData: function formData() {
          return [{
            name: "id",
            value: transportId
          }, {
            name: "token",
            value: token
          }];
        },
        progress: function progress(e, data) {
          console.log('File upload in progress..');
          var progress = parseInt(data.loaded / data.total * 100, 10);
          (0, _jquery.default)('.progress').find('.bar').css('width', progress + '%');
        },
        done: function done(e, data) {
          console.log('File upload finished.');

          if (data.result.status === 'fileExists') {
            alert(self.get('intl').t('file.errors.' + data.result.status, {
              fileName: data.result.fileName
            }));
          } else {
            self.fileUploadSuccess(transportId);
          }

          (0, _jquery.default)('.progress').find('.bar').css('width', '0');
        }
      });
    },
    actions: {
      fileUploadSuccess: function fileUploadSuccess(transportId) {
        this.fileUploadSuccess(transportId);
      }
    }
  });

  _exports.default = _default;
});