define("apollo/pods/components/transport/main-section/component", ["exports", "apollo/mixins/autocomplete-utils-mixin"], function (_exports, _autocompleteUtilsMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_autocompleteUtilsMixin.default, {
    store: Ember.inject.service(),
    cookies: Ember.inject.service(),
    intl: Ember.inject.service(),
    principalCompany: Ember.computed(function () {
      return this.get('store').peekRecord('company', this.get('cookies').read('principalId'));
    }),
    clientChanged: function clientChanged(newClientId) {
      return this.get('transport.clientCompany.id') && this.get('transport.clientCompany.id') !== newClientId;
    },
    invalidSpecificDatesForNewClient: function invalidSpecificDatesForNewClient(advice, newClientId) {
      if (!advice) {
        return false;
      }

      return advice.findInvalidResourceDates(newClientId, false).filter(function (rd) {
        return rd.get('resourceDateType.specificClientId') === newClientId;
      });
    },
    findCannotChangeClientMessage: function findCannotChangeClientMessage(invalidSpecificDatesForNewClient, newClientName) {
      var _this = this;

      var basic = this.get('intl').t('resources.cannotChangeClientMessage.basic', {
        company: newClientName
      });
      var hint = this.get('intl').t('resources.cannotChangeClientMessage.hint', {
        company: newClientName
      });
      var details = "";
      invalidSpecificDatesForNewClient.forEach(function (rd) {
        var resourceTypeName = rd.get('vehicle.id') ? 'vehicle' : 'driver';
        var params = {
          resource: _this.get('intl').t("resources.of.".concat(rd.get("".concat(resourceTypeName, ".exactTypeName")))),
          document: rd.get('resourceDateType.label')
        };
        details += "".concat(_this.get('intl').t('documentsExpirationDate.expired', params), "\n");
      });
      return "".concat(basic, "\n").concat(details, "\n").concat(hint);
    },
    actions: {
      setClientCompany: function setClientCompany(newClient) {
        var _this2 = this;

        var previousClientId = this.get('transport.clientCompany.id');
        this.get('transport.advice').then(function (advice) {
          if (advice && _this2.clientChanged(newClient.id)) {
            var invalidSpecificDatesForNewClient = _this2.invalidSpecificDatesForNewClient(advice, newClient.id);

            if (invalidSpecificDatesForNewClient.get('length') !== 0) {
              alert(_this2.findCannotChangeClientMessage(invalidSpecificDatesForNewClient, newClient.name));

              _this2.setItem('transport.clientCompany', 'company', previousClientId);

              return;
            }
          }

          _this2.setItem('transport.clientCompany', 'company', newClient.id);
        });
      },
      setForwarder: function setForwarder(forwarder) {
        this.setItem('transport.forwarder', 'user', forwarder.id);
      },
      removeClientCompany: function removeClientCompany() {
        this.clearItem('transport.clientCompany');
      },
      removeForwarder: function removeForwarder() {
        this.clearItem('transport.forwarder');
      }
    }
  });

  _exports.default = _default;
});