define("apollo/pods/components/routes-dropzone/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['routesDropzone'],
    classNameBindings: ['dragClass'],
    dragClass: 'deactivated',
    dragLeave: function dragLeave(event) {
      event.preventDefault();
      this.set('dragClass', 'deactivated');
    },
    dragOver: function dragOver(event) {
      event.preventDefault();
      this.set('dragClass', 'activated');
    },
    drop: function drop(event) {
      var data = event.dataTransfer.getData('text/data');
      this.dropped(data, this.get('dropType'));
      this.set('dragClass', 'deactivated');
    }
  });

  _exports.default = _default;
});