define("apollo/pods/components/transports/transports-table/transport-filters/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    classNames: ['transport-filters', 'row'],
    actions: {}
  });

  _exports.default = _default;
});