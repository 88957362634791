define("apollo/mixins/input-utils-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    intl: Ember.inject.service(),
    removeWhiteSpaces: function removeWhiteSpaces(e) {
      var driverMobilePhoneNumberLength = e.get('driverMobilePhoneNumber.length'),
          carRegistrationNumberLength = e.get('carRegistrationNumber.length'),
          trailerRegistrationNumberLength = e.get('trailerRegistrationNumber.length'),
          documentNumberLength = e.get('documentNumber.length');
      if (driverMobilePhoneNumberLength > 0) e.set('driverMobilePhoneNumber', e.get('driverMobilePhoneNumber').replace(/\s+/g, ''));
      if (carRegistrationNumberLength > 0) e.set('carRegistrationNumber', e.get('carRegistrationNumber').replace(/\s+/g, ''));
      if (trailerRegistrationNumberLength > 0) e.set('trailerRegistrationNumber', e.get('trailerRegistrationNumber').replace(/\s+/g, ''));
      if (documentNumberLength > 0) e.set('documentNumber', e.get('documentNumber').replace(/\s+/g, ''));
      return e;
    },
    isNumber: function isNumber(value) {
      return typeof value === "number";
    },
    isCorrectNumber: function isCorrectNumber(value) {
      return /^([0-9]*|[0-9]+\.([0-9])*)$/.test(value);
    },
    isCorrectInteger: function isCorrectInteger(value) {
      return /^[0-9]*$/.test(value);
    },
    isCorrectPrice: function isCorrectPrice(value) {
      if (value === undefined || value === null) {
        return false;
      }

      return /^([0-9]*|[0-9]+\.([0-9]){1,2})$/.test(value.toString().trim());
    },
    actions: {
      correctNumberInput: function correctNumberInput(object, propertyToCorrect) {
        var previousValue = object.get(propertyToCorrect);

        if (!previousValue) {
          return;
        }

        object.set(propertyToCorrect, previousValue.replace(",", "."));
      }
    }
  });

  _exports.default = _default;
});