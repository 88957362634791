define("apollo/pods/components/transports/transports-table/load-more/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/tY/ComQ",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"hasMore\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"col-md-4 col-md-offset-4\"],[8],[0,\"\\n            \"],[7,\"button\",false],[12,\"class\",\"btn btn-info load-more\"],[12,\"disabled\",[22,\"loadingMore\"]],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],\"loadMore\"]],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"loadingMore\"]]],null,{\"statements\":[[0,\"                    \"],[7,\"span\",true],[8],[0,\"\\n                        \"],[7,\"i\",true],[10,\"class\",\"fa fa-hourglass\"],[8],[9],[0,\"\\n                        \"],[1,[28,\"t\",[\"table.loading\"],null],false],[0,\"...\\n                    \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"                    \"],[7,\"span\",true],[8],[0,\"\\n                        \"],[7,\"i\",true],[10,\"class\",\"fa fa-refresh\"],[8],[9],[0,\"\\n                        \"],[1,[28,\"t\",[\"table.loadMore\"],null],false],[0,\"\\n                    \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"            \"],[9],[0,\"\\n        \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/transports/transports-table/load-more/template.hbs"
    }
  });

  _exports.default = _default;
});