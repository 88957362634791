define("apollo/pods/external-cost-orders/archive/controller", ["exports", "apollo/mixins/transports-mixin", "apollo/mixins/modal-mixin"], function (_exports, _transportsMixin, _modalMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_transportsMixin.default, _modalMixin.default, {
    store: Ember.inject.service(),
    searchParams: Ember.computed('model', function () {
      return {
        query: '',
        filters: Ember.A(),
        transportTypesIds: this.get('transportTypes').getEach('id'),
        assetType: 'EXTERNAL_COST_ORDER'
      };
    })
  });

  _exports.default = _default;
});