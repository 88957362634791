define("apollo/pods/components/transport-grouping/component", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['transport-grouping-transport', 'draggableItem', 'col-xs-12', 'col-lg-6', 'all-columns-the-same-height'],
    attributeBindings: ['draggable'],
    draggable: 'true',
    init: function init() {
      document.addEventListener('drag', onMouseUpdate);
      document.addEventListener('mouseup', mouseUp);
      document.addEventListener('mousedown', mouseDown);
      var clicked = false,
          clickY;

      var updateScrollPos = function updateScrollPos(e) {
        (0, _jquery.default)(window).scrollTop(e.offsetY);
      };

      function onMouseUpdate(e) {
        clicked && updateScrollPos(e);
      }

      function mouseDown(e) {
        clicked = true;
        clickY = e.pageY;
      }

      function mouseUp() {
        clicked = false;
      }

      this._super();
    },
    dragStart: function dragStart(event) {
      return event.dataTransfer.setData('text/data', this.get('transport-id'));
    },
    transport: Ember.computed(function () {
      return this.get('transportMap').values().next().value;
    })
  });

  _exports.default = _default;
});