define("apollo/pods/components/vehicles/vehicles-list/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    queryParams: ['carrierId'],
    carrierId: Ember.computed(function () {
      return this.get('company.id');
    }),
    init: function init() {
      this._super();

      var self = this;
      var vehicles = self.get('vehicles');
      var allTransportsAndVehicles = [];
      var allVehicles = [];

      if (self.get('transports.length') > 0) {
        self.get('transports').filter(function (t) {
          vehicles.filter(function (v) {
            if (self.get('vehicleType') === 'TRUCK') {
              if (v.get('id') === t.get('advice.truck.id')) {
                allTransportsAndVehicles.push(new Map().set(v.get('id'), t));
              }
            } else if (self.get('vehicleType') === 'TRAILER') {
              if (v.get('id') === t.get('advice.trailer.id')) {
                allTransportsAndVehicles.push(new Map().set(v.get('id'), t));
              }
            }
          });
        });
      }

      vehicles.then(function (vehicles) {
        vehicles.filter(function (vehicle) {
          if (vehicle.get('enabled')) {
            allVehicles.push(vehicle);
          }
        });
        self.set('filteredVehicles', allVehicles);
      });
      self.set('transportsGroupedByVehicle', allTransportsAndVehicles);
    },
    actions: {}
  });

  _exports.default = _default;
});