define("apollo/pods/audit-log/model", ["exports", "ember-data/model", "ember-data"], function (_exports, _model, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    transport: _emberData.default.belongsTo('transport', {
      async: true
    }),
    newValue: _emberData.default.attr('string'),
    oldValue: _emberData.default.attr('string'),
    dateCreated: _emberData.default.attr('date'),
    propertyName: _emberData.default.attr('string'),
    actor: _emberData.default.attr('string'),
    className: _emberData.default.attr('string'),
    propertyNameType: _emberData.default.attr('string'),
    newValueFormat: Ember.computed('propertyNameType', 'newValue', function () {
      if (this.get('propertyNameType') === "Date") {
        var date = this.get('newValue') ? this.get('newValue').replace(/CET|CEST/, "") : '';
        return date ? moment(date).format("DD.MM.YYYY HH:mm") : 'X';
      } else if (this.get('propertyNameType') === "Boolean") {
        return this.get('newValue') === 'true' ? '✓' : 'x';
      } else {
        return this.get('newValue');
      }
    }),
    oldValueFormat: Ember.computed('propertyNameType', 'oldValue', function () {
      if (this.get('propertyNameType') === "Date") {
        var date = this.get('oldValue') ? this.get('oldValue').replace(/CET|CEST/, "") : '';
        return date ? moment(date).format("DD.MM.YYYY HH:mm") : 'X';
      } else if (this.get('propertyNameType') === "Boolean") {
        return this.get('oldValue') === 'true' ? '✓' : 'x';
      } else {
        return this.get('oldValue');
      }
    })
  });

  _exports.default = _default;
});