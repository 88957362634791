define("apollo/pods/components/vehicle-auto-complete/component", ["exports", "apollo/pods/components/custom-auto-complete/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    valueProperty: "id",
    urlAddress: "/vehicle/find",
    options: [],
    suggestions: Ember.computed('inputVal', 'options.@each', function () {
      var inputVal = this.get("inputVal") || "";
      return this.get("options").filter(function (item) {
        var lowerCaseInput = inputVal.toLowerCase();
        var lowerCaseRegistrationNumber = item.registrationNumber ? item.registrationNumber.toLowerCase() : "";
        var lowerCaseSideNumber = item.sideNumber ? item.sideNumber.toLowerCase() : "";
        return lowerCaseRegistrationNumber.indexOf(lowerCaseInput) > -1 || lowerCaseSideNumber.indexOf(lowerCaseInput) > -1;
      });
    }),
    optionsToMatch: Ember.computed('options.@each', function () {
      var caseInsensitiveOptions = [];
      this.get("options").forEach(function (item) {
        var value = item.registrationNumber;
        caseInsensitiveOptions.push(value);
        caseInsensitiveOptions.push(value.toLowerCase());
      });
      return caseInsensitiveOptions;
    }),
    actions: {
      selectItem: function selectItem(item) {
        this.set("selectedFromList", true);
        this.set("selectedValue", item.driverName);
        this.selectItem(item);
      }
    }
  });

  _exports.default = _default;
});