define("apollo/pods/transports/archive/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    sessionAccount: Ember.inject.service('session-account'),
    access: ['ROLE_ARCHIVE_TAB'],
    beforeModel: function beforeModel(transition) {
      if (!this.get('sessionAccount').hasAnyOfTheRoles(this.get('access'))) {
        transition.abort();
        this.transitionTo('forbidden');
      }
    },
    model: function model(params) {
      return this.store.peekRecord('transportType', params.type);
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      controller.setProperties({
        model: model,
        totalPages: model.get('meta.totalPages'),
        archive: true,
        allAvailableTransportTypes: this.store.peekAll('transport-type').filter(function (tt) {
          return ['TRANSPORT', 'MANUAL_TRANSPORT', 'EXTERNAL_MANUAL_TRANSPORT'].includes(tt.get('typeGroup'));
        })
      });
    },
    actions: {}
  });

  _exports.default = _default;
});