define("apollo/pods/components/users/rodo-information/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    policyLink: 'https://pl.logintegra.com/static/Polityka.pdf',
    manualLink: 'https://pl.logintegra.com/static/Instrukcja.pdf',
    statuteLink: 'https://pl.logintegra.com/static/Regulamin.pdf',
    allParagraphsAccepted: Ember.observer('paragraph_1_accept', 'paragraph_2_accept', 'paragraph_3_accept', 'paragraph_4_accept', 'paragraph_5_accept', 'paragraph_6_accept', 'paragraph_7_1_accept', 'paragraph_7_2_accept', 'paragraph_7_3_accept', 'paragraph_8_accept', 'paragraph_9_accept', 'paragraph_10_accept', 'test', function () {
      console.log('acc: ');
      var acceptance = this.get('paragraph_1_accept') && this.get('paragraph_2_accept') && this.get('paragraph_3_accept') && this.get('paragraph_4_accept') && this.get('paragraph_5_accept') && this.get('paragraph_6_accept') && this.get('paragraph_7_1_accept') && this.get('paragraph_7_2_accept') && this.get('paragraph_7_3_accept') && this.get('paragraph_8_accept') && this.get('paragraph_9_accept') && this.get('paragraph_10_accept');

      if (acceptance) {
        this.approveAllPoints();
      } else {
        this.disapproveSomePoints();
      }
    }),
    actions: {}
  });

  _exports.default = _default;
});