define("apollo/pods/components/create-external-cost/component", ["exports", "apollo/mixins/autocomplete-utils-mixin"], function (_exports, _autocompleteUtilsMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_autocompleteUtilsMixin.default, {
    store: Ember.inject.service(),
    cookies: Ember.inject.service(),
    currencies: Ember.computed(function () {
      return this.get('store').peekAll('currency');
    }),
    setTypes: Ember.on('init', function () {
      var self = this;
      this.get('store').query('external-cost-type', {
        category: self.get('category')
      }).then(function (types) {
        self.set('types', types);
      });
    }),
    actions: {
      createExternalCost: function createExternalCost() {
        var self = this;
        var externalCost = this.get('externalCost');
        this.set('externalCost.transport', this.get('transport'));

        if (!externalCost.validate()) {
          return;
        }

        externalCost.save().then(function () {
          self.closeCreateExternalCostArea();
        });
      },
      cancel: function cancel() {
        this.closeCreateExternalCostArea();
      },
      setCompany: function setCompany(company) {
        this.setItem('externalCost.company', 'company', company.id);
      },
      removeCompany: function removeCompany() {
        this.clearItem('externalCost.company');
      }
    }
  });

  _exports.default = _default;
});