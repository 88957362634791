define("apollo/pods/role-group/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    notifications: _emberData.default.hasMany('notification', {
      async: true
    }),
    transportTypes: _emberData.default.hasMany('transportTypes', {
      async: true
    }),
    name: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    canCreateTransportOfType: _emberData.default.attr('boolean'),
    canSeeTransportOfType: _emberData.default.attr('boolean')
  });

  _exports.default = _default;
});