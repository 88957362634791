define("apollo/templates/components/object-bin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1kbrzTS1",
    "block": "{\"symbols\":[\"obj\",\"&default\"],\"statements\":[[4,\"draggable-object-target\",null,[[\"action\"],[[28,\"action\",[[23,0,[]],\"handleObjectDropped\"],null]]],{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"object-bin-title\"],[8],[1,[22,\"name\"],false],[9],[0,\"\\n  \"],[7,\"br\",true],[8],[9],[0,\"\\n\"],[4,\"each\",[[24,[\"model\"]]],null,{\"statements\":[[4,\"draggable-object\",null,[[\"action\",\"content\"],[\"handleObjectDragged\",[23,1,[]]]],{\"statements\":[[0,\"      \"],[14,2,[[23,1,[]]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/templates/components/object-bin.hbs"
    }
  });

  _exports.default = _default;
});