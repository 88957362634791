define("apollo/pods/components/load-kilometers/component", ["exports", "apollo/mixins/input-utils-mixin"], function (_exports, _inputUtilsMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_inputUtilsMixin.default, {
    store: Ember.inject.service(),
    cookies: Ember.inject.service(),
    hasErrors: false,
    didUpdate: function didUpdate() {
      this._super.apply(this, arguments);

      var collapseCostsTab = document.querySelector('[aria-controls="collapseCosts"]');
      var helpBlockElement = document.querySelector(".help-block");

      if (this.hasErrors === false && helpBlockElement && collapseCostsTab) {
        collapseCostsTab.click();
        this.hasErrors = true;
      }

      if (this.hasErrors === true && helpBlockElement) {
        helpBlockElement.scrollIntoView();
      }
    },
    stepsGroupedResult: Ember.computed({
      get: function get() {
        if (this._stepsGroupedResult) {
          return this._stepsGroupedResult;
        }

        return this.get('stepsGrouped');
      },
      set: function set(key, value) {
        return this._stepsGroupedResult = value;
      }
    }),
    isForm: Ember.computed(function () {
      return true;
    }),
    stepsGrouped: Ember.on('init', Ember.observer('transport.steps', function () {
      var _this = this;

      //grupowanie stepów w taki sposób aby ostatni w parze był pierwszym kolejnej pary.
      //zatem dla listy stepów [A,B,C,D,E,F] tworzymy listę par stepów: [[A,B], [B,C], [C,D], [D,E], [E, F]]
      var steps = this.get('transport.steps');
      var self = this;
      if (steps) steps.then(function (s) {
        var length = s.length;
        var stepsGrouped = Ember.A();
        var driveWay = Ember.A();

        for (var i = 0; i < length - 1; ++i) {
          var stepsPair = Ember.A();
          stepsPair.pushObject(steps.objectAt(i));
          stepsPair.pushObject(steps.objectAt(i + 1));
          stepsGrouped.pushObject(stepsPair);
        }

        if (!_this.get('isForm')) {
          driveWay.pushObject(null);
          driveWay.pushObject(stepsGrouped.objectAt(0).objectAt(0));
          stepsGrouped.insertAt(0, driveWay);
        }

        self.set('stepsGroupedResult', stepsGrouped);
      });
    }))
  });

  _exports.default = _default;
});