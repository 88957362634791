define("apollo/pods/components/transports/search-transports/component", ["exports", "apollo/mixins/modal-mixin"], function (_exports, _modalMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_modalMixin.default, {
    tagName: 'div',
    classNames: ['transport-table-filters'],
    store: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    cookies: Ember.inject.service(),
    showSearchButton: false,
    start: null,
    stop: null,
    searchParams: {},
    //rzeczywista zmienna propoagowana po całym systemie
    queryProxy: '',
    //zmienna działająca tylko w ramach komponentu
    filterMode: null,
    dateFilterMode: 'LOAD_DATE',
    didReceiveAttrs: function didReceiveAttrs() {
      if (this.get('sessionAccount.currentUser.roleGroup.name') === "TRADESMAN") {
        this.set('filterMode', 'user');
      }
    },
    willDestroyElement: function willDestroyElement() {
      this.send('clearFilters');

      this._super();
    },
    firstTransportTypeName: Ember.computed('transportTypes.@each.name', function () {
      return this.get('transportTypes.firstObject.name');
    }),
    dateFilterCode: Ember.computed('firstTransportTypeName', function () {
      var typeName = this.get('firstTransportTypeName');

      if (typeName === 'EXTERNAL_COST_ORDER') {
        return "executionDate";
      } else {
        return "transportDate";
      }
    }),
    setShowSearchButton: Ember.observer('queryProxy', 'start', 'stop', function () {
      Ember.run.once(this, function () {
        if (this.get('queryProxy') !== '' || this.get('start') || this.get('stop')) {
          this.set('showSearchButton', true);
        }
      });
    }),
    groups: Ember.computed('sessionAccount.currentUser', function () {
      return this.get('store').peekAll('user-group');
    }),
    showExportButton: Ember.computed('archive', 'disableExport', function () {
      if (this.get('disableExport') || !this.get('archive')) {
        return false;
      }

      return this.get('sessionAccount').hasRole('ROLE_ADVICE_EXCEL');
    }),
    actions: {
      clearFilters: function clearFilters() {
        Ember.run.once(this, function () {
          this.get('searchParams.filters').clear();
          this.setProperties({
            'queryProxy': '',
            'start': null,
            'stop': null,
            'dateFilterMode': 'LOAD_DATE',
            'searchParams.query': '',
            'searchParams.start': null,
            'searchParams.stop': null,
            'searchParams.dateFilterMode': 'LOAD_DATE'
          });
        });
      },
      setParams: function setParams() {
        Ember.run.once(this, function () {
          this.setProperties({
            'searchParams.query': this.get('queryProxy'),
            'searchParams.start': this.get('start'),
            'searchParams.stop': this.get('stop'),
            'searchParams.dateFilterMode': this.get('dateFilterMode')
          });
        });
      },
      cleanParams: function cleanParams() {
        Ember.run.once(this, function () {
          this.setProperties({
            'searchParams.query': null,
            'searchParams.start': null,
            'searchParams.stop': null,
            'searchParams.clearingTimestamp': new Date().getTime(),
            queryProxy: '',
            start: '',
            stop: '',
            showSearchButton: false,
            dateFilterMode: 'LOAD_DATE'
          });
        });
      },
      setDateFilterMode: function setDateFilterMode(mode) {
        this.set('dateFilterMode', mode);
      },
      setFilterMode: function setFilterMode(mode, group) {
        Ember.run.once(this, function () {
          this.setProperties({
            'searchParams.filterMode': mode,
            'searchParams.groupId': group.get ? group.get('id') : null,
            filterMode: mode
          });
        });
      },
      showXLSDialog: function showXLSDialog() {
        var self = this;
        self.set('showModalAction', 'showModal');
        var model = {
          documentMode: self.get('documentMode'),
          transportTypes: self.get('transportTypes'),
          searchParams: self.get('searchParams'),
          start: self.get('start'),
          stop: self.get('stop'),
          dateFilterMode: self.get('dateFilterMode'),
          queryProxy: self.get('queryProxy'),
          archive: self.get('archive')
        };
        self.sendAction('showModalAction', 'transports.modals.transports-excel', model);
      },
      changeDate: function changeDate(dateToChange, newDate) {
        this.set(dateToChange, newDate);
      }
    }
  });

  _exports.default = _default;
});