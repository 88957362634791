define("apollo/helpers/format-date", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatDate = formatDate;
  _exports.default = void 0;

  function formatDate(rawData) {
    var date = rawData[0];
    var dateFormat = rawData[1] || 'DD.MM.YYYY HH:mm';
    var dateIsDateType = Object.prototype.toString.call(date) === '[object Date]';
    var dateIsStringType = Object.prototype.toString.call(date) === '[object String]';
    var dateIsValidDate = dateIsDateType || dateIsStringType;

    if (!dateIsValidDate) {
      return '';
    }

    return moment(date).format(dateFormat ? dateFormat : "DD.MM.YYYY HH:mm");
  }

  var _default = Ember.Helper.helper(formatDate);

  _exports.default = _default;
});