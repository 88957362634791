define("apollo/pods/transports/modals/create-transport/controller", ["exports", "jquery", "apollo/config/environment", "apollo/mixins/attachment-mixin", "apollo/mixins/advice-utils-mixin", "apollo/mixins/persistence-mixin", "apollo/mixins/transports-mixin", "apollo/mixins/input-utils-mixin", "apollo/mixins/modal-mixin"], function (_exports, _jquery, _environment, _attachmentMixin, _adviceUtilsMixin, _persistenceMixin, _transportsMixin, _inputUtilsMixin, _modalMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_attachmentMixin.default, _adviceUtilsMixin.default, _persistenceMixin.default, _transportsMixin.default, _inputUtilsMixin.default, _modalMixin.default, {
    intl: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    cookies: Ember.inject.service(),
    transportController: Ember.inject.controller('transports/index'),
    chooseCarrierController: Ember.inject.controller('carrier/modals/choose-carrier'),
    errors: [],
    browserOld: Ember.computed(function () {
      return (0, _jquery.default)('html').is('.old');
    }),
    dateOfAdvice: moment().startOf('day').toDate(),
    trailerHasUnconfirmedWash: Ember.computed('model.advice.trailer.numberOfUnconfirmedWashingCheckpoints', function () {
      return this.get('model.advice.trailer.numberOfUnconfirmedWashingCheckpoints') > 1;
    }),
    attachments: [],
    existingFilesToRemove: [],
    transport: Ember.computed('model', function () {
      return this.get('model');
    }),
    canNotEdit: true,
    setCanNotEdit: Ember.observer('model', 'model.operationsInProgress', function () {
      if (this.get('model.operationsInProgress') || this.get('model.canNotBeEdited')) {
        this.set('canNotEdit', true);
        return;
      }

      if (!this.get('model.id') || this.get('sessionAccount').hasRole('ROLE_EDIT_ANY_ADVICE')) {
        this.set('canNotEdit', false);
        return;
      }

      if (this.get('model.archived') || this.get('canNotEditBecauseOfOtherContractors')) {
        this.set('canNotEdit', true);
        return;
      }

      if (!this.get('sessionAccount').hasRole('ROLE_EDIT_ADVICE')) {
        this.set('canNotEdit', true);
        return;
      }

      var self = this;
      var loggedCompany = this.get('sessionAccount.currentUser.company');
      this.get('model.creator').then(function (creator) {
        creator.get('company').then(function (company) {
          if (creator.get('isPrincipal')) {
            if (self.get('model').then) {
              self.get('model').then(function (transport) {
                var canAccessTransport = transport.canBeAccessedByCompany(loggedCompany);
                console.log("Tw\xF3rca transportu ".concat(transport.id, " jest producentem - zalogowany u\u017Cytkownik jest z firmy ").concat(loggedCompany.get('name'), "; canAccessTransport: ").concat(canAccessTransport, "."));
                self.set('canNotEdit', !canAccessTransport);
              });
            } else {
              var canAccessTransport = self.get('model').canBeAccessedByCompany(loggedCompany);
              console.log("Tw\xF3rca transportu ".concat(self.get('model').id, " jest producentem - zalogowany u\u017Cytkownik jest z firmy ").concat(loggedCompany.get('name'), "; canAccessTransport: ").concat(canAccessTransport, "."));
              self.set('canNotEdit', !canAccessTransport);
            }
          } else {
            var transportCreatorCompany = company.get('name');

            var _canAccessTransport = company.get('id') === loggedCompany.get('id');

            console.log("Tw\xF3rca transportu to firma ".concat(transportCreatorCompany, " - zalogowany u\u017Cytkownik jest z firmy ").concat(loggedCompany.get('name'), "; canAccessTransport: ").concat(_canAccessTransport, "."));
            self.set('canNotEdit', !_canAccessTransport);
          }
        });
      });
    }),
    canBeDeleted: Ember.computed('model.id', 'model.canBeDeleted', 'model.archived', function () {
      if (this.get('model.remoteId')) {
        return false;
      }

      if (!this.get('model.id') || this.get('model').get('archived')) {
        return false;
      }

      if (this.get('sessionAccount').hasRole('ROLE_DELETE_ADVICE_ANY_TIME')) {
        return true;
      }

      return this.get('sessionAccount').hasRole('ROLE_DELETE_ADVICE') && this.get("model.canBeDeleted");
    }),
    canNotEditAfterProcessStarts: Ember.computed('model.id', 'canNotEdit', 'canNotEditBecauseOfOtherContractors', 'model.canBeUpdated', function () {
      if (this.get('sessionAccount').hasRole('ROLE_EDIT_ADVICE_ANY_TIME')) {
        return false;
      }

      return this.get('canNotEdit') || this.get('canNotEditBecauseOfOtherContractors') || this.get('model.id') && !this.get('model').get('canBeUpdated');
    }),
    canNotEditBecauseOfOtherContractors: Ember.computed('model.contractors.length', function () {
      var isCurrentUserContractor = this.get('sessionAccount.currentUser').get('company').get('partnershipType').get('name') === 'SUPPLIER';

      if (!isCurrentUserContractor) {
        return false;
      }

      return this.get('model.contractors.length') > 1;
    }),
    principalCompany: Ember.computed(function () {
      return this.store.peekRecord('company', this.get('cookies').read('principalId'));
    }),

    /**
     * Formularz myjni pokazujemy w szczegółach zlecenia popiero gdy myjnia została dodana
     */
    showWashForm: Ember.computed('model.washStep.id', function () {
      return this.get('model.washStep.id');
    }),
    setShowSaveTransportConfirm: Ember.observer('model.steps.@each.address', 'model.steps.length', function () {
      if ((0, _jquery.default)('.transport-modal').length > 0 && this.get('model') && this.get('model.carrierCompany.name')) {
        this.set('showSaveTransportConfirm', true);
      }
    }),
    setCurrencies: Ember.on('init', function () {
      var self = this;
      var currencies = this.get('store').peekAll('currency');
      self.set('currencies', currencies.sortBy('idx'));

      if (self.get('model') && !self.get('model').get('transaction').get('currency.id')) {
        self.get('model').get('transaction').set('currency', currencies.get('firstObject'));
      }
    }),
    carTypes: Ember.computed(function () {
      return this.get('store').findAll('car-type');
    }),
    showCreateAdviceButton: Ember.computed('model.advice', 'model.carrierCompany', function () {
      return !this.get('model.advice.id') && this.get('model.carrierCompany.id') && this.get('model.carrierCompany.isExternal') || this.get('model.advice.id');
    }),
    copyTransport: function copyTransport(transportModel) {
      var _this = this;

      transportModel.copy(true, {
        ignoreAttributes: ['advice', 'carrierCompany', 'externalCosts', 'checkpoints', 'erpIdentifier']
      }).then(function (t) {
        t.set('canNotBeEdited', false); //set false for archived transports

        _this.set('showCreateAdviceButton', false);

        _this.set('isCopy', true);

        t.set('carrierCompany', null);
        t.set('transaction.priceOfFreight', null);
        t.get('steps').forEach(function (s) {
          s.set('deliveryDateFrom', null);
          s.set('deliveryDateTo', null);

          if (s.get('stepTypeName') === 'wash') {
            t.get('steps').removeObject(s);
          }
        });
        t.get('checkpoints').forEach(function (c) {
          c.set('executionDate', null);
          c.set('step', null);
        });
        t.set('creator', _this.get('sessionAccount').get('currentUser'));
        setTimeout(function () {
          _this.send('showModal', 'transports.modals.create-transport', t);
        }, 2000);
      });
    },
    actions: {
      createSimilar: function createSimilar() {
        var _this2 = this;

        var transportModel = this.get('model');
        this.send('removeModal');
        console.log("Pr\xF3bujemy utworzy\u0107 transport podobny do ".concat(transportModel.get('id'), "..."));

        if (transportModel.then !== undefined) {
          transportModel.then(function (transport) {
            return _this2.copyTransport(transport);
          });
        } else {
          this.copyTransport(transportModel);
        }
      },
      downloadAsPDF: function downloadAsPDF(id) {
        var token = this.get('sessionAccount.token');
        window.location = "".concat(_environment.default.serverURL, "/transports/").concat(id, "/pdf?token=").concat(token);
      },
      syncWithRemote: function syncWithRemote() {
        var self = this;
        console.log("Transport ".concat(self.get('transport.id'), " is synchronized.."));
        var token = this.get('sessionAccount.token');
        this.get('transport').set('operationsInProgress', true);

        _jquery.default.ajax({
          url: _environment.default.serverURL + '/transports/' + self.get('transport.id') + '/sync',
          type: 'POST',
          beforeSend: function beforeSend(xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
          }
        }).then(function () {
          console.log("Transport ".concat(self.get('transport.id'), " has been synced."));
          self.get('transport').refresh();
          self.get('transport').set('operationsInProgress', false);
        });
      },
      syncCheckpoints: function syncCheckpoints() {
        var _this3 = this;

        console.log("Statusy transportu ".concat(this.get('transport.id'), " s\u0105 synchronizowane"));
        var token = this.get('sessionAccount.token');
        this.get('transport').set('operationsInProgress', true);

        _jquery.default.ajax({
          url: _environment.default.serverURL + '/transports/' + this.get('transport.id') + '/syncCheckpoints',
          type: 'POST',
          beforeSend: function beforeSend(xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
          }
        }).then(function () {
          console.log("Statusy transportu ".concat(_this3.get('transport.id'), " zosta\u0142y zsynchronizowane"));

          _this3.get('transport').refresh();

          _this3.get('transport').set('operationsInProgress', false);
        });
      },
      save: function save() {
        var self = this;
        var transportModel = this.get('model');

        if (transportModel.then !== undefined) {
          transportModel.then(function (transport) {
            self.send('updateTransport', transport);
          });
        } else {
          if (self.get('showSaveTransportConfirm')) {
            var text = this.get('text') || this.get('intl').t('transport.saveTransportConfirm');

            if (confirm(text)) {
              self.send('updateTransport', transportModel);
            }
          } else {
            self.send('updateTransport', transportModel);
          }
        }
      },
      updateTransport: function updateTransport(transport) {
        var self = this;
        transport.set('operationsInProgress', true);

        if (transport.get('id')) {
          console.log("Aktualizujemy ".concat(transport.get('logString'), ".."));
        } else {
          console.log("Zapisujemy nowy transport..");
        }

        transport.set('principalCompany', self.get('principalCompany'));
        transport.set('advice', self.removeWhiteSpaces(transport.get('advice')));
        this.set('model.adviceChecked', !!transport.get('advice.id'));
        transport.get('transaction').then(function (transaction) {
          transport.validate();
          var validate = self.customValidate(transaction);

          if (transport.get('errors.length') || !validate || !transaction.validate()) {
            transport.set('operationsInProgress', false);
            return;
          }

          transport.get('advice').then(function (advice) {
            var blockingDates = self.blockingResourceDates(advice);

            if (blockingDates.get('length') !== 0) {
              alert(self.findInvalidResourceDateAlert(blockingDates));
              transport.set('operationsInProgress', false);
              return;
            }

            transport.save().then(function () {
              console.log("Poprawnie zapisali\u015Bmy transport ".concat(transport.get('logString'), "."));
              self.existingFilesToRemove.forEach(function (fileId) {
                console.log("self.existingFilesToRemove..");
                self.deleteFile(fileId);
              });
              self.existingFilesToRemove.clear();
              var token = self.get('sessionAccount').get('token');

              if (self.attachments) {
                self.uploadAttachment(transport.id, 'transports', token, self.attachments);
                self.attachments.clear();
              }

              self.handleUpdate(transport);
              self.setProperties({
                model: null,
                errors: []
              });
              self.set('showSaveTransportConfirm', false);
              (0, _jquery.default)('.modal').modal('hide');
              (0, _jquery.default)('.modal, .modal-backdrop').hide();
              (0, _jquery.default)('body').removeClass('modal-open');
            }).catch(function (response) {
              transport.set('operationsInProgress', false);
              self.set('errors', response.errors);
            });
          });
        });
      },
      cancelCreateWindow: function cancelCreateWindow() {
        var _this4 = this;

        if (this.get('model.isAdviceChecked') && !this.get('model.advice.id')) {
          this.set('model.isAdviceChecked', false);
        }

        var model = this.get('transport');

        if (model) {
          if (model.rollbackAttributes) {
            this.rollbackTransportChanges(model);
          } else {
            model.then(function (transportModel) {
              _this4.rollbackTransportChanges(transportModel);
            });
          }
        }

        if (model && model.get('currentState.stateName') !== 'root.deleted.saved') {
          model.set('operationsInProgress', false);
        }

        this.setProperties({
          errors: [],
          model: null,
          showSaveTransportConfirm: false
        });
      },
      confirm: function confirm(checkpoint) {
        this.get('transportController').send('confirm', checkpoint);
      },
      deleteTransport: function deleteTransport(transport) {
        var self = this;
        (0, _jquery.default)(".delete-progress").show();
        var token = this.get('sessionAccount').get('token');

        _jquery.default.ajax({
          type: "PUT",
          url: _environment.default.serverURL + '/transports/' + transport.get("id") + "/delete",
          beforeSend: function beforeSend(xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
          }
        }).then(function () {
          transport.set('deleted', true); //needed for triggering events' observer when event is deleting

          self.setProperties({
            model: null,
            errors: []
          });
          (0, _jquery.default)('.modal').modal('hide');
        });
      },
      getControllingIntegrations: function getControllingIntegrations(transport) {
        var self = this;
        var token = this.get('sessionAccount').get('token');

        _jquery.default.ajax({
          url: _environment.default.serverURL + '/transports/' + transport.get("id") + '/integrations',
          type: 'GET',
          beforeSend: function beforeSend(xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
          }
        }).then(function (json) {
          self.get('store').pushPayload(json);
          json.controllingIntegrations.sortBy('dateCreated').reverse().forEach(function (integration) {
            var log = self.get('store').peekRecord('controllingIntegration', integration.id);
            transport.get('controllingIntegrations').pushObject(log);
          });
        });
      },
      getFlOrderIntegrations: function getFlOrderIntegrations(transport) {
        var self = this;
        var token = this.get('sessionAccount').get('token');

        _jquery.default.ajax({
          url: _environment.default.serverURL + '/transports/' + transport.get("id") + '/flOrderIntegrations',
          type: 'GET',
          beforeSend: function beforeSend(xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
          }
        }).then(function (json) {
          self.get('store').pushPayload(json);
          json.flOrderIntegrations.sortBy('dateCreated').forEach(function (integration) {
            var log = self.get('store').peekRecord('flOrderIntegration', integration.id);
            transport.get('flOrderIntegrations').pushObject(log);
          });
          transport.set('flOrderNumberIntegrations', json.flOrderNumberIntegrations);
        });
      },
      getExternalCostIntegrations: function getExternalCostIntegrations(transport) {
        var self = this;
        var token = this.get('sessionAccount').get('token');

        _jquery.default.ajax({
          url: _environment.default.serverURL + '/external-costs/' + transport.get("id") + '/integrations',
          type: 'GET',
          beforeSend: function beforeSend(xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
          }
        }).then(function (json) {
          self.get('store').pushPayload(json);
          json.externalCostIntegrations.forEach(function (integration) {
            var log = self.get('store').peekRecord('externalCostIntegration', integration.id);
            transport.get('externalCostIntegrations').pushObject(log);
          });
        });
      }
    },
    customValidate: function customValidate() {
      var validates = true;

      if (!this.get('dateOfAdvice')) {
        this.get('transport').get('errors').add('dateOfAdvice', 'blank');
        validates = false;
      }

      this.get('transport.steps').map(function (s) {
        if (!s.validate()) {
          validates = false;
        }
      });
      this.get('transport.products').map(function (p) {
        if (!p.validate()) {
          validates = false;
        }
      });
      this.get('transport.externalCosts').map(function (ec) {
        if (!ec.validate()) {
          validates = false;
        }
      });

      if (this.get('transport.carrierCompany.name')) {
        if (!this.get('chooseCarrierController').customValidate(this.get('transport'))) {
          validates = false;
        }
      }

      return validates;
    },
    rollbackTransportChanges: function rollbackTransportChanges(transportModel) {
      transportModel.restoreData();
      this.get('attachments').clear();
    }
  });

  _exports.default = _default;
});