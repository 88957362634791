define("apollo/pods/company/model", ["exports", "ember-data", "apollo/mixins/model-validator"], function (_exports, _emberData, _modelValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend(_modelValidator.default, {
    users: _emberData.default.hasMany('user', {
      async: true
    }),
    groups: _emberData.default.hasMany('roleGroup', {
      async: false
    }),
    userGroups: _emberData.default.hasMany('userGroup', {
      async: true
    }),
    partnerships: _emberData.default.hasMany('partnership', {
      async: true,
      inverse: 'contractorCompany',
      deepEmbedded: ['partnershipType', 'principalCompany']
    }),
    companyEmails: _emberData.default.hasMany('companyEmail'),
    availableRoles: _emberData.default.hasMany('role', {
      async: true
    }),
    transportTypes: _emberData.default.hasMany('transportType', {
      async: true
    }),
    addresses: _emberData.default.hasMany('address', {
      async: true,
      deepEmbedded: ['country']
    }),
    driverLanguages: _emberData.default.hasMany('country', {
      async: true
    }),
    files: _emberData.default.hasMany('file', {
      async: true
    }),
    drivers: _emberData.default.hasMany('driver', {
      async: true
    }),
    trucks: _emberData.default.hasMany('truck', {
      async: true
    }),
    trailers: _emberData.default.hasMany('trailer', {
      async: true
    }),
    containers: _emberData.default.hasMany('containers', {
      async: true
    }),
    resourceDates: _emberData.default.hasMany('resource-date', {
      async: true
    }),
    country: _emberData.default.belongsTo('country', {
      async: true
    }),
    partnershipType: _emberData.default.belongsTo('partnershipType', {
      async: true
    }),
    isPrincipal: _emberData.default.attr('boolean'),
    name: _emberData.default.attr('string'),
    shortName: _emberData.default.attr('string'),
    taxIdNumber: _emberData.default.attr('string'),
    paymentOption: _emberData.default.attr('string'),
    email: _emberData.default.attr('string'),
    alias: _emberData.default.attr('string'),
    enabled: _emberData.default.attr('boolean'),

    /**
     * Tymczasowa zmienna wykorzystywana do przechowania adresów email firmy,
     * jesli zostały dodane przed jej zapisaniem
     */
    companyEmailsTmp: [],
    attachmentsTmp: _emberData.default.attr(),
    validations: {
      name: {
        length: {
          minimum: {
            value: 3,
            message: 'tooShort'
          }
        }
      },
      shortName: {
        length: {
          minimum: {
            value: 2,
            message: 'tooShort'
          }
        }
      },
      country: {
        presence: {
          message: 'blank'
        }
      },
      taxIdNumber: {
        custom: {
          validation: function validation(key, value, model) {
            if (model.get('country.ibanSymbol') !== 'PL') {
              return true;
            }

            return /^[\d]+$/.test(value);
          },
          message: 'notANumber'
        },
        length: {
          minimum: {
            value: 3,
            message: 'tooShort'
          }
        }
      }
    },
    symbol: Ember.computed('shortNameOrName', function () {
      try {
        return this.get('shortNameOrName').substr(0, 5);
      } catch (e) {
        console.debug('brak nazwy firmy dostawcy');
        return '';
      }
    }),
    sortedUsers: Ember.computed('users.@each.name', function () {
      return this.get('users').sortBy('name');
    }),
    shortNameOrName: Ember.computed('shortName', 'name', function () {
      return this.get('shortName') ? this.get('shortName') : this.get('name');
    }),
    forwarders: Ember.computed('users.@each.id', 'users.@each.roleGroup', function () {
      var _this = this;

      return this.store.findAll('role-group').then(function () {
        return _this.get('users').filter(function (user) {
          return user.get('roleGroup.name') === 'FORWARDER';
        });
      });
    }),
    address: Ember.computed('addresses.@each.isHeadquarter', function () {
      return this.get('addresses').filter(function (a) {
        return a.get('isHeadquarter') === true;
      }).get('firstObject');
    }),
    partnershipWithPrincipal: Ember.computed('partnerships.firstObject', function () {
      return this.get('partnerships').get('firstObject');
    }),
    isExternal: Ember.computed('isPrincipal', 'partnershipWithPrincipal.integrated', function () {
      if (this.get('isPrincipal')) {
        return false;
      }

      return !this.get('partnershipWithPrincipal.integrated');
    }),
    companyTypeName: Ember.computed('partnershipType.name', function () {
      return this.get('partnershipType.name');
    }),
    vehicles: Ember.computed('trucks.@each', 'trailers.@each', function () {
      var vehicles = Ember.A();
      this.get('trucks').forEach(function (t) {
        vehicles.pushObject(t);
      });
      this.get('trailers').forEach(function (t) {
        vehicles.pushObject(t);
      });
      return vehicles;
    }),
    asResource: function asResource(children, group) {
      var company = this;
      return {
        id: group ? group.get('id') + '-' + this.get('id') : this.get('id'),
        title: this.get('shortName'),
        children: children ? children : [],
        onDrop: function onDrop(context, transport, revertFunc) {
          if (transport.get('transportType').content.get('name') === 'SERVICE') {
            context.chooseService(transport, company, null, revertFunc);
          } else {
            transport.cancelAdvice();
            context.chooseCarrier(transport, company, group, revertFunc);
          }
        }
      };
    },
    sortedTrucks: Ember.computed('trucks.@each.sideNumber', 'trucks.@each.enabled', function () {
      return this.get('trucks').filterBy('enabled').sort(function (a, b) {
        var sideNumberA = a.get('sideNumber') ? a.get('sideNumber').replace(/\s/g, '') : '';
        var sideNumberB = b.get('sideNumber') ? b.get('sideNumber').replace(/\s/g, '') : '';
        return sideNumberA.localeCompare(sideNumberB);
      });
    }),
    sortedActiveFiles: Ember.computed('files.@each.dateCreated', 'files.@each.active', function () {
      return this.get('files').filterBy('active').sortBy('dateCreated');
    }).readOnly(),
    findControlledDates: function findControlledDates() {
      return this.get('resourceDates');
    }
  });

  _exports.default = _default;
});