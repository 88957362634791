define("apollo/pods/forbidden/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "v5wBkztA",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"centered-section\"],[8],[0,\"\\n    \"],[7,\"h3\",true],[8],[1,[28,\"t\",[\"wrongUrl\"],null],false],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/forbidden/template.hbs"
    }
  });

  _exports.default = _default;
});