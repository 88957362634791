define("apollo/pods/transports/modals/edit-execution-date/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cQQxWIpG",
    "block": "{\"symbols\":[],\"statements\":[[4,\"checkpoint-modal\",null,[[\"model\",\"close\",\"title\",\"actionToPerform\",\"mustBeInThePast\"],[[24,[\"model\"]],[28,\"action\",[[23,0,[]],\"removeModal\"],null],[28,\"t\",[\"external-cost.order.editExecutionDate\"],null],[28,\"action\",[[23,0,[]],\"editExecutionDate\"],null],false]],{\"statements\":[[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/transports/modals/edit-execution-date/template.hbs"
    }
  });

  _exports.default = _default;
});