define("apollo/pods/users/index/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    sessionAccount: Ember.inject.service('session-account'),
    queryParams: ['query', 'page', 'size'],
    queryInputChanged: Ember.observer('queryInput', function () {
      Ember.run.debounce(this, this.applyFilter, 500);
    }),
    applyFilter: function applyFilter() {
      var queryInput = this.get('queryInput');
      this.set('query', queryInput);

      if (queryInput) {
        this.setProperties({
          page: 1,
          filterActive: true
        });
      }
    },
    actions: {
      clearFilter: function clearFilter() {
        this.setProperties({
          query: '',
          queryInput: '',
          filterActive: false
        });
      }
    }
  });

  _exports.default = _default;
});