define("apollo/pods/invitations/handle/controller", ["exports", "jquery", "apollo/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    loginController: Ember.inject.controller('auth/login'),
    intl: Ember.inject.service(),
    actions: {
      join: function join() {
        var self = this;
        var user = this.get('model.user');
        var company = this.get('model.company');
        var data = user.serialize();
        data.company = company.serialize();
        var invitationId = this.get('model.invitation.id');

        _jquery.default.ajax({
          url: _environment.default.serverURL + "/invitations/".concat(invitationId, "/join"),
          type: 'POST',
          data: JSON.stringify(data),
          contentType: 'application/json'
        }).then(function (jsonResponse) {
          if (jsonResponse.errors) {
            self.set('errors', jsonResponse.errors);
            return;
          }

          self.setProperties({
            inProgress: false,
            errors: []
          });
          var msg = self.get('intl').t('invitation.registrationComplete', {
            username: jsonResponse.user.username
          });
          self.get('loginController').set('message', msg);
          self.transitionToRoute('auth.login');
        }, function (response) {
          self.set('errors', response.errors);
        });
      }
    }
  });

  _exports.default = _default;
});