define("apollo/pods/service/model", ["exports", "ember-data", "apollo/mixins/model-validator", "apollo/mixins/input-utils-mixin"], function (_exports, _emberData, _modelValidator, _inputUtilsMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend(_modelValidator.default, _inputUtilsMixin.default, {
    mechanic: _emberData.default.belongsTo('user', {
      async: true
    }),
    step: _emberData.default.belongsTo('step', {
      async: true,
      embedded: 'save',
      deepEmbedded: ['transport']
    }),
    serviceType: _emberData.default.belongsTo('service-type', {
      async: true
    }),
    requestedJobs: _emberData.default.attr('string'),
    finishedJobs: _emberData.default.attr('string'),
    mileage: _emberData.default.attr('number'),
    workload: _emberData.default.attr('string'),
    validations: {
      mileage: {
        custom: {
          validation: function validation(key, value) {
            return /^[\d]+$/.test(value);
          },
          message: 'notAnInteger'
        },
        presence: {
          message: 'blank'
        }
      },
      serviceType: {
        presence: {
          message: 'blank'
        }
      },
      workload: {
        custom: {
          validation: function validation(key, value, model) {
            if (!value) {
              return true;
            }

            return model.isCorrectNumber(value);
          },
          message: 'incorrectNumber'
        }
      }
    }
  });

  _exports.default = _default;
});