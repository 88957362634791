define("apollo/pods/external-cost-orders/archive/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      controller.setProperties({
        transportTypes: this.store.peekAll('transport-type').filterBy('name', 'EXTERNAL_COST_ORDER')
      });
    }
  });

  _exports.default = _default;
});