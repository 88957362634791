define("apollo/pods/additional-datum/model", ["exports", "ember-data-copyable", "ember-data"], function (_exports, _emberDataCopyable, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend(_emberDataCopyable.default, {
    transport: _emberData.default.belongsTo('transport', {
      async: true
    }),
    comment: _emberData.default.attr('string')
  });

  _exports.default = _default;
});