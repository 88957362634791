define("apollo/pods/invitation/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  //noinspection NpmUsedModulesInstalled
  var _default = _emberData.default.Model.extend({
    partnershipType: _emberData.default.belongsTo('partnershipType', {
      async: true
    }),
    creator: _emberData.default.belongsTo('user', {
      async: true
    }),
    registeredAccount: _emberData.default.belongsTo('user', {
      async: true,
      inverse: 'invitation'
    }),
    personWhoAccepted: _emberData.default.belongsTo('user', {
      async: true
    }),
    personWhoRejected: _emberData.default.belongsTo('user', {
      async: true
    }),
    roleGroup: _emberData.default.belongsTo('roleGroup', {
      async: true
    }),
    origin: _emberData.default.attr('string'),
    email: _emberData.default.attr('string'),
    enabled: _emberData.default.attr('boolean'),
    expirationDate: _emberData.default.attr('date'),
    dateAccepted: _emberData.default.attr('date'),
    dateRejected: _emberData.default.attr('date'),
    dateCreated: _emberData.default.attr('date'),
    lastUpdated: _emberData.default.attr('date'),
    isAccepted: Ember.computed('dateAccepted', function () {
      return !!this.get('dateAccepted');
    }),
    isRejected: Ember.computed('dateRejected', function () {
      return !!this.get('dateRejected');
    }),
    isHandled: Ember.computed('dateAccepted', 'dateRejected', function () {
      return !!this.get('dateAccepted') || !!this.get('dateRejected');
    }),
    isToJoinCompany: Ember.computed('partnershipType.id', function () {
      return !this.get('partnershipType.id');
    }),
    type: Ember.computed('partnershipType.name', function () {
      return this.get('isToJoinCompany') ? 'JOIN_COMPANY' : this.get('partnershipType').get('name');
    })
  });

  _exports.default = _default;
});