define("apollo/pods/users/index/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    queryParams: {
      query: {
        refreshModel: true
      },
      page: {
        refreshModel: true
      },
      max: {
        refreshModel: true
      }
    },
    model: function model(params) {
      params.max = params.max || 10;
      params.page = params.page || 1;

      if (params) {
        return this.store.query('user', params);
      } else {
        return this.store.findAll('user');
      }
    },
    setupController: function setupController(controller, model) {
      this.get('controller').setProperties({
        model: model,
        totalPages: model.get('meta.totalPages')
      });
    },
    actions: {
      willTransition: function willTransition(transition) {
        this.get('controller').setProperties({
          info: null,
          errors: null,
          successMessage: null
        });

        if (transition.targetName !== 'users.index') {
          this.get('controller').setProperties({
            query: '',
            page: 1,
            totalPages: 0,
            queryInput: '',
            filterActive: false
          });
        }

        return true;
      }
    }
  });

  _exports.default = _default;
});