define("apollo/pods/trailers/create/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    sessionAccount: Ember.inject.service('session-account'),
    access: ['ROLE_CREATE_VEHICLE'],
    queryParams: {
      carrierId: {
        refreshModel: true
      },
      trailerType: {
        refreshModel: true
      }
    },
    beforeModel: function beforeModel(transition) {
      if (!this.get('sessionAccount').hasAnyOfTheRoles(this.get('access'))) {
        transition.abort();
        this.transitionTo('forbidden');
      }
    },
    model: function model(params) {
      var trailer = this.store.createRecord('trailer', {
        enabled: true,
        trailerType: params.trailerType
      });
      var carrierId = params.carrierId || this.get('sessionAccount.currentUser.company.id');
      return Ember.RSVP.hash({
        vehicle: trailer,
        carrierId: carrierId
      });
    },
    setupController: function setupController(controller, model) {
      this.get('store').findRecord('company', model.carrierId).then(function (company) {
        console.log("Przygotowujemy formularz naczepy dla przewo\u017Anika ".concat(company.get('name'), ".."));
        model.vehicle.set('owner', company);
        controller.setProperties({
          model: model,
          isEditing: false
        });
      });
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.set('successMessage', null);
      }
    },
    actions: {
      willTransition: function willTransition() {
        this.controllerFor('trailers.create').setProperties({
          successMessage: null,
          errors: []
        });
        this.controllerFor('trailers.create').get('model.vehicle').unloadRecord();
      }
    }
  });

  _exports.default = _default;
});