define("apollo/pods/components/advice-form-warnings/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ELgiG3FQ",
    "block": "{\"symbols\":[\"warning\"],\"statements\":[[7,\"div\",true],[11,\"data-field-name\",[22,\"dataFieldName\"]],[10,\"class\",\"text-right\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"displayedWarnings\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\",true],[11,\"class\",[29,[\"form-control-static \",[23,1,[\"className\"]]]]],[8],[0,\"\\n            \"],[1,[23,1,[\"text\"]],false],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\n\"],[4,\"if\",[[28,\"gt\",[[24,[\"warnings\",\"length\"]],[24,[\"MIN_WARNINGS_DISPLAYED\"]]],null]],null,{\"statements\":[[0,\"        \"],[7,\"div\",true],[10,\"class\",\"form-control-static text-link\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"toggleShowMore\"],null]],[8],[0,\"\\n            \"],[1,[22,\"showMoreLabel\"],false],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/advice-form-warnings/template.hbs"
    }
  });

  _exports.default = _default;
});