define("apollo/pods/address/model", ["exports", "ember-data", "apollo/mixins/model-validator", "apollo/mixins/input-utils-mixin"], function (_exports, _emberData, _modelValidator, _inputUtilsMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend(_modelValidator.default, _inputUtilsMixin.default, {
    country: _emberData.default.belongsTo('country', {
      async: true
    }),
    company: _emberData.default.belongsTo('company'),
    recipient: _emberData.default.attr('string'),
    city: _emberData.default.attr('string'),
    postal: _emberData.default.attr('string'),
    street: _emberData.default.attr('string'),
    isHeadquarter: _emberData.default.attr('boolean'),
    enabled: _emberData.default.attr('boolean'),
    lat: _emberData.default.attr('number'),
    lon: _emberData.default.attr('number'),
    openingTimeString: _emberData.default.attr('string'),
    closingTimeString: _emberData.default.attr('string'),
    validations: {
      country: {
        presence: {
          message: 'blank'
        }
      },
      city: {
        length: {
          minimum: {
            value: 3,
            message: 'tooShort'
          }
        }
      },
      postal: {
        length: {
          minimum: {
            value: 2,
            message: 'tooShort'
          }
        },
        custom: [{
          validation: function validation(key, value, model) {
            var isPL = model.get('country.ibanSymbol') === 'PL';
            return !isPL || /^[0-9]{2}-[0-9]{3}$/.test(value);
          },
          message: 'wrongFormatForPL'
        }]
      },
      street: {
        length: {
          minimum: {
            value: 3,
            message: 'tooShort'
          }
        }
      },
      openingTimeString: {
        custom: [{
          validation: function validation(key, value) {
            return !value || /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(value);
          },
          message: 'wrongFormat'
        }, {
          validation: function validation(key, value, model) {
            // Można nie podać godziny otwarcia, jeśli nie ma też godz. zamknięcia.
            return value || !model.get('closingTimeString');
          },
          message: 'null'
        }]
      },
      closingTimeString: {
        custom: [{
          validation: function validation(key, value) {
            return !value || /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(value);
          },
          message: 'wrongFormat'
        }, {
          validation: function validation(key, value, model) {
            // Można nie podać godziny zamknięcia, jeśli nie ma też godz. otwarcia.
            return value || !model.get('openingTimeString');
          },
          message: 'null'
        }, {
          validation: function validation(key, value, model) {
            if (!value) {
              return true;
            }

            return model.get('closingMomentToday') > model.get('openingMomentToday');
          },
          message: 'beforeOpening'
        }]
      },
      lat: {
        custom: {
          validation: function validation(key, value, model) {
            if (!value) {
              return true;
            }

            return model.isCorrectNumber(value);
          },
          message: 'incorrectNumber'
        }
      },
      lon: {
        custom: {
          validation: function validation(key, value, model) {
            if (!value) {
              return true;
            }

            return model.isCorrectNumber(value);
          },
          message: 'incorrectNumber'
        }
      }
    },
    openingMomentToday: Ember.computed('openingTimeString', function () {
      var openingTimeString = this.get('openingTimeString');

      if (!openingTimeString) {
        return null;
      }

      var h = openingTimeString.split(':')[0];
      var m = openingTimeString.split(':')[1];
      return moment().startOf('day').add(h, 'hours').add(m, 'minutes');
    }),
    closingMomentToday: Ember.computed('closingTimeString', function () {
      var closingTimeString = this.get('closingTimeString');

      if (!closingTimeString) {
        return null;
      }

      var h = closingTimeString.split(':')[0];
      var m = closingTimeString.split(':')[1];
      return moment().startOf('day').add(h, 'hours').add(m, 'minutes');
    }),
    nameAndKey: Ember.computed('recipient', function () {
      var label = this.get('city') + " " + this.get('recipient');
      return {
        name: label,
        key: this.get('recipient')
      };
    })
  });

  _exports.default = _default;
});