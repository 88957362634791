define("apollo/pods/components/checkpoint-modal/component", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    actions: {
      ok: function ok() {
        (0, _jquery.default)('.modal').modal('hide');
        this.ok();
      },
      setSelectedExecutionDate: function setSelectedExecutionDate(document, newDate) {
        this.set('selectedExecutionDate', newDate);
      },
      cancelConfirmation: function cancelConfirmation() {
        try {
          this.get('model').rollbackAttributes();
          this.setProperties({
            errors: []
          });
        } catch (e) {
          console.log(e);
        }
      }
    },
    show: Ember.on('didInsertElement', function () {
      this.get('model').set('executionDate', new Date());
      this.get('model').notifyPropertyChange('id');
      var modal = (0, _jquery.default)('.modal');
      modal.modal({
        backdrop: 'static'
      }).on('hidden.bs.modal', function () {
        this.close();
      }.bind(this));
      modal.modal({
        backdrop: 'static'
      }).on('shown.bs.modal', function () {
        (0, _jquery.default)('.focus-input').focus();
        (0, _jquery.default)('input').change(function () {
          (0, _jquery.default)(this).val((0, _jquery.default)(this).val().trim());
        });
      }.bind(this));
    }),
    setExecutionDateFields: Ember.on('init', Ember.observer('model', function () {
      var now = this.get('model.executionDate') ? moment(this.get('model.executionDate')) : moment();
      var h = parseInt(now.format("HH"));
      var m = parseInt(now.format("mm"));
      this.setProperties({
        selectedExecutionDate: now.startOf('day').toDate(),
        executionDateHours: h,
        executionDateMinutes: m
      });
    })),
    setExecutionTime: Ember.observer('selectedExecutionDate', 'executionDateHours', 'executionDateMinutes', function () {
      var self = this;
      Ember.run.once(this, function () {
        var selectedExecutionDate = self.get('selectedExecutionDate');
        var executionDateHours = self.get('executionDateHours') || 0;
        var executionDateMinutes = self.get('executionDateMinutes') || 0;
        var executionDate = moment(selectedExecutionDate).add(executionDateHours, 'h').add(executionDateMinutes, 'm').toDate();
        self.set('model.executionDate', executionDate);
      });
    }),
    validateExpectedDate: Ember.observer('model.executionDate', 'model.reasonForTheDelayMustBeGiven', 'model.delayReason', function () {
      var expectedDateValidates = true;
      var errors = [];
      var h = this.get('executionDateHours');

      if (!_jquery.default.isNumeric(h) || h > 23 || h < 0) {
        expectedDateValidates = false;
      }

      var m = this.get('executionDateMinutes');

      if (!_jquery.default.isNumeric(m) || m > 59 || m < 0) {
        expectedDateValidates = false;
      }

      if (!this.get('model.executionDate') || isNaN(this.get('model.executionDate').getTime())) {
        expectedDateValidates = false;
      }

      if (this.get('mustBeInThePast') && moment(this.get('model.executionDate')) > moment()) {
        errors.push(this.get('intl').t('checkpoints.errors.executionTimeInFuture'));
        console.log('Data, która wywołała błąd \'mustBeInThePast\': ' + this.get('model.executionDate'));
        expectedDateValidates = false;
      }

      var minExecutionDate = this.get('model.minExecutionDate');

      if (minExecutionDate && moment(this.get('model.executionDate')) < moment(minExecutionDate)) {
        errors.push(this.get('intl').t('checkpoints.errors.executionTimeBeforePreviousCheckpoint', {
          minDate: moment(minExecutionDate).format('DD.MM.YYYY HH.mm')
        }));
        expectedDateValidates = false;
      }

      if (this.get('model.reasonForTheDelayMustBeGiven') && (this.get('model.delayReason') === undefined || this.get('model.delayReason').trim().length === 0)) {
        errors.push(this.get('intl').t('checkpoints.errors.reasonForTheDelay.blank'));
        expectedDateValidates = false;
      }

      console.log("expectedDateValidates: ".concat(expectedDateValidates));
      this.set('isExpectedDateValid', expectedDateValidates);
      this.set('errors', errors);
    })
  });

  _exports.default = _default;
});