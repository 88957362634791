define("apollo/pods/components/create-transport-button/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    actions: {
      openTransportModal: function openTransportModal(type) {
        var store = this.get('store');
        var transport = store.createRecord('transport', {
          transportType: type,
          transaction: store.createRecord('transaction', {})
        });
        store.createRecord('additionalDatum', {
          transport: transport
        });
        transport.set('advice', store.createRecord('advice', {}));

        if (type.get('typeGroup') === "MANUAL_TRANSPORT") {
          // zlecenie transportowe - dowolna ilość etapów, w dowolnej konfiguracji
          var loadStep = store.createRecord('step', {
            stepTypeName: 'load',
            orderInTransport: 0
          });
          transport.get('steps').pushObject(loadStep); // zakładamy, że najczęściej 'transport' będzie kończył się rozładunkiem w magazynie klienta;
          // użytkownik będzie mógł to zmienić podczas tworzenia transportu

          var unloadStep = store.createRecord('step', {
            stepTypeName: 'unload',
            orderInTransport: 1
          });
          transport.get('steps').pushObject(unloadStep);
        }

        this.showModal('transports.modals.create-transport', transport);
      }
    }
  });

  _exports.default = _default;
});