define("apollo/pods/companies/index/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['query', 'page', 'max'],
    query: '',
    companiesCreateButtonVisible: false,
    sessionAccount: Ember.inject.service('session-account'),
    queryInputChanged: Ember.observer('queryInput', function () {
      Ember.run.debounce(this, this.applyFilter, 500);
    }),
    partnershipType: Ember.computed('model.companyType', function () {
      var companyTypeName = this.get('model.companyType');
      return this.store.peekAll('partnershipType').filterBy('name', companyTypeName).get('firstObject');
    }),
    applyFilter: function applyFilter() {
      var queryInput = this.get('queryInput');
      this.set('query', queryInput);

      if (queryInput) {
        this.set('filterActive', true);
        this.set('page', 1);
      }
    },
    dateColumns: Ember.computed('', function () {
      return this.get('sessionAccount').get('resourceDateFormSettings').CARRIER;
    }),
    actions: {
      clearFilter: function clearFilter() {
        this.setProperties({
          query: '',
          queryInput: '',
          filterActive: false,
          page: 1
        });
      }
    }
  });

  _exports.default = _default;
});