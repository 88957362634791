define("apollo/pods/components/create-advice-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4mlqT/A+",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n\"],[4,\"unless\",[[24,[\"transport\",\"advice\",\"id\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\",true],[10,\"class\",\"col-xs-12\"],[8],[0,\"\\n            \"],[7,\"button\",false],[12,\"class\",\"btn btn-sm\"],[12,\"style\",[24,[\"transport\",\"backgroundColorStyle\"]]],[3,\"action\",[[23,0,[]],\"createAdvice\",[24,[\"transport\"]]]],[8],[0,\"\\n                \"],[1,[28,\"t\",[\"transport.advice\"],null],false],[0,\"\\n            \"],[9],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/create-advice-button/template.hbs"
    }
  });

  _exports.default = _default;
});