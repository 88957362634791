define("apollo/pods/trailers/index/controller", ["exports", "apollo/mixins/vehicle-mixin", "apollo/mixins/resource-mixin", "apollo/mixins/modal-mixin"], function (_exports, _vehicleMixin, _resourceMixin, _modalMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_vehicleMixin.default, _resourceMixin.default, _modalMixin.default, {
    queryParams: ['query', 'showAll', 'from', 'to', 'dateStatus', 'trailerType', 'page'],
    showAll: false,
    sessionAccount: Ember.inject.service('session-account'),
    trailers: Ember.computed('model', function () {
      return this.get('model');
    }),
    dateColumns: Ember.computed('', function () {
      return this.get('sessionAccount').get('resourceDateFormSettings').TRAILER;
    }),
    actions: {
      showDetails: function showDetails(vehicleId) {
        if (this.get('sessionAccount').hasRole('ROLE_CREATE_VEHICLE')) {
          this.transitionToRoute('trailers.edit', vehicleId);
        }
      }
    }
  });

  _exports.default = _default;
});