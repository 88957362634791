define("apollo/authenticators/custom", ["exports", "ember-simple-auth/authenticators/base", "apollo/config/environment", "jquery"], function (_exports, _base, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    cookies: Ember.inject.service(),
    restore: function restore(data) {
      return new Ember.RSVP.Promise(function (resolve, reject) {
        if (!Ember.isEmpty(data.token)) {
          Ember.run(function () {
            resolve({
              token: data.token,
              username: data.username
            });
          });
        } else {
          reject();
        }
      });
    },
    authenticate: function authenticate(credentials) {
      var tokenEndpoint = _environment.default.serverURL + '/login';
      var data = {
        username: credentials.identification,
        password: credentials.password
      };
      return new Ember.RSVP.Promise(function (resolve, reject) {
        _jquery.default.support.cors = true;

        _jquery.default.ajax({
          url: tokenEndpoint,
          type: 'POST',
          data: JSON.stringify(data),
          contentType: 'application/json'
        }).then(function (response) {
          Ember.run(function () {
            resolve({
              token: response.access_token,
              username: response.username
            });
          });
        }, function (xhr) {
          var response = xhr.statusText || xhr.responseText;
          Ember.run(function () {
            reject(response);
          });
        });
      });
    },
    invalidate: function invalidate(data) {
      var logoutEndpoint = _environment.default.serverURL + '/logout';
      return new Ember.RSVP.Promise(function (resolve) {
        _jquery.default.ajax({
          url: logoutEndpoint,
          type: 'POST',
          data: {
            access_token: data.token
          }
        }).always(function () {
          resolve();
        });
      });
    },
    actions: {
      sessionAuthenticationFailed: function sessionAuthenticationFailed(error) {
        console.log("sessionAuthenticationFailed..");
        this.controllerFor('application').set('loginErrorMessage', error.message);
      }
    }
  });

  _exports.default = _default;
});