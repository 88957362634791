define("apollo/pods/components/drivers/driver-form/component", ["exports", "apollo/mixins/autocomplete-utils-mixin"], function (_exports, _autocompleteUtilsMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_autocompleteUtilsMixin.default, {
    store: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    isExternalCompany: true,
    dateFields: Ember.computed('', function () {
      return this.get('sessionAccount').get('resourceDateFormSettings').DRIVER;
    }),
    setIsExternalCompany: Ember.on('init', Ember.observer('driver.company.isExternal', function () {
      var self = this;
      var company = this.get('driver').get('company');

      if (!company) {
        return;
      }

      company.then(function (loadedCompany) {
        if (loadedCompany) {
          self.set('isExternalCompany', loadedCompany.get('isExternal'));
        }
      });
    })),
    previousLeaves: Ember.computed('driver.leaves.length', function () {
      var self = this;
      return self.get('driver.leaves').filter(function (l) {
        return l.get('leaveStep.deliveryDateTo') > new Date().setFullYear(new Date().getFullYear() - 1) && l.get('leaveStep.deliveryDateTo') < new Date();
      }).sortBy('leaveStep.deliveryDateFrom');
    }),
    nextLeaves: Ember.computed('driver.leaves.@each.deleted', 'driver.leaves.length', function () {
      var self = this;
      return self.get('driver.leaves').filter(function (l) {
        return l.get('leaveStep.deliveryDateTo') > new Date() && l.get('deleted') === false;
      }).sortBy('leaveStep.deliveryDateFrom');
    }),
    actions: {
      setTruck: function setTruck(truck) {
        this.setItem('driver.truck', 'truck', truck.id);
      },
      removeTruck: function removeTruck() {
        this.clearItem('driver.truck');
      },
      changeResourceDate: function changeResourceDate(driver, dateField, newDate) {
        var resourceDate = driver.get('resourceDates').find(function (rd) {
          return rd.get('controlledDateType.name') === dateField;
        });

        if (!resourceDate) {
          var type = this.get('store').peekAll('controlledDateType').find(function (rdt) {
            return rdt.get('name') === dateField;
          });
          resourceDate = this.get('store').createRecord('resourceDate', {
            driver: driver,
            controlledDateType: type
          });
        }

        resourceDate.set('date', newDate);
      },
      changeDate: function changeDate(dateToChange, newDate) {
        this.set(dateToChange, newDate);
      },
      toggleProperty: function toggleProperty(propertyName) {
        this.get('driver').toggleProperty(propertyName);
      },
      showModal: function showModal(template, transport) {
        this.showModal(template, transport);
      }
    }
  });

  _exports.default = _default;
});