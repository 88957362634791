define("apollo/pods/controlling-integration/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    transport: _emberData.default.belongsTo('transport', {
      async: true
    }),
    message: _emberData.default.attr('string'),
    responseValue: _emberData.default.attr('string'),
    dateCreated: _emberData.default.attr('date'),
    lastUpdated: _emberData.default.attr('date'),
    isErrorMessage: _emberData.default.attr('boolean')
  });

  _exports.default = _default;
});