define("apollo/helpers/find-warning-class", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    compute: function compute(params) {
      var controlledObject = params[0];
      var dateName = params[1];
      var controlledDate = controlledObject.findControlledDates().find(function (date) {
        return date.get('controlledDateType.name') === dateName;
      });

      if (!controlledDate || !controlledDate.get('date')) {
        return '';
      }

      var firstWarning = controlledDate.get('controlledDateType.daysBeforeFirstWarning');
      var secondWarning = controlledDate.get('controlledDateType.daysBeforeSecondWarning');
      var today = moment(new Date());
      var date = moment(controlledDate.get('date'));
      var difference = date.diff(today, 'days');

      if (difference < secondWarning) {
        return 'document-second-expiration-warning';
      } else if (difference < firstWarning) {
        return 'document-first-expiration-warning';
      }

      return '';
    }
  });

  _exports.default = _default;
});