define("apollo/mixins/modal-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    actions: {
      showModal: function showModal(name, model) {
        this.target.send("showModal", name, model);
      },
      removeModal: function removeModal() {
        this.target.send("removeModal");
      }
    }
  });

  _exports.default = _default;
});