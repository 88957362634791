define("apollo/pods/transport-types/permissions/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    errors: [],
    actions: {
      save: function save() {
        var self = this;
        var transportType = self.get('model.transportType');
        transportType.save().then(function () {
          self.setProperties({
            success: true,
            errors: []
          });
          var msg = self.get('intl').t('settings.transportTypes.permissionsSaveSuccess');
          self.set('successMessage', msg);
        }).catch(function (response) {
          self.set('errors', response.errors);
        });
      }
    }
  });

  _exports.default = _default;
});