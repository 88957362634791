define("apollo/pods/transaction/model", ["exports", "ember-data", "apollo/mixins/model-validator", "ember-data-copyable", "apollo/mixins/input-utils-mixin"], function (_exports, _emberData, _modelValidator, _emberDataCopyable, _inputUtilsMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend(_modelValidator.default, _emberDataCopyable.default, _inputUtilsMixin.default, {
    intl: Ember.inject.service(),
    transport: _emberData.default.belongsTo('transport', {
      async: true
    }),
    currency: _emberData.default.belongsTo('currency', {
      async: true
    }),
    currencyOfFreight: _emberData.default.belongsTo('currency', {
      async: true
    }),
    currencyOfWash: _emberData.default.belongsTo('currency', {
      async: true
    }),
    priceOfFreight: _emberData.default.attr('number'),
    priceOfService: _emberData.default.attr('number'),
    priceOfWash: _emberData.default.attr('number'),
    validations: {
      currency: {
        presence: {
          message: 'blank'
        }
      },
      priceOfService: {
        custom: [{
          validation: function validation(key, value, model) {
            return !!value && model.isCorrectPrice(value);
          },
          message: 'incorrectNumber'
        }]
      },
      priceOfFreight: {
        custom: [{
          validation: function validation(key, value, model) {
            if (value || !model.get('transport.carrierCompany.id')) {
              return true;
            }

            return !!value && model.isCorrectPrice(value);
          },
          message: 'incorrectNumber'
        }]
      },
      priceOfWash: {
        custom: [{
          validation: function validation(key, value, model) {
            if (!model.get('transport.wash.id')) {
              return true;
            }

            return !Ember.isEmpty(value) && model.isCorrectPrice(value);
          },
          message: 'incorrectNumber'
        }]
      },
      currencyOfWash: {
        custom: [{
          validation: function validation(key, value, model) {
            if (!model.get('transport.wash.id')) {
              return true;
            }

            return !!value;
          },
          message: 'blank'
        }]
      }
    },
    profitMargin: Ember.computed('priceOfService', 'priceOfFreight', function () {
      var priceOfFreight = this.get('priceOfFreight');

      if (this.get('priceOfService') === null || priceOfFreight === null) {
        return null;
      }

      var formattedPriceOfFreight = this.isNumber(priceOfFreight) ? priceOfFreight : Number(this.get('priceOfFreight').replace(",", "."));

      if (isNaN(formattedPriceOfFreight)) {
        return null;
      }

      return (this.get('priceOfService') - formattedPriceOfFreight).toFixed(2);
    }),
    minimalMarginSettingName: Ember.computed('transport.personsGroup.name', function () {
      switch (this.get('transport.personsGroup.name')) {
        case 'NATIONAL':
          {
            return 'MINIMAL_NATIONAL_EXPECTED_MARGIN';
          }

        case 'INTERNATIONAL':
          {
            return 'MINIMAL_INTERNATIONAL_EXPECTED_MARGIN';
          }

        case 'INTERMODAL':
          {
            return 'MINIMAL_INTERMODAL_EXPECTED_MARGIN';
          }

        default:
          {
            return 'MINIMAL_NATIONAL_EXPECTED_MARGIN';
          }
      }
    }),
    minimalProfitMargin: Ember.computed('minimalMarginName', function () {
      return this.get('store').peekAll('setting').filterBy('name', this.get('minimalMarginSettingName')).get('firstObject').get('value');
    }),
    profitMarginTooLow: Ember.computed('profitMargin', 'minimalProfitMargin', 'transport.carrierCompany.isPrincipal', function () {
      if (this.get('transport.carrierCompany.isPrincipal') || this.get('profitMargin') === null) {
        return false;
      }

      return parseFloat(this.get('profitMargin')) < parseFloat(this.get('minimalProfitMargin'));
    })
  });

  _exports.default = _default;
});