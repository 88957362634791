define("apollo/pods/file/model", ["exports", "ember-data", "apollo/config/environment"], function (_exports, _emberData, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    creator: _emberData.default.belongsTo('user', {
      async: true
    }),
    company: _emberData.default.belongsTo('company', {
      async: true
    }),
    transport: _emberData.default.belongsTo('transport', {
      async: true
    }),
    resourceDate: _emberData.default.belongsTo('resource-date', {
      async: true
    }),
    name: _emberData.default.attr('string'),
    active: _emberData.default.attr('boolean'),
    dateCreated: _emberData.default.attr('date'),
    refLink: Ember.computed('transport.id', 'company.id', function () {
      var url = _environment.default.serverURL + '/files/' + this.id + '/download?';

      if (this.get('transport.id')) {
        url += 'transportId=' + this.get('transport.id');
      } else if (this.get('company.id')) {
        url += 'companyId=' + this.get('company.id');
      }

      return url;
    })
  });

  _exports.default = _default;
});