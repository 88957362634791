define("apollo/pods/driver/model", ["exports", "ember-data", "apollo/mixins/model-validator"], function (_exports, _emberData, _modelValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend(_modelValidator.default, {
    leaves: _emberData.default.hasMany('transport', {
      async: true
    }),
    company: _emberData.default.belongsTo('company', {
      async: true
    }),
    country: _emberData.default.belongsTo('country', {
      async: true
    }),
    truck: _emberData.default.belongsTo('truck', {
      async: true
    }),
    name: _emberData.default.attr('string'),
    documentNumber: _emberData.default.attr('string'),
    identifier: _emberData.default.attr('string'),
    phoneNumberPrefix: _emberData.default.attr('string'),
    phoneNumber: _emberData.default.attr('string'),
    driverLicenceNumber: _emberData.default.attr('string'),
    driverCardNumber: _emberData.default.attr('string'),
    adrNumber: _emberData.default.attr('string'),
    comment: _emberData.default.attr('string'),
    isExternalResource: _emberData.default.attr('boolean'),
    enabled: _emberData.default.attr('boolean'),
    active: _emberData.default.attr('boolean', {
      defaultValue: function defaultValue() {
        return true;
      }
    }),
    rodoAccepted: _emberData.default.attr('boolean'),
    flTrackerId: _emberData.default.attr('string'),
    resourceDates: _emberData.default.hasMany('resource-date'),
    exactTypeName: Ember.computed('', function () {
      return 'DRIVER';
    }),
    validations: {
      name: {
        presence: {
          message: 'blank'
        }
      },
      phoneNumber: {
        presence: {
          message: 'blank'
        }
      },
      phoneNumberPrefix: {
        presence: {
          message: 'blank'
        }
      },
      documentNumber: {
        presence: {
          message: 'blank'
        }
      },
      rodoAccepted: {
        custom: [{
          validation: function validation(key, value, model) {
            return model.get('canNotBeEdited') || !!value;
          },
          message: 'blank'
        }]
      }
    },
    findControlledDates: function findControlledDates() {
      return this.get('resourceDates');
    }
  });

  _exports.default = _default;
});