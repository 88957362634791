define("apollo/pods/invitations/index/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    sessionAccount: Ember.inject.service('session-account'),
    receivedInvitations: Ember.computed('model.@each', function () {
      var currentUserId = this.get('sessionAccount').get('currentUser.id');
      return this.get('model').filter(function (item) {
        return item.get("creator.id") !== currentUserId;
      });
    }),
    sentInvitations: Ember.computed('model.@each', function () {
      var currentUserId = this.get('sessionAccount').get('currentUser.id');
      return this.get('model').filter(function (item) {
        return item.get("creator.id") === currentUserId;
      });
    })
  });

  _exports.default = _default;
});