define("apollo/pods/trucks/edit/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    sessionAccount: Ember.inject.service('session-account'),
    access: ['ROLE_CREATE_VEHICLE'],
    beforeModel: function beforeModel(transition) {
      if (!this.get('sessionAccount').hasAnyOfTheRoles(this.get('access'))) {
        transition.abort();
        this.transitionTo('forbidden');
      }
    },
    model: function model(params) {
      var truck = this.store.findRecord('truck', params.truck_id);
      return Ember.RSVP.hash({
        vehicle: truck
      });
    },
    renderTemplate: function renderTemplate() {
      this.render('trucks.edit', {
        controller: 'trailers.create'
      });
    },
    setupController: function setupController(controller, model) {
      this.controllerFor('trailers.create').setProperties({
        model: model,
        isEditing: true
      });
    },
    actions: {
      willTransition: function willTransition() {
        this.controllerFor('trailers.create').setProperties({
          successMessage: null,
          errors: [],
          showOrderHistory: false,
          showServiceHistory: false,
          showResourcesHistory: false,
          historyLogs: []
        });
      }
    }
  });

  _exports.default = _default;
});