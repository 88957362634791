define("apollo/pods/components/audit-log/component", ["exports", "apollo/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    sessionAccount: Ember.inject.service('session-account'),
    intl: Ember.inject.service(),
    cookies: Ember.inject.service(),
    store: Ember.inject.service(),
    tagName: 'span',
    type: 'button',
    actions: {
      getLogs: function getLogs(modelId, className) {
        var self = this;
        var token = self.get('sessionAccount').get('token');
        jQuery.ajax({
          url: _environment.default.serverURL + '/audit-log/getLogs/',
          type: 'GET',
          data: {
            className: className,
            id: modelId
          },
          beforeSend: function beforeSend(xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
          }
        }).then(function (json) {
          self.get('store').pushPayload(json);
          var model = self.get('store').peekRecord(className.toLowerCase(), modelId);
          model.set('auditLogs', []);
          json.auditLogs.forEach(function (auditLog) {
            var log = self.get('store').peekRecord('auditLog', auditLog.id);
            model.get('auditLogs').pushObject(log);
          });
          self.sendAction('loadingInProgress');
          self.sendAction('toggleTable');
        });
      },
      loadingData: function loadingData() {
        this.sendAction('loadingInProgress');
      }
    }
  });

  _exports.default = _default;
});