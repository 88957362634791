define("apollo/pods/components/show-manuals/component", ["exports", "apollo/config/environment", "apollo/pods/components/show-manuals/template"], function (_exports, _environment, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    sessionAccount: Ember.inject.service('session-account'),
    setManualHref: Ember.on('init', function () {
      var href = _environment.default.serverURL + "/files/manual?token=" + this.get("sessionAccount").get("token");
      this.set("manualHref", href);
    })
  });

  _exports.default = _default;
});