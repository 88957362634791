define("apollo/services/fresh-widget", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    loadWidget: function loadWidget() {
      FreshWidget.init("", {
        "queryString": "&widgetType=popup&formTitle=Wsparcie+techniczne&submitTitle=Wy%C5%9Blij&submitThanks=Dzi%C4%99kujemy+za+zg%C5%82oszenie&captcha=yes&searchArea=no",
        "utf8": "✓",
        "widgetType": "popup",
        "buttonType": "text",
        "buttonText": "Support",
        "buttonColor": "white",
        "buttonBg": "#f57004",
        "alignment": "2",
        "offset": "200px",
        "submitThanks": "Dziękujemy za zgłoszenie",
        "formHeight": "550px",
        "captcha": "yes",
        "url": "https://logintegra.freshdesk.com",
        "loadOnEvent": "immediate"
      });
    }
  });

  _exports.default = _default;
});