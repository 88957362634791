define("apollo/pods/advice/model", ["exports", "ember-data", "apollo/mixins/model-validator", "ember-data-copyable", "apollo/mixins/input-utils-mixin"], function (_exports, _emberData, _modelValidator, _emberDataCopyable, _inputUtilsMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend(_modelValidator.default, _emberDataCopyable.default, _inputUtilsMixin.default, {
    changeTracker: {
      auto: true,
      enableIsDirty: true
    },
    sessionAccount: Ember.inject.service('session-account'),
    transport: _emberData.default.belongsTo('transport', {
      async: true
    }),
    creator: _emberData.default.belongsTo('user', {
      async: true,
      embedded: 'save'
    }),
    driver: _emberData.default.belongsTo('driver', {
      async: true
    }),
    additionalDriver: _emberData.default.belongsTo('driver', {
      async: true
    }),
    trailer: _emberData.default.belongsTo('vehicle', {
      async: true
    }),
    truck: _emberData.default.belongsTo('truck', {
      async: true
    }),
    associatedContainer: _emberData.default.belongsTo('container', {
      async: true
    }),
    dateCreated: _emberData.default.attr(),
    emptyKilometers: _emberData.default.attr('number'),
    termsOfConditionsAccepted: _emberData.default.attr('boolean'),
    canBeUpdated: _emberData.default.attr('boolean'),
    intermodalSettlement: _emberData.default.attr('boolean'),
    validations: {
      driver: {
        presence: {
          message: 'blank'
        }
      },
      truck: {
        presence: {
          message: 'blank'
        }
      },
      emptyKilometers: {
        custom: {
          validation: function validation(key, value, model) {
            if (model.get('transport.carrierCompany.isExternal')) {
              return true;
            }

            return model.isCorrectInteger(value) && Number(value) > 0;
          },
          message: 'incorrectNumber'
        }
      }
    },
    canNotBeEdited: Ember.computed('transport.canNotBeEdited', 'transport.advice.id', 'advice.canBeUpdated', function () {
      if (!this.get('transport.advice.id')) {
        return false;
      }

      return this.get('transport.canNotBeEdited') || !this.get('canBeUpdated');
    }),
    trailerLabel: Ember.computed('trailer.{registrationNumber,sideNumber,model,owner,associatedContainer.registrationNumber}', 'transport.archived', 'associatedContainer', function () {
      var trailer = this.get('trailer.content');

      if (!trailer) {
        return '';
      }

      if (trailer.get('owner.isExternal')) {
        return trailer.get('registrationNumber');
      } else {
        var registrationNumber = trailer.get('registrationNumber') ? trailer.get('registrationNumber') : "";
        var sideNumber = trailer.get('sideNumber') ? trailer.get('sideNumber') : "";
        var model = trailer.get('model') ? trailer.get('model') : "";
        var label = "".concat(registrationNumber, " ").concat(sideNumber, " ").concat(model);

        if (this.get('transport.archived') && this.get('associatedContainer.id')) {
          label += "/ ".concat(this.get('associatedContainer.registrationNumber'));
        } else if (trailer.get('associatedContainer.id')) {
          label += "/ ".concat(trailer.get('associatedContainer.registrationNumber'));
        }

        return label.trim();
      }
    }),
    containerLabel: Ember.computed('associatedContainer.registrationNumber', function () {
      return this.get('associatedContainer.registrationNumber');
    }),
    findInvalidResourceDates: function findInvalidResourceDates(clientId, onlyChangedResources) {
      var _this = this;

      var invalidResourceDates = [];
      ['truck', 'trailer', 'trailer.associatedContainer', 'driver', 'additionalDriver'].forEach(function (resource) {
        var resourceId = _this.get("".concat(resource, ".id")); // przerywamy walidację, jeśli zasób nie jest wybrany lub jeśli nie zaszły w nim zmiany
        //w przypadku zmiany klienta - sprawdzamy wszystkie daty niezależnie od zmian w nich (flaga onlyChangedResources === false)


        if (!resourceId || onlyChangedResources && _this.get('id') && !Object.keys(_this.modelChanges()).includes(resource)) {
          return;
        }

        var resourcesWithInvalidDates = _this.get('store').peekAll('resourceDate').filter(function (rd) {
          return rd.belongsToResource(resourceId);
        }).filter(function (rd) {
          return rd.applyToClient(clientId);
        }).filter(function (rd) {
          return rd.isInvalid();
        });

        if (resourcesWithInvalidDates) {
          invalidResourceDates.pushObjects(resourcesWithInvalidDates);
        }
      });
      return invalidResourceDates;
    },
    clearFormData: function clearFormData() {
      this.setProperties({
        driver: null,
        additionalDriver: null,
        trailer: null,
        truck: null,
        associatedContainer: null,
        emptyKilometers: null,
        termsOfConditionsAccepted: false
      });
    }
  });

  _exports.default = _default;
});