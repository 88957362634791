define("apollo/pods/components/custom-auto-complete/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0FDLF/38",
    "block": "{\"symbols\":[\"result\",\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"typeahead-wrap\"],[8],[0,\"\\n    \"],[1,[28,\"input\",null,[[\"class\",\"disabled\",\"value\",\"placeholder\",\"autocomplete\",\"spellcheck\"],[[24,[\"inputClazz\"]],[24,[\"disabled\"]],[24,[\"selectedValue\"]],[24,[\"placeHolderText\"]],\"off\",\"false\"]]],false],[0,\"\\n\\n\"],[4,\"if\",[[28,\"or\",[[24,[\"suggestions\"]],[24,[\"noMesssagePlaceHolderText\"]]],null]],null,{\"statements\":[[0,\"        \"],[7,\"div\",true],[10,\"class\",\"tt-dropdown-menu\"],[11,\"style\",[22,\"visibility\"]],[8],[0,\"\\n            \"],[7,\"div\",true],[10,\"class\",\"tt-dataset-states\"],[8],[0,\"\\n                \"],[7,\"span\",true],[10,\"class\",\"tt-suggestions\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"suggestions\"]]],null,{\"statements\":[[0,\"                        \"],[7,\"div\",false],[12,\"class\",[29,[\"suggestion \",[28,\"if\",[[23,1,[\"highlight\"]],\"highlight\"],null]]]],[3,\"action\",[[23,0,[]],\"selectItem\",[23,1,[]]]],[8],[0,\"\\n                            \"],[14,2,[[23,1,[]]]],[0,\"\\n                        \"],[9],[0,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[0,\"                        \"],[7,\"div\",true],[10,\"class\",\"message\"],[8],[0,\"\\n                            \"],[7,\"p\",true],[8],[1,[22,\"noMesssagePlaceHolderText\"],false],[9],[0,\"\\n                        \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"                \"],[9],[0,\"\\n            \"],[9],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/custom-auto-complete/template.hbs"
    }
  });

  _exports.default = _default;
});