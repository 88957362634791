define("apollo/helpers/format-calendar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatCalendar = formatCalendar;
  _exports.default = void 0;

  function formatCalendar(params) {
    var date = params[0];
    var localeCode = params[1];
    var dateFormat = params[2] || 'DD.MM.YYYY HH:mm';
    var dateIsValidDate = date && Object.prototype.toString.call(date) === '[object Date]';

    if (!dateIsValidDate) {
      return '';
    }

    moment.locale(localeCode);
    return moment(date).calendar(null, {
      sameDay: dateFormat,
      nextDay: dateFormat,
      nextWeek: dateFormat,
      lastDay: dateFormat,
      lastWeek: dateFormat,
      sameElse: dateFormat
    });
  }

  var _default = Ember.Helper.helper(formatCalendar);

  _exports.default = _default;
});