define("apollo/pods/dummy/create/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ad7JzyP5",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h2\",true],[8],[0,\"\\n  Dummy\\n\"],[9],[0,\"\\n\"],[7,\"h3\",true],[8],[0,\"\\n  Create\\n\"],[9],[0,\"\\n\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/dummy/create/template.hbs"
    }
  });

  _exports.default = _default;
});