define("apollo/pods/controlled-date-types/edit/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    success: false,
    errors: [],
    intl: Ember.inject.service(),
    specificClient: Ember.computed('model.specificClientId', function () {
      if (!this.get('model.specificClientId')) {
        return null;
      }

      return this.get('store').findRecord('company', this.get('model.specificClientId'));
    }),
    actions: {
      save: function save() {
        var self = this;
        var controlledDateType = this.get('model');
        controlledDateType.save().then(function () {
          self.set('success', true);
        }).catch(function (response) {
          self.set('errors', response.errors);
        });
      },
      cancel: function cancel() {
        this.get('model').rollbackAttributes();
        this.transitionToRoute('settings.index');
      }
    }
  });

  _exports.default = _default;
});