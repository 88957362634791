define("apollo/pods/components/file-upload/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4ifAAZW6",
    "block": "{\"symbols\":[],\"statements\":[[7,\"span\",true],[10,\"class\",\"btn btn-default btn-file\"],[8],[0,\"\\n    \"],[1,[28,\"t\",[\"common.uploadFile\"],null],false],[0,\" \"],[7,\"input\",true],[10,\"class\",\"file-upload-input\"],[10,\"name\",\"files[]\"],[10,\"type\",\"file\"],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"progress\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"bar\"],[10,\"style\",\"width: 0%;\"],[8],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/file-upload/template.hbs"
    }
  });

  _exports.default = _default;
});