define("apollo/pods/components/resources-history/component", ["exports", "jquery", "apollo/config/environment", "apollo/mixins/persistence-mixin"], function (_exports, _jquery, _environment, _persistenceMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_persistenceMixin.default, {
    store: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    session: Ember.inject.service('session'),
    cookies: Ember.inject.service(),
    intl: Ember.inject.service(),
    dataLoaded: true,
    searchParams: {},
    page: 1,
    totalPages: 0,
    loadingMore: false,
    queryParams: Ember.computed('searchParams.query', 'page', 'searchParams.filters', 'searchParams.filters.@each.filterValue', 'modelId', function () {
      var params = {
        query: this.get('searchParams.query'),
        principalId: this.get('cookies').read('principalId'),
        page: this.get('page'),
        filters: this.get('searchParams.filters'),
        actor: this.get('searchParams.actor'),
        assetType: this.get('searchParams.assetType'),
        className: this.get('searchParams.className'),
        resourceId: this.get('searchParams.driverId') || this.get('searchParams.trailerId') || this.get('searchParams.containerId') || this.get('searchParams.truckId') || this.get('searchParams.carrierId') || this.get('searchParams.clientId') || this.get('searchParams.washId') || this.get('searchParams.id')
      };
      return params;
    }),
    historyLogs: Ember.computed({
      get: function get() {
        if (this._historyLogs) {
          return this._historyLogs;
        }

        return [];
      },
      set: function set(key, value) {
        return this._historyLogs = value;
      }
    }),
    loadingInProgress: Ember.computed({
      get: function get() {
        if (this._loadingInProgress) {
          return this._loadingInProgress;
        }

        return false;
      },
      set: function set(key, value) {
        return this._loadingInProgress = value;
      }
    }),
    queryInputChanged: Ember.observer('searchParams.filters.@each.filterValue', 'searchParams.query', function () {
      Ember.run.debounce(this, this.applyFilter, 500);
    }),
    applyFilter: function applyFilter() {
      this.setProperties({
        historyLogs: [],
        page: 1,
        dataLoaded: false
      });
    },
    searchHistory: Ember.observer('dataLoaded', function () {
      if (this.get('dataLoaded') === true) {
        return;
      }

      var self = this;
      this.set('loadingInProgress', true);
      var queryParams = Object.assign({}, self.get('queryParams'));
      var token = self.get('session.data.authenticated.token');

      _jquery.default.ajax({
        url: _environment.default.serverURL + '/audit-log/getLogsFiltered/',
        data: JSON.stringify(queryParams),
        type: 'POST',
        dataType: 'json',
        contentType: 'application/json; charset=utf-8',
        beforeSend: function beforeSend(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        }
      }).then(function (json) {
        self.get('store').pushPayload(json);
        self.set('historyLogs', []);
        json.auditLogs.forEach(function (auditLog) {
          var log = self.get('store').peekRecord('auditLog', auditLog.id);
          self.get('historyLogs').pushObject(log);
        });
        self.set('loadingInProgress', false);
        self.set('loadingMore', false);
      });
    }),
    didRender: function didRender() {
      var self = this;
      Ember.run.later(function () {
        if (!self.isDestroyed) {
          if (self.get('historyLogs.length') > 0) {
            console.debug("History loading finished.");
            self.setProperties({
              dataLoaded: true
            });
          } else if (self.get('historyLogs.length') > 0) {
            console.debug('Loading history');
          } else if (self.get('historyLogs.length') === 0) {
            self.set('dataLoaded', true);
          }
        }
      });
    }
  });

  _exports.default = _default;
});