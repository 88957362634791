define("apollo/helpers/find-forwarder-in-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.findForwarderInGroup = findForwarderInGroup;
  _exports.default = void 0;

  function findForwarderInGroup(params) {
    var key = params[0];
    var param1 = params[1];
    if (param1.keys().next().value === key) return param1.values().next().value;
  }

  var _default = Ember.Helper.helper(findForwarderInGroup);

  _exports.default = _default;
});