define("apollo/pods/transports/modals/edit-execution-date/controller", ["exports", "apollo/config/environment", "apollo/mixins/modal-mixin", "jquery"], function (_exports, _environment, _modalMixin, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_modalMixin.default, {
    sessionAccount: Ember.inject.service('session-account'),
    actions: {
      editExecutionDate: function editExecutionDate(model) {
        var orderId = model.get('id');
        var executionDateString = model.get('executionDate') ? moment(model.get('executionDate')).format() : "";
        var token = this.get('sessionAccount').get('token');

        _jquery.default.ajax({
          url: _environment.default.serverURL + "/external-costs/".concat(orderId, "/editExecutionDate"),
          type: 'PUT',
          contentType: 'application/json; charset=utf-8',
          dataType: 'json',
          data: JSON.stringify({
            executionDate: executionDateString
          }),
          beforeSend: function beforeSend(xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
          },
          complete: function complete() {
            (0, _jquery.default)('.modal').modal('hide');
          }
        }).then(function () {}, function (response) {
          if (response.responseJSON.errors) {
            alert(response.responseJSON.errors.join("\n"));
          }
        });
      }
    }
  });

  _exports.default = _default;
});