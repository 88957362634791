define("apollo/pods/car-type/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    intl: Ember.inject.service(),
    carName: _emberData.default.attr('string'),
    englishCarName: _emberData.default.attr('string'),
    nameInAppropriateLanguage: Ember.computed('carName', 'englishCarName', function () {
      if (this.get('intl.locale')[0] !== 'pl' && this.get('englishCarName')) {
        return this.get('englishCarName');
      } else {
        return this.get('carName');
      }
    })
  });

  _exports.default = _default;
});