define("apollo/pods/transport-types/permissions/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    sessionAccount: Ember.inject.service('session-account'),
    access: ['ROLE_SHOW_PERMISSIONS_TRANSPORT_TYPE'],
    beforeModel: function beforeModel(transition) {
      if (!this.get('sessionAccount').hasAnyOfTheRoles(this.get('access'))) {
        transition.abort();
        this.transitionTo('forbidden');
      }
    },
    model: function model(params) {
      return Ember.RSVP.hash({
        transportType: this.store.peekRecord('transport-type', params.id),
        roleGroups: this.store.query('role-group', {
          transportTypeId: params.id
        })
      });
    }
  });

  _exports.default = _default;
});