define("apollo/pods/components/vehicles/trailer-form/component", ["exports", "apollo/mixins/vehicle-mixin", "apollo/mixins/autocomplete-utils-mixin"], function (_exports, _vehicleMixin, _autocompleteUtilsMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_vehicleMixin.default, _autocompleteUtilsMixin.default, {
    store: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    dateFields: Ember.computed('vehicle.trailerType', function () {
      return this.get('sessionAccount').get('resourceDateFormSettings')[this.get('vehicle.trailerType')];
    }),
    actions: {
      setTruck: function setTruck(truck) {
        this.setItem('vehicle.truck', 'truck', truck.id);
      },
      removeTruck: function removeTruck() {
        this.clearItem('vehicle.truck');
      },
      setContainer: function setContainer(container) {
        this.setItem('vehicle.associatedContainer', 'container', container.id);
      },
      removeContainer: function removeContainer() {
        this.clearItem('vehicle.associatedContainer');
      }
    }
  });

  _exports.default = _default;
});