define("apollo/mixins/attachment-mixin", ["exports", "jquery", "apollo/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    cookies: Ember.inject.service(),
    intl: Ember.inject.service(),
    uploadAttachment: function uploadAttachment(id, endpoint, token, attachments) {
      var self = this;
      var errors = [];

      if (Ember.isEmpty(attachments)) {
        return;
      }

      self.set('uploading', true);
      return new Ember.RSVP.Promise(function (resolve, reject) {
        var promises = attachments.map(function (file) {
          return self.readFile(file).then(function (data) {
            return self.uploadFile(data, file.name, id, endpoint, token);
          }).then(function success() {
            self.incrementProperty('completed');
          }, function error(error) {
            errors.push({
              file: file.name,
              reason: error
            });
          });
        });
        Ember.RSVP.all(promises).then(function () {
          if (errors.length) {
            self.set('errors', errors);
          }

          resolve();
        }, function (response) {
          reject(response);
        });
      });
    },
    readFile: function readFile(file) {
      return new Ember.RSVP.Promise(function (resolve) {
        var reader = new moxie.file.FileReader();

        reader.onloadend = function () {
          resolve(reader.result);
        };

        reader.readAsDataURL(file);
      });
    },
    uploadFile: function uploadFile(file, fileName, id, endpoint, token) {
      var self = this;
      return new Ember.RSVP.Promise(function (resolve) {
        _jquery.default.ajax({
          type: 'POST',
          url: "".concat(_environment.default.serverURL, "/").concat(endpoint, "/").concat(id, "/files/"),
          data: JSON.stringify({
            file: file,
            fileName: fileName
          }),
          contentType: 'application/json; charset=utf-8',
          beforeSend: function beforeSend(xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
          }
        }).then(function (response) {
          if (response.status === 'fileExists') {
            alert(self.get('intl').t('file.errors.' + response.status, {
              fileName: response.fileName
            }));
          } else {
            resolve();
          }
        }, function (response) {
          reject(response);
        });
      });
    },
    deleteFile: function deleteFile(fileId) {
      console.log("deleteFile: ".concat(fileId));
      this.get('store').findRecord('file', fileId, {
        backgroundReload: false
      }).then(function (f) {
        f.destroyRecord();
      });
    },
    actions: {
      generateXLS: function generateXLS(model, documentMode) {
        var self = this;
        console.log("documentMode: ".concat(documentMode));
        Ember.run.once(this, function () {
          var urlPart = documentMode === 'TRANSPORTS' ? '/transports/xls' : '/drivers/routes/xls';
          var url = _environment.default.serverURL + "".concat(urlPart, "?token=") + this.get("sessionAccount").get("token");
          var transportTypeIds = model.transportTypes ? model.transportTypes.map(function (type) {
            return type.get('id');
          }) : [];
          var params = {
            //do przemyślenia czy cały ten obiekt nie powinien być wymieniany między komponentami zamiast tylko searchParams
            query: model.queryProxy,
            archived: model.archive,
            transportTypeIds: transportTypeIds.join(','),
            principalId: this.get('cookies').read('principalId'),
            start: model.start ? moment(model.start).format() : '',
            stop: model.stop ? moment(model.stop).format() : '',
            dateFilterMode: model.dateFilterMode,
            filters: JSON.stringify(model.searchParams.filters)
          };
          var currentUser = this.get('sessionAccount').get('currentUser');

          if (currentUser.get('roleGroup.name') === 'TRADESMAN') {
            params.tradesmanId = currentUser.id;
            params.filterMode = model.searchParams.filterMode;
          }

          url += "&" + $.param(params);
          window.location = url;
        });
      }
    }
  });

  _exports.default = _default;
});