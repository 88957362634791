define("apollo/pods/invitations/create/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      var origin = window.location.origin + '#';
      var invitationModel = this.store.createRecord('invitation', {
        enabled: true,
        origin: origin
      });
      var partnershipTypes = this.store.peekAll('partnershipType').filterBy('canBeInvited', true);
      return Ember.RSVP.hash({
        invitation: invitationModel,
        partnershipTypes: partnershipTypes
      });
    },
    actions: {
      willTransition: function willTransition() {
        this.get('controller').setProperties({
          invitationEmail: null,
          invitationRoleGroup: null,
          invitationError: null,
          invitationSuccess: null,
          success: false,
          errors: []
        });
        this.get('controller.model.invitation').unloadRecord();
        return true;
      }
    }
  });

  _exports.default = _default;
});