define("apollo/pods/transport-notification/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    transport: _emberData.default.belongsTo('transport', {
      async: true
    }),
    notification: _emberData.default.belongsTo('notification', {
      async: true
    }),
    sender: _emberData.default.belongsTo('user', {
      async: true
    }),
    active: _emberData.default.attr('boolean'),
    sent: _emberData.default.attr('boolean'),
    dateSent: _emberData.default.attr('isodate'),
    receivers: _emberData.default.attr('string'),
    delivered: _emberData.default.attr('boolean'),
    dateCreated: _emberData.default.attr('isodate'),
    canBeSent: Ember.computed('sent', 'transport.isActive', 'notification.sentAutomatically', function () {
      return !this.get('sent') && this.get('transport.isActive') && !this.get('notification.sentAutomatically');
    })
  });

  _exports.default = _default;
});