define("apollo/pods/workflow/model", ["exports", "ember-data", "apollo/mixins/model-validator"], function (_exports, _emberData, _modelValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend(_modelValidator.default, {
    tasks: _emberData.default.hasMany('task', {
      async: true
    }),
    name: _emberData.default.attr('string'),
    transportType: _emberData.default.belongsTo('transportType', {
      async: true
    }),
    validations: {
      tasks: {
        relations: ['hasMany']
      }
    },
    sortedTasks: Ember.computed('tasks.@each.idxInGroup', function () {
      return this.get('tasks').sortBy('taskGroupIdentifier', 'idxInGroup');
    })
  });

  _exports.default = _default;
});