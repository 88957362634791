define("apollo/pods/components/vehicles/truck-form/component", ["exports", "apollo/mixins/vehicle-mixin", "apollo/mixins/autocomplete-utils-mixin"], function (_exports, _vehicleMixin, _autocompleteUtilsMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_vehicleMixin.default, _autocompleteUtilsMixin.default, {
    store: Ember.inject.service(),
    cookies: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    dateFields: Ember.computed('', function () {
      return this.get('sessionAccount').get('resourceDateFormSettings').TRUCK;
    }),
    principalCompany: Ember.computed('sessionAccount.username', function () {
      var principalCompanyId = this.get('cookies').read('principalId');
      return this.get('store').peekRecord('company', principalCompanyId);
    }),
    groups: Ember.computed('principalCompany.userGroups.[]', function () {
      return this.get('principalCompany.userGroups');
    }),
    actions: {
      setDriver: function setDriver(driver) {
        this.setItem('vehicle.driver', 'driver', driver.id);
      },
      setTrailer: function setTrailer(trailer) {
        this.setItem('vehicle.trailer', 'trailer', trailer.id);
      },
      removeDriver: function removeDriver() {
        this.clearItem('vehicle.driver');
      },
      removeTrailer: function removeTrailer() {
        this.clearItem('vehicle.driver');
      }
    }
  });

  _exports.default = _default;
});