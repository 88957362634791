define("apollo/pods/drivers/index/controller", ["exports", "apollo/mixins/resource-mixin"], function (_exports, _resourceMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_resourceMixin.default, {
    cookies: Ember.inject.service(),
    queryParams: ['query', 'showAll', 'from', 'to', 'dateStatus', 'page', 'ownerships'],
    showAll: false,
    ownerships: ["OWN"],
    sessionAccount: Ember.inject.service('session-account'),
    showAllDriversFilterLabel: Ember.computed('showAll', function () {
      return this.get('intl').t(this.get('showAll') ? 'resources.hideInactiveDrivers' : 'resources.showAllDrivers');
    }),
    showIntegratedDriversFilterLabel: Ember.computed('ownerships.[]', function () {
      var integratedCarriersIncluded = this.get('ownerships').includes("INTEGRATED");
      return this.get('intl').t(integratedCarriersIncluded ? 'resources.hideIntegratedDrivers' : 'resources.showIntegratedDrivers');
    }),
    drivers: Ember.computed('model', function () {
      return this.get('model');
    }),
    dateColumns: Ember.computed('', function () {
      return this.get('sessionAccount').get('resourceDateFormSettings').DRIVER;
    }),
    actions: {
      showDetails: function showDetails(driverId) {
        if (this.get('sessionAccount').hasRole('ROLE_CREATE_DRIVER')) {
          this.transitionToRoute('drivers.edit', driverId);
        }
      }
    }
  });

  _exports.default = _default;
});