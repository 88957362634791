define("apollo/pods/components/transport/add-address/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    addNewAddress: false,
    recipientSelectIsOpen: Ember.computed(function () {
      return false;
    }),
    countries: Ember.computed(function () {
      return this.get('store').peekAll('country').sortBy('ibanSymbol');
    }),
    setStepCompanyObserver: Ember.observer('transport.clientCompany', function () {
      this.set("step.company", this.get('transport.clientCompany'));
    }),
    transportCanNotBeEditedAndIsClientEmpty: Ember.computed('step.company.addresses', 'transport.clientCompany.addresses', 'addNewAddress', function () {
      if (!this.get('transport.canNotBeEdited') && this.get('addNewAddress') && (this.get('step.company.addresses') || this.get('transport.clientCompany.addresses'))) {
        return false;
      }

      if (!this.get('transport.canNotBeEdited') && !this.get('addNewAddress') && (this.get('step.company.addresses') || this.get('transport.clientCompany.addresses'))) {
        return true;
      }

      return true;
    }),
    addressCanNotBeEdited: Ember.computed('transport.canNotBeEdited', 'transport.remoteId', 'addNewAddress', function () {
      return this.get('transport.canNotBeEdited') || this.get('transport.remoteId') || !this.get('addNewAddress');
    }),
    actions: {
      setAddressData: function setAddressData(address) {
        var self = this;

        if (address.id) {
          this.get('store').findRecord('address', address.id).then(function (a) {
            self.get('step').set('address', a);
          });
        } else {
          this.get('step.address').setProperties({
            street: address.street,
            postal: address.postal,
            city: address.city
          });
          this.get('store').findRecord('country', address.country).then(function (c) {
            self.get('step.address').set('country', c);
          });
        }
      },
      toogleNewAddress: function toogleNewAddress(step) {
        if (!step.get('transport.remoteId')) {
          if (this.get('addNewAddress')) {
            this.send('deleteAddressFromStep', step);
            this.set('addNewAddress', false);
          } else {
            this.send('setAddressForStep', step);
            this.set('addNewAddress', true);
          }
        }
      },
      removeAddress: function removeAddress() {
        console.debug("Removing address from step..");
        this.get('step').set('address', null);
      },
      deleteAddressFromStep: function deleteAddressFromStep(step) {
        step.get('address').then(function (address) {
          address.unloadRecord();
        });
      },
      setAddressForStep: function setAddressForStep(step) {
        step.set('address', this.get('store').createRecord('address', {}));
      },
      recipientSelectOnOpen: function recipientSelectOnOpen() {
        this.set('recipientSelectIsOpen', !this.get('recipientSelectIsOpen'));
      },
      recipientSelectOnClose: function recipientSelectOnClose() {
        this.set('recipientSelectIsOpen', !this.get('recipientSelectIsOpen'));
      }
    }
  });

  _exports.default = _default;
});