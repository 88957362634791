define("apollo/pods/components/framelogic-add-secure/component", ["exports", "jquery", "apollo/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    sessionAccount: Ember.inject.service('session-account'),
    store: Ember.inject.service(),
    cookies: Ember.inject.service(),
    intl: Ember.inject.service(),
    errors: null,
    errorMessages: Ember.computed('intl.locale', function () {
      var intl = this.get('intl');
      return {
        generic: intl.t('table.errors.generic'),
        routeStatus: intl.t('table.errors.routeStatus'),
        conflicts: intl.t('table.errors.conflicts'),
        alreadyAssigned: intl.t('table.errors.alreadyAssigned'),
        unexpectedException: intl.t('table.errors.unexpectedException')
      };
    }),
    transport: null,
    waitingForConnection: false,
    createOrderFullData: function createOrderFullData(transportId) {
      var _this = this;

      var self = this;

      _jquery.default.ajax({
        type: "POST",
        url: _environment.default.serverURL + '/framelogic/createOrderFullData',
        data: {
          id: transportId
        },
        beforeSend: function beforeSend(xhr) {
          xhr.setRequestHeader("Authorization", "Bearer " + self.get('sessionAccount').get("token"));
        }
      }).then(function (response) {
        _this.handleResponse(response);
      }).catch(function (error) {
        console.log(error);

        _this.set('waitingForConnection', false);

        _this.set('errors', _this.get('errorMessages.unexpectedException'));

        _this.get('handleErrors')(_this.errors);
      });
    },
    handleResponse: function handleResponse(response) {
      this.set('waitingForConnection', false);

      if (response.error === this.get('errorMessages.routeStatus')) {
        this.set('errors', this.get('errorMessages.routeStatus'));
      } else if (response.error === this.get('errorMessages.conflicts')) {
        this.set('errors', this.get('errorMessages.conflicts'));
      } else if (response.error === this.get('errorMessages.alreadyAssigned')) {
        this.set('errors', this.get('errorMessages.alreadyAssigned'));
      } else if (response.error) {
        this.set('errors', this.get('errorMessages.unexpectedException'));
      } else {
        this.set('errors', null);
      }

      this.get('handleErrors')(this.errors);
    },
    actions: {
      createNewRoute: function createNewRoute(transportId) {
        this.set('waitingForConnection', true);
        this.createOrderFullData(transportId);
      }
    }
  });

  _exports.default = _default;
});