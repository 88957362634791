define("apollo/pods/reset-password/controller", ["exports", "Ember/service"], function (_exports, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: (0, _service.inject)('session'),
    sessionAccount: (0, _service.inject)('session-account'),
    intl: (0, _service.inject)(),
    errors: [],
    actions: {
      resetPassword: function resetPassword() {
        var self = this;
        var user = self.get('sessionAccount').get('currentUser');
        var passwords = self.getProperties('password', 'confirmedPassword');

        if (passwords.password) {
          passwords.password = passwords.password.trim();
        }

        if (passwords.password !== passwords.confirmedPassword) {
          var message = self.get('intl').t('resetPasswordWindow.enteredPasswordsIsNotIdentical');
          this.set('errorMessage', message);
          return;
        }

        user.set('password', passwords.password);
        user.save().then(function () {
          self.get('sessionAccount').set('passwordValid', true);
          self.transitionToRoute('transports');
        }).catch(function (response) {
          self.set('errors', response.errors);
        });
      },
      invalidateSession: function invalidateSession() {
        this.get('session').invalidate(this.get('sessionAccount'));
      }
    }
  });

  _exports.default = _default;
});