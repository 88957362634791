define("apollo/pods/components/transports/transports-table/transport-header/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LsSEv6SV",
    "block": "{\"symbols\":[],\"statements\":[[4,\"unless\",[[24,[\"loadingInProgress\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"padding-top\"],[8],[0,\"\\n        \"],[7,\"i\",true],[10,\"class\",\"fa fa-truck\"],[8],[9],[0,\" \"],[1,[22,\"countLabel\"],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[1,[28,\"transports/transports-table/column-names\",null,[[\"tableColumnNames\",\"transportTypes\"],[[24,[\"tableColumnNames\"]],[24,[\"transportTypes\"]]]]],false],[0,\"\\n\"],[1,[28,\"transports/transports-table/transport-filters\",null,[[\"archive\",\"tableColumnNames\",\"searchParams\",\"transportTypes\",\"queryParams\"],[[24,[\"archive\"]],[24,[\"tableColumnNames\"]],[24,[\"searchParams\"]],[24,[\"transportTypes\"]],[24,[\"queryParams\"]]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/transports/transports-table/transport-header/template.hbs"
    }
  });

  _exports.default = _default;
});