define("apollo/initializers/string-utils", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize() {
    String.prototype.decapitalize = function () {
      if (!this) {
        return this;
      }

      return this.charAt(0).toLowerCase() + this.slice(1);
    };
  }

  var _default = {
    initialize: initialize
  };
  _exports.default = _default;
});