define("apollo/pods/settings/edit/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    success: false,
    errors: [],
    actions: {
      save: function save() {
        var self = this;
        var setting = this.get('model');

        if (setting.get('settingValueType') === 'boolean') {
          setting.set('value', $('#settingCheckbox').is(':checked'));
        }

        setting.save().then(function () {
          self.set('success', true);
        }).catch(function (response) {
          self.set('errors', response.errors);
        });
      },
      cancel: function cancel() {
        this.get('model').rollbackAttributes();
        this.transitionToRoute('settings.index');
      }
    }
  });

  _exports.default = _default;
});