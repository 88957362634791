define("apollo/pods/auth/login/route", ["exports", "ember-simple-auth/mixins/unauthenticated-route-mixin"], function (_exports, _unauthenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {
    renderTemplate: function renderTemplate(controller, model) {
      this.render('auth/login', {
        into: 'application',
        outlet: 'login',
        controller: controller,
        model: model
      });
    },
    setupController: function setupController(controller) {
      controller.set('errorMessage', null);
    }
  });

  _exports.default = _default;
});