define("apollo/pods/components/wash-form/component", ["exports", "apollo/mixins/autocomplete-utils-mixin", "apollo/mixins/input-utils-mixin"], function (_exports, _autocompleteUtilsMixin, _inputUtilsMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_autocompleteUtilsMixin.default, _inputUtilsMixin.default, {
    sessionAccount: Ember.inject.service('session-account'),
    cookies: Ember.inject.service(),
    store: Ember.inject.service(),
    principalCompany: Ember.computed(function () {
      return this.get('store').peekRecord('company', this.get('cookies').read('principalId'));
    }),

    /**
     * Formularz myjni powinien być:
     * <ul>
     *     <li> odblokowany w momencie dodawania myjni czyli gdy step mycia nie posiada id
     *     <li> zablokowany w szczegółach zlecenia czyli gdy step mycia posiada id, do momentu kliknięcia przycisku edytuj
     */
    editionIsDisabled: Ember.computed('washStep.id', function () {
      return !!this.get('washStep.id');
    }),

    /**
     * Przycisk odblokowujący edycję myjni powinien być:
     * <ul>
     *     <li> niewidoczny w momencie dodawania myjni czyli gdy step mycia nie posiada id
     *     <li> widoczny w szczegółach zlecenia:
     *          <ul>
     *              <li>czyli step mycia posiada id,
     *              <li>gdy użytkownik posiada uprawnienie #Authority.ROLE_CAN_EDIT_WASH
     *              <li>gdy nie jest jeszcze potwierczony status mycia
     */
    showEditButton: Ember.computed('washStep.id', 'washStep.transport.washIsConfirmed', function () {
      return this.get('washStep.id') && this.get('sessionAccount').hasRole('ROLE_CAN_EDIT_WASH') && !this.get('washStep.transport.washIsConfirmed') && !this.get('transport.washSentToSap');
    }),
    washSelectClass: Ember.computed('showEditButton', function () {
      return this.get('showEditButton') ? 'col-xs-5' : 'col-xs-7';
    }),
    currencies: Ember.computed(function () {
      return this.get('store').peekAll('currency');
    }),
    actions: {
      toggleEditionIsDisabled: function toggleEditionIsDisabled() {
        this.toggleProperty('editionIsDisabled');
      },
      removeWashData: function removeWashData() {
        this.clearItem('washStep.company');
      },
      setCurrencyOfWash: function setCurrencyOfWash(currency) {
        this.set('transaction.currencyOfWash', currency);
      },
      setWashData: function setWashData(company) {
        var _this = this;

        this.store.findRecord('company', company.id).then(function (company) {
          company.get('addresses').then(function () {
            _this.setProperties({
              'washStep.company': company,
              'washStep.address': company.get('address')
            });
          });
        });
      }
    }
  });

  _exports.default = _default;
});