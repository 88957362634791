define("apollo/pods/step/model", ["exports", "ember-data", "apollo/mixins/model-validator", "ember-data-copyable", "apollo/mixins/input-utils-mixin"], function (_exports, _emberData, _modelValidator, _emberDataCopyable, _inputUtilsMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend(_modelValidator.default, _emberDataCopyable.default, _inputUtilsMixin.default, {
    STEP_TYPES: {
      LOAD: 'load',
      UNLOAD: 'unload',
      WASH: 'wash',
      SERVICE: 'service'
    },
    transport: _emberData.default.belongsTo('transport', {
      async: true
    }),
    company: _emberData.default.belongsTo('company', {
      async: true
    }),
    address: _emberData.default.belongsTo('address', {
      async: true
    }),
    service: _emberData.default.belongsTo('service', {
      async: true,
      embedded: 'save'
    }),
    stepTypeName: _emberData.default.attr('string'),
    orderInTransport: _emberData.default.attr(),
    documentIdentifier: _emberData.default.attr(),
    quantity: _emberData.default.attr('number'),
    deliveryDateFrom: _emberData.default.attr('isodate'),
    deliveryDateTo: _emberData.default.attr('isodate'),
    weight: _emberData.default.attr('number'),
    volume: _emberData.default.attr('number'),
    distance: _emberData.default.attr('number'),
    importClearance: _emberData.default.attr('string'),
    exportClearance: _emberData.default.attr('string'),
    validations: {
      company: {
        presence: {
          message: 'blank'
        }
      },
      address: {
        presence: {
          message: 'blank'
        },
        relations: ['belongsTo']
      },
      deliveryDateFrom: {
        presence: {
          message: 'blank'
        }
      },
      deliveryDateTo: {
        custom: [{
          validation: function validation(key, value, model) {
            if (!value || !model.get('deliveryDateFrom')) {
              return true;
            }

            return value >= model.get('deliveryDateFrom');
          },
          message: 'wrongTimeWindow'
        }]
      },
      distance: {
        custom: [{
          validation: function validation(key, value, model) {
            //dla stepu myjni nie przeprowadzamy walidacji dystansu
            if (model.get('isWash')) {
              return true;
            }

            if (!model.get('transport.carrierCompany.id') || model.get('orderInTransport') === 0 || model.get('transport.carrierCompany.isExternal')) {
              return true;
            }

            return !!value && model.isCorrectNumber(value);
          },
          message: 'incorrectNumber'
        }]
      }
    },
    isLoad: Ember.computed('stepTypeName', function () {
      return this.get('stepTypeName') === this.get('STEP_TYPES').LOAD;
    }),
    isUnload: Ember.computed('stepTypeName', function () {
      return this.get('stepTypeName') === this.get('STEP_TYPES').UNLOAD;
    }),
    isService: Ember.computed('stepTypeName', function () {
      return this.get('stepTypeName') === this.get('STEP_TYPES').SERVICE;
    }),
    isWash: Ember.computed('stepTypeName', function () {
      return this.get('stepTypeName') === this.get('STEP_TYPES').WASH;
    }),
    companyName: Ember.computed('company', function () {
      return this.get('company.name');
    }),
    country: Ember.computed('company', function () {
      return this.get('company.country');
    }),
    deliveryDate: Ember.computed('deliveryDateFrom', 'deliveryDateTo', function () {
      return this.get('deliveryDateTo') || this.get('deliveryDateFrom');
    })
  });

  _exports.default = _default;
});