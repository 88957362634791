define("apollo/pods/components/create-service-button/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    cookies: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    initiallySelectedOwner: Ember.computed(function () {
      return this.get('store').peekRecord('company', this.get('cookies').read('principalId'));
    }),
    actions: {
      openServiceModal: function openServiceModal() {
        var advice = this.get('store').createRecord('advice', {});
        var transport = this.get('store').createRecord('transport', {
          carrierCompany: this.get('initiallySelectedOwner'),
          advice: advice
        });
        var step = this.get('store').createRecord('step', {
          stepTypeName: 'service',
          transport: transport,
          service: this.get('store').createRecord('service', {}),
          deliveryDateFrom: moment().startOf('hour').add(1, 'h').toDate(),
          deliveryDateTo: moment().startOf('hour').add(2, 'h').toDate()
        });
        this.showModal('services.modals.create-service', transport);
      }
    }
  });

  _exports.default = _default;
});