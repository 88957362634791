define("apollo/pods/components/show-sms-statuses-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/JQU+mzw",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"modal fade checkpoint-modal\"],[10,\"role\",\"dialog\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"modal-dialog\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"modal-content\"],[8],[0,\"\\n            \"],[7,\"div\",true],[10,\"class\",\"modal-header\"],[8],[0,\"\\n                \"],[7,\"h4\",true],[10,\"class\",\"modal-title\"],[8],[0,\"\\n                    \"],[1,[28,\"t\",[\"notification.statusesHeader\"],[[\"id\"],[[24,[\"statuses\",\"smsId\"]]]]],false],[0,\"\\n                \"],[9],[0,\"\\n            \"],[9],[0,\"\\n            \"],[14,1],[0,\"\\n        \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/show-sms-statuses-modal/template.hbs"
    }
  });

  _exports.default = _default;
});