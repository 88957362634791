define("apollo/pods/components/file-picker/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "++sl6RRw",
    "block": "{\"symbols\":[],\"statements\":[[7,\"button\",true],[10,\"class\",\"btn btn-default upload-button\"],[10,\"type\",\"button\"],[8],[0,\"\\n    \"],[1,[28,\"t\",[\"common.addFile\"],null],false],[0,\"...\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/file-picker/template.hbs"
    }
  });

  _exports.default = _default;
});