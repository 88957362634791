define("apollo/pods/services/modals/create-service/controller", ["exports", "jquery", "apollo/config/environment", "apollo/mixins/attachment-mixin", "apollo/mixins/transports-mixin", "apollo/mixins/autocomplete-utils-mixin", "apollo/mixins/input-utils-mixin", "apollo/mixins/modal-mixin"], function (_exports, _jquery, _environment, _attachmentMixin, _transportsMixin, _autocompleteUtilsMixin, _inputUtilsMixin, _modalMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_attachmentMixin.default, _transportsMixin.default, _autocompleteUtilsMixin.default, _inputUtilsMixin.default, _modalMixin.default, {
    intl: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    store: Ember.inject.service(),
    cookies: Ember.inject.service(),
    createTransportController: Ember.inject.controller('transports/modals/create-transport'),
    errors: [],
    attachments: [],
    existingFilesToRemove: [],
    title: Ember.computed('model.id', function () {
      if (this.get('model.id')) {
        return "".concat(this.get('intl').t('service.title'), " #").concat(this.get('model.id'));
      } else {
        return this.get('intl').t('service.createTitle');
      }
    }),
    startH: '',
    showInternalWork: Ember.computed('model.steps.firstObject.company.id', 'model.steps.firstObject.company.isExternal', function () {
      return this.get('model.steps.firstObject.company.id') && !this.get('model.steps.firstObject.company.isExternal');
    }),
    service: Ember.computed('model.steps.firstObject.service', function () {
      return this.get('model.steps.firstObject.service');
    }),
    setWorkload: Ember.observer('model.steps.firstObject.service', function () {
      var _this = this;

      var setting = this.get('store').peekAll('setting').filterBy('name', 'DEFAULT_WORKLOAD_TIME_IN_HOURS').get('firstObject.value');

      if (this.get('model.steps.firstObject.service.content') && !this.get('service.workload')) {
        this.get('model.steps.firstObject.service').then(function (s) {
          _this.set('model.steps.firstObject.service.workload', setting);
        });
      }
    }),
    vehicleObserver: Ember.observer('model.carrierCompany.id', function () {
      if (this.get('service.step.transport.advice')) {
        this.set('service.step.transport.advice.vehicle', null);
      }
    }),
    serviceTypes: Ember.computed('model', function () {
      return this.get('store').peekAll('service-type');
    }),
    canNotEdit: Ember.computed('model.id', 'model.archived', 'sessionAccount.currentUser', function () {
      if (!this.get('sessionAccount').hasRole('ROLE_EDIT_ADVICE')) {
        return true;
      }

      return this.get('model') && this.get('model').get('archived');
    }),
    canBeDeleted: Ember.computed('model.canBeDeleted', 'model.archived', function () {
      if (!this.get('model.id') || this.get('model').get('archived')) {
        return false;
      }

      if (this.get('sessionAccount').hasRole('ROLE_DELETE_ADVICE_ANY_TIME')) {
        return true;
      }

      return this.get('sessionAccount').hasRole('ROLE_DELETE_ADVICE') && this.get("model.canBeDeleted");
    }),
    selectedVehicle: Ember.computed('model.advice.truck', 'model.advice.trailer', function () {
      if (this.get('model.advice.truck.id')) {
        return this.get('model.advice.truck');
      }

      if (this.get('model.advice.trailer.id')) {
        return this.get('model.advice.trailer');
      }
    }),
    selectedCarrierCompany: Ember.computed('model.carrierCompany', function () {
      if (this.get('model.carrierCompany.id')) {
        return this.get('model.carrierCompany');
      }
    }),
    actions: {
      setMechanic: function setMechanic(mechanic) {
        this.setItem('service.mechanic', 'user', mechanic.id);
      },
      setVehicle: function setVehicle(vehicle) {
        var _this2 = this;

        this.get('store').findRecord(vehicle.type, vehicle.id).then(function (vehicle) {
          _this2.set('model.advice.truck', {});

          _this2.set('model.advice.trailer', {});

          if (vehicle.get('vehicleType') === 'TRUCK') {
            _this2.set('model.advice.truck', vehicle);
          } else {
            _this2.set('model.advice.trailer', vehicle);
          }

          if ((_this2.get('model.advice.truck.id') || _this2.get('model.advice.trailer.id')) && _this2.get('model.advice.errors.vehicle')) {
            _this2.get('model.advice.errors.vehicle').clear();
          }

          var group = _this2.get('model.advice.truck.group');

          _this2.get('model').set('personsGroup', group);
        });
      },
      setCarrierCompany: function setCarrierCompany(company) {
        var _this3 = this;

        this.get('store').findRecord('company', company.id).then(function (company) {
          _this3.set('model.carrierCompany', company);

          _this3.set('model.advice.truck', {});

          _this3.set('model.advice.trailer', {});
        });
      },
      setServiceType: function setServiceType(serviceType) {
        if (serviceType && this.get('model.service.errors.serviceType')) {
          this.get('model.service.errors.serviceType').clear();
        }

        this.set('service.serviceType', serviceType);
      },
      setServicePlace: function setServicePlace(company) {
        var _this4 = this;

        this.get('store').findRecord('company', company.id).then(function (company) {
          _this4.set('model.steps.firstObject.company', company);

          _this4.set('model.steps.firstObject.address', company.get('address'));

          _this4.set('service.mechanic', null);
        });
      },
      removeServicePlace: function removeServicePlace() {
        this.clearItem('model.steps.firstObject.company');
      },
      removeVehicle: function removeVehicle() {
        this.clearItem('model.advice.truck');
        this.clearItem('model.advice.trailer');
      },
      removeMechanic: function removeMechanic() {
        this.clearItem('service.mechanic');
      },
      save: function save() {
        var _this5 = this;

        this.set('inProgress', true);
        var self = this;
        var transport = self.get('model');
        transport.set('transportType', this.get('store').peekAll('transport-type').filterBy('name', 'SERVICE').get('firstObject'));
        transport.set('principalCompany', this.get('store').peekRecord('company', this.get('cookies').read('principalId')));
        transport.get('steps.firstObject.service').then(function (service) {
          var validationResult = service.validate();

          var validationResultCustom = _this5.customValidate(transport);

          if (!validationResult || !validationResultCustom) {
            _this5.set('inProgress', false);

            return;
          }

          transport.save().then(function (t) {
            console.log("Poprawnie zapisali\u015Bmy serwis ".concat(t.get('logString'), "."));
            self.existingFilesToRemove.forEach(function (fileId) {
              console.log("self.existingFilesToRemove..");
              self.deleteFile(fileId);
            });
            self.existingFilesToRemove.clear();
            var token = self.get('sessionAccount').get('token');

            if (self.attachments) {
              self.uploadAttachment(t.id, 'transports', token, self.attachments);
              self.attachments.clear();
            }

            self.handleUpdate(t);
            self.setProperties({
              model: null,
              errors: []
            });
            self.set('showSaveTransportConfirm', false);
            (0, _jquery.default)('.modal').modal('hide');
            (0, _jquery.default)('.modal, .modal-backdrop').hide();
            (0, _jquery.default)('body').removeClass('modal-open');
          }).finally(function () {
            return self.set('inProgress', false);
          });
        });
      },
      cancel: function cancel() {
        console.log("cancelCreateService..");

        if (this.get('model.revertFunc')) {
          this.get('model').revertFunc();
        }

        this.setProperties({
          errors: []
        });
        this.get('createTransportController').rollbackTransportChanges(this.get('model'));
        (0, _jquery.default)('div.fc-scroller').scrollTop('0px');
        (0, _jquery.default)('.modal').modal('hide');
      },
      deleteTransport: function deleteTransport(transport) {
        var self = this;
        (0, _jquery.default)(".delete-progress").show();

        _jquery.default.ajax({
          type: "PUT",
          url: _environment.default.serverURL + '/transports/' + transport.get("id") + "/delete"
        }).then(function () {
          self.setProperties({
            errors: []
          });
          (0, _jquery.default)('.modal').modal('hide');
        });
      },
      setDeliveryDateFrom: function setDeliveryDateFrom(date) {
        this.set('model.steps.firstObject.deliveryDateFrom', date[0]);
      },
      setDeliveryDateTo: function setDeliveryDateTo(date) {
        this.set('model.steps.firstObject.deliveryDateTo', date[0]);
      }
    },
    customValidate: function customValidate(transport) {
      var validate = true;

      if (!transport.get('carrierCompany.id')) {
        validate = false;
      }

      if (!transport.get('advice.truck.id') && !transport.get('advice.trailer.id')) {
        transport.get('advice.errors').add('vehicle', 'blank');
        validate = false;
      }

      if (!transport.get('steps.firstObject.service.mileage')) {
        validate = false;
      }

      if (!transport.get('steps.firstObject.deliveryDateFrom')) {
        transport.get('steps.firstObject.errors').add('deliveryDateFrom', 'blankServiceFrom');
        validate = false;
      }

      if (!transport.get('steps.firstObject.deliveryDateTo')) {
        transport.get('steps.firstObject.errors').add('deliveryDateTo', 'blankServiceTo');
        validate = false;
      }

      return validate;
    }
  });

  _exports.default = _default;
});