define("apollo/pods/drivers/create/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    cookies: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    access: ['ROLE_CREATE_DRIVER'],
    queryParams: {
      carrierId: {
        refreshModel: true
      }
    },
    beforeModel: function beforeModel(transition) {
      if (!this.get('sessionAccount').hasAnyOfTheRoles(this.get('access'))) {
        transition.abort();
        this.transitionTo('forbidden');
      }
    },
    model: function model(params) {
      var principalCompany = this.store.peekRecord('company', this.get('cookies').read('principalId'));
      var principalCountry = principalCompany.get('country');
      var carrierId = params.carrierId || this.get('sessionAccount.currentUser.company.id');
      return this.store.createRecord('driver', {
        country: principalCountry,
        carrierId: carrierId
      });
    },
    setupController: function setupController(controller, model) {
      var self = this;
      this.get('store').findRecord('company', model.get('carrierId')).then(function (company) {
        console.log("Przygotowujemy formularz kierowcy dla przewo\u017Anika ".concat(company.get('name'), ".."));
        model.setProperties({
          company: company,
          carrierId: null
        });
      });
      controller.setProperties({
        model: model,
        isEditing: false
      });
    },
    actions: {
      willTransition: function willTransition() {
        this.get('controller').setProperties({
          errors: [],
          attachments: [],
          successMessage: null
        });
        this.get('controller').get('model').unloadRecord();
      }
    }
  });

  _exports.default = _default;
});