define("apollo/pods/partnership-type/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    PARTNERSHIP_TYPES: {
      CARRIER: 'CARRIER',
      SERVICE: 'SERVICE',
      SUPPLIER: 'SUPPLIER',
      CLIENT: 'CLIENT',
      WASH: 'WASH'
    },
    availableRoleGroups: _emberData.default.hasMany('roleGroup', {
      async: true
    }),
    name: _emberData.default.attr('string'),
    canBeInvited: _emberData.default.attr('boolean'),
    isCarrier: Ember.computed('name', function () {
      return this.get('name') === this.get('PARTNERSHIP_TYPES').CARRIER;
    }),
    isService: Ember.computed('name', function () {
      return this.get('name') === this.get('PARTNERSHIP_TYPES').SERVICE;
    }),
    isWash: Ember.computed('name', function () {
      return this.get('name') === this.get('PARTNERSHIP_TYPES').WASH;
    }),
    isClient: Ember.computed('name', function () {
      return this.get('name') === this.get('PARTNERSHIP_TYPES').CLIENT;
    }),
    nameInLowerCase: Ember.computed('name', function () {
      return this.get('name') ? this.get('name').toLowerCase() : this.get('name');
    })
  });

  _exports.default = _default;
});