define("apollo/initializers/setup-pikaday-i18n", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(application) {
    var i18n = Ember.Object.extend({
      months: _moment.default.months('names'),
      weekdays: _moment.default.localeData()._weekdays,
      weekdaysShort: _moment.default.localeData()._weekdaysShort
    });
    application.register('pikaday-i18n:main', i18n, {
      singleton: true
    });
    application.inject('component:pikaday-input', 'i18n', 'pikaday-i18n:main');
  }

  var _default = {
    name: 'setup-pikaday-i18n',
    initialize: initialize
  };
  _exports.default = _default;
});