define("apollo/helpers/is-last-transport-step", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isLastTransportStep = isLastTransportStep;
  _exports.default = void 0;

  function isLastTransportStep(params) {
    var transportSteps = params[0];
    var idx = params[1];
    return transportSteps.get('length') - 1 === idx;
  }

  var _default = Ember.Helper.helper(isLastTransportStep);

  _exports.default = _default;
});