define("apollo/pods/components/address-auto-complete/component", ["exports", "apollo/pods/components/custom-auto-complete/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    valueProperty: "id",
    urlAddress: "/addresses/find",
    field: "city",
    options: [],
    suggestions: Ember.computed('options.@each', function () {
      return this.get("options");
    }),
    actions: {
      selectItem: function selectItem(item) {
        console.log("item: ".concat(item));
        this.set("selectedFromList", true);
        this.set("selectedValue", item.driverName);
        this.selectItem(item);
      }
    }
  });

  _exports.default = _default;
});