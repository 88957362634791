define("apollo/pods/components/audit-log/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OOuNCo8w",
    "block": "{\"symbols\":[],\"statements\":[[7,\"button\",false],[12,\"class\",\"btn btn-default\"],[3,\"action\",[[23,0,[]],\"loadingData\"]],[3,\"action\",[[23,0,[]],\"getLogs\",[24,[\"modelId\"]],[24,[\"className\"]]]],[8],[0,\"\\n    \"],[1,[28,\"t\",[\"transport.logs.label\"],null],false],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/audit-log/template.hbs"
    }
  });

  _exports.default = _default;
});