define("apollo/mixins/resource-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    intl: Ember.inject.service(),
    DATE_STATUSES: ['VALID', 'INVALID', 'YELLOW', 'RED'],
    filterChanged: Ember.observer('queryInput', function () {
      Ember.run.debounce(this, this.applyFilter, 500);
    }),
    showAllFilterLabel: Ember.computed('showAll', function () {
      var vehicleType = this.get('intl').t("vehicle.plural.".concat(this.get('vehicleType')));
      return this.get('intl').t(this.get('showAll') ? 'resources.hideOutOfCommission' : 'resources.showAll', {
        vehicleType: vehicleType
      });
    }),
    applyFilter: function applyFilter() {
      var queryInput = this.get('queryInput');
      this.set('query', queryInput);

      if (queryInput) {
        this.setFirstPage();
      }
    },
    setFirstPage: function setFirstPage() {
      this.setProperties({
        page: 1
      });
    },
    actions: {
      mutStatusDateInput: function mutStatusDateInput(value) {
        this.set('dateStatusInput', value);
      },
      setDatesFilter: function setDatesFilter() {
        this.set('dateStatus', this.get('dateStatusInput'));

        if (this.get('fromInput')) {
          this.set('from', moment(this.get('fromInput')).format('YYYY-MM-DD'));
        }

        if (this.get('toInput')) {
          this.set('to', moment(this.get('toInput')).format('YYYY-MM-DD'));
        }

        this.setFirstPage();
      },
      toggleShowAllFilter: function toggleShowAllFilter() {
        this.toggleProperty('showAll');
        this.setFirstPage();
      },
      toggleShowIntegratedFilter: function toggleShowIntegratedFilter() {
        if (!this.get('ownerships').includes("INTEGRATED")) {
          this.get('ownerships').push("INTEGRATED");
        } else {
          this.set('ownerships', ["OWN"]);
        }

        this.setFirstPage();
      },
      clearFilter: function clearFilter() {
        this.setFirstPage();
        this.setProperties({
          query: '',
          queryInput: '',
          fromInput: null,
          toInput: null,
          from: null,
          to: null,
          dateStatusInput: null,
          dateStatus: null
        });
      }
    }
  });

  _exports.default = _default;
});