define("apollo/helpers/find-resource-date-label", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    store: Ember.inject.service(),
    compute: function compute(params) {
      var dateName = params[0];
      var controlledDateType = this.get('store').peekAll('controlledDateType').find(function (rdt) {
        return rdt.get('name') === dateName;
      });

      if (!controlledDateType) {
        return null;
      }

      return controlledDateType.get('label');
    }
  });

  _exports.default = _default;
});