define("apollo/pods/services/modals/select-mechanic/controller", ["exports", "jquery", "apollo/mixins/autocomplete-utils-mixin", "apollo/mixins/modal-mixin"], function (_exports, _jquery, _autocompleteUtilsMixin, _modalMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_autocompleteUtilsMixin.default, _modalMixin.default, {
    intl: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    store: Ember.inject.service(),
    cookies: Ember.inject.service(),
    errors: [],
    title: Ember.computed('model.transport.archived', function () {
      return this.get('intl').t('service.selectMechanic');
    }),
    actions: {
      setMechanic: function setMechanic(mechanic) {
        this.setItem('model.transport.steps.firstObject.service.mechanic', 'user', mechanic.id);
      },
      removeMechanic: function removeMechanic() {
        this.clearItem('model.transport.steps.firstObject.service.mechanic');
      },
      save: function save() {
        this.get('model.transport').save().then(function () {
          (0, _jquery.default)('.modal').modal('hide');
        });
      },
      cancel: function cancel() {
        this.setProperties({
          errors: []
        });
        (0, _jquery.default)('.modal').modal('hide');
      }
    }
  });

  _exports.default = _default;
});