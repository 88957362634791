define("apollo/pods/components/choose-mechanic-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "f7qCk2YY",
    "block": "{\"symbols\":[],\"statements\":[[7,\"button\",false],[12,\"class\",\"btn btn-sm\"],[12,\"style\",[24,[\"transport\",\"backgroundColorStyle\"]]],[3,\"action\",[[23,0,[]],\"openSelectMechanicModal\",[24,[\"transport\"]]]],[8],[0,\"\\n    \"],[1,[28,\"t\",[\"table.select\"],null],false],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/choose-mechanic-button/template.hbs"
    }
  });

  _exports.default = _default;
});