define("apollo/pods/setting/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    value: _emberData.default.attr('string'),
    dateCreated: _emberData.default.attr('date'),
    lastUpdated: _emberData.default.attr('date'),
    settingValueType: Ember.computed('value', function () {
      var settingValue = this.get('value');

      if (settingValue === 'true' || settingValue === 'false') {
        return 'boolean';
      }
    }),
    isChecked: Ember.computed('value', function () {
      var settingValue = this.get('value');
      return settingValue === 'true';
    })
  });

  _exports.default = _default;
});