define("apollo/pods/transports/modals/confirm-checkpoint/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "P+VIf/3T",
    "block": "{\"symbols\":[],\"statements\":[[4,\"checkpoint-modal\",null,[[\"model\",\"close\",\"title\",\"actionToPerform\",\"mustBeInThePast\"],[[24,[\"model\"]],[28,\"action\",[[23,0,[]],\"removeModal\"],null],[28,\"t\",[\"checkpoints.confirmTitle\"],[[\"stateName\",\"identifier\"],[[24,[\"model\",\"decapitalizedName\"]],[24,[\"model\",\"transport\",\"id\"]]]]],[28,\"action\",[[23,0,[]],\"confirm\",[24,[\"model\"]]],null],true]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[24,[\"model\",\"reasonForTheDelayMustBeGiven\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n            \"],[1,[28,\"textarea\",null,[[\"type\",\"placeholder\",\"class\",\"rows\",\"value\"],[\"textarea\",[28,\"t\",[\"checkpoints.reasonForTheDelay\"],null],\"form-control min-width-100p vertical-resizable-only\",\"3\",[24,[\"model\",\"delayReason\"]]]]],false],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/transports/modals/confirm-checkpoint/template.hbs"
    }
  });

  _exports.default = _default;
});