define("apollo/pods/dummy/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FxgDmJqR",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h2\",true],[8],[0,\"\\n  Dummy\\n\"],[9],[0,\"\\n\"],[7,\"h3\",true],[8],[0,\"\\n  Index\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[8],[0,\"\\n  \"],[4,\"link-to\",null,[[\"route\"],[\"dummy.create\"]],{\"statements\":[[0,\"Create new\"]],\"parameters\":[]},null],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/dummy/index/template.hbs"
    }
  });

  _exports.default = _default;
});