define("apollo/pods/components/custom-auto-complete/component", ["exports", "jquery", "apollo/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var focusOutEvent;

  var _default = Ember.Component.extend({
    sessionAccount: Ember.inject.service('session-account'),
    highlightIndex: -1,
    escapedChars: [40, 38, 13],
    visibility: Ember.String.htmlSafe('display: none;'),
    inputClass: '',
    inputClazz: Ember.computed(function () {
      return 'typeahead form-control' + this.get('inputClass');
    }),
    classNames: ['custom-auto-complete'],
    layoutName: 'components/custom-auto-complete',
    max: 25,
    queryInputChanged: Ember.observer('inputVal', function () {
      Ember.run.debounce(this, this.getOptionsFromServer, 500);
    }),
    getOptionsFromServer: function getOptionsFromServer() {
      var self = this;
      var inputVal = this.get('inputVal') || '';
      var data = {
        query: inputVal,
        field: this.field,
        type: this.type,
        autocomplete: true,
        companyId: this.companyId,
        max: this.get('max')
      };
      var dataOptions = this.getDataOptions ? this.getDataOptions() : null;

      if (dataOptions) {
        Object.assign(data, dataOptions);
      }

      _jquery.default.ajax({
        type: 'GET',
        url: _environment.default.serverURL + this.urlAddress,
        data: data,
        beforeSend: function beforeSend(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + self.get('sessionAccount').get('token'));
        }
      }).then(function (resp) {
        self.set('options', resp);
      });
    },
    dropDownStyle: Ember.computed('visibility', function () {
      return 'Visibility: ' + this.get('visibility');
    }),
    click: function click() {
      if ((0, _jquery.default)().find('input').is(':disabled') || this.get('asPlainTemplate')) {
        console.debug("Element is disabled - not showing options.");
        return;
      }

      Ember.run.debounce(this, this.getOptionsFromServer, 500);
      this.set('visibility', Ember.String.htmlSafe('display: block;'));
    },
    keyUp: function keyUp(event) {
      if (event.keyCode === 27) {
        this.set('visibility', Ember.String.htmlSafe('display: none;'));
      } else if (this.escapedChars.indexOf(event.keyCode) === -1) {
        this.set('visibility', Ember.String.htmlSafe('display: block;'));
        this.set('inputVal', $(event.target).val());
      }
    },
    focusIn: function focusIn() {
      this.set('selectedFromList', false);

      if (this.get('visibility') === 'display:none;') {
        this.set('visibility', Ember.String.htmlSafe('display: block;'));
      }
    },
    focusOut: function focusOut() {
      clearTimeout(focusOutEvent);
      var self = this;

      var func = function func() {
        if (self.isDestroyed) {
          return;
        }

        self.set('visibility', Ember.String.htmlSafe('display: none;'));

        if (self.get('selectedFromList')) {
          return;
        }

        var value = this.get('selectedValue');
        var optionsToMatch = this.get('optionsToMatch') || [];
        var prevValue = self.get('attrs.selectedValue');

        if (optionsToMatch.indexOf(value) === -1) {
          if (value && value !== prevValue) {
            console.log("Adding new option is disallowed. Restoring previous value - ".concat(prevValue, ".."));
            self.set('inputVal', prevValue);
            self.set('selectedValue', prevValue);
          } else if (!value) {
            console.debug("User has removed value - we're removing selection..");
            self.clearSelection();
          }
        }
      };

      focusOutEvent = Ember.run.later(this, func, 200);
    },
    keyDown: function keyDown(event) {
      var ENTER_KEY_CODE = 13;

      if (event.keyCode === ENTER_KEY_CODE) {
        event.preventDefault();
      }

      var $container = $(event.target).parent('.typeahead-wrap').find('.tt-suggestions');

      if ($container.is(':visible')) {
        if (event.keyCode === 40) {
          this.highlight('down', event);
        } else if (event.keyCode === 38) {
          this.highlight('up', event);
        } else if (event.keyCode === ENTER_KEY_CODE || event.keyCode === 9) {
          if (!Ember.isBlank(this.get('selectableSuggestion'))) {
            this.send('selectItem', this.get('selectableSuggestion'));
            this.set('visibility', Ember.String.htmlSafe('display: none;'));
          } else {
            var value = this.get('selectedValue');
            var optionsToMatch = this.get('optionsToMatch');

            if (optionsToMatch && optionsToMatch.indexOf(value) >= 0) {
              console.log("selectedFromList..");
              this.set('selectedFromList', true);
              this.set('visibility', Ember.String.htmlSafe('display: none;'));
            }
          }
        }
      } else {
        this.set('visibility', Ember.String.htmlSafe('display: block;'));
      }
    },
    highlight: function highlight(direction, event) {
      var newHighlightIndex = -1;

      if (direction === 'down') {
        newHighlightIndex = this.highlightIndex + 1;
      } else if (this.highlightIndex > 0) {
        newHighlightIndex = this.highlightIndex - 1;
      }

      if (newHighlightIndex < this.get('suggestions').length) {
        if (this.highlightIndex > -1) {
          var currentResult = this.get('suggestions').objectAt(this.highlightIndex);
          Ember.set(currentResult, 'highlight', false);
        }

        this.set('highlightIndex', newHighlightIndex);

        if (this.highlightIndex > -1) {
          var nextResult = this.get('suggestions').objectAt(this.highlightIndex);
          Ember.set(nextResult, 'highlight', true);
          this.set('selectableSuggestion', nextResult);
        }
      }

      if (newHighlightIndex > -1 && newHighlightIndex < this.get('suggestions.length')) {
        var $container = $(event.target).parent('.typeahead-wrap').find('.tt-suggestions');
        var $elementToScrollTo = $($container.find('.autocomplete-result')[newHighlightIndex]);
        $container.scrollTop($elementToScrollTo.offset().top - $container.offset().top + $container.scrollTop() - 5);
      }
    }
  });

  _exports.default = _default;
});