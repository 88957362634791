define("apollo/pods/components/show-statuses/component", ["exports", "jquery", "apollo/pods/components/show-statuses/template", "apollo/config/environment", "apollo/mixins/checkpoints-mixin", "apollo/mixins/persistence-mixin"], function (_exports, _jquery, _template, _environment, _checkpointsMixin, _persistenceMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_checkpointsMixin.default, _persistenceMixin.default, {
    sessionAccount: Ember.inject.service('session-account'),
    store: Ember.inject.service(),
    layout: _template.default,
    action: 'confirm',
    actions: {
      showStatuses: function showStatuses() {
        var element = this.element;
        var self = this;
        var token = this.get('sessionAccount').get('token');

        _jquery.default.ajax({
          url: _environment.default.serverURL + '/transports/' + self.get('transport.id') + '/checkpoints',
          type: 'GET',
          beforeSend: function beforeSend(xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
          }
        }).then(function (json) {
          self.get('store').pushPayload(json);
          var cpIds = json.checkpoints.map(function (cp) {
            return cp.id;
          });
          self.get('transport.checkpoints').filter(function (cp) {
            return !cpIds.includes(cp.get('id'));
          }).forEach(function (cp) {
            return cp.set('transport', null);
          });
          json.checkpoints.forEach(function (checkpoint) {
            var c = self.get('store').peekRecord('checkpoint', checkpoint.id);

            if (!self.get('transport.checkpoints').includes(c)) {
              console.debug("Pushing checkpoint ".concat(c.get('id'), " into transport ").concat(self.get('transport.id'), ".."));
              self.get('transport.checkpoints').pushObject(c);
            }
          });
          self.setCheckpointCanBeApproved();
        });
      },
      confirm: function confirm(checkpoint) {
        this.showModal('transports.modals.confirm-checkpoint', checkpoint);
      },
      revoke: function revoke(checkpoint) {
        console.debug("Revoking status ".concat(checkpoint.get('id'), ".."));
        this.revoke(checkpoint);
      },
      edit: function edit(checkpoint) {
        checkpoint.set('isEdition', true);
        this.showModal('transports.modals.confirm-checkpoint', checkpoint);
      }
    }
  });

  _exports.default = _default;
});