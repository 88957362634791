define("apollo/pods/auth/remind/controller", ["exports", "jquery", "apollo/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    scheduleController: Ember.inject.controller('auth/login'),
    remindEmail: '',
    actions: {
      sendRemindEmail: function sendRemindEmail() {
        var self = this;
        self.set('errorMessage', null);
        (0, _jquery.default)(".reminding-progress").show();
        var url = _environment.default.serverURL + '/remind';
        var identification = this.get('identification');
        var email = this.get('remindEmail');
        var data = {
          email: email,
          identification: identification
        };

        _jquery.default.ajax({
          url: url,
          type: 'POST',
          contentType: 'application/json; charset=utf-8',
          dataType: 'json',
          data: JSON.stringify(data)
        }).then(function (resp) {
          $('.reminding-progress').hide();

          if (resp.errors) {
            self.set('errorMessage', resp.errors);
            console.error(resp.errors);
          } else {
            var msg = self.get('intl').t('auth.passwordRemindedMessage', {
              username: resp.user.name,
              email: email
            });
            self.get('scheduleController').set('message', msg);
            self.transitionToRoute('auth.login');
          }
        });
      }
    }
  });

  _exports.default = _default;
});