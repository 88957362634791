define("apollo/pods/components/transports/transports-table/transport-title/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5kU+xJTH",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[11,\"id\",[29,[[24,[\"transportType\",\"id\"]]]]],[10,\"class\",\"pull-left\"],[8],[0,\"\\n    \"],[1,[28,\"t\",[[28,\"concat\",[\"transport.type.\",[24,[\"transportType\",\"name\"]]],null]],null],false],[0,\"\\n\"],[4,\"unless\",[[24,[\"transportType\",\"loadingCompleted\"]]],null,{\"statements\":[[0,\"        \"],[7,\"small\",true],[8],[0,\"...\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/transports/transports-table/transport-title/template.hbs"
    }
  });

  _exports.default = _default;
});