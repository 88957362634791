define("apollo/pods/transports/index/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    cookies: Ember.inject.service(),
    model: function model() {
      // if the controller has content already, just return that
      if (this.controller && !this.controller.archive && this.controller.get('content')) {
        return this.controller.get('content');
      }

      var principalId = this.get('cookies').read('principalId');
      return this.store.peekRecord('company', principalId);
    },
    setupController: function setupController(controller, model) {
      controller.setProperties({
        model: model,
        allAvailableTransportTypes: this.store.peekAll('transport-type').filter(function (tt) {
          return ['TRANSPORT', 'MANUAL_TRANSPORT', 'EXTERNAL_MANUAL_TRANSPORT'].includes(tt.get('typeGroup'));
        })
      });
    }
  });

  _exports.default = _default;
});