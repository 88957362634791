define("apollo/pods/services/modals/select-service-place/controller", ["exports", "jquery", "apollo/mixins/autocomplete-utils-mixin", "apollo/mixins/modal-mixin"], function (_exports, _jquery, _autocompleteUtilsMixin, _modalMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_autocompleteUtilsMixin.default, _modalMixin.default, {
    intl: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    store: Ember.inject.service(),
    cookies: Ember.inject.service(),
    errors: [],
    title: Ember.computed('model.transport.archived', function () {
      return this.get('intl').t('service.createTitle');
    }),
    setStepAddressObserver: Ember.observer('model.transport.steps.firstObject.company', function () {
      var _this = this;

      this.get('model.transport.steps.firstObject.company').then(function (company) {
        if (company) {
          _this.set('model.transport.steps.firstObject.address', company.get('addresses.firstObject'));
        }
      });
    }),
    actions: {
      setServicePlace: function setServicePlace(service) {
        this.setItem('model.transport.steps.firstObject.company', 'company', service.id);
      },
      removeServicePlace: function removeServicePlace() {
        this.clearItem('model.transport.steps.firstObject.company');
      },
      save: function save() {
        this.get('model.transport').save().then(function () {
          (0, _jquery.default)('.modal').modal('hide');
        });
      },
      cancel: function cancel() {
        this.setProperties({
          errors: []
        });
        this.get('model.transport.steps.firstObject').set('company', null);
        (0, _jquery.default)('.modal').modal('hide');
      }
    }
  });

  _exports.default = _default;
});