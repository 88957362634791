define("apollo/pods/components/pagination-buttons/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    availableMaxPages: Ember.computed(function () {
      return ['10', '25', '100'];
    }).readOnly(),
    prevPage: Ember.computed('page', function () {
      return (parseInt(this.get('page')) || 1) - 1;
    }),
    nextPage: Ember.computed('page', function () {
      return (parseInt(this.get('page')) || 1) + 1;
    }),
    availablePages: Ember.computed('totalPages', function () {
      var pages = [];

      for (var idx = 1; idx <= parseInt(this.get('totalPages')); idx++) {
        pages.push(idx.toString());
      }

      return pages;
    }),
    isLastPage: Ember.computed('page', 'totalPages', function () {
      return (parseInt(this.get('page')) || 1) >= parseInt(this.get('totalPages'));
    })
  });

  _exports.default = _default;
});