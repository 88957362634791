define("apollo/pods/transports/modals/transports-excel/controller", ["exports", "apollo/mixins/attachment-mixin", "apollo/mixins/modal-mixin"], function (_exports, _attachmentMixin, _modalMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_attachmentMixin.default, _modalMixin.default, {
    intl: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    documentMode: "TRANSPORTS",
    title: Ember.computed(function () {
      return this.get('intl').t('xls.title');
    }),
    actions: {
      setDocumentMode: function setDocumentMode(mode) {
        this.set("documentMode", mode);
      }
    }
  });

  _exports.default = _default;
});