define("apollo/pods/schedulers/index/controller", ["exports", "apollo/mixins/modal-mixin"], function (_exports, _modalMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_modalMixin.default, {
    session: Ember.inject.service('session'),
    formattedDate: Ember.computed('model.date', function () {
      return this.get('model.date').format('YYYY-MM-DD');
    }),
    actions: {
      nextWeek: function nextWeek() {
        var newStartDate = moment(this.get('model.date')).add(7, 'day');
        this.transitionToRoute('schedulers', newStartDate.format('YYYY-MM-DD'), this.get('model.delta'), this.get('model.transportTypeParam'));
      },
      prevWeek: function prevWeek() {
        var newStartDate = moment(this.get('model.date')).subtract(7, 'day');
        this.transitionToRoute('schedulers', newStartDate.format('YYYY-MM-DD'), this.get('model.delta'), this.get('model.transportTypeParam'));
      },
      goToNewDate: function goToNewDate(newDate) {
        this.transitionToRoute('schedulers', moment(newDate).format('YYYY-MM-DD'), this.get('model.delta'), this.get('model.transportTypeParam'));
      }
    }
  });

  _exports.default = _default;
});