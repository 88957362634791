define("apollo/pods/components/transport/integration-section/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    sortedExternalCostIntegrations: Ember.computed('transport.externalCostIntegrations.[]', function () {
      if (!this.get('transport.externalCostIntegrations')) {
        return null;
      }

      return this.get('transport.externalCostIntegrations').sortBy('dateCreated').reverse();
    }),
    actions: {
      getIntegrations: function getIntegrations(transport) {
        this.getControllingIntegrations(transport);
        this.getExternalCostIntegrations(transport);
        this.getFlOrderIntegrations(transport);
      }
    }
  });

  _exports.default = _default;
});