define("apollo/mixins/advice-utils-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    intl: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    findInvalidResourceDateAlert: function findInvalidResourceDateAlert(invalidResourceDates) {
      var _this = this;

      var info = "".concat(this.get('intl').t('cannotAdviceDueToInvalidResourceDates.info'), "\n");
      var hint = this.get('intl').t('cannotAdviceDueToInvalidResourceDates.hint');
      var invalidDatesMsg = "";
      invalidResourceDates.forEach(function (invalidDate) {
        var resourceTypeName = invalidDate.get('vehicle.id') ? 'vehicle' : 'driver';
        var params = {
          resource: _this.get('intl').t("resources.of.".concat(invalidDate.get("".concat(resourceTypeName, ".exactTypeName")))),
          document: invalidDate.get('controlledDateType.label')
        };
        invalidDatesMsg += "".concat(_this.get('intl').t('documentsExpirationDate.expired', params), "\n");
      });
      return info + invalidDatesMsg + hint;
    },
    blockingResourceDates: function blockingResourceDates(advice) {
      if (this.get('sessionAccount').hasRole('ROLE_CAN_ADVICE_DESPITE_RESOURCE_VALIDITY_DATES') || !advice) {
        return [];
      }

      var clientId = advice.get('transport.clientCompany.id');
      return advice.findInvalidResourceDates(clientId, true);
    }
  });

  _exports.default = _default;
});