define("apollo/pods/task/model", ["exports", "ember-data", "apollo/mixins/model-validator", "apollo/mixins/input-utils-mixin"], function (_exports, _emberData, _modelValidator, _inputUtilsMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend(_modelValidator.default, _inputUtilsMixin.default, {
    intl: Ember.inject.service(),
    roleGroupsAuthorizedToConfirm: _emberData.default.hasMany('roleGroup', {
      async: true
    }),
    roleGroupsAuthorizedToSee: _emberData.default.hasMany('roleGroup', {
      async: true
    }),
    roleGroupsAuthorizedToRevoke: _emberData.default.hasMany('roleGroup', {
      async: true
    }),
    workflow: _emberData.default.belongsTo('workflow', {
      async: true,
      inverse: 'tasks'
    }),
    enabled: _emberData.default.attr('boolean'),
    taskGroupIdentifier: _emberData.default.attr('number'),
    idxInGroup: _emberData.default.attr('number'),
    tracked: _emberData.default.attr('boolean'),
    markIfWasDelayed: _emberData.default.attr('boolean'),
    stepType: _emberData.default.attr('string'),
    checkpointName: _emberData.default.attr('string'),
    checkpointNameEn: _emberData.default.attr('string'),
    stageName: _emberData.default.attr('string'),
    stageNameEn: _emberData.default.attr('string'),
    color: _emberData.default.attr('string'),
    lateColor: _emberData.default.attr('string'),
    blocksTransportEdition: _emberData.default.attr('boolean'),
    blocksAdviceEdition: _emberData.default.attr('boolean'),
    blocksWindowEdition: _emberData.default.attr('boolean'),
    blocksCarrierEdition: _emberData.default.attr('boolean'),
    canBeConfirmedInRemoteTransport: _emberData.default.attr('boolean'),
    shouldBeApprovedWithAdvice: _emberData.default.attr('boolean'),
    expectedDateInterval: _emberData.default.attr('string'),
    expectedDateConstantHourAndMinute: _emberData.default.attr('string'),
    expectedDateReference: _emberData.default.attr('string'),
    acceptableDelayInterval: _emberData.default.attr('string'),
    isEditableOnStatusList: _emberData.default.attr('boolean'),
    isEditableOnStatusListWithSap: _emberData.default.attr('boolean'),
    isExecutionDateRequired: _emberData.default.attr('boolean'),
    validations: {
      stepType: {
        custom: [{
          validation: function validation(key, value, model) {
            return !(model.get('taskGroupIdentifier') === 1 && !value);
          },
          message: 'blank'
        }]
      },
      checkpointName: {
        presence: {
          message: 'blank'
        }
      },
      expectedDateReference: {
        presence: {
          message: 'blank'
        }
      },
      expectedDateIntervalModulo: {
        custom: {
          validation: function validation(key, value, model) {
            if (!value) {
              return true;
            }

            return model.isCorrectInteger(value);
          },
          message: 'incorrectNumber'
        }
      }
    },
    roleGroupsAuthorizedToSeeIds: Ember.computed('roleGroupsAuthorizedToSee', function () {
      return this.get("roleGroupsAuthorizedToSee").map(function (rg) {
        return rg.id;
      });
    }),
    roleGroupsAuthorizedToConfirmIds: Ember.computed('roleGroupsAuthorizedToConfirm', function () {
      return this.get("roleGroupsAuthorizedToConfirm").map(function (rg) {
        return rg.id;
      });
    }),
    roleGroupsAuthorizedToRevokeIds: Ember.computed('roleGroupsAuthorizedToRevoke', function () {
      return this.get("roleGroupsAuthorizedToRevoke").map(function (rg) {
        return rg.id;
      });
    }),
    expectedDateIntervalSign: Ember.computed('expectedDateInterval', function () {
      var interval = this.get('expectedDateInterval');

      if (!interval) {
        return null;
      }

      return interval[0] === '-' ? 'before' : 'after';
    }),
    expectedDateIntervalUnit: Ember.computed('expectedDateInterval', function () {
      var interval = this.get('expectedDateInterval');

      if (!interval) {
        return null;
      }

      return interval[interval.length - 1];
    }),
    expectedDateIntervalModulo: Ember.computed('expectedDateInterval', function () {
      var interval = this.get('expectedDateInterval');

      if (!interval) {
        return null;
      }

      var unit = this.get('expectedDateIntervalUnit');
      return parseInt(interval.replace('-', '').replace(unit, ''));
    }),
    expectedDateIntervalUnitChanged: Ember.observer('expectedDateIntervalUnit', 'expectedDateIntervalSign', 'expectedDateIntervalModulo', function () {
      var unit = this.get('expectedDateIntervalUnit');
      var sign = this.get('expectedDateIntervalSign') === 'before' ? '-' : '';
      var number = this.get('expectedDateIntervalModulo');
      var newInterval = sign + number + unit;
      this.set('expectedDateInterval', newInterval);
    }),
    expectedDateMode: Ember.computed('expectedDateConstantHourAndMinute', function () {
      return this.get('expectedDateConstantHourAndMinute') ? 'exact' : 'reference';
    }),
    defaultStageName: Ember.computed('stageName', 'checkpointName', function () {
      return this.get('checkpointName') ? this.get('stageName') || this.get('intl').t('task.defaultStagePrefix') + ' ' + this.get('checkpointName') : this.get('intl').t('task.stageName');
    }),
    checkpointNameInAppropriateLanguage: Ember.computed('checkpointName', 'checkpointNameEn', function () {
      if (this.get('intl.locale')[0] !== 'pl' && this.get('checkpointNameEn')) {
        return this.get('checkpointNameEn');
      } else {
        return this.get('checkpointName');
      }
    }),
    stageNameInAppropriateLanguage: Ember.computed('stageName', 'stageNameEn', function () {
      if (this.get('intl.locale')[0] !== 'pl' && this.get('checkpointNameEn')) {
        return this.get('stageNameEn');
      } else {
        return this.get('stageName');
      }
    })
  });

  _exports.default = _default;
});