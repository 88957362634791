define("apollo/pods/car-type-group/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    intl: Ember.inject.service(),
    name: _emberData.default.attr('string'),
    englishName: _emberData.default.attr('string'),
    nameInAppropriateLanguage: Ember.computed('name', 'englishName', function () {
      if (this.get('intl.locale')[0] !== 'pl' && this.get('englishName')) {
        return this.get('englishName');
      } else {
        return this.get('name');
      }
    })
  });

  _exports.default = _default;
});