define("apollo/pods/register/index/route", ["exports", "ember-simple-auth/mixins/unauthenticated-route-mixin"], function (_exports, _unauthenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {
    model: function model() {
      var countries = this.store.findAll("country");
      var company = this.store.createRecord("company", {
        enabled: true,
        isPrincipal: true
      });
      var user = this.store.createRecord("user", {});
      return Ember.RSVP.hash({
        countries: countries,
        user: user,
        company: company
      });
    },
    renderTemplate: function renderTemplate(controller, model) {
      this.render('register/index', {
        into: 'application',
        outlet: 'unauthenticated',
        controller: controller,
        model: model
      });
    }
  });

  _exports.default = _default;
});