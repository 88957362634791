define("apollo/pods/advice/modals/create-advice/controller", ["exports", "jquery", "apollo/mixins/advice-utils-mixin", "apollo/mixins/persistence-mixin", "apollo/mixins/modal-mixin"], function (_exports, _jquery, _adviceUtilsMixin, _persistenceMixin, _modalMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_adviceUtilsMixin.default, _persistenceMixin.default, _modalMixin.default, {
    intl: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    store: Ember.inject.service(),
    errors: [],
    canNotEdit: Ember.computed('model.transport.advice.trailer.numberOfUnconfirmedWashingCheckpoints', function () {
      var trailerNumOfUnCheck = this.get('model.transport.advice.trailer.numberOfUnconfirmedWashingCheckpoints');
      return trailerNumOfUnCheck > 1;
    }),
    title: Ember.computed('model.archived', function () {
      return this.get('intl').t('warehouse.reserveFreePlace', {
        initialStart: this.get('initialStartFormatted')
      });
    }),
    transport: Ember.computed('model.transport', function () {
      return this.get('model.transport');
    }),
    initialStart: Ember.computed('model.initialStart', function () {
      return this.get('model.initialStart');
    }),
    initialStartFormatted: Ember.computed('model.initialStart', function () {
      return moment(this.get('model.initialStart')).format('DD.MM.YYYY HH:mm');
    }),
    actions: {
      createAdvice: function createAdvice() {
        var _this = this;

        var self = this;
        self.get('transport.advice').then(function (advice) {
          var blockingDates = self.blockingResourceDates(advice);

          if (blockingDates.get('length') !== 0) {
            alert(self.findInvalidResourceDateAlert(blockingDates));
            return;
          }

          if (!advice.validate()) {
            return;
          }

          console.log("Zapisujemy awizacj\u0119 dla transportu ".concat(self.get('transport.id'), " i przewo\u017Anika ").concat(self.get('transport.carrierCompany.shortName'), ".."));
          (0, _jquery.default)('#save-advice-button').button('loading');
          var transport = self.get('transport');
          transport.set('adviceChecked', true);
          transport.save().then(function () {
            (0, _jquery.default)('.modal').modal('hide');

            _this.set('errors', []);

            transport.refresh();
            (0, _jquery.default)('#save-advice-button').button('reset');
          }, function (error) {
            if (error.errors) {
              self.set('errors', error.errors);
              (0, _jquery.default)('#save-advice-button').button('reset');
            } else {
              (0, _jquery.default)('.modal').modal('hide');
            }
          });
        });
      },
      cancel: function cancel() {
        console.debug("Cancelling advice form..");
        this.set('errors', []);

        if (this.get('model.revertFunc')) {
          console.log('revert scheduler change position');
          this.get('model').revertFunc();
          this.get('model.transport').reload();
        }

        (0, _jquery.default)('div.fc-scroller').scrollTop('0px');
        (0, _jquery.default)('.modal').modal('hide');
        (0, _jquery.default)('#save-advice-button').button('reset');
      }
    }
  });

  _exports.default = _default;
});