define("apollo/pods/components/show-docs/component", ["exports", "apollo/pods/components/show-docs/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    errors: [],
    uploading: false,
    completed: 0,
    attachments: [],
    setFiles: Ember.observer('isOpened', function () {
      var _this = this;

      this.get('transport.files').then(function (f) {
        return _this.set('files', f);
      });
    }),
    actions: {
      fileUploadSuccess: function fileUploadSuccess(transportId) {
        this.fileUploadSuccess(transportId);
      },
      deleteFile: function deleteFile(file) {
        this.deleteFile(file);
      }
    }
  });

  _exports.default = _default;
});