define("apollo/pods/checkpoint/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    intl: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    transport: _emberData.default.belongsTo('transport', {
      async: true,
      inverse: 'checkpoints'
    }),
    step: _emberData.default.belongsTo('step', {
      async: true
    }),
    task: _emberData.default.belongsTo('task', {
      async: true
    }),
    confirmator: _emberData.default.belongsTo('user', {
      async: true
    }),
    expectedDate: _emberData.default.attr('isodate'),
    alertDate: _emberData.default.attr('isodate'),
    executionDate: _emberData.default.attr('isodate'),
    minExecutionDate: _emberData.default.attr('isodate'),
    idx: _emberData.default.attr('number'),
    checkpointName: _emberData.default.attr('string'),
    delayReason: _emberData.default.attr('string'),
    reasonForTheDelayMustBeGiven: _emberData.default.attr('boolean', {
      defaultValue: function defaultValue() {
        return false;
      }
    }),
    isEdition: _emberData.default.attr('boolean', {
      defaultValue: function defaultValue() {
        return false;
      }
    }),
    isLate: Ember.computed('executionDate', 'expectedDate', function () {
      return !this.get('isConfirmed') && this.get('expectedDate').getTime() <= new Date().getTime();
    }),
    wasLate: Ember.computed('executionDate', 'expectedDate', function () {
      if (this.get('executionDate') === null) {
        return false;
      } else {
        return this.get('expectedDate') < this.get('executionDate');
      }
    }),
    isConfirmed: Ember.computed('executionDate', function () {
      return this.get('executionDate') != null;
    }),
    name: Ember.computed('task.checkpointName', function () {
      return this.get('task').get('checkpointName');
    }),
    checkpointNameInAppropriateLanguage: Ember.computed('task.checkpointNameInAppropriateLanguage', function () {
      return this.get('task').get('checkpointNameInAppropriateLanguage');
    }),
    stageName: Ember.computed('task.stageNameInAppropriateLanguage', 'name', function () {
      return this.get('task.stageNameInAppropriateLanguage') || this.get('intl').t('task.defaultStagePrefix') + ' ' + this.get('name');
    }),
    checkpointGroup: Ember.computed('task.checkpointGroup', function () {
      return this.get('task.checkpointGroup');
    }),
    idxInGroup: Ember.computed('task.idxInGroup', function () {
      return this.get('task.idxInGroup');
    }),
    tracked: Ember.computed('task.tracked', function () {
      return this.get('task.tracked');
    }),
    hasAlert: Ember.computed('isConfirmed', 'alertDate', 'transport.lastUpdated', function () {
      return !this.get('isConfirmed') && this.get('alertDate') && this.get('alertDate').getTime() <= new Date().getTime();
    }),
    decapitalizedName: Ember.computed('checkpointNameInAppropriateLanguage', function () {
      return this.get('checkpointNameInAppropriateLanguage').decapitalize();
    }),
    isEditableOnStatusList: Ember.computed('task.isEditableOnStatusList', function () {
      return this.get('task.isEditableOnStatusList');
    }),
    isEditableOnStatusListWithSap: Ember.computed('task.isEditableOnStatusListWithSap', function () {
      return this.get('task.isEditableOnStatusListWithSap');
    }),
    canBeEditedOnStatusList: Ember.computed('isEditableOnStatusList', 'isEditableOnStatusListWithSap', 'transport.isFromSap', function () {
      if (this.get('isEditableOnStatusList') && !this.get('transport.isFromSap')) {
        return true;
      } else if (this.get('transport.isFromSap') && this.get('isEditableOnStatusListWithSap')) {
        return true;
      }

      return false;
    }),
    confirmButtonShouldBeDisabled: Ember.computed('isBeingProcessed', 'transport.executionDate', function () {
      return this.get('isBeingProcessed') || !this.get('task.roleGroupsAuthorizedToConfirmIds').includes(this.get('sessionAccount.currentUser.roleGroup.id')) || this.get('task.isExecutionDateRequired') && !this.get('transport.executionDate');
    }),
    disabledHint: Ember.computed('isBeingProcessed', 'transport.executionDate', function () {
      if (!this.get('confirmButtonShouldBeDisabled')) {
        return null;
      }

      if (this.get('isBeingProcessed')) {
        return this.get('intl').t('checkpoints.beingProcessedHint');
      } else if (!this.get('task.roleGroupsAuthorizedToConfirmIds').includes(this.get('sessionAccount.currentUser.roleGroup.id'))) {
        return this.get('intl').t('checkpoints.cannotConfirmHint');
      } else if (this.get('task.isExecutionDateRequired') && !this.get('transport.executionDate')) {
        return this.get('intl').t('checkpoints.noExecutionDateHint');
      } else {
        return null;
      }
    })
  });

  _exports.default = _default;
});