define("apollo/pods/components/transport/step-section/component", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    cookies: Ember.inject.service(),
    companyType: "CLIENT",
    companies: Ember.computed(function () {
      var self = this;
      var principalCompanyId = self.get('cookies').read('principalId');
      return self.get('store').query('company', {
        principalCompanyId: principalCompanyId,
        companyType: self.get('companyType')
      }).then(function (items) {
        return items;
      });
    }),
    deliveryDate: Ember.computed({
      get: function get() {
        if (this._deliveryDate) {
          return this._deliveryDate;
        }

        if (!this.get('step.id')) {
          return this.get('store').createRecord('step');
        }

        return this.get('step.deliveryDateFrom');
      },
      set: function set(key, value) {
        return this._deliveryDate = value;
      }
    }),
    deliveryTimeFrom: Ember.computed(function () {
      return this.get('step.deliveryDateFrom') ? moment(this.get('step.deliveryDateFrom')).format('HH:mm') : '00:00';
    }),
    setDeliveryDateFrom: Ember.observer('deliveryDate', 'deliveryTimeFrom', function () {
      var date = moment(this.get('deliveryDate')).format('YYYY-MM-DD');
      var time = this.get('deliveryTimeFrom');
      var timeAndDate = moment(date + ' ' + time);
      this.set('step.deliveryDateFrom', timeAndDate);
    }),
    deliveryTimeTo: Ember.computed(function () {
      return this.get('step.deliveryDateTo') ? moment(this.get('step.deliveryDateTo')).format('HH:mm') : '00:00';
    }),
    setDeliveryDateTo: Ember.observer('deliveryDate', 'deliveryTimeTo', function () {
      var date = moment(this.get('deliveryDate')).format('YYYY-MM-DD');
      var time = this.get('deliveryTimeTo');
      var timeAndDate = moment(date + ' ' + time);
      this.set('step.deliveryDateTo', timeAndDate.toDate());
    }),
    actions: {
      deleteStep: function deleteStep(idx) {
        this.deleteStep(idx);
      },
      setContractorCompanyData: function setContractorCompanyData(step, item) {
        this.get('store').findRecord('company', item.id).then(function (company) {
          step.set('company', company);
          step.set('address', null);
        });
      },
      selectContractorCompany: function selectContractorCompany(id) {
        if (!id) {
          return;
        }

        var self = this;
        this.get('store').findRecord("company", id).then(function (company) {
          self.get("transport").set("contractorCompany", company);
        });
      },
      offScroll: function offScroll() {
        (0, _jquery.default)(':input').on('mousewheel', function (event) {
          event.preventDefault();
        });
      },
      setCompanies: function setCompanies() {
        this.notifyPropertyChange('companies');
      },
      setDeliveryDate: function setDeliveryDate(date) {
        this.set('deliveryDate', date);
      }
    }
  });

  _exports.default = _default;
});