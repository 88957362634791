define("apollo/pods/components/transport-grouping/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "E5jhzFEd",
    "block": "{\"symbols\":[\"s\",\"idx\",\"p\"],\"statements\":[[4,\"each\",[[24,[\"transport\",\"steps\"]]],null,{\"statements\":[[4,\"if\",[[28,\"eq\",[[23,2,[]],0],null]],null,{\"statements\":[[0,\"        \"],[1,[23,1,[\"company\",\"shortName\"]],false],[0,\" \"],[7,\"b\",true],[8],[1,[23,1,[\"address\",\"country\",\"ibanSymbol\"]],false],[9],[0,\"\\n        \"],[1,[23,1,[\"address\",\"city\"]],false],[0,\" \"],[1,[28,\"format-date\",[[23,1,[\"deliveryDateFrom\"]],\"DD.MM\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[28,\"is-last-transport-step\",[[24,[\"transport\",\"steps\"]],[23,2,[]]],null]],null,{\"statements\":[[0,\"        - \"],[1,[28,\"format-date\",[[23,1,[\"deliveryDateFrom\"]],\"DD.MM\"],null],false],[0,\" \"],[7,\"b\",true],[8],[1,[23,1,[\"address\",\"country\",\"ibanSymbol\"]],false],[9],[0,\"\\n        \"],[1,[23,1,[\"address\",\"city\"]],false],[0,\"\\n\"],[4,\"each\",[[24,[\"transport\",\"products\"]]],null,{\"statements\":[[0,\"            \"],[1,[23,3,[\"name\"]],false],[0,\"\\n\"]],\"parameters\":[3]},null]],\"parameters\":[]},null]],\"parameters\":[1,2]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/transport-grouping/template.hbs"
    }
  });

  _exports.default = _default;
});