define("apollo/pods/components/transports/transports-table/transport-row/component", ["exports", "jquery", "apollo/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    tagName: 'tr',
    classNames: ['transport-row'],
    classNameBindings: ['transport.isHighlighted:checked-transport-row'],
    click: function click() {
      this.toggleProperty('transport.isHighlighted');
      this.updateHighlightedRow(this.get('transport.id'));
    },
    sessionAccount: Ember.inject.service('session-account'),
    //uzywane w template
    store: Ember.inject.service(),
    cookies: Ember.inject.service(),
    transport: null,
    action: '',
    loadCitiesAndRecipients: Ember.computed('transport.loadSteps.length', 'transport.loadSteps.@each.address', 'transport.transport.loadSteps.length', 'transport.transport.loadSteps.@each.address', function () {
      var citiesAndRecipients = [];
      var steps = this.get('transport.externalCost.id') ? this.get('transport.transport.loadSteps') : this.get('transport.loadSteps');
      steps.map(function (s) {
        var cityAndRecipient = s.get('address.city');

        if (s.get('address.recipient')) {
          var recipient = s.get('address.recipient.length') < 20 ? s.get('address.recipient') : s.get('address.recipient').substring(0, 20) + "...";
          cityAndRecipient += ' ' + recipient;
        }

        citiesAndRecipients.push(cityAndRecipient);
      });
      return citiesAndRecipients;
    }),
    unloadCitiesAndRecipients: Ember.computed('transport.unloadSteps.length', 'transport.unloadSteps.@each.address', 'transport.transport.unloadSteps.length', 'transport.transport.unloadSteps.@each.address', function () {
      var citiesAndRecipients = [];
      var steps = this.get('transport.externalCost.id') ? this.get('transport.transport.unloadSteps') : this.get('transport.unloadSteps');
      steps.map(function (s) {
        var cityAndRecipient = s.get('address.city');

        if (s.get('address.recipient')) {
          var recipient = s.get('address.recipient.length') < 20 ? s.get('address.recipient') : s.get('address.recipient').substring(0, 20) + "...";
          cityAndRecipient += ' ' + recipient;
        }

        citiesAndRecipients.push(cityAndRecipient);
      });
      return citiesAndRecipients;
    }),
    canChooseWash: Ember.computed('transport.carrierCompany', function () {
      var carrier = this.get('transport.carrierCompany');
      return carrier.get('id') && !carrier.get('isExternal');
    }),
    sendSmsAfterAdviceButtonIsDisabled: Ember.computed(function () {
      return false;
    }),
    costsCurrencies: Ember.computed('transport.externalCosts.@each.price', function () {
      var _this = this;

      var costCurrencies = [];
      var costsForCurrencies = {};
      var currencies = this.get('transport.externalCosts').uniqBy('currency.symbol').getEach('currency.symbol');
      currencies.forEach(function (c) {
        costsForCurrencies[c] = _this.get('transport.externalCosts').filterBy('currency.symbol', c);
      });
      currencies.forEach(function (c) {
        var sum = 0;
        costsForCurrencies[c].forEach(function (ex) {
          sum += parseFloat(ex.get('price'));
        });
        costCurrencies.push(" ".concat(sum, " ").concat(c));
      });
      return costCurrencies;
    }),
    serviceWorkloadWithUnit: Ember.computed('transport.service.workload', function () {
      return this.get('transport.service.workload') && !this.get('transport.steps.firstObject.company.isExternal') ? '+ ' + this.get('transport.service.workload') + ' [h]' : '';
    }),
    showChooseMechanicButton: Ember.computed('transport.steps.firstObject.company.id', 'transport.steps.firstObject.company.isExternal', function () {
      return this.get('transport.steps.firstObject.company.id') && !this.get('transport.steps.firstObject.company.isExternal');
    }),
    deletingOrderNotPossibleHint: Ember.computed('deletingInProgress', 'transport.{deleted,pzNumber}', 'archive', function () {
      if (this.get('deletingInProgress')) {
        return this.get('intl').t('external-cost.order.deletingInProgress');
      } else {
        return this.get('transport.deletingOrderNotPossibleHint');
      }
    }),
    actions: {
      showModal: function showModal(template, transport) {
        var self = this;

        if (template === "transports.modals.create-transport" && transport.get('additionalData.length') === 0 && !transport.get('remoteId')) {
          this.store.createRecord('additionalDatum', {
            transport: transport
          });
        }

        if (transport.reload) {
          transport.reload().then(function (t) {
            self.showModal(template, t);
          });
        } else {
          self.showModal(template, transport);
        }
      },
      confirm: function confirm(checkpoint) {
        this.confirm(checkpoint);
      },
      revoke: function revoke(checkpoint) {
        this.revoke(checkpoint);
      },
      markNotesAsReadOff: function markNotesAsReadOff(transport) {
        this.markNotesAsReadOff(transport);
      },
      addNote: function addNote(noteContent, transportId) {
        this.addNote(noteContent, transportId);
      },
      deleteFile: function deleteFile(file) {
        this.deleteFile(file);
      },
      fileUploadSuccess: function fileUploadSuccess(transportId) {
        this.fileUploadSuccess(transportId);
      },
      confirmTransport: function confirmTransport(transport) {
        var self = this;
        self.set('confirmTransportButtonIsDisabled', true);

        _jquery.default.ajax({
          type: "POST",
          url: _environment.default.serverURL + '/checkpoints/confirmTransport',
          data: {
            id: transport.get('id')
          },
          beforeSend: function beforeSend(xhr) {
            xhr.setRequestHeader("Authorization", "Bearer " + self.get('sessionAccount').get("token"));
          }
        }).then(function () {
          self.set('transport.isConfirmed', true);
          transport.refresh();
        });
      },
      createAdvice: function createAdvice(transport) {
        var model = {
          transport: transport
        };
        model.transport.set('advice', this.get('store').createRecord('advice', {}));
        this.showModal('advice.modals.create-advice', model);
      },
      chooseCarrier: function chooseCarrier(transport) {
        this.showModal('carrier.modals.choose-carrier', transport);
      },
      removeCost: function removeCost(costOrder) {
        var _this2 = this;

        this.set('deletingInProgress', true);
        costOrder.get('externalCost').then(function (costModel) {
          costModel.destroyRecord().then(function () {
            costOrder.refresh();
          }).catch(function (response) {
            alert(response.errors.join("\n"));
          }).finally(function () {
            _this2.set('deletingInProgress', false);
          });
        });
      },
      editExecutionDate: function editExecutionDate(externalCostOrder) {
        this.showModal('transports.modals.edit-execution-date', externalCostOrder);
      },
      handleErrors: function handleErrors(errors) {
        this.get('handleErrors')(errors);
      }
    }
  });

  _exports.default = _default;
});