define("apollo/initializers/older-browsers", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  // http://www.gevious.com/2015/01/07/making-an-ember-enterprise-app-work-on-ie8/?utm_source=Ember+Weekly&utm_campaign=6a8c312b3a-Ember_Weekly_Issue_93&utm_medium=email&utm_term=0_e96229d21d-6a8c312b3a-95806901
  function initialize()
  /* container, application */
  {
    _jquery.default.ajaxSetup({
      cache: false
    });

    if ((0, _jquery.default)('html').is('.polyfill')) {
      // trim function for IE8
      String.prototype.trim = function () {
        return this.replace(/^\s+|\s+$/g, '');
      }; // simple Object.keys polyfill that could work


      if (!Object.keys) {
        Object.keys = function (obj) {
          var keys = [];

          for (var i in obj) {
            if (obj.hasOwnProperty(i)) {
              keys.push(i);
            }
          }

          return keys;
        };
      }

      if (typeof console === "undefined" || typeof console.log === "undefined") {
        console = {};

        console.log = function (msg) {};

        console.error = function (msg) {};

        console.debug = function (msg) {};

        console.warn = function (msg) {};

        console.info = function (msg) {};
      }
    } // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/assign


    if (typeof Object.assign !== 'function') {
      console.debug("Using polyfill for 'assign' method..");

      Object.assign = function (target, varArgs) {
        // .length of function is 2
        'use strict';

        if (target == null) {
          // TypeError if undefined or null
          throw new TypeError('Cannot convert undefined or null to object');
        }

        var to = Object(target);

        for (var index = 1; index < arguments.length; index++) {
          var nextSource = arguments[index];

          if (nextSource != null) {
            // Skip over if undefined or null
            for (var nextKey in nextSource) {
              // Avoid bugs when hasOwnProperty is shadowed
              if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
                to[nextKey] = nextSource[nextKey];
              }
            }
          }
        }

        return to;
      };
    }
  }

  var _default = {
    name: 'older-browsers',
    initialize: initialize
  };
  _exports.default = _default;
});