define("apollo/cldrs/pl", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*jslint eqeq: true*/
  var _default = [{
    "locale": "pl",
    "pluralRuleFunction": function pluralRuleFunction(n, ord) {
      var s = String(n).split("."),
          i = s[0],
          v0 = !s[1],
          i10 = i.slice(-1),
          i100 = i.slice(-2);
      if (ord) return "other";
      return n == 1 && v0 ? "one" : v0 && i10 >= 2 && i10 <= 4 && (i100 < 12 || i100 > 14) ? "few" : v0 && i != 1 && (i10 == 0 || i10 == 1) || v0 && i10 >= 5 && i10 <= 9 || v0 && i100 >= 12 && i100 <= 14 ? "many" : "other";
    },
    "fields": {
      "year": {
        "displayName": "rok",
        "relative": {
          "0": "w tym roku",
          "1": "w przyszłym roku",
          "-1": "w zeszłym roku"
        },
        "relativeTime": {
          "future": {
            "one": "za {0} rok",
            "few": "za {0} lata",
            "many": "za {0} lat",
            "other": "za {0} roku"
          },
          "past": {
            "one": "{0} rok temu",
            "few": "{0} lata temu",
            "many": "{0} lat temu",
            "other": "{0} roku temu"
          }
        }
      },
      "year-short": {
        "displayName": "r.",
        "relative": {
          "0": "w tym roku",
          "1": "w przyszłym roku",
          "-1": "w zeszłym roku"
        },
        "relativeTime": {
          "future": {
            "one": "za {0} rok",
            "few": "za {0} lata",
            "many": "za {0} lat",
            "other": "za {0} roku"
          },
          "past": {
            "one": "{0} rok temu",
            "few": "{0} lata temu",
            "many": "{0} lat temu",
            "other": "{0} roku temu"
          }
        }
      },
      "month": {
        "displayName": "miesiąc",
        "relative": {
          "0": "w tym miesiącu",
          "1": "w przyszłym miesiącu",
          "-1": "w zeszłym miesiącu"
        },
        "relativeTime": {
          "future": {
            "one": "za {0} miesiąc",
            "few": "za {0} miesiące",
            "many": "za {0} miesięcy",
            "other": "za {0} miesiąca"
          },
          "past": {
            "one": "{0} miesiąc temu",
            "few": "{0} miesiące temu",
            "many": "{0} miesięcy temu",
            "other": "{0} miesiąca temu"
          }
        }
      },
      "month-short": {
        "displayName": "mies.",
        "relative": {
          "0": "w tym miesiącu",
          "1": "w przyszłym miesiącu",
          "-1": "w zeszłym miesiącu"
        },
        "relativeTime": {
          "future": {
            "one": "za {0} mies.",
            "few": "za {0} mies.",
            "many": "za {0} mies.",
            "other": "za {0} mies."
          },
          "past": {
            "one": "{0} mies. temu",
            "few": "{0} mies. temu",
            "many": "{0} mies. temu",
            "other": "{0} mies. temu"
          }
        }
      },
      "day": {
        "displayName": "dzień",
        "relative": {
          "0": "dzisiaj",
          "1": "jutro",
          "2": "pojutrze",
          "-2": "przedwczoraj",
          "-1": "wczoraj"
        },
        "relativeTime": {
          "future": {
            "one": "za {0} dzień",
            "few": "za {0} dni",
            "many": "za {0} dni",
            "other": "za {0} dnia"
          },
          "past": {
            "one": "{0} dzień temu",
            "few": "{0} dni temu",
            "many": "{0} dni temu",
            "other": "{0} dnia temu"
          }
        }
      },
      "day-short": {
        "displayName": "dzień",
        "relative": {
          "0": "dzisiaj",
          "1": "jutro",
          "2": "pojutrze",
          "-2": "przedwczoraj",
          "-1": "wczoraj"
        },
        "relativeTime": {
          "future": {
            "one": "za {0} dzień",
            "few": "za {0} dni",
            "many": "za {0} dni",
            "other": "za {0} dnia"
          },
          "past": {
            "one": "{0} dzień temu",
            "few": "{0} dni temu",
            "many": "{0} dni temu",
            "other": "{0} dnia temu"
          }
        }
      },
      "hour": {
        "displayName": "godzina",
        "relative": {
          "0": "ta godzina"
        },
        "relativeTime": {
          "future": {
            "one": "za {0} godzinę",
            "few": "za {0} godziny",
            "many": "za {0} godzin",
            "other": "za {0} godziny"
          },
          "past": {
            "one": "{0} godzinę temu",
            "few": "{0} godziny temu",
            "many": "{0} godzin temu",
            "other": "{0} godziny temu"
          }
        }
      },
      "hour-short": {
        "displayName": "godz.",
        "relative": {
          "0": "ta godzina"
        },
        "relativeTime": {
          "future": {
            "one": "za {0} godz.",
            "few": "za {0} godz.",
            "many": "za {0} godz.",
            "other": "za {0} godz."
          },
          "past": {
            "one": "{0} godz. temu",
            "few": "{0} godz. temu",
            "many": "{0} godz. temu",
            "other": "{0} godz. temu"
          }
        }
      },
      "minute": {
        "displayName": "minuta",
        "relative": {
          "0": "ta minuta"
        },
        "relativeTime": {
          "future": {
            "one": "za {0} minutę",
            "few": "za {0} minuty",
            "many": "za {0} minut",
            "other": "za {0} minuty"
          },
          "past": {
            "one": "{0} minutę temu",
            "few": "{0} minuty temu",
            "many": "{0} minut temu",
            "other": "{0} minuty temu"
          }
        }
      },
      "minute-short": {
        "displayName": "min",
        "relative": {
          "0": "ta minuta"
        },
        "relativeTime": {
          "future": {
            "one": "za {0} min",
            "few": "za {0} min",
            "many": "za {0} min",
            "other": "za {0} min"
          },
          "past": {
            "one": "{0} min temu",
            "few": "{0} min temu",
            "many": "{0} min temu",
            "other": "{0} min temu"
          }
        }
      },
      "second": {
        "displayName": "sekunda",
        "relative": {
          "0": "teraz"
        },
        "relativeTime": {
          "future": {
            "one": "za {0} sekundę",
            "few": "za {0} sekundy",
            "many": "za {0} sekund",
            "other": "za {0} sekundy"
          },
          "past": {
            "one": "{0} sekundę temu",
            "few": "{0} sekundy temu",
            "many": "{0} sekund temu",
            "other": "{0} sekundy temu"
          }
        }
      },
      "second-short": {
        "displayName": "sek.",
        "relative": {
          "0": "teraz"
        },
        "relativeTime": {
          "future": {
            "one": "za {0} sek.",
            "few": "za {0} sek.",
            "many": "za {0} sek.",
            "other": "za {0} sek."
          },
          "past": {
            "one": "{0} sek. temu",
            "few": "{0} sek. temu",
            "many": "{0} sek. temu",
            "other": "{0} sek. temu"
          }
        }
      }
    },
    "numbers": {
      "decimal": {
        "long": [[1000, {
          "one": ["0 tysiąc", 1],
          "few": ["0 tysiące", 1],
          "many": ["0 tysięcy", 1],
          "other": ["0 tysiąca", 1]
        }], [10000, {
          "one": ["00 tysiąc", 2],
          "few": ["00 tysiące", 2],
          "many": ["00 tysięcy", 2],
          "other": ["00 tysiąca", 2]
        }], [100000, {
          "one": ["000 tysiąc", 3],
          "few": ["000 tysiące", 3],
          "many": ["000 tysięcy", 3],
          "other": ["000 tysiąca", 3]
        }], [1000000, {
          "one": ["0 milion", 1],
          "few": ["0 miliony", 1],
          "many": ["0 milionów", 1],
          "other": ["0 miliona", 1]
        }], [10000000, {
          "one": ["00 milion", 2],
          "few": ["00 miliony", 2],
          "many": ["00 milionów", 2],
          "other": ["00 miliona", 2]
        }], [100000000, {
          "one": ["000 milion", 3],
          "few": ["000 miliony", 3],
          "many": ["000 milionów", 3],
          "other": ["000 miliona", 3]
        }], [1000000000, {
          "one": ["0 miliard", 1],
          "few": ["0 miliardy", 1],
          "many": ["0 miliardów", 1],
          "other": ["0 miliarda", 1]
        }], [10000000000, {
          "one": ["00 miliard", 2],
          "few": ["00 miliardy", 2],
          "many": ["00 miliardów", 2],
          "other": ["00 miliarda", 2]
        }], [100000000000, {
          "one": ["000 miliard", 3],
          "few": ["000 miliardy", 3],
          "many": ["000 miliardów", 3],
          "other": ["000 miliarda", 3]
        }], [1000000000000, {
          "one": ["0 bilion", 1],
          "few": ["0 biliony", 1],
          "many": ["0 bilionów", 1],
          "other": ["0 biliona", 1]
        }], [10000000000000, {
          "one": ["00 bilion", 2],
          "few": ["00 biliony", 2],
          "many": ["00 bilionów", 2],
          "other": ["00 biliona", 2]
        }], [100000000000000, {
          "one": ["000 bilion", 3],
          "few": ["000 biliony", 3],
          "many": ["000 bilionów", 3],
          "other": ["000 biliona", 3]
        }]],
        "short": [[1000, {
          "one": ["0 tys'.'", 1],
          "few": ["0 tys'.'", 1],
          "many": ["0 tys'.'", 1],
          "other": ["0 tys'.'", 1]
        }], [10000, {
          "one": ["00 tys'.'", 2],
          "few": ["00 tys'.'", 2],
          "many": ["00 tys'.'", 2],
          "other": ["00 tys'.'", 2]
        }], [100000, {
          "one": ["000 tys'.'", 3],
          "few": ["000 tys'.'", 3],
          "many": ["000 tys'.'", 3],
          "other": ["000 tys'.'", 3]
        }], [1000000, {
          "one": ["0 mln", 1],
          "few": ["0 mln", 1],
          "many": ["0 mln", 1],
          "other": ["0 mln", 1]
        }], [10000000, {
          "one": ["00 mln", 2],
          "few": ["00 mln", 2],
          "many": ["00 mln", 2],
          "other": ["00 mln", 2]
        }], [100000000, {
          "one": ["000 mln", 3],
          "few": ["000 mln", 3],
          "many": ["000 mln", 3],
          "other": ["000 mln", 3]
        }], [1000000000, {
          "one": ["0 mld", 1],
          "few": ["0 mld", 1],
          "many": ["0 mld", 1],
          "other": ["0 mld", 1]
        }], [10000000000, {
          "one": ["00 mld", 2],
          "few": ["00 mld", 2],
          "many": ["00 mld", 2],
          "other": ["00 mld", 2]
        }], [100000000000, {
          "one": ["000 mld", 3],
          "few": ["000 mld", 3],
          "many": ["000 mld", 3],
          "other": ["000 mld", 3]
        }], [1000000000000, {
          "one": ["0 bln", 1],
          "few": ["0 bln", 1],
          "many": ["0 bln", 1],
          "other": ["0 bln", 1]
        }], [10000000000000, {
          "one": ["00 bln", 2],
          "few": ["00 bln", 2],
          "many": ["00 bln", 2],
          "other": ["00 bln", 2]
        }], [100000000000000, {
          "one": ["000 bln", 3],
          "few": ["000 bln", 3],
          "many": ["000 bln", 3],
          "other": ["000 bln", 3]
        }]]
      }
    }
  }];
  _exports.default = _default;
});