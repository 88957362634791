define("apollo/pods/terms/review/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "w4KqeTdT",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"container container-medium\"],[8],[0,\"\\n    \"],[7,\"h2\",true],[8],[0,\"\\n        \"],[1,[28,\"t\",[\"statute.accept.header\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"padding-top-big\"],[8],[0,\"\\n        \"],[1,[28,\"users/rodo-information\",null,[[\"approveAllPoints\",\"disapproveSomePoints\"],[[28,\"action\",[[23,0,[]],\"approveAllPoints\"],null],[28,\"action\",[[23,0,[]],\"disapproveSomePoints\"],null]]]],false],[0,\"\\n\\n        \"],[7,\"div\",true],[10,\"class\",\"padding-top-big\"],[8],[0,\"\\n            \"],[7,\"div\",true],[10,\"class\",\"text-right\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"statuteAccepted\"]]],null,{\"statements\":[[0,\"                    \"],[7,\"button\",false],[12,\"class\",\"btn btn-primary\"],[3,\"action\",[[23,0,[]],\"acceptStatute\"]],[8],[0,\"\\n                        \"],[1,[28,\"t\",[\"common.save\"],null],false],[0,\"\\n                    \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"                    \"],[7,\"button\",true],[10,\"class\",\"btn btn-default\"],[10,\"disabled\",\"disabled\"],[11,\"title\",[28,\"t\",[\"statute.accept.disabled.title\"],null]],[8],[0,\"\\n                        \"],[1,[28,\"t\",[\"common.save\"],null],false],[0,\"\\n                    \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[4,\"button-with-confirmation\",null,[[\"class\",\"text\",\"onConfirm\"],[\"btn btn-danger\",[28,\"t\",[\"statute.reject.confirmation\"],null],[28,\"action\",[[23,0,[]],\"rejectStatute\"],null]]],{\"statements\":[[0,\"                    \"],[1,[28,\"t\",[\"statute.reject.button\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"            \"],[9],[0,\"\\n        \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/terms/review/template.hbs"
    }
  });

  _exports.default = _default;
});