define("apollo/pods/trailer/model", ["exports", "ember-data", "apollo/pods/vehicle/model"], function (_exports, _emberData, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    SCHEDULE_WARNINGS: ['INSURANCE', 'ADR', 'LAST_REVIEW'],
    intl: Ember.inject.service(),
    truck: _emberData.default.belongsTo('truck', {
      async: true
    }),
    axisType: _emberData.default.attr('string'),
    valveType: _emberData.default.attr('string'),
    trailerType: _emberData.default.attr('string'),
    validations: {
      registrationNumber: {
        presence: {
          message: 'blank'
        },
        length: {
          minimum: {
            value: 3,
            message: 'tooShort'
          },
          maximum: {
            value: 40,
            message: 'tooLong'
          }
        }
      }
    },
    trailerTypeInAppropriateLanguage: Ember.computed('trailerType', function () {
      var trailerType = this.get('trailerType');

      if (!trailerType) {
        return '';
      }

      return this.get('intl').t("vehicle.trailerType.".concat(trailerType));
    }),
    unconfirmedWashingNumber: Ember.computed('truck.numberOfUnconfirmedWashingCheckpoints', 'numberOfUnconfirmedWashingCheckpoints', function () {
      return this.get('numberOfUnconfirmedWashingCheckpoints') ? this.get('numberOfUnconfirmedWashingCheckpoints') : this.get('truck.numberOfUnconfirmedWashingCheckpoints');
    })
  });

  _exports.default = _default;
});