define("apollo/pods/user/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    sessionAccount: Ember.inject.service('session-account'),
    workflows: _emberData.default.hasMany('workflow', {
      async: true
    }),
    roles: _emberData.default.hasMany('role', {
      async: true
    }),
    tableColumns: _emberData.default.hasMany('tableColumn', {
      async: true
    }),
    notifications: _emberData.default.hasMany('notification', {
      async: true
    }),
    transportTypes: _emberData.default.hasMany('transportType', {
      async: true
    }),
    services: _emberData.default.hasMany('service', {
      async: true
    }),
    company: _emberData.default.belongsTo('company', {
      async: true
    }),
    roleGroup: _emberData.default.belongsTo('roleGroup', {
      async: true
    }),
    userGroup: _emberData.default.belongsTo('userGroup', {
      async: true
    }),
    whoDeleted: _emberData.default.belongsTo('user', {
      async: true
    }),
    invitation: _emberData.default.belongsTo('invitation', {
      async: true,
      inverse: 'registeredAccount'
    }),
    username: _emberData.default.attr('string'),
    name: _emberData.default.attr('string'),
    email: _emberData.default.attr('string'),
    enabled: _emberData.default.attr('boolean'),
    password: _emberData.default.attr('string'),
    phoneNumber: _emberData.default.attr('string'),
    mobilePhoneNumber: _emberData.default.attr('string'),
    occupation: _emberData.default.attr('string'),
    workHours: _emberData.default.attr('string'),
    newPassword: _emberData.default.attr('string'),
    repeatedNewPassword: _emberData.default.attr('string'),
    changePassword: _emberData.default.attr('boolean', {
      defaultValue: function defaultValue() {
        return false;
      }
    }),
    accountExpired: _emberData.default.attr('boolean'),
    accountLocked: _emberData.default.attr('boolean'),
    passwordExpired: _emberData.default.attr('boolean'),
    dateCreated: _emberData.default.attr('date'),
    dateDeleted: _emberData.default.attr('date'),
    resetPasswordDate: _emberData.default.attr('date'),
    resetPasswordIsDisabled: _emberData.default.attr('boolean'),
    statuteAccepted: _emberData.default.attr('boolean'),
    sapLogin: _emberData.default.attr('string'),
    roleNames: Ember.computed('roles.length', 'roles.@each.name', function () {
      return this.get('roles').map(function (r) {
        return r.get('name');
      });
    }),
    isPrincipal: Ember.computed('company.isPrincipal', function () {
      return this.get('company').get('isPrincipal');
    }),
    isFullyRegistered: Ember.computed('isPrincipal', function () {
      var isFullyRegistered = !this.get('isPrincipal');
      console.log("isFullyRegistered? ".concat(isFullyRegistered));
      return isFullyRegistered;
    }),
    canNotEditCompany: Ember.computed(function () {
      return !this.get('sessionAccount').hasRole('ROLE_EDIT_COMPANY');
    }),
    canNotEditResources: Ember.computed(function () {
      return !this.get('sessionAccount').hasRole('ROLE_CREATE_RESOURCES');
    }),
    asResource: function asResource(children, group) {
      var company = this.get('company');
      var self = this;
      return {
        id: group ? group.get('id') + '-' + this.get('id') : this.get('id'),
        title: this.get('name'),
        children: children ? children : [],
        onDrop: function onDrop(context, transport, revertFunc) {
          if (transport.get('transportType').content.get('name') === 'SERVICE') {
            context.chooseService(transport, company, self, revertFunc);
          } else {
            transport.cancelAdvice();
            context.chooseCarrier(transport, company, group, revertFunc);
          }
        }
      };
    }
  });

  _exports.default = _default;
});