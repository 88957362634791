define("apollo/pods/service-type/model", ["exports", "ember-data", "apollo/mixins/model-validator"], function (_exports, _emberData, _modelValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend(_modelValidator.default, {
    services: _emberData.default.hasMany('service', {
      async: true
    }),
    name: _emberData.default.attr('string'),
    validations: {
      name: {
        presence: {
          message: 'blank'
        }
      }
    }
  });

  _exports.default = _default;
});