define("apollo/pods/transports/modals/show-sms-statuses/controller", ["exports", "jquery", "apollo/mixins/modal-mixin"], function (_exports, _jquery, _modalMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_modalMixin.default, {
    intl: Ember.inject.service(),
    statuses: Ember.computed('model', function () {
      return this.get('model');
    }),
    actions: {
      cancel: function cancel() {
        (0, _jquery.default)('.modal').modal('hide');
      }
    }
  });

  _exports.default = _default;
});