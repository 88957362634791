define("apollo/pods/truck/model", ["exports", "ember-data", "apollo/pods/vehicle/model"], function (_exports, _emberData, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    SCHEDULE_WARNINGS: ['INSURANCE', 'ADR', 'TACHOGRAPH', 'LAST_REVIEW'],
    trailer: _emberData.default.belongsTo('trailer', {
      async: true
    }),
    group: _emberData.default.belongsTo('userGroup', {
      async: true
    }),
    carTypeGroup: _emberData.default.belongsTo('carTypeGroup', {
      async: true
    }),
    euroStandard: _emberData.default.attr('string'),
    costObject: _emberData.default.attr('string'),
    validations: {
      registrationNumber: {
        presence: {
          message: 'blank'
        },
        length: {
          minimum: {
            value: 3,
            message: 'tooShort'
          },
          maximum: {
            value: 20,
            message: 'tooLong'
          }
        }
      },
      carTypeGroup: {
        presence: {
          message: 'blank'
        }
      },
      group: {
        custom: {
          validation: function validation(key, value, model) {
            if (model.get('owner.isExternal')) {
              return true;
            } else {
              return value.get('id') !== null && value.get('id') !== undefined;
            }
          },
          message: 'chooseGroup'
        }
      }
    },
    label: Ember.computed('registrationNumber', 'sideNumber', function () {
      var registrationNumber = this.get('registrationNumber') ? this.get('registrationNumber') : "";
      var sideNumber = this.get('sideNumber') ? this.get('sideNumber') : "";

      if (this.get('owner.isExternal')) {
        return registrationNumber;
      } else {
        return "".concat(registrationNumber, " ").concat(sideNumber).trim();
      }
    }),
    asResource: function asResource(children) {
      var truck = this;
      return {
        id: this.get('group.id') + '-' + this.get('owner.id') + '-' + this.get('id'),
        title: this.get('sideNumber') ? this.get('sideNumber') : this.get('registrationNumber'),
        children: children ? children : [],
        onDrop: function onDrop(context, transport, revertFunc) {
          if (transport.get('transportType.typeGroup') === 'SERVICE') {
            revertFunc();
          } else {
            if (transport.get('carrierCompany.id') === truck.get('owner.id')) {
              context.createAdvice(transport, truck, revertFunc);
            } else {
              transport.cancelAdvice();
              context.chooseCarrier(transport, truck.get('owner'), truck.get('group'), revertFunc);
            }
          }
        }
      };
    }
  });

  _exports.default = _default;
});