define("apollo/pods/components/file-picker/component", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      var self = this;
      var fileInput = new moxie.file.FileInput({
        browse_button: (0, _jquery.default)(this.element).find('.upload-button')[0],
        multiple: true
      });

      fileInput.onchange = function () {
        self.addFiles(fileInput.files);
      };

      fileInput.init();
    }
  });

  _exports.default = _default;
});