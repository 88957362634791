define("apollo/pods/settings/index/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    sessionAccount: Ember.inject.service('session-account'),
    model: function model() {
      return Ember.RSVP.hash({
        settings: this.store.peekAll('setting').sortBy('name'),
        controlledDateTypes: this.store.peekAll('controlledDateType').sortBy('name'),
        workflows: this.get('sessionAccount').get('currentUser.workflows')
      });
    },
    actions: {
      willTransition: function willTransition() {
        this.get('controller').setProperties({
          info: null,
          errors: null,
          successMessage: null
        });
      }
    }
  });

  _exports.default = _default;
});