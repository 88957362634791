define("apollo/pods/components/transport/add-company/component", ["exports", "jquery", "apollo/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    sessionAccount: Ember.inject.service('session-account'),
    store: Ember.inject.service(),
    cookies: Ember.inject.service(),
    intl: Ember.inject.service(),
    countries: Ember.computed(function () {
      return this.get('store').peekAll('country');
    }),
    cooperatingCompanies: [],
    errors: [],
    postKey: Ember.observer('companyName', function () {
      var self = this;
      var searchText = this.get('companyName');
      self.send('searchCompanies', searchText);
    }),
    principalCompanyId: Ember.computed(function () {
      return this.get('cookies').read('principalId');
    }),
    existingCompany: {},
    existingCompanyAddresses: [],
    onlyCompanyExist: false,
    companyAndPartnershipExist: false,
    sendInvitation: true,
    actions: {
      showAddCompanyForm: function showAddCompanyForm() {
        this.set('errors', []);
        var $el = (0, _jquery.default)(this.element);
        $el.find('.add-company-popover').toggle();
        var searchText = this.get('companyName');

        if (searchText) {
          this.send('searchCompanies', searchText);
        }
      },
      addCompany: function addCompany(partnershipTypeName) {
        var self = this;
        var token = this.get('sessionAccount').get('token');
        var email = this.get('email');
        var taxIdNumber = this.get('taxIdNumber');
        var companyName = this.get('companyName');
        var country = this.get('country');

        if (this.get('sendInvitation')) {
          if (!email) {
            var message = this.get('intl').t('transport.addNewCompany.emailRequired');
            this.set('errors', [message]);
            return;
          }

          if (!email.match(/\S+@\S+\.\S+/)) {
            var _message = this.get('intl').t('transport.addNewCompany.wrongEmail');

            this.set('errors', [_message]);
            return;
          }
        }

        var companyData = {
          token: token,
          name: companyName,
          country: country,
          taxIdNumber: taxIdNumber,
          partnershipType: partnershipTypeName,
          principalCompanyId: self.get('principalCompanyId')
        };

        _jquery.default.ajax({
          type: "POST",
          url: _environment.default.serverURL + '/companies',
          data: JSON.stringify(companyData),
          beforeSend: function beforeSend(xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
          },
          contentType: 'application/json; charset=utf-8'
        }).then(function (response) {
          if (response.onlyCompanyExist) {
            self.set('existingCompany', response.company);
            self.set('existingCompanyAddresses', response.addresses);
            self.set('onlyCompanyExist', true);
          } else if (response.companyAndPartnershipExist) {
            self.set('existingCompany', response.company);
            self.set('existingCompanyAddresses', response.addresses);
            self.set('companyAndPartnershipExist', true);
          } else {
            self.send('setCarrierOrSupplierCompany', response.company.id, partnershipTypeName);

            if (self.get('sendInvitation')) {
              self.send('inviteCompany', response.partnership);
            }
          }
        }, function (error) {
          self.set('errors', JSON.parse(error.jqXHR.responseText).errors);
        });
      },
      inviteCompany: function inviteCompany(partnership) {
        var partnershipType = this.get('store').peekRecord('partnershipType', partnership.partnershipType.id);
        var origin = window.location.origin;
        var typeId = partnershipType.get('id');
        var roleGroupId = partnershipType.get('availableRoleGroups').get('firstObject').get('id');
        var token = this.get('sessionAccount').get('token');
        var invitationData = {
          token: token,
          email: this.get('email'),
          roleGroup: roleGroupId,
          partnershipType: typeId,
          origin: origin,
          companyId: this.get('existingCompany.id'),
          partnershipId: partnership.id
        };

        _jquery.default.ajax({
          type: "POST",
          url: _environment.default.serverURL + '/invitations',
          data: JSON.stringify(invitationData),
          beforeSend: function beforeSend(xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
          },
          contentType: "application/json"
        });
      },
      searchCompanies: function searchCompanies(searchText) {
        var self = this;
        var token = this.get('sessionAccount').get('token');

        if (!searchText) {
          self.set('cooperatingCompanies', []);
        } else {
          _jquery.default.ajax({
            type: "GET",
            url: _environment.default.serverURL + '/companies',
            data: {
              query: searchText,
              principalCompanyId: self.get('principalCompanyId')
            },
            beforeSend: function beforeSend(xhr) {
              xhr.setRequestHeader('Authorization', 'Bearer ' + token);
            },
            contentType: "application/json"
          }).then(function (resp) {
            self.set('cooperatingCompanies', resp.companies);
          });
        }
      },
      cancelAssignCompany: function cancelAssignCompany() {
        this.set('existingCompany', {});
        this.set('companyAndPartnershipExist', false);
        this.set('onlyCompanyExist', false);
        var $el = (0, _jquery.default)(this.element);
        $el.find('.add-company-popover').hide();
      },
      assignCompanyAndCreatePartnership: function assignCompanyAndCreatePartnership(partnershipTypeName) {
        var self = this;
        var token = this.get('sessionAccount').get('token');
        var partnershipData = {
          token: token,
          partnershipTypeName: partnershipTypeName,
          principalCompanyId: self.get('principalCompanyId'),
          contractorCompany: self.get('existingCompany'),
          status: "NO_INVITATION"
        };

        _jquery.default.ajax({
          type: "POST",
          url: _environment.default.serverURL + '/partnerships',
          data: JSON.stringify(partnershipData),
          beforeSend: function beforeSend(xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
          },
          contentType: 'application/json; charset=utf-8'
        }).then(function (response) {
          self.send('setCarrierOrSupplierCompany', self.get('existingCompany.id'), partnershipTypeName);

          if (self.get('sendInvitation')) {
            self.send('inviteCompany', response.partnership);
          }
        }, function (error) {
          self.set('errors', JSON.parse(error.jqXHR.responseText).errors);
        });
      },
      setCarrierOrSupplierCompany: function setCarrierOrSupplierCompany(companyId, partnershipTypeName) {
        var self = this;
        self.get('store').findRecord('company', companyId).then(function (company) {
          if (partnershipTypeName === "CARRIER") {
            self.get("transport").set('carrierCompany', company);
          } else if (partnershipTypeName === "SUPPLIER") {
            self.get("step").set('company', company);
          }

          self.set('companyAndPartnershipExist', false);
          self.set('onlyCompanyExist', false);
          var $el = (0, _jquery.default)(self.element);
          $el.find('.add-company-popover').hide();
        });
      },
      toggleSendInvitation: function toggleSendInvitation() {
        this.set('sendInvitation', !this.get('sendInvitation'));
      }
    }
  });

  _exports.default = _default;
});