define("apollo/pods/controlled-date-type/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    intl: Ember.inject.service(),
    name: _emberData.default.attr('string'),
    daysBeforeFirstWarning: _emberData.default.attr('number'),
    daysBeforeSecondWarning: _emberData.default.attr('number'),
    daysBeforeSendEmail: _emberData.default.attr('number'),
    daysBeforeAdviceWarning: _emberData.default.attr('number'),
    updateDateIntervalWarning: _emberData.default.attr('string'),
    labelPl: _emberData.default.attr('string'),
    labelEn: _emberData.default.attr('string'),
    specificClientId: _emberData.default.attr('number'),
    label: Ember.computed('labelPl', 'labelEn', function () {
      if (this.get('intl.locale')[0] !== 'pl') {
        return this.get('labelEn');
      } else {
        return this.get('labelPl');
      }
    })
  });

  _exports.default = _default;
});