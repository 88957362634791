define("apollo/pods/schedulers/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8QhlyVYm",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"container-fluid\"],[8],[0,\"\\n    \"],[1,[28,\"schedule-window\",null,[[\"model\",\"prevWeek\",\"nextWeek\",\"startDate\",\"goToNewDate\",\"showModal\",\"nextWeek\",\"prevWeek\",\"goToNewDate\"],[[24,[\"model\"]],\"prevWeek\",\"nextWeek\",[24,[\"formattedDate\"]],\"goToNewDate\",[28,\"action\",[[23,0,[]],\"showModal\"],null],[28,\"action\",[[23,0,[]],\"nextWeek\"],null],[28,\"action\",[[23,0,[]],\"prevWeek\"],null],[28,\"action\",[[23,0,[]],\"goToNewDate\"],null]]]],false],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/schedulers/index/template.hbs"
    }
  });

  _exports.default = _default;
});