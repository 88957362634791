define("apollo/pods/components/companies/company-form/component", ["exports", "apollo/mixins/input-utils-mixin"], function (_exports, _inputUtilsMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_inputUtilsMixin.default, {
    sessionAccount: Ember.inject.service('session-account'),
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    canNotEditCompanyRuleOrCompanyExists: Ember.computed(function () {
      return this.get('sessionAccount.currentUser.canNotEditCompany') || this.get('company.id');
    }),
    paymentOptions: Ember.computed(function () {
      return this.get('sessionAccount.paymentOptions');
    }),
    dateFields: Ember.computed('', function () {
      return this.get('sessionAccount').get('resourceDateFormSettings').CARRIER;
    }),
    resourceDates: Ember.computed('dateFields', 'company.{id,resourceDates}', function () {
      var _this = this;

      console.log("prepareResourceDates...");
      var company = this.get('company');
      var companyResourceDates = company.get('resourceDates');
      var targetResourceDates = Ember.A();
      this.get('dateFields').forEach(function (df) {
        var resourceDate = companyResourceDates.find(function (rd) {
          return rd.get('controlledDateType.name') === df;
        });

        if (!resourceDate) {
          var type = _this.get('store').peekAll('controlledDateType').find(function (rdt) {
            return rdt.get('name') === df;
          });

          resourceDate = _this.get('store').createRecord('resourceDate', {
            controlledDateType: type,
            company: company
          });
        }

        targetResourceDates.pushObject(resourceDate);
      });
      return targetResourceDates;
    }),
    actions: {
      enableIntegrated: function enableIntegrated() {
        this.get('company.partnershipWithPrincipal').setProperties({
          integrated: true,
          onlyAsCustomerOfService: null
        });
      },
      toggleProperty: function toggleProperty(objectPath, propertyName) {
        this.get(objectPath).toggleProperty(propertyName);
      },
      showAddCompanyEmailDialog: function showAddCompanyEmailDialog() {
        var companyEmail = this.get('store').createRecord('companyEmail', {
          company: this.get('company')
        });
        this.showModal('companies.modals.add-company-email', companyEmail);
      },
      deleteCompanyEmail: function deleteCompanyEmail(ce) {
        var msg = this.get('intl').t("company.sureToDeleteEmail");

        if (confirm(msg)) {
          ce.destroyRecord();
        }
      }
    }
  });

  _exports.default = _default;
});