define("apollo/pods/components/schedule-window/component", ["exports", "jquery", "apollo/mixins/persistence-mixin", "ember-resize/mixins/resize-aware", "apollo/config/environment"], function (_exports, _jquery, _persistenceMixin, _resizeAware, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_persistenceMixin.default, _resizeAware.default, {
    cookies: Ember.inject.service(),
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    servicePartnerships: Ember.computed('model.partnerships.[]', function () {
      return this.get('model.partnerships').filterBy('partnershipType.name', 'SERVICE');
    }),
    didInsertElement: function didInsertElement() {
      var self = this;
      var fullCalendar = (0, _jquery.default)('.full-calendar');
      fullCalendar.fullCalendar('option', 'resourceLabelText', this.get('intl').t('tabs.resources.label'));
      fullCalendar.fullCalendar('gotoDate', this.get('startDate'));
      this.set('selectedStartDate', this.get('startDate'));
      (0, _jquery.default)('.fc-body .fc-time-area .fc-scroller-clip .fc-scroller').on('scroll', function () {
        self.set('topOffset', (0, _jquery.default)(".fc-body .fc-time-area .fc-scroller-clip .fc-scroller").scrollTop());
      });

      this._super();
    },
    //PCA-707 Nasłuchiwacz, sprawdzający czy zwijano/rozwijano zasoby
    isAtLeastOneResourceRowCollapsed: Ember.computed(function () {
      return this.get('resourcesInitiallyExpanded');
    }),
    transportTypes: Ember.computed('model.transportTypesIds.@each', function () {
      var transportTypesIds = this.get('model.transportTypesIds');
      return this.get('store').peekAll('transportType').filter(function (tt) {
        return transportTypesIds.includes(tt.get('id'));
      });
    }),
    didRender: function didRender() {
      var self = this;
      (0, _jquery.default)('.fc-expander-space.fc-expander > .fc-icon').click(function () {
        self.set('isAtLeastOneResourceRowCollapsed', !self.get('isAtLeastOneResourceRowCollapsed'));
      });
      (0, _jquery.default)(".fc-body .fc-time-area .fc-scroller-clip .fc-scroller").scrollTop(0); // Uwaga: Ten log służy też załadowaniu do sesji typów transportu - lepiej go nie usuwać.

      console.log("\u0141adujemy harmonogram dla typ\xF3w ".concat(this.get('transportTypes').getEach('name').join(', '), ".."));
    },
    didUpdate: function didUpdate() {
      if (this.get('model.reloadResources')) {
        var fullCalendar = (0, _jquery.default)('.full-calendar');
        fullCalendar.fullCalendar('getResources').forEach(function (r) {
          fullCalendar.fullCalendar('removeResource', r);
        });
        this.get('resources').forEach(function (r) {
          fullCalendar.fullCalendar('addResource', r, true);
        });
        fullCalendar.fullCalendar("removeEventSources", fullCalendar.fullCalendar("getEventSources"));
        fullCalendar.fullCalendar('addEventSource', this.get('events'));
      }

      (0, _jquery.default)('.fc-body .fc-time-area .fc-scroller-clip .fc-scroller').scrollTop(this.get('topOffset'));
      this.send('collapseSelectedResources');
      this.set('model.reloadResources', false);
    },
    init: function init() {
      this._super();

      this.set('timer', this.schedule(this.get('onPoll')));
      console.log("Polling started with frequency of ".concat(this.get('interval'), " ms."));
    },
    willDestroyElement: function willDestroyElement() {
      this._super();

      Ember.run.cancel(this.get('timer'));
    },
    schedule: function schedule(f) {
      return Ember.run.later(this, function () {
        f.apply(this);
        this.set('timer', this.schedule(f));
      }, this.get('interval'));
    },
    onPoll: function onPoll() {
      try {
        var self = this;
        var lastPollFormatted = moment(this.get('lastPoll')).format();
        var queryParams = {
          transportTypesIds: this.get('model.transportTypesIds'),
          lastPoll: lastPollFormatted
        };
        var token = this.get('session.data.authenticated.token');

        _jquery.default.ajax({
          url: _environment.default.serverURL + '/transports/updates',
          data: queryParams,
          type: 'GET',
          beforeSend: function beforeSend(xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
          }
        }).then(function (json) {
          if (self.isDestroyed) {
            return;
          }

          json.transportIds.forEach(function (transportId) {
            self.get('store').findRecord('transport', transportId, {
              reload: true
            }).then(function (transport) {
              if (self.get('model.transports').includes(transport)) {
                transport.notifyPropertyChange('lastUpdated');
                console.debug("Transport ".concat(transport.get('id'), " reloaded."));
              } else {
                self.refreshCurrentRoute();
                console.debug("Transport ".concat(transport.get('id'), " added to scheduler's model."));
              }
            });
          });
          var pollTime = json.pollTime;
          self.set('lastPoll', pollTime);
        });
      } catch (e) {
        console.error(e);
      }
    },
    interval: Ember.computed('', function () {
      var s = this.get('store').peekAll('setting').filterBy('name', 'SCHEDULER_POLLING_FREQUENCY');
      return s.get('firstObject').get('value') || 15000;
    }).readOnly(),
    pollRequested: Ember.observer('transportTypes.@each.forcedPollRequestTime', function () {
      var self = this;
      Ember.run.once(this, function () {
        var tt = self.get('transportTypes').findBy('forcedPollRequestTime');

        if (tt) {
          console.debug("Od\u015Bwie\u017Camy harmonogram na pro\u015Bb\u0119 dla typu ".concat(tt.get('name'), ".."));
          self.onPoll();
          self.get('transportTypes').forEach(function (tt) {
            return tt.set('forcedPollRequestTime', null);
          });
        }
      });
    }),
    viewName: Ember.computed('model.delta', function () {
      switch (this.get('model.delta')) {
        case 'month':
          return 'agendaMonth';

        case '14days':
          return 'agendaFourteenDay';

        case 'week':
          return 'timelineWeek';
      }
    }),
    views: Ember.computed(function () {
      return {
        agendaFourteenDay: {
          type: 'timeline',
          duration: {
            days: 14
          }
        },
        agendaMonth: {
          type: 'timeline',
          duration: {
            days: 31
          }
        }
      };
    }),
    startDateChanged: Ember.observer('startDate', function () {
      (0, _jquery.default)('.full-calendar').fullCalendar('gotoDate', this.get('startDate'));
      this.set('selectedStartDate', this.get('startDate'));
    }),
    cellsWithWarningFlag: Ember.computed(function () {
      return Ember.A();
    }),
    slotDurationChangeTime: Ember.computed(function () {}),
    scheduleHeight: Ember.computed(function () {
      return this.calculateScheduleHeight();
    }),
    principalCompanyId: Ember.computed(function () {
      return this.get('cookies').read('principalId');
    }),
    principalCompany: Ember.computed('principalCompanyId', function () {
      return this.get('store').peekRecord('company', this.get('principalCompanyId'));
    }),
    didResize: function didResize(width, height, evt) {
      (0, _jquery.default)('.full-calendar').fullCalendar('option', 'height', this.calculateScheduleHeight());
    },
    addDocumentsWarnings: Ember.observer('isAtLeastOneResourceRowCollapsed', 'selectedStartDate', 'cellsWithWarningFlag', 'slotDurationChangeTime', function () {
      var uniqueObjects = this.get('cellsWithWarningFlag').uniqBy('obj');
      var cellsWithWarningFlag = this.get('cellsWithWarningFlag');
      var objectsWithMessages = Ember.A();
      uniqueObjects.forEach(function (obj) {
        var messages = Ember.A();
        var filteredCells = cellsWithWarningFlag.filterBy('obj', obj.obj);
        filteredCells.forEach(function (filteredCell) {
          messages.pushObject(filteredCell.message);
        });
        objectsWithMessages.pushObject({
          obj: obj.obj,
          messages: messages
        });
      });
      objectsWithMessages.forEach(function (obj) {
        var messagesDOMLi = '';
        obj.messages.forEach(function (message) {
          messagesDOMLi += '<li class=' + message.domClass + '>' + message.text + '</li>';
        }); //PCA-707 Sprawdzenie, czy DOM jest gotowy jest konieczne, ponieważ przy zwinięciu usuwana jest z DOMU lista, do której przypisywane są notyfikacje

        (0, _jquery.default)(document).ready(function () {
          var objectId = obj.obj.replace('data-resource-id=', '');
          var $parentDiv = (0, _jquery.default)('[' + obj.obj + '] .fc-cell-text');
          $parentDiv.find('.document-date-expiration-element').remove();
          $parentDiv.append('<span class="document-date-expiration-warning-flag document-date-expiration-element">!</span>');
          $parentDiv.append('<div class="document-date-expiration-warning-messages document-date-expiration-element ' + objectId + '"></div>');
          var messagesDOM = '<ul>' + messagesDOMLi + '</ul>';
          (0, _jquery.default)('.document-date-expiration-warning-messages.' + objectId).empty().append(messagesDOM);
          (0, _jquery.default)('.document-date-expiration-warning-flag').mouseover(function (event) {
            (0, _jquery.default)('.document-date-expiration-warning-messages.' + objectId).css('top', event.clientY - 15);
          });
        });
      });
    }),
    resources: Ember.computed('model.transportTypeParam', 'principalCompany.userGroups.@each.name', 'principalCompany.userGroups.@each.trucks', function () {
      var transportType = this.get('model.transportTypeParam').toUpperCase();

      switch (transportType) {
        case 'TRANSPORT':
          return this.getTransportResource();

        case 'SERVICE':
          return this.getServiceResource();

        default:
          return;
      }
    }),
    events: Ember.computed('model.transportTypeParam', 'model.transports.@each.lastUpdated', 'model.transports.@each.isCompleted', 'model.transports.@each.deleted', 'model.transports.@each.firstIbanSymbol', 'model.transports.@each.lastIbanSymbol', 'model.servicePartnerships.@each.contractorCompany', function () {
      var transportType = this.get('model.transportTypeParam').toUpperCase();
      var transports = this.get('model.transports');
      var weekStartDate = moment(this.get('startDate')).toDate();
      var events = Ember.A();

      switch (transportType) {
        case 'TRANSPORT':
          return this.getTransportEvents(transports, weekStartDate, events);

        case 'SERVICE':
          return this.getServiceEvents(transports, weekStartDate, events);

        default:
          return;
      }
    }),
    showFilters: Ember.computed('model.transportTypeParam', function () {
      return this.get('model.transportTypeParam').toUpperCase() === 'TRANSPORT';
    }),
    filters: Ember.computed('clients.length', function () {
      var filters = Ember.A();
      var importExportFilter = {
        type: 'select',
        key: 'importExport',
        value: '',
        options: ['import', 'export'],
        placeholder: 'import/export'
      };
      var productFilter = {
        type: 'text',
        key: 'product',
        value: '',
        opacityFilter: true,
        placeholder: this.get('intl').t('columns.product')
      };
      var clientFilter = {
        type: 'select',
        key: 'client',
        value: '',
        options: this.get('clients'),
        opacityFilter: true,
        placeholder: this.get('intl').t('transport.clientCompany')
      };
      filters.pushObject(importExportFilter);
      filters.pushObject(productFilter);
      filters.pushObject(clientFilter);
      return filters;
    }),
    clients: Ember.computed('', function () {
      var self = this;
      return self.get('store').query('company', {
        principalCompanyId: self.get('cookies').read('principalId'),
        companyType: 'CLIENT'
      }).then(function (items) {
        return items.map(function (item) {
          return item.get('shortName') || item.get('name');
        }).uniq().sort();
      });
    }),
    actions: {
      refreshCurrentRoute: function refreshCurrentRoute() {
        this.refreshCurrentRoute();
      },
      changeScale: function changeScale(scale) {
        var fullCalendar = (0, _jquery.default)('.full-calendar');

        if (scale === 'one') {
          fullCalendar.fullCalendar('option', 'slotDuration', "01:00:00"); //umożliwia wywołanie zmiany dla obserwera, generującego ostrzeżenia w dokumentach
          // - rozwiązuje problem braku generowania ostrzeżeń przy podwójnym kliknięciu na zmianę gęstości siatki
          // harmonogramu

          this.set('slotDurationChangeTime', moment());
        } else if (scale === 'two') {
          fullCalendar.fullCalendar('option', 'slotDuration', "02:00:00");
          this.set('slotDurationChangeTime', moment());
        } else if (scale === 'three') {
          fullCalendar.fullCalendar('option', 'slotDuration', "03:00:00");
          this.set('slotDurationChangeTime', moment());
        } else if (scale === 'six') {
          fullCalendar.fullCalendar('option', 'slotDuration', "06:00:00");
          this.set('slotDurationChangeTime', moment());
        } else if (scale === 'week') {
          fullCalendar.fullCalendar('option', 'slotDuration', "03:00:00");
          this.set('slotDurationChangeTime', moment());
        } else if (scale === 'next') {
          this.nextWeek();
        } else if (scale === 'prev') {
          this.prevWeek();
        }

        this.send('collapseSelectedResources');
      },
      goToNewDate: function goToNewDate(selectedDate) {
        this.goToNewDate(selectedDate);
      },
      eventDropped: function eventDropped(event, delta, revertFunc, jsEvent, ui, view) {
        var transport = this.get('model.transports').filterBy('id', event.transportId).get('firstObject'); //jak data końcowa będzie równa dacie początkowej, to fullscheduler traktuje event.end jako null, zatem
        //warto ustawić ją ręcznie, aby nie pozostawało puste pole w formularzu

        if (!event.end) {
          event.end = event.start;
        } // Transportów nie można przesuwać w poziomie


        if (transport.get('transportType.name') !== 'SERVICE') {
          event.start = transport.get('eventDates.start');
          event.end = transport.get('eventDates.end');
        } else {
          if (event.resourceId == this.get('principalCompany.id')) {
            revertFunc();
            return;
          }

          var dateFormat = "DD.MM.Y HH:mm";
          var dateFrom = moment(moment.utc(event.start).format(dateFormat), dateFormat).toDate();
          transport.set('steps.firstObject.deliveryDateFrom', dateFrom);
          var dateTo = moment(moment.utc(event.end).format(dateFormat), dateFormat).toDate();
          transport.set('steps.firstObject.deliveryDateTo', dateTo);
        }

        var hash = view.resourceRowHash[event.resourceId];
        var onDrop = hash.options ? hash.options.onDrop : hash.resource.onDrop;
        onDrop(this, transport, revertFunc);
      },
      eventClicked: function eventClicked(calEvent, jsEvent, view) {
        if (calEvent.leave) {
          return;
        }

        if (calEvent.service && this.get('model.transportTypeParam').toUpperCase() !== 'TRANSPORT') {
          var transport = this.get('model.transports').filterBy('id', calEvent.transportId);
          this.showModal('services.modals.create-service', transport.get('firstObject'));
        } else if (!calEvent.service) {
          var _transport = this.get('model.transports').filterBy('id', calEvent.transportId);

          this.showModal('transports.modals.create-transport', _transport.get('firstObject'));
        }
      },
      eventAfterRender: function eventAfterRender(event, element) {
        (0, _jquery.default)(element).tooltip({
          container: 'body',
          placement: 'top',
          title: event.title
        });
        (0, _jquery.default)(element).on('show.bs.tooltip', function () {
          // Only one tooltip should ever be open at a time
          (0, _jquery.default)('.tooltip').not(this).hide();
        });
      },
      eventDestroy: function eventDestroy(event, element) {
        (0, _jquery.default)(element).tooltip('hide');
      },
      resourceRender: function resourceRender(resourceObj, labelTds, bodyTds) {
        if (resourceObj.isMainInGroup) {
          labelTds.css('font-weight', '600');
          bodyTds.css('background-color', '#f8f8f8');
          bodyTds.css('opacity', '0.75');
        } else if (this.get('model.transportTypeParam').toUpperCase() === 'SERVICE' && resourceObj.id === this.get('principalCompany.id')) {
          labelTds.css('font-weight', '600');
          bodyTds.css('background-color', '#f6f6f6');
          bodyTds.css('opacity', '0.87');
        }
      },
      executePolling: function executePolling() {
        this.onPoll();
      },
      collapseSelectedResources: function collapseSelectedResources() {
        (0, _jquery.default)('.full-calendar').fullCalendar("getResources").filter(function (r) {
          return r.shouldBeCollapsed;
        }).forEach(function (r) {
          var rowToCollapse = (0, _jquery.default)('.full-calendar').find("*[data-resource-id=\"".concat(r.id, "\"]"));
          rowToCollapse.find(".fc-cell-text").siblings('.fc-expander').click();
        });
      },
      filterWindows: function filterWindows() {
        this.notifyPropertyChange('events');
      },
      clearFilters: function clearFilters() {
        this.get('filters').map(function (f) {
          return Ember.set(f, 'value', '');
        });
        this.notifyPropertyChange('events');
      }
    },
    createAdvice: function createAdvice(transport, truck, revertFunc) {
      console.debug("Create advice..");
      transport.set('personsGroup', truck.get('group'));
      transport.setAdvice(truck);
      var model = {
        transport: transport,
        revertFunc: revertFunc
      };
      this.showModal('action', 'advice.modals.create-advice', model);
    },
    chooseCarrier: function chooseCarrier(transport, company, group, revertFunc) {
      console.debug("Choose carrier..");

      if (group) {
        transport.set('personsGroup', group);
      }

      transport.set('carrierCompany', company);
      transport.set('revertFunc', revertFunc);
      this.showModal('carrier.modals.choose-carrier', transport);
    },
    chooseService: function chooseService(transport, company, mechanic, revertFunc) {
      console.debug("Choose service..");
      transport.set('steps.firstObject.company', company);

      if (mechanic) {
        transport.set('steps.firstObject.service.mechanic', mechanic);
      }

      transport.set('revertFunc', revertFunc);
      this.showModal('services.modals.create-service', transport);
    },
    revokeMechanic: function revokeMechanic(transport) {
      //not assigned mechanic = null
      transport.set('steps.firstObject.service.mechanic', null);
    },
    revokeServiceCompany: function revokeServiceCompany(transport) {
      //not assigned mechanic = null, company = null
      transport.set('steps.firstObject.company', null);
    },
    calculateScheduleHeight: function calculateScheduleHeight() {
      return window.innerHeight - (0, _jquery.default)('nav.navbar-fixed-top').outerHeight(true) - (0, _jquery.default)('#schedule-nav').outerHeight(true);
    },
    prepareConveyanceWarnings: function prepareConveyanceWarnings(truck) {
      var _this = this;

      var resourceDateFormSettings = this.get('sessionAccount').get('resourceDateFormSettings');
      this.prepareVehicleWarnings(truck, truck, resourceDateFormSettings.TRUCK);

      if (!truck.get('trailer.id')) {
        return;
      }

      truck.get('trailer').then(function (trailer) {
        return _this.prepareVehicleWarnings(trailer, truck, resourceDateFormSettings[trailer.get('trailerType')]);
      });
    },
    prepareVehicleWarnings: function prepareVehicleWarnings(vehicle, vehicleInSchedule, controlledDateTypes) {
      var _this2 = this;

      if (!vehicle.get('resourceDates') || !controlledDateTypes) {
        return;
      }

      var cellsWithWarningFlag = this.get('cellsWithWarningFlag');
      var vehicleWarnings = vehicle.get('SCHEDULE_WARNINGS');
      controlledDateTypes.filter(function (resourceDate) {
        return vehicleWarnings.includes(resourceDate);
      }).forEach(function (resourceDate) {
        var warning = _this2.findWarning(vehicle, vehicleInSchedule, resourceDate);

        if (warning) {
          cellsWithWarningFlag.pushObject(warning);
        }
      });
    },
    findWarning: function findWarning(vehicle, vehicleInSchedule, controlledDateType) {
      var resourceDate = vehicle.findResourceDate(controlledDateType);

      if (resourceDate && resourceDate.get('date')) {
        var documentDate = resourceDate.get('date');
        var diff = moment(documentDate).diff(moment(), 'days');

        if (diff < 0) {
          return this.getWarningObject(vehicle, vehicleInSchedule, controlledDateType, 'expired', 'warning');
        } else if (diff >= 0 && diff <= resourceDate.get('secondWarning')) {
          return this.getWarningObject(vehicle, vehicleInSchedule, controlledDateType, 'warning', 'caution');
        }
      } else {
        return this.getWarningObject(vehicle, vehicleInSchedule, controlledDateType, 'notExists', 'warning');
      }
    },
    getWarningObject: function getWarningObject(vehicle, vehicleInSchedule, controlledDateType, warningType, domClass) {
      var vehicleName = this.get('intl').t("resources.of.".concat(vehicle.get('vehicleType')));
      var documentName = this.get('store').peekAll('controlledDateType').find(function (rdt) {
        return rdt.get('name') === controlledDateType;
      }).get('label');
      return {
        obj: 'data-resource-id=' + vehicleInSchedule.asResource().id,
        message: {
          text: this.get('intl').t("documentsExpirationDate.".concat(warningType), {
            resource: vehicleName,
            document: documentName
          }),
          domClass: domClass
        }
      };
    },
    getTransportResource: function getTransportResource() {
      var _this3 = this;

      var resources = Ember.A();
      var principalGroups = this.get('principalCompany.userGroups'); //każdy z resources musi zawierać id, title i onDrop
      //onDrop musi przyjmować:
      //          context - do wywołowania akcji z tego componentu/controlera
      //          transport - to obiekt transportu uposzczonego na ten resource
      //          revertFunc - należy wywołać aby transport wrócił na poprzednie miejsce

      resources.pushObject({
        id: 'NOTASSIGNED',
        title: this.get('intl').t('schedule.NOTASSIGNED'),
        onDrop: function onDrop(context, transport) {
          transport.cancelAdvice();
          transport.cancelCarrier();
          transport.cancelGroup();
          transport.save().then(function () {
            context.unloadDeletedRecords('transport', transport.id, 'checkpoint');
          });
        }
      });
      principalGroups.forEach(function (principalGroup) {
        var companies = [];
        principalGroup.get('companiesWithTrucksInGroup').forEach(function (company) {
          if (!company.get('id')) {
            return;
          }

          company = _this3.get('store').peekRecord('company', company.get('id'));
          var trucks = [];
          principalGroup.get('sortedTrucks').filterBy('owner.id', company.get('id')).forEach(function (truck) {
            trucks.push(truck.asResource());

            _this3.prepareConveyanceWarnings(truck);
          });

          if (company.get('isPrincipal')) {
            companies.unshift(company.asResource(trucks, principalGroup));
          } else {
            companies.push(company.asResource(trucks, principalGroup));
          }
        });
        var principalCompany = companies.shift();
        var sortedCompanies = companies.sortBy('title');
        sortedCompanies.unshift(principalCompany);
        sortedCompanies.unshift({
          id: 'NOTASSIGNED-' + principalGroup.get('id'),
          title: _this3.get('intl').t('schedule.NOTASSIGNED'),
          onDrop: function onDrop(context, transport) {
            transport.cancelAdvice();
            transport.cancelCarrier();
            transport.cancelGroup();
            transport.set('personsGroup', principalGroup);
            transport.save().then(function () {
              context.unloadDeletedRecords('transport', transport.id, 'checkpoint');
            });
          }
        });

        if (sortedCompanies[0] !== undefined) {
          resources.push(principalGroup.asResource(sortedCompanies));
        }
      });
      var externalCompanies = [];
      this.get('model.companies').filterBy('enabled', true).filter(function (company) {
        if (!company.get('partnershipWithPrincipal.integrated') && !company.get('partnershipWithPrincipal.partnershipType.isService') && !company.get('partnershipWithPrincipal.partnershipType.isWash') && !company.get('partnershipWithPrincipal.partnershipType.isClient')) {
          externalCompanies.push(company.asResource());
        }
      });
      var external = {
        id: 'EXTERNAL',
        title: this.get('intl').t('schedule.EXTERNAL'),
        onDrop: function onDrop(context, transport, revertFunc) {
          transport.cancelAdvice();
          context.chooseCarrier(transport, null, null, revertFunc);
        },
        children: externalCompanies.sortBy('title')
      };
      resources.pushObject(external);
      return resources;
    },
    getServiceResource: function getServiceResource() {
      var _this4 = this;

      var resources = Ember.A();
      var principalServices = Ember.A();
      var externalServices = Ember.A();
      var principalCompany = this.get('principalCompany');
      var notPlanned = {
        id: 'NOTPLANNED',
        title: this.get('intl').t('schedule.NOTPLANNED'),
        onDrop: function onDrop(context, transport) {
          context.revokeServiceCompany(transport);
          transport.save();
        }
      };
      var services = this.get('servicePartnerships').getEach('contractorCompany');
      services.forEach(function (servicePromise) {
        var service = _this4.get('store').peekRecord('company', servicePromise.get('id'));

        var principalServiceMen = [];
        var externalServiceMen = [];
        service.get('users').forEach(function (user) {
          if (!service.get('isExternal')) {
            principalServiceMen.push(user.asResource([], service));
          } else {
            externalServiceMen.push(user.asResource([], service));
          }
        });

        if (service.get('isExternal')) {
          var planned = {
            id: 'PLANNED-' + service.get('id'),
            title: _this4.get('intl').t('schedule.PLANNED'),
            onDrop: function onDrop(context, transport, revertFunc) {
              context.revokeMechanic(transport);
              context.chooseService(transport, service, null, revertFunc);
            }
          };
          externalServiceMen.insertAt(0, planned);
          externalServices.pushObject(service.asResource(externalServiceMen));
        } else {
          var notAssigned = {
            id: 'NOTASSIGNED-' + service.get('id'),
            title: _this4.get('intl').t('schedule.NOTASSIGNED'),
            onDrop: function onDrop(context, transport, revertFunc) {
              context.revokeMechanic(transport);
              context.chooseService(transport, service, null, revertFunc);
              transport.save();
            }
          };
          var serviceWithServiceMen = service.asResource(principalServiceMen);
          serviceWithServiceMen.children.insertAt(0, notAssigned);

          if (serviceWithServiceMen) {
            principalServices.pushObject(serviceWithServiceMen);
          }
        }
      });
      resources.pushObject(principalCompany.asResource(principalServices));
      externalServices.forEach(function (ex) {
        resources.pushObject(ex);
      });
      resources.insertAt(0, notPlanned);
      return resources;
    },
    getTransportEvents: function getTransportEvents(transports, weekStartDate, events) {
      var self = this;
      transports.forEach(function (t) {
        t.notifyPropertyChange('resourceId');
        t.set('transportTypeParam', 'TRANSPORT');
        var event = t.get('asScheduleEvent'); //PCA-632 possibility to display events starting before first day of week of schedule

        if (event.start < weekStartDate && weekStartDate < event.end) {
          event.start = weekStartDate;
        }

        if (!t.get('deleted')) {
          if (t.get('transportType.name') === 'SERVICE') {
            event.service = true;
            event.editable = false;
          }

          if (t.get('transportType.name') === 'LEAVE') {
            event.leave = true;
            event.editable = false;
          }

          if (self.eventShouldBeVisibleInTransportSchedule(t)) {
            //filtrujemy tylko zlecenia znajdujących się w grupie nieprzydzielone
            event.availableForFilter = t.get('resourceId').match('NOTASSIGNED');

            if (event.availableForFilter && !self.matchesFilter(event, t)) {
              event.matchesFilter = false;
              event.className = "opacity-40";
            } else {
              event.matchesFilter = true;
            }

            events.pushObject(event);
          }
        }
      });
      return events;
    },
    eventShouldBeVisibleInTransportSchedule: function eventShouldBeVisibleInTransportSchedule(transport) {
      if (!transport.get('visibleInTransportSchedule')) {
        return false;
      } //filtrujemy tylko zlecenia znajdujące się w grupie nieprzydzielone


      if (!transport.get('resourceId').match('NOTASSIGNED')) {
        return true;
      }

      var importExportFilterValue = this.get('filters').find(function (f) {
        return f.key === 'importExport';
      }).value;

      if (importExportFilterValue === '') {
        return transport.get('visibleInTransportSchedule');
      }

      if (importExportFilterValue === 'import' && transport.get('isImport')) {
        return true;
      }

      return importExportFilterValue === 'export' && transport.get('isExport');
    },
    matchesFilter: function matchesFilter(event, transport) {
      var productFilterValue = this.get('filters').find(function (f) {
        return f.key === 'product';
      }).value;
      var clientFilterValue = this.get('filters').find(function (f) {
        return f.key === 'client';
      }).value;
      var productRegex = new RegExp(productFilterValue, "i");
      var clientRegex = new RegExp(clientFilterValue, "i");
      var products = transport.get('products');
      var clientShortName = transport.get('clientCompany.shortName');
      var productFilterIsEmpty = productFilterValue === '';
      var transportNotContainProducts = products.get('length') === 0;
      return (transportNotContainProducts && productFilterIsEmpty || products.find(function (p) {
        return p.get('symbol').match(productRegex);
      })) && clientShortName.match(clientRegex);
    },
    eventsThatMatchFilter: Ember.computed('events.@each.matchesFilter', function () {
      return this.get('events').filter(function (e) {
        return e.availableForFilter && e.matchesFilter;
      });
    }),
    filtersAreEmpty: Ember.computed('filters.@each.value', function () {
      return this.get('filters').filter(function (f) {
        return f.opacityFilter;
      }).getEach('value').every(function (f) {
        return !f;
      });
    }),
    showNoResultsInfo: Ember.computed('eventsThatMatchFilter.length', 'filtersAreEmpty', function () {
      return !this.get('filtersAreEmpty') && !this.get('eventsThatMatchFilter.length');
    }),
    getServiceEvents: function getServiceEvents(transports, weekStartDate, events) {
      transports.forEach(function (t) {
        t.notifyPropertyChange('resourceId');
        t.set('transportTypeParam', 'SERVICE');
        var event = t.get('asScheduleEvent'); //PCA-632 possibility to display events starting before first day of week of schedule

        if (event.start < weekStartDate && weekStartDate < event.end) {
          event.start = weekStartDate;
        }

        if (!t.get('deleted')) {
          event.service = true;
          events.pushObject(event);
        }
      });
      return events;
    }
  });

  _exports.default = _default;
});