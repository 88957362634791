define("apollo/pods/drivers/edit/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    sessionAccount: Ember.inject.service('session-account'),
    access: ['ROLE_CREATE_DRIVER'],
    beforeModel: function beforeModel(transition) {
      if (!this.get('sessionAccount').hasAnyOfTheRoles(this.get('access'))) {
        transition.abort();
        this.transitionTo('forbidden');
      }
    },
    model: function model(params) {
      return this.store.findRecord('driver', params.id, {
        reload: true
      });
    },
    renderTemplate: function renderTemplate() {
      this.render('drivers.edit', {
        controller: 'drivers.create'
      });
    },
    setupController: function setupController(controller, model) {
      this.controllerFor('drivers.create').setProperties({
        model: model,
        isEditing: true
      });
    },
    actions: {
      willTransition: function willTransition() {
        this.controllerFor('drivers.create').setProperties({
          errors: [],
          attachments: [],
          successMessage: null,
          showOrderHistory: false,
          showResourcesHistory: false,
          historyLogs: []
        });
      }
    }
  });

  _exports.default = _default;
});