define("apollo/pods/workflows/edit/controller", ["exports", "apollo/mixins/input-utils-mixin"], function (_exports, _inputUtilsMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_inputUtilsMixin.default, {
    settingsIndexController: Ember.inject.controller('settings/index'),
    intl: Ember.inject.service(),
    expectedDateModes: ['reference', 'exact'],
    dateSigns: ['before', 'after'],
    dateUnits: ['h', 'm', 's'],
    taskGroupIndexes: [0, 1, 2],
    stepTypes: ['load', 'unload'],
    tasks: Ember.computed('model.tasks', function () {
      return this.get('model.tasks');
    }),
    proxiedTaskGroups: [],
    errors: [],
    scheduleSetProxiedTaskGroups: Ember.observer('model', 'tasks.@each.idxInGroup', function () {
      Ember.run.once(this, 'setProxiedTaskGroups');
    }),
    setProxiedTaskGroups: function setProxiedTaskGroups() {
      var _this = this;

      this.get('tasks').then(function (loadedTasks) {
        var proxiedTaskGroups = _this.get('taskGroupIndexes').map(function (groupIdx) {
          var filteredTasks = loadedTasks.filter(function (task) {
            return task.get('taskGroupIdentifier') === groupIdx;
          });
          var references = groupIdx === 1 ? ['stepStart', 'stepStop'] : ['transportCreated', 'earliestStepStart', 'latestStepStop'];
          return Ember.ObjectProxy.create({
            content: groupIdx,
            tasks: filteredTasks.sortBy('idxInGroup'),
            references: references
          });
        });

        _this.set('proxiedTaskGroups', proxiedTaskGroups);
      });
    },
    actions: {
      toggleTracked: function toggleTracked(task) {
        var tracked = task.get('tracked');
        task.set('tracked', !tracked);
      },
      toggleMarkIfWasDelayed: function toggleMarkIfWasDelayed(task) {
        var markIfWasDelayed = task.get('markIfWasDelayed');
        task.set('markIfWasDelayed', !markIfWasDelayed);
      },
      addTask: function addTask(identifier, idx) {
        var workflow = this.get('model');
        this.get('store').createRecord('task', {
          workflow: workflow,
          taskGroupIdentifier: identifier,
          idxInGroup: idx,
          expectedDateInterval: '30m'
        });
      },
      dragStart: function dragStart(object) {
        console.log('Drag Start', object);
      },
      sortEndAction: function sortEndAction() {
        this.get('proxiedTaskGroups').forEach(function (tg) {
          tg.get('tasks').forEach(function (t, idx) {
            t.set('idxInGroup', idx);
          });
        });
      },
      update: function update() {
        var self = this;

        if (!self.get('model').validate()) {
          return;
        }

        self.get('model').save().then(function (w) {
          console.log("Workflow " + w.id + " saved.");
          self.setProperties({
            success: true,
            errors: []
          });
          var msg = self.get('intl').t('workflows.saveSuccessMessage');
          self.get('settingsIndexController').set('successMessage', msg);
          self.transitionToRoute('settings.index');
        }).catch(function (response) {
          self.set('errors', response.errors);
        });
      },
      cancel: function cancel() {
        this.transitionToRoute('settings.index');
      }
    }
  });

  _exports.default = _default;
});