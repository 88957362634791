define("apollo/pods/components/companies/company-files/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    sessionAccount: Ember.inject.service('session-account'),
    actions: {
      addFiles: function addFiles(files) {
        var model = this.get('model');

        if (!model.get('attachmentsTmp')) {
          model.set('attachmentsTmp', []);
        }

        model.get('attachmentsTmp').pushObjects(files);
      },
      removeTemporaryFile: function removeTemporaryFile(file) {
        var model = this.get('model');
        model.get('attachmentsTmp').removeObject(file);
      },
      removeFile: function removeFile(file) {
        file.set('active', false);
      }
    }
  });

  _exports.default = _default;
});