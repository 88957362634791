define("apollo/helpers/find-transport-id", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.findTransportId = findTransportId;
  _exports.default = void 0;

  function findTransportId(params) {
    var transport = params[0];
    return transport.values().next().value.get('id');
  }

  var _default = Ember.Helper.helper(findTransportId);

  _exports.default = _default;
});