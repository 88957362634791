define("apollo/router", ["exports", "apollo/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Router = Ember.Router.extend({
    location: _environment.default.locationType
  });
  Router.map(function () {
    this.route("users", function () {
      this.route("show", {
        path: "/:user_id"
      });
      this.route("create");
      this.route('edit', {
        path: "/:user_id/edit"
      });
    });
    this.route("auth", function () {
      this.route("login");
      this.route('remind');
    });
    this.route("dummy", function () {
      this.route("create");
    });
    this.route("transports", function () {
      this.route("show");
      this.route("create");
      this.route('archive', {
        path: '/archive/:type'
      });
      this.route("grouping");
    });
    this.route("services", function () {
      this.route("show");
      this.route("archive");
    });
    this.route('settings', function () {
      this.route('edit', {
        path: '/:id'
      });
    });
    this.route('register', function () {
      this.route('after-register');
    });
    this.route('workflows', function () {
      this.route('edit', {
        path: '/:id'
      });
    });
    this.route('notifications', function () {
      this.route('edit', {
        path: '/notifications/:id'
      });
    });
    this.route('invitations', function () {
      this.route('create');
      this.route('handle');
      this.route('review');
      this.route('show', {
        path: '/:id'
      });
    });
    this.route('transport-types', function () {
      this.route('permissions', {
        path: '/:id/permissions'
      });
    });
    this.route('service-types', function () {
      this.route('edit', {
        path: '/:id/edit'
      });
      this.route('index', {
        path: '/:id/index'
      });
      this.route('create');
    });
    this.route('reset-password');
    this.route('forbidden');
    this.route("/", {
      path: "/:alias"
    });
    this.route('companies', function () {
      this.route("index", {
        path: '/:companyType'
      });
      this.route("create", {
        path: '/:companyType/create'
      });
      this.route('edit', {
        path: '/:id/edit'
      });
    });
    this.route('drivers', function () {
      this.route('create');
      this.route('edit', {
        path: '/:id/edit'
      });
    });
    this.route('trucks', function () {
      this.route('create');
      this.route('edit', {
        path: '/:truck_id/edit'
      });
    });
    this.route('trailers', function () {
      this.route('create');
      this.route('edit', {
        path: '/:trailer_id/edit'
      });
    });
    this.route("schedulers", function () {
      this.route("index", {
        path: '/:date_string/:delta/:transport_type'
      });
    });
    this.route('terms', function () {
      this.route('review');
    });
    this.route('containers', function () {
      this.route('index');
      this.route('create');
      this.route('edit', {
        path: '/:container_id/edit'
      });
    });
    this.route('controlled-date-types', function () {
      this.route('edit', {
        path: '/:id'
      });
    });
    this.route('external-cost-orders', function () {
      this.route('index', {
        path: '/'
      });
      this.route('archive');
    });
  });
  var _default = Router;
  _exports.default = _default;
});