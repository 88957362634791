define("apollo/pods/components/log-change/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    tagName: 'span',
    fieldErrors: Ember.computed('model.errors.@each', function () {
      var fieldName = this.get('fieldName');
      return this.get('model.errors.' + fieldName);
    }),
    className: Ember.computed('log.className', function () {
      var modelName = this.get('log.className');
      return modelName ? modelName.charAt(0).toLowerCase() + modelName.substr(1) : '';
    }),
    label: Ember.computed('className', 'log.propertyName', 'fallbackLabel', function () {
      var key = "".concat(this.get('className'), ".").concat(this.get('log.propertyName'));
      var fallbackKey = "transport.".concat(this.get('log.propertyName'));
      return this.get('intl').t(key, {
        default: fallbackKey
      });
    })
  });

  _exports.default = _default;
});