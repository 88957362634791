define("apollo/pods/components/choose-mechanic-button/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      openSelectMechanicModal: function openSelectMechanicModal(transport) {
        var model = {
          transport: transport
        };
        this.showModal('services.modals.select-mechanic', model);
      }
    }
  });

  _exports.default = _default;
});