define("apollo/pods/transports/modals/confirm-checkpoint/controller", ["exports", "apollo/mixins/transports-mixin", "apollo/mixins/modal-mixin"], function (_exports, _transportsMixin, _modalMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_transportsMixin.default, _modalMixin.default, {
    setReasonForTheDelayMustBeGiven: Ember.observer('model.executionDate', function () {
      var reasonForTheDelayMustBeGiven = false;

      if (this.get('model.transport.remoteId') && this.get('model.step.isUnload') && this.get('model.executionDate') > this.get('model.expectedDate')) {
        reasonForTheDelayMustBeGiven = true;
      }

      this.set('model.reasonForTheDelayMustBeGiven', reasonForTheDelayMustBeGiven);
    })
  });

  _exports.default = _default;
});