define("apollo/pods/external-cost-orders/archive/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8MF34+Ln",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"container-fluid\"],[8],[0,\"\\n\\n    \"],[1,[28,\"transports/search-transports\",null,[[\"archive\",\"searchParams\",\"transportTypes\",\"disableExport\"],[true,[24,[\"searchParams\"]],[24,[\"transportTypes\"]],true]]],false],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"clearfix\"],[8],[9],[0,\"\\n\\n    \"],[1,[28,\"transports/transports-table\",null,[[\"transportTypes\",\"archive\",\"searchParams\",\"showModal\",\"fileUploadSuccess\",\"deleteFile\",\"confirm\",\"revoke\",\"markNotesAsReadOff\",\"addNote\"],[[24,[\"transportTypes\"]],true,[24,[\"searchParams\"]],[28,\"action\",[[23,0,[]],\"showModal\"],null],[28,\"action\",[[23,0,[]],\"fileUploadSuccess\"],null],[28,\"action\",[[23,0,[]],\"deleteFile\"],null],[28,\"action\",[[23,0,[]],\"confirm\"],null],[28,\"action\",[[23,0,[]],\"revoke\"],null],[28,\"action\",[[23,0,[]],\"markNotesAsReadOff\"],null],[28,\"action\",[[23,0,[]],\"addNote\"],null]]]],false],[0,\"\\n\\n    \"],[1,[28,\"outlet\",[\"modal\"],null],false],[0,\"\\n\\n\"],[9],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/external-cost-orders/archive/template.hbs"
    }
  });

  _exports.default = _default;
});