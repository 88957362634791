define("apollo/pods/companies/modals/add-company-email/controller", ["exports", "apollo/mixins/modal-mixin"], function (_exports, _modalMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_modalMixin.default, {
    store: Ember.inject.service(),
    cookies: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    errors: [],
    actions: {
      saveCompanyEmail: function saveCompanyEmail() {
        var _this = this;

        var companyEmail = this.get('model');

        if (!companyEmail.validate()) {
          return;
        }

        if (!companyEmail.get('company.id')) {
          companyEmail.get('company.companyEmailsTmp').push(companyEmail);
          jQuery('.modal').modal('hide');
          return;
        }

        companyEmail.save().then(function () {
          _this.set('errors', []);

          jQuery('.modal').modal('hide');
        }).catch(function (response) {
          _this.set('errors', response.errors);
        });
      },
      cancel: function cancel() {
        this.get('model').deleteRecord();
        this.set('errors', []);
        jQuery('.modal').modal('hide');
      }
    }
  });

  _exports.default = _default;
});