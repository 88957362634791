define("apollo/pods/users/create/controller", ["exports", "apollo/mixins/persistence-mixin"], function (_exports, _persistenceMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_persistenceMixin.default, {
    intl: Ember.inject.service(),
    session: Ember.inject.service('session'),
    sessionAccount: Ember.inject.service('session-account'),
    cookies: Ember.inject.service(),
    canNotEditRamps: Ember.computed('sessionAccount.currentUser', function () {
      return !this.get('sessionAccount').hasRole('ROLE_EDIT_USER');
    }),
    isEditing: Ember.computed('model.id', function () {
      return this.get('model').get('id') !== null;
    }),
    errors: [],
    setNotifications: Ember.observer('model.roleGroup.notifications', function () {
      var self = this;
      this.get('model').get('roleGroup').then(function (loadedGroup) {
        self.removeDuplicateRecords(self.get('model').get('roleGroup'), 'notifications');
        self.set('notifications', loadedGroup.get('notifications').sortBy('name'));
      });
    }),
    proxiedNotifications: Ember.computed.map('notifications', function (model) {
      return Ember.ObjectProxy.create({
        model: model,
        checked: true
      });
    }),
    setProxiedNotifications: Ember.observer('proxiedNotifications.length', 'model', function () {
      var self = this;
      Ember.run.once(this, function () {
        self.checkNotifications();
      });
    }),
    proxiedCheckedNotifications: Ember.computed.filterBy('proxiedNotifications', 'checked', true),
    checkedNotifications: Ember.computed.mapBy('proxiedCheckedNotifications', 'model'),
    setCompanies: Ember.observer('model', function () {
      var self = this;
      var companies = Ember.A();
      var principalCompany = this.store.peekRecord('company', this.get('cookies').read('principalId'));
      this.store.query('company', {
        principalCompanyId: this.get('cookies').read('principalId')
      }).then(function (cs) {
        cs.forEach(function (c) {
          companies.pushObject(c);
        });
        companies.pushObject(principalCompany);
        self.set('companies', companies);
      });
    }),
    roleGroups: Ember.observer('model.id', function () {
      if (this.get('model.roleGroup.id')) {
        return;
      }

      var self = this;
      this.get('model.company').then(function (company) {
        if (company) {
          self.get('model').set('roleGroup', company.get('groups.firstObject'));
        }
      });
    }),
    userGroups: Ember.computed(function () {
      return this.get('store').peekAll('userGroup');
    }),
    isInMyAccount: Ember.computed('model.id', function () {
      return this.get('model.id') === this.get('sessionAccount.currentUser.id');
    }),
    actions: {
      setCompany: function setCompany(item) {
        var self = this;
        this.store.findRecord('company', item.id).then(function (company) {
          self.get('model').setProperties({
            company: company,
            roleGroup: company.get('groups.firstObject')
          });
        });
      },
      setRoleGroup: function setRoleGroup(group) {
        this.get('model').setProperties({
          oldRoleGroup: this.get('model').get('roleGroup'),
          roleGroup: group
        });
      },
      setUserGroup: function setUserGroup(group) {
        this.get('model').setProperties({
          oldUserGroup: this.get('model').get('userGroup'),
          userGroup: group
        });
      },
      save: function save() {
        var self = this;
        var user = this.get('model');

        if (user.get('password')) {
          user.set('password', user.get('password').trim());
        }

        var checkedNotifications = this.get('checkedNotifications');
        user.get('notifications').clear();
        checkedNotifications.forEach(function (s) {
          user.get('notifications').pushObject(s);
        });
        user.save().then(function (savedUser) {
          console.debug("User ".concat(savedUser.get('username'), " successfully saved."));
          savedUser.get('company').then(function (company) {
            company.reload().then(function () {
              console.debug("Company ".concat(company.get('shortName'), " reloaded."));
              self.set('errors', []);

              if (self.get('isEditing')) {
                self.transitionToRoute('users.show', savedUser.id);
              } else {
                self.transitionToRoute('users.index');
              }
            });
          });
        }).catch(function (response) {
          self.set('errors', response.errors);
        });
      },
      cancel: function cancel() {
        this.get('errors').clear();

        if (!this.get('isEditing')) {
          this.get('model').unloadRecord();
          window.history.back();
        } else {
          var user = this.get('model');
          user.rollbackAttributes();

          if (user.get('oldRoleGroup')) {
            user.setProperties({
              roleGroup: user.get('oldRoleGroup'),
              oldRoleGroup: null
            });
          }

          if (user.get('oldUserGroup')) {
            user.setProperties({
              userGroup: user.get('oldUserGroup'),
              oldUserGroup: null
            });
          }

          this.checkNotifications();
          this.transitionToRoute('users.show', user);
        }
      },
      delete: function _delete() {
        var self = this;
        var userModel = this.get('model');
        userModel.destroyRecord().then(function (u) {
          console.log("Poprawnie usun\u0119li\u015Bmy u\u017Cytkownika ".concat(u.get('name'), "."));
          self.transitionToRoute('users.index');
        }).catch(function (response) {
          self.set('errors', response.errors);
        });
      },
      toggleChangePasswordAndSetProperty: function toggleChangePasswordAndSetProperty() {
        this.toggleProperty('model.changePassword');
        this.get('model').setProperties({
          password: '',
          newPassword: '',
          repeatedNewPassword: ''
        });
      }
    },
    checkNotifications: function checkNotifications() {
      var proxiedNotifications = this.get('proxiedNotifications');
      var isEditing = this.get('isEditing');
      var userNotifications = this.get('model').get('notifications');
      var userNotificationsIds = userNotifications.map(function (s) {
        return s.get('id');
      });
      proxiedNotifications.forEach(function (s) {
        var checked = !isEditing || userNotificationsIds.includes(s.get('model.id'));
        s.set('checked', checked);
      });
    }
  });

  _exports.default = _default;
});