define("apollo/pods/wash/modals/choose-wash/controller", ["exports", "jquery", "apollo/mixins/persistence-mixin", "apollo/mixins/input-utils-mixin", "apollo/mixins/modal-mixin"], function (_exports, _jquery, _persistenceMixin, _inputUtilsMixin, _modalMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_persistenceMixin.default, _inputUtilsMixin.default, _modalMixin.default, {
    intl: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    title: Ember.computed(function () {
      return this.get('intl').t('transport.wash.chooseWash');
    }),
    transactionCopy: Ember.computed('model.transport.id', function () {
      var currency = this.get('store').peekAll('currency').sortBy('idx').get('firstObject');
      return this.get('store').createRecord('transaction', {
        currencyOfWash: currency
      });
    }),
    washStepCopy: Ember.computed('model.transport.id', function () {
      return this.get('store').createRecord('step');
    }),
    obtainDataFromCopies: function obtainDataFromCopies() {
      var _this = this;

      ['priceOfWash', 'currencyOfWash'].forEach(function (transactionProperty) {
        _this.set("model.transaction.".concat(transactionProperty), _this.get("transactionCopy.".concat(transactionProperty)));
      });
      ['distance', 'company', 'address'].forEach(function (washStepProperty) {
        _this.set("model.washStep.".concat(washStepProperty), _this.get("washStepCopy.".concat(washStepProperty)));
      });
    },
    actions: {
      chooseWash: function chooseWash() {
        var _this2 = this;

        var self = this;
        this.obtainDataFromCopies();
        var washStep = this.get('model.washStep');
        var lastUnload = this.get('model.transport.sortedUnloadSteps.lastObject.deliveryDate');
        var distanceToWash = washStep.get('distance');
        var DEFAULT_AVG_SPEED_IN_KMPH = 60;
        var avgSpeed = this.get('sessionAccount').getSettingValue('AVERAGE_TRUCK_SPEED_IN_KMPH') || DEFAULT_AVG_SPEED_IN_KMPH;
        var timeOnRoad = distanceToWash / avgSpeed;
        console.log("Last unload: ".concat(lastUnload, ". Avg speed: ").concat(avgSpeed, ". Time on road: ").concat(timeOnRoad, "."));
        washStep.set('deliveryDateFrom', moment(lastUnload).add(timeOnRoad, 'hours').toDate());
        var DEFAULT_WASHING_TIME_IN_HOURS = 1;
        var timeForWashing = this.get('sessionAccount').getSettingValue('WASHING_TIME_IN_HOURS') || DEFAULT_WASHING_TIME_IN_HOURS;
        washStep.set('deliveryDateTo', moment(washStep.get('deliveryDateFrom')).add(timeForWashing, 'hours').toDate());

        if (!this.customValidate()) {
          return;
        }

        this.get('model.transport').save().then(function (transport) {
          transport.refresh();
          transport.get('externalCosts').then(function (costs) {
            var costsCopy = costs.slice();
            costsCopy.forEach(function (cost) {
              if (cost !== undefined && !cost.get('id')) {
                costs.removeObject(cost);
                cost.unloadRecord();
              }
            });
          });
          self.removeDuplicateRecords(transport, 'steps');
          self.removeDuplicateRecords(transport, 'checkpoints');
          var stepsCopy = transport.get('steps').slice();
          stepsCopy.forEach(function (step) {
            if (step !== undefined && !step.get('id')) {
              transport.get('steps').removeObject(step);
              step.unloadRecord();
            }
          });
        }, function () {
          _this2.set('model.transport.washStep.company', null);

          alert(_this2.get('intl').t("transport.wash.choosingWashFailedAlert"));
        });
        (0, _jquery.default)('.modal').modal('hide');
      },
      cancel: function cancel() {
        console.debug("Canceling choose wash..");

        if (this.get('model.transport.washStep.stepTypeName') === 'wash') {
          var washStep = this.get('model.transport.washStep');
          this.get('model.transport.steps').removeObject(washStep);
          washStep.unloadRecord();
        }

        this.set('errors', null);
        (0, _jquery.default)('.modal').modal('hide');
      }
    },
    customValidate: function customValidate() {
      var validates = true;
      var washStep = this.get('model.washStep');
      var transaction = this.get('model.transaction');
      var priceOfWash = this.get('model.transaction.priceOfWash');

      if (!washStep.validate()) {
        validates = false;
      }

      if (!transaction.get('currencyOfWash')) {
        validates = false;
        transaction.get('errors').add('currencyOfWash', 'blank');
      }

      if (!priceOfWash || !this.isCorrectPrice(priceOfWash)) {
        validates = false;
        transaction.get('errors').add('priceOfWash', 'incorrectNumber');
      }

      return validates;
    }
  });

  _exports.default = _default;
});