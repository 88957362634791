define("apollo/pods/resource-date/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    changeTracker: {
      auto: true,
      enableIsDirty: true
    },
    intl: Ember.inject.service(),
    vehicle: _emberData.default.belongsTo('vehicle'),
    driver: _emberData.default.belongsTo('driver'),
    company: _emberData.default.belongsTo('company'),
    attachmentsTmp: _emberData.default.attr(),
    date: _emberData.default.attr('isodate'),
    controlledDateType: _emberData.default.belongsTo('controlledDateType', {
      async: true
    }),
    identifier: _emberData.default.attr('string'),
    files: _emberData.default.hasMany('file', {
      async: true
    }),
    warningInAdviceForm: Ember.computed('date', 'controlledDateType.daysBeforeAdviceWarning', 'vehicle.registrationNumber', 'driver.name', function () {
      var expirationDate = moment(this.get('date'));
      var today = moment(new Date().setHours(0, 0, 0, 0));
      var difference = expirationDate.diff(today, 'days');
      var adviceWarning = this.get('controlledDateType.daysBeforeAdviceWarning');

      if (difference > adviceWarning) {
        return null;
      } else {
        var resource = this.get('vehicle.id') ? this.get('vehicle') : this.get('driver');
        var resourceLabel = this.get('vehicle.id') ? this.get('vehicle.registrationNumber') : this.get('driver.name');
        var params = {
          resource: "".concat(this.get('intl').t('resources.of.' + resource.get('exactTypeName')), " ").concat(resourceLabel),
          document: this.get('controlledDateType.label'),
          date: expirationDate.format('DD.MM.YYYY')
        };

        if (difference < 0) {
          return {
            className: 'warning',
            text: this.get('intl').t('documentsExpirationDate.expiredOn', params),
            date: expirationDate
          };
        }

        if (difference < adviceWarning) {
          return {
            className: 'info',
            text: this.get('intl').t('documentsExpirationDate.willExpireOn', params),
            date: expirationDate
          };
        }
      }
    }),
    belongsToResource: function belongsToResource(resourceId) {
      return this.get('vehicle.id') === resourceId || this.get('driver.id') === resourceId || this.get('company.id') === resourceId;
    },
    applyToClient: function applyToClient(clientId) {
      return !this.get('controlledDateType.specificClientId') || this.get('controlledDateType.specificClientId') === Number(clientId);
    },
    isInvalid: function isInvalid() {
      return this.get('date') < new Date().setHours(0, 0, 0, 0);
    },
    sortedActiveFiles: Ember.computed('files.@each.{dateCreated,active,length}', function () {
      return this.get('files').filterBy('active').sortBy('dateCreated');
    }).readOnly()
  });

  _exports.default = _default;
});