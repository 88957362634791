define("apollo/translations/en", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "address": {
      "addNewAddress": "Add new address",
      "businessHours": "Business hours",
      "city": "City",
      "confirmMarkAsHeadquarter": "Are you sure you want to mark this address as headquarter?",
      "coordinates": "Coordinates",
      "country": "Country",
      "deleteAddress": "Are you sure you want to remove this address?",
      "errors": {
        "city": {
          "tooShort": "The name of the city should consist of at least three characters."
        },
        "closingTimeString": {
          "beforeOpening": "The closing time must be later than the opening hour.",
          "null": "Please enter the closing hour.",
          "wrongFormat": "Please specify closing time in HH: mm format, eg 16:30."
        },
        "country": {
          "blank": "Please, provide a country"
        },
        "lat": {
          "incorrectNumber": "Incorrect coordinates"
        },
        "lon": {
          "incorrectNumber": "Incorrect coordinates"
        },
        "openingTimeString": {
          "null": "Please enter the opening hour.",
          "wrongFormat": "Please specify opening time in HH: mm format, eg 16:30."
        },
        "postal": {
          "tooShort": "The zip code should consist of at least three characters.",
          "wrongFormatForPL": "Please enter zip code in 01-234 format."
        },
        "street": {
          "tooShort": "The street name should consist of at least three characters."
        }
      },
      "isHeadquarter": "Headquarters",
      "label": "Address",
      "mark": "Mark",
      "markAsHeadquarter": "Mark as headquarter",
      "postal": "Postal code",
      "recipient": "Recipient",
      "recipientPlaceholder": "Start typing to search for recipient..",
      "selectCompany": "Select a client first",
      "street": "Street"
    },
    "advice": {
      "additionalDriver": "Second driver",
      "carrierAdvice": "Carrier's advice",
      "carrierChosen": "Carrier chosen",
      "costsFirstStepDescription": "<b>km</b> (wash - load)",
      "costsSecondStepDescription": "<b>km</b> (load - unload)",
      "costsThirdStepDescription": "<b>km</b> (unload - wash)",
      "creator": "Advice creator",
      "driver": "Driver",
      "emptyKilometers": "Distance in km",
      "emptyKilometersTitle": "Set value for transport without load",
      "emptyKm": "Underpass",
      "errors": {
        "carRegistrationNumber": {
          "blank": "Please, provide a car registration number"
        },
        "driver": {
          "blank": "Please, provide a driver"
        },
        "driverMobilePhoneNumber": {
          "blank": "Please, provide a driver mobile phone number"
        },
        "driverName": {
          "blank": "Please, provide a driver name"
        },
        "emptyKilometers": {
          "incorrectNumber": "Please provide correct number"
        },
        "rodoAccepted": {
          "blank": "Data must be processed legally"
        },
        "trailer": {
          "blank": "Please, provide a trailer"
        },
        "truck": {
          "blank": "Please, provide a truck"
        },
        "vehicle": {
          "blank": "Please select a vehicle"
        }
      },
      "intermodalSettlement": "Intermodal settlement",
      "intermodalSettlementHint": "If \"Intermodal settlement\" is not checked the cost object is taken from \"Cost object\" field in truck details.\n\n If \"Intermodal settlement\" is checked the cost object is taken from:\n - MPK field in chosen container (if chosen trailer is container without undercarriage),\n - MPK field in container assigned to trailer (if chosen trailer is undercarriage with associated container),\n - in case of lack of container in advice cost object is undefined.",
      "ownAdvice": "Own advice",
      "rodo": "Data entered into the form can be legally processed by Logintegra sp. z o.o.",
      "trailer": "Trailer",
      "truck": "Truck",
      "washChosen": "Wash chosen"
    },
    "afterRegistration": {
      "invitationButton": "Invitation list",
      "label": "Hello!",
      "title": "You are on the right way to say goodbye queues!"
    },
    "auth": {
      "alreadyRegistered": "You already have an account?",
      "changePassword": {
        "false": "Change password",
        "true": "Do not change password"
      },
      "confirmPassword": "Confirm password",
      "description": "Logistics platform",
      "emailPlaceholder": "Enter e-mail address",
      "fail": "Logging failed",
      "footer": "Prepared by",
      "invalidLoginOrPassword": "Invalid login or password.",
      "login": "Login",
      "loginPlaceholder": "Enter login",
      "logout": "Logout",
      "newPassword": "Password",
      "noLoginAndPassword": "Please provide login and password.",
      "notRegistered": "You don't have an account yet?",
      "oldPassword": "Old password",
      "password": "Password",
      "passwordPlaceholder": "Enter password",
      "passwordRemindedMessage": "A new password for user {username} has been send at {email}.",
      "remind": "Remind",
      "remindEmail": "Enter the e-mail connected with Your account, on which we will send a temporary password",
      "remindFail": "Reminding failed",
      "remindHeader": "Remind password",
      "remindLink": "Forgot password?",
      "remindingInProgress": "We're sending an e-mail",
      "repeatNewPassword": "Repeat password",
      "serverError": "Server is not responding. Try again in few minutes or contact us at info@logintegra.com.",
      "username": "User name"
    },
    "cannotAdviceDueToInvalidResourceDates": {
      "hint": "Please update validity dates in resource details.",
      "info": "You cannot advice due to invalid resources' documents:"
    },
    "checkpoints": {
      "beingProcessedHint": "Status is being processed",
      "cannotConfirmHint": "You don't have authority to confirm this status",
      "confirm": "Confirm",
      "confirmExecutionOnTime": "Done on {executionDate} at {executionTime}",
      "confirmStatus": "Confirm status",
      "confirmTitle": "Confirm {stateName} of transport {identifier}",
      "confirmedMessage": "confirmed on {date} by {person}",
      "empty": "No statuses",
      "errors": {
        "CheckpointNotFound": "The selected stage does not exist, or has already been confirmed in the PCC application. Please check or refresh the page.",
        "CheckpointNotFoundOrDeletedByAnotherTransactionError": "We can not confirm the selected stage. The order has been updated by another user. Please try again.",
        "CheckpointsSynchronizationError": "The selected stage has already been confirmed in the PCC application. Please check or refresh the page.",
        "SapException": "Sending cost to SAP failed",
        "canNotConfirmCheckpoint": "You can not yet confirm this stage. The car probably has not left the loading place or you have not yet sent the advice to the customer.  Try again later.",
        "executionTimeBeforePreviousCheckpoint": "Execution date must take place after confirmation of previous checkpoint ({minDate})",
        "executionTimeInFuture": "Stage execution time must be in the past.",
        "invalidData": "Incorrect data!",
        "orderNotFound": "The order synchronization attempt failed: the selected order does not exist in the PCC system. Please contact the administrator.",
        "reasonForTheDelay": {
          "blank": "Please, provide the reason for the delay."
        }
      },
      "executionTime": "Execution time",
      "noExecutionDateHint": "Button will be active after confirmation of appropriate checkpoint in transport related to this order",
      "noMechanic": "You have to choose mechanic before",
      "noServicePlace": "Please, first choose the place of service",
      "noWash": "You have to choose wash first",
      "plan": "plan.",
      "processFinished": "Process is finished!",
      "processFinishedShort": "Finished",
      "reasonForTheDelay": "Reason for the delay",
      "revokeConfirm": "Are you sure you want to revoke the confirmation of this status?",
      "revokeManyConfirm": "Warning: as it is not the last confirmed status, we are going to also revoke the confirmations of all latter statuses. Are you sure you want to revoke those confirmations?"
    },
    "columns": {
      "advice": "Advice",
      "arrival": "Arrival",
      "arrivalDate": "Delivery date",
      "car": "Vehicle",
      "cargo": "Pallets",
      "carrier": "Carrier",
      "city": "City",
      "collectionPlace": "Collection place",
      "company": "Company",
      "contact": "Contact",
      "costs": "Settlements",
      "costsHeader": "Own fleet",
      "country": "Country",
      "creationDate": "Creation date",
      "deliveryPlace": "Delivery place",
      "departure": "Departure",
      "description": "Description",
      "driver": "Driver",
      "driverPhoneNumber": "Phone",
      "forwarder": "Forwarder",
      "forwarderGroup": "Group",
      "group": "Group",
      "hour": "Hour",
      "identifier": "Id",
      "load": "Load",
      "mechanic": "Mechanic",
      "notifications": "SMS/Email",
      "number": "Number",
      "owner": "Owner",
      "phone": "Phone",
      "product": "Product",
      "serviceCost": "Cost",
      "serviceEndDate": "End date",
      "serviceFinishedJobs": "Finished jobs",
      "serviceNumber": "Service number",
      "servicePlace": "Place of service",
      "serviceRemarks": "Service remarks",
      "serviceRequestedJobs": "Requested jobs",
      "serviceStartDate": "Start date",
      "status": "Status",
      "supplier": "Customer",
      "tasks": {
        "Approve": "Approved",
        "Confirm": "Confirmed"
      },
      "technicalEmployee": "Service department worker",
      "term": {
        "full": "Cooled chain product",
        "short": "Cooled"
      },
      "timeWindowDate": "Load date",
      "tradesman": "Tradesman",
      "trailer": "Trailer",
      "truckSideNumber": "Side number",
      "unload": "Unload",
      "vehicle": "Vehicle",
      "wash": "Wash"
    },
    "common": {
      "DOCUMENT": "document",
      "accept": "Accept",
      "accepted": "Accepted",
      "active": "Active",
      "add": "Add",
      "addFile": "Add file",
      "additionalComments": "Additional comments",
      "address": "Address",
      "appVersion": "Version",
      "back": "Back",
      "browseAndUploadDocuments": "Browse and upload documents",
      "by": "by",
      "byYou": "by you",
      "cancel": "Cancel",
      "cancelAndBack": "Cancel and go back",
      "check": "check",
      "clear": "Clear",
      "clearFilters": "Clear filters",
      "close": "Close",
      "company": "Company",
      "confirmMessage": "Are you sure?",
      "create": "Create",
      "createSimilar": "Create similar",
      "dateCreated": "Date of creation",
      "day": "Day",
      "days14": "14 Days",
      "days2": "2 Days",
      "days4": "4 Days",
      "daysOfWeek": {
        "1": "Mon",
        "2": "Tue",
        "3": "We",
        "4": "Thu",
        "5": "Fr",
        "6": "Sat",
        "7": "Sun",
        "label": "for days"
      },
      "delete": "Delete",
      "deleteTitle": "Delete this transport (deleted transport will appear in the archive).",
      "deletingProgress": "deleting",
      "desc": "Description",
      "description": "Description",
      "details": "details",
      "disable": "Disable",
      "downloadManualHeader": "Download manual",
      "edit": "Edit",
      "email": "Email address",
      "enable": "Enable",
      "enterEmail": "Enter email address",
      "exportAsXLS": "Export as XLS",
      "externalCostOrders": "{count} {count, plural, one {order} other {orders}}",
      "false": "No",
      "forDateRange": {
        "M": "month",
        "Y": "year"
      },
      "from": "from",
      "fromCompany": "from company",
      "hint": "Hint",
      "identifier": "Identifier",
      "idx": "Index",
      "instructionalVideo": "instructional video",
      "interval": {
        "d": "{count} {count, plural, one {day} other {days}}",
        "m": "{count} {count, plural, one {month} other {months}}",
        "w": "{count} {count, plural, one {week} other {weeks}}",
        "y": "{count} {count, plural, one {year} other {years}}"
      },
      "kg": "kg",
      "lastUpdated": "Date of latest change",
      "lat": "lat",
      "less": "Less...",
      "loading": "Loading...",
      "lon": "lon",
      "maxDate": "max date",
      "minDate": "min date",
      "month": "Month",
      "more": "More...",
      "name": "Name",
      "newMessagesTitle": "You have new messages!",
      "no": "No",
      "noAdviceAddedYet": "No advice added yet",
      "noMatches": "No matches",
      "noResults": "No results",
      "notSpecified": "not specified",
      "oldBrowser": "You are using old browser. Our application will work better on modern browser such as Google Chrome, Firefox, Opera, Vivaldi, Safari, or IE Edge.",
      "phone": "Phone",
      "processing": "Working on it..",
      "recipient": "Recipient",
      "register": "Join",
      "reject": "Reject",
      "rejected": "Rejected",
      "role": "Role",
      "save": "Save",
      "saving": "Saving",
      "search": "Search",
      "selectDate": "Select date",
      "send": "Send",
      "sendSms": "Send SMS",
      "services": "{count} {count, plural, one {service} other {services}}",
      "settings": "Settings",
      "shortName": "Short name",
      "show": "Show",
      "timeSign": {
        "after": "after",
        "before": "before"
      },
      "timeUnit": {
        "d": "days",
        "h": "hours",
        "m": "minutes",
        "s": "seconds"
      },
      "to": "to",
      "today": "Today",
      "transportDeletionDisabled": "You can not delete this transport anymore. Please contact an administrator.",
      "transports": "{count} {count, plural, one {transport} other {transports}}",
      "true": "Yes",
      "type": "Type",
      "unavailable": "Unavailable",
      "update": "Update",
      "uploadFile": "Upload file",
      "value": "Value",
      "waiting": "Waiting",
      "week": "Week",
      "yes": "Yes"
    },
    "company": {
      "addDriver": "Add driver to {companyName}",
      "addEmail": "Add new email address",
      "addVehicle": "Add vehicle to {companyName}",
      "address": "Address",
      "addresses": "Addresses",
      "card": {
        "CARRIER": "Carrier card",
        "CLIENT": "Client card",
        "SERVICE": "Service card",
        "WASH": "Wash card"
      },
      "contractorNumber": "Contractor number",
      "country": "Country",
      "create": {
        "CARRIER": "Create carrier",
        "CLIENT": "Create client",
        "SERVICE": "Create service",
        "WASH": "Create wash"
      },
      "deleteConfirm": "Are you sure, you want to delete this company?",
      "deleteEmail": "Delete email address",
      "deletedMessage": "We successfully deleted company \"{companyName}\".",
      "editCompany": "Edit company",
      "emails": "Email addresses",
      "employees": "Employees",
      "errors": {
        "companyType": {
          "blank": "Please select a business type."
        },
        "country": {
          "blank": "Please select a country."
        },
        "name": {
          "blank": "Please enter the company name.",
          "tooShort": "Company name should consist of at least three characters."
        },
        "shortName": {
          "blank": "Please enter the company short name.",
          "tooShort": "Company short name should consist of at least two characters."
        },
        "taxIdNumber": {
          "notANumber": "The NIP/VAT number should be the correct number.",
          "tooShort": "The NIP/VAT number should consist of at least three characters."
        }
      },
      "files": "Documents",
      "integrated": {
        "CARRIER": "Integrated",
        "SERVICE": "Integrated service",
        "WASH": "PCC Autochem Wash"
      },
      "intermodalCarrier": "Intermodal carrier",
      "label": "Company",
      "list": "List of companies",
      "name": "Name",
      "noEmployees": "This company has no employees",
      "onlyAsCustomerOfService": "Only as a customer of a service",
      "openingHours": "Opening hours",
      "partnershipStatus": {
        "ACCEPTED": "Accepted",
        "NO_INVITATION": "No invitation",
        "REJECTED": "Rejected",
        "WAITING": "Waiting",
        "label": "Status"
      },
      "paymentOptions": {
        "_15_DAYS_AFTER_ISSUING_INVOICE": "15 days after issuing of an invoice",
        "_15_DAYS_AFTER_RECEIVING_INVOICE": "15 days after receiving an invoice",
        "_30_DAYS_AFTER_ISSUING_INVOICE": "30 days after issuing of an invoice",
        "_30_DAYS_AFTER_RECEIVING_INVOICE": "30 days after receiving an invoice",
        "_45_DAYS_AFTER_ISSUING_INVOICE": "45 days after issuing of an invoice",
        "_45_DAYS_AFTER_RECEIVING_INVOICE": "45 days after receiving an invoice",
        "_60_DAYS_AFTER_ISSUING_INVOICE": "60 days after issuing of an invoice",
        "_60_DAYS_AFTER_RECEIVING_INVOICE": "60 days after receiving an invoice",
        "label": "Payment options",
        "select": "Choose payment option"
      },
      "savedMessage": "We successfully saved company \"{companyName}\".",
      "selectCountry": "Select country",
      "selectDriver": "Select driver",
      "selectType": "Select type",
      "selectVehicle": "Select vehicle",
      "shortName": "Short name",
      "subcontractorCode": "Subcontractor code",
      "sureToDeleteEmail": "Are you sure you want to delete this email address?",
      "taxIdNumber": "Taxpayer ID Number",
      "taxIdNumberHint": "Enter a valid 10-digit number in the NIP/VAT field (without dashes). Only one company per NIP/VAT number can be registered in the system.",
      "type": {
        "CARRIER": "Carrier",
        "CLIENT": "Client",
        "FORWARDER": "Forwarder",
        "SERVICE": "Service",
        "SUPPLIER": "Supplier \\\\ Recipient",
        "WASH": "Wash",
        "label": "Type"
      },
      "updatedMessage": "We successfully updated company \"{companyName}\"."
    },
    "company-email": {
      "errors": {
        "email": {
          "notValidEmail": "Incorrect email address"
        }
      }
    },
    "container": {
      "errors": {
        "registrationNumber": {
          "blank": "Provide a registration number, please."
        }
      }
    },
    "controlledDateTypes": {
      "daysBeforeAdviceWarning": {
        "full": "How many days before the expiry date should warnings in advice form be visible",
        "short": "Warning in advice form"
      },
      "daysBeforeFirstWarning": {
        "full": "How many days before the expiry date should date be colored in yellow",
        "short": "Yellow warning"
      },
      "daysBeforeSecondWarning": {
        "full": "How many days before the expiry date should date be colored in red",
        "short": "Red warning"
      },
      "daysBeforeSendEmail": {
        "full": "How many days before the expiry date should be sent mail notification",
        "short": "Email notification"
      },
      "documentNameLabel": "Document name",
      "label": "Controlled dates settings",
      "labelEn": "English label",
      "labelPl": "Polish label",
      "specificClientInfo": "Advice will be blocked due to expiration of this date only in transports with company {company} as a client",
      "updateDateIntervalWarning": {
        "full": "Minimal interval which causes warning display during resource update",
        "short": "Warning during date update"
      }
    },
    "deliveries": {
      "add": "Add delivery",
      "label": "Deliveries"
    },
    "delivery": {
      "label": "Delivery"
    },
    "documentsExpirationDate": {
      "expired": "{document}: the validity date for {resource} expired",
      "expiredOn": "{document}: the validity date of {resource} expired on {date}",
      "notExists": "{document}: expiration date for {resource} does not exist",
      "warning": "{document}: the validity date for {resource} will expire soon",
      "willExpireOn": "{document}: the validity date of {resource} will expire on {date}"
    },
    "driver": {
      "active": "Active driver",
      "adrNumber": "ADR document number",
      "card": "Driver card",
      "comment": "Additional comments",
      "country": "Nationality",
      "deleteConfirm": "Are you sure, you want to delete this driver?",
      "deletedMessage": "We successfully deleted driver \"{driverName}\".",
      "documentNumber": "Document number",
      "driverCardNumber": "Driver card number",
      "driverLicenceNumber": "Driver licence number",
      "errors": {
        "documentNumber": {
          "blank": "Please fill up document number",
          "tooShort": "The document number should consist of at least three characters."
        },
        "flTrackerId": {
          "notANumber": "Number must contain only digits"
        },
        "identifier": {
          "wrongControlSum": "Entered number is not a correct Social Security Number in Poland",
          "wrongLength": "The Social Security Number in Poland should consist of 11 characters."
        },
        "name": {
          "blank": "Provide a driver's name, please.",
          "tooShort": "The driver's name should consist of at least three characters."
        },
        "phoneNumber": {
          "blank": "Please fill up phone number",
          "tooShort": "The phone number should consist of at least three characters."
        },
        "phoneNumberPrefix": {
          "blank": "Please fill up prefix"
        },
        "rodoAccepted": {
          "blank": "Data must be processed legally"
        }
      },
      "flTrackerId": "ID Number (tracker)",
      "identifier": "Social Security Number",
      "inactiveHint": "Driver is not active",
      "intermodalCarrier": "Intermodal carrier",
      "name": "Surname and name",
      "new": "New driver",
      "owner": "Owner",
      "phoneNumber": "Phone number",
      "phoneNumberPrefix": "Prefix number",
      "savedMessage": "We successfully saved driver \"{driverName}\".",
      "shortName": "Short name",
      "taxIdNumber": "Taxpayer ID Number"
    },
    "external-cost": {
      "ADDITIONAL_INCOME": "Additional income",
      "AUTOMATIC_WASHING_COST": "Costs of a car wash assigned to the order",
      "EXTERNAL_COSTS": "External costs",
      "FREIGHT_COSTS": "Freight",
      "approval": "Approval",
      "company": "Company",
      "confirmation": "Confirmation",
      "controllingOrderNumber": "Controlling order number",
      "costHasBeenSentToSapInfo": "Order related to this cost has been approved in orders' table and sent to SAP",
      "dateOfExecution": "Date of execution",
      "errors": {
        "company": {
          "blank": "Set company"
        },
        "currency": {
          "blank": "Set currency"
        },
        "externalCostType": {
          "blank": "Set cost type"
        },
        "price": {
          "blank": "Set price"
        }
      },
      "generateCost": {
        "AUTOMATIC_WASHING_COST": "Generate cost of the wash assigned to transport",
        "FREIGHT_COSTS": "Generate freight cost"
      },
      "messages": {
        "APPROVE_EXTERNAL_COST_ORDER": "External cost was created",
        "CONFIRM_EXTERNAL_COST_ORDER": "External cost was confirmed",
        "DELETE_EXTERNAL_COST_ORDER": "External cost was deleted"
      },
      "order": {
        "delete": "Delete order",
        "deleted": "Order has been deleted",
        "deletingInProgress": "The order is being deleted...",
        "deletingNotPossibleConfirmedInSap": "The order has been confirmed in SAP and there is no possibility to delete it.",
        "editExecutionDate": "Edit execution date",
        "noAuthorityToDelete": "You have no authority to delete this order.",
        "sureToDeleteCost": "Are you sure you want to delete this cost? The related order will be deleted as well.",
        "sureToDeleteOrder": "Are you sure you want to delete this order? The related cost in transport's settlements section will be deleted as well."
      },
      "orderNumber": "Order number",
      "price": "Price",
      "pzNumber": "PZ number",
      "table": {
        "company": "Company",
        "date": "Date",
        "price": "Price",
        "type": "Type"
      },
      "type": "Type",
      "types": {
        "AUTOMATIC_WASHING": "Automatic washing cost",
        "FREEZING": "Freezing",
        "FREIGHT": "Freight",
        "HEATING": "Heating",
        "LOAD_DELAYED": "Delayed loading",
        "ORDER_CANCELLED": "Cancellation of order",
        "OTHER": "Other",
        "OTHER_INCOME": "Other",
        "UNLOAD_DELAYED": "Delayed unloading",
        "WASHING": "Washing"
      }
    },
    "file": {
      "desc": "Added by {personName} on {dateCreated}.",
      "errors": {
        "fileExists": "The file named {fileName} already exists."
      }
    },
    "filterNames": {
      "ADVICE_CREATED": "Unsent advice to the customer",
      "ADVICE_SMS": "Unsent information to the driver",
      "AUTOMATIC_WASHING": "Automatic washing cost",
      "CONFIRMED": "Confirmed",
      "DELETED": "Deleted",
      "DURING_UNLOAD": "During unload",
      "FINISHED": "Finished",
      "FREEZING": "Freezing",
      "FREIGHT": "Freight",
      "FREIGHT_ORDER_NUMBER": "Unsent order to the carrier",
      "HEATING": "Heating",
      "INTERMODAL": "Intermodalna",
      "INTERNATIONAL": "International",
      "IN_PROGRESS": "In progress",
      "LOADED": "Loaded",
      "NATIONAL": "National",
      "NO_ADVICE": "No advice",
      "ON_LOAD": "On load",
      "ON_THE_ROAD": "On the road",
      "OTHER": "Other",
      "PLANNED": "Planned",
      "READY_FOR_UNLOAD": "Ready for unload",
      "TO_PLAN": "To plan",
      "TO_WASH": "To wash",
      "UNCONFIRMED": "Unconfirmed",
      "UNLOADED": "Unloaded",
      "UNSAVED": "Unsaved",
      "WAITING_FOR_APPROVAL": "Waiting for approval",
      "WAITING_FOR_CONFIRMATION": "Waiting for confirmation",
      "WAITING_ON_LOAD": "Waiting on load",
      "WASH": "Wash",
      "WASHING": "Washing",
      "WASH_SMS": "Unsent car wash to the driver",
      "false": "No",
      "true": "Yes"
    },
    "forwarderGroup": {
      "INTERMODAL": "Intermodal",
      "INTERMODAL.abbr": "Interm.",
      "INTERNATIONAL": "International",
      "INTERNATIONAL.abbr": "Internat.",
      "NATIONAL": "National",
      "NATIONAL.abbr": "National"
    },
    "help": {
      "contactUs": "or contact us",
      "contactWithWarehouse": "Contact with warehouse",
      "deleteTitle": "Delete this transport (deleted transport will appear in the archive).",
      "newMessagesTitle": "You have %@1 new messages!",
      "statute": "Statute"
    },
    "invitation": {
      "acceptedByAnotherPerson": "The invitation has already been accepted by another person in your company.",
      "acceptedSummary": "Accepted",
      "alreadyAccepted": "This invitation is already accepted.",
      "alreadyHandled": "This invitation has been reviewed",
      "createdBy": "{personName} invites You to join company {companyName} as {roleName}.",
      "creator": "Creator",
      "for": {
        "CARRIER": "Carrier",
        "CLIENT": "Client",
        "JOIN_COMPANY": "To your company",
        "SERVICE": "Servicer",
        "SUPPLIER": "Supplier \\\\ Recipient",
        "WASH": "Wash"
      },
      "invite": "Invite",
      "label": "An Invitation",
      "notFound": "Invitation does not exist or has expired.",
      "registeredAccount": "Registered account",
      "registrationComplete": "Thank you. Your account has been registered. Please log-in using username {username}.",
      "rejectedSummary": "Rejected",
      "sendTo": "Send an invitation",
      "sent": "Invitation sent at {email}.",
      "toPartnership": "{personName} invites you to join company {companyName} as {roleName}.",
      "type": {
        "CARRIER": "Join as carrier",
        "CLIENT": "Join as client",
        "JOIN_COMPANY": "Join company",
        "SERVICE": "Join as service",
        "SUPPLIER": "Join as supplier \\\\ recipient",
        "WASH": "Join as wash"
      }
    },
    "invitations": {
      "emptyReceived": "You did not receive an invitation yet.",
      "emptySent": "You did not send an invitation yet.",
      "label": "Invitations",
      "received": "Received",
      "sent": "Sent"
    },
    "leave": {
      "createTitle": "Plan leave",
      "deleteLeave": "Are you sure you want to delete this leave?",
      "next": "Next vacation",
      "plan": "Plan",
      "previous": "Previous vacation",
      "startDate": "Leave start date",
      "stopDate": "Leave stop date",
      "title": "Leave"
    },
    "log": {
      "actor": "Author of change",
      "change": "Change",
      "dateCreated": "Date",
      "properties": {
        "ADR": "ADR",
        "BBS_TRAINING": "BBS Training",
        "CARRIER_LICENCE": "Licence",
        "CARRIER_LICENCE_added_file": "Added file (Licence)",
        "CARRIER_LICENCE_removed_file": "Removed file (Licence)",
        "DRIVER_CARD": "Driver card",
        "DRIVER_LICENCE": "Driver licence",
        "ID_DOCUMENT": "Identity document",
        "INSURANCE": "Insurance",
        "LAST_REVIEW": "Last review",
        "MEDICAL_EXAMINATION": "Medical examination",
        "OCP_INSURANCE": "OCP insurance",
        "OCP_INSURANCE_added_file": "Added filek (OCP insurance)",
        "OCP_INSURANCE_removed_file": "Removed file (OCP insurance)",
        "OSH_TRAINING": "OSH training",
        "PCC_MCAA_TRAINING": "PCC MCAA training",
        "PSYCHOTECHNICAL_EXAMINATION": "Psychotechnical examination",
        "SUPERVISION": "Supervision date",
        "TACHOGRAPH": "Tachograph review",
        "TDT_PERMISSIONS": "TDT (UNO) Permissions",
        "active": "Active driver",
        "adrNumber": "ADR document number",
        "automaticConfirmationWash": "Automatic confirmation wash",
        "axisType": "Axis type",
        "bulwarks": "Bulwarks",
        "capacity": "Capacity",
        "carType": "Car type",
        "carTypeGroup": "Car type group",
        "comment": "Additional comments",
        "company_added_file": "Added file (Documents)",
        "company_removed_file": "Removed file (Documents)",
        "contractorCompany": "Short name",
        "contractorNumber": "Contractor number",
        "controllingNumber": "Controlling number",
        "costObject": "Cost object",
        "country": "Nationality",
        "dateCreated": "Date created",
        "description": "Description",
        "documentNumber": "Document number",
        "downAirConnection": "Down air connection",
        "driver": "Driver",
        "driver.taxIdNumber": "Taxpayer ID Number",
        "driverCardNumber": "Driver card number",
        "driverLicenceNumber": "Driver licence number",
        "earliestStepStart": "Changing the holiday start date",
        "euroStandard": "Euro standard",
        "flTrackerId": "ID Number (tracker)",
        "group": "Group",
        "identifier": "Social Security Number",
        "integrated": "Integrated",
        "intermodalCarrier": "Intermodal carrier",
        "invitation": "Invitation",
        "latestStepStop": "Changing the leave end date",
        "model": "Model",
        "mpk": "MPK",
        "name": "Surname and name",
        "newAddress": "New address",
        "newEmail": "New email",
        "newLeave": "New leave",
        "numberOfCompartments": "Number of compartments",
        "numberOfManholes": "Number of manholes",
        "oldAddress": "Old address",
        "onlyAsCustomerOfService": "Only as a customer of a service",
        "outOfCommission": "Out of commission",
        "partnershipType": "Partnership Type",
        "paymentOption": "Payment options",
        "phoneNumber": "Phone number",
        "phoneNumberPrefix": "Prefix number",
        "principalCompany": "Principal company",
        "registrationNumber": "Registration number",
        "removedEmail": "Removed email",
        "removedLeave": "Removed leave",
        "shortName": "Short name",
        "sideNumber": "Side number",
        "status": "Status",
        "subcontractorCode": "Subcontractor code",
        "taxIdNumber": "Taxpayer ID Number",
        "trailer": "Trailer",
        "valveType": "Valve type",
        "yearOfProduction": "Year of production"
      },
      "property": "Property",
      "valueAfterChange": "Value after change",
      "valueBeforeChange": "Value before change"
    },
    "notification": {
      "ADVICE_CREATED": {
        "desc": "The notification sent to the client and the creator after saving of an advice.",
        "label": "Advice to customer",
        "label.short": "Advice to customer",
        "unsent": "Unsent advice to the customer"
      },
      "ADVICE_SMS": {
        "desc": "SMS to the driver with the order data",
        "label": "Order to the driver",
        "label.short": "Order to driver",
        "unsent": "Unsent information to the driver"
      },
      "ADVICE_UPDATED": {
        "desc": "The notification sent to stakeholders (eg. If the creator of an advice is a supplier \\\\ recipient, e-mail will be sent to the logistics and carriers) after the change of an advice.",
        "label": "Change of an advice",
        "label.short": "Advice updated"
      },
      "CONFIRMED_CHECKPOINT": {
        "desc": "The notification sent when status {checkpointName} is confirmed.",
        "label": "Confirmation status {checkpointName} for transport type {transportTypeName}"
      },
      "FREIGHT_ORDER_NUMBER": {
        "desc": "The notification sent to a carrier and the contractors after assignment of the order.",
        "label": "Order to carrier",
        "label.short": "Order to carrier",
        "unsent": "Unsent order to the carrier"
      },
      "LATE_ADVICE": {
        "desc": "The notification sent in case of delay in the driver's arrival.",
        "label": "Delay of an advice",
        "label.short": "Advice delayed"
      },
      "LATE_CARRIER_OCP": {
        "desc": "The notification sent X days before expiry of the dates from carrier card",
        "label": "The expiry date of the OCP insurance"
      },
      "LATE_CONTAINER_SUPERVISION": {
        "desc": "The notification sent X days before expiry of supervision date",
        "label": "The expiry date of the supervision of the container"
      },
      "LATE_DOCUMENTS": {
        "desc": "The notification sent if the supplier does not add shipping documents in a timely manner.",
        "label": "Delay in handing over documents"
      },
      "LATE_DRIVER_DOCUMENTS": {
        "desc": "The notification sent X days before expiry of the dates from driver card",
        "label": "The expiry date of the dates from driver card"
      },
      "LATE_TRAILER_ADR": {
        "desc": "The notification sent X days before expiry of ADR",
        "label": "The expiry date of the ADR of the trailer"
      },
      "LATE_TRAILER_INSURANCE": {
        "desc": "The notification sent X days before expiry of insurance",
        "label": "The expiry date of the insurance of the trailer"
      },
      "LATE_TRAILER_LAST_REVIEW": {
        "desc": "The notification sent X days before expiry of last review",
        "label": "The expiry date of the last review of the trailer"
      },
      "LATE_TRAILER_SUPERVISION": {
        "desc": "The notification sent X days before expiry of supervision date",
        "label": "The expiry date of the supervision of the trailer"
      },
      "LATE_TRAILER_TACHOGRAPH": {
        "desc": "The notification sent X days before expiry of tachograph review",
        "label": "The expiry date of the tachograph review of the trailer"
      },
      "LATE_TRUCK_ADR": {
        "desc": "The notification sent X days before expiry of ADR",
        "label": "The expiry date of the ADR of the truck"
      },
      "LATE_TRUCK_INSURANCE": {
        "desc": "The notification sent X days before expiry of insurance",
        "label": "The expiry date of the insurance of the truck"
      },
      "LATE_TRUCK_LAST_REVIEW": {
        "desc": "The notification sent X days before expiry of last review",
        "label": "The expiry date of the last review of the truck"
      },
      "LATE_TRUCK_TACHOGRAPH": {
        "desc": "The notification sent X days before expiry of tachograph review",
        "label": "The expiry date of the tachograph review of the truck"
      },
      "TRANSPORT_READY_AFTER_CONFIRMATION": {
        "desc": "Notification about transport confirmation sent after transport confirmation set by forwarder.",
        "label": "Information about transport confirmation",
        "label.short": "Order confirmed"
      },
      "WASH_SMS": {
        "desc": "SMS to the driver with the car wash data",
        "label": "Car wash for the driver",
        "label.short": "Wash for driver",
        "unsent": "Unsent car wash to the driver"
      },
      "checkingStatusFailed": "Checking status at external service failed. Displayed status could be not consistent with reality.",
      "confirmation": "Are you sure you want to send it?",
      "dateCreated": "Date created",
      "dateSent": "Date handled",
      "errors": {
        "SmsServerMessageIdNotFound": "Unable to check notification status - no sms server message id",
        "TransportNotificationNotFound": "Notification with given id not found",
        "WrongNotificationType": "Wrong notification type - statuses are available only for sms notifications"
      },
      "label": "Notification",
      "label.EMAIL": "E-mail Notification",
      "label.SMS": "SMS Notification",
      "noReceivers": {
        "desc": "The addressees did not have a given email address / phone number, or they have indicated they did not want to receive this notification in their account settings.",
        "label": "The notification has not been sent"
      },
      "receivers": "Receivers",
      "roleGroups": {
        "desc": "Users with specified role group will receive specified notification. They can enable or disable it in their users' account settings.",
        "label": "Users' role groups"
      },
      "sender": "Sender",
      "sent": "Sent",
      "status": "Status:",
      "statusDesc": {
        "delivered": "Sms delivered to the recipient",
        "queued": "Sms received by external service",
        "sent": "Sms sent to the recipient"
      },
      "statusLabel": {
        "delivered": "Delivered",
        "in_progress": "In progress",
        "queued": "Queued",
        "saved": "Saved",
        "sent": "Sent",
        "undelivered": "Undelivered",
        "unsent": "Unsent"
      },
      "statusesHeader": "Statuses of notification no. {id}"
    },
    "notifications": {
      "label": "Notifications",
      "noNotificationsForRole": "There is no notifications available for selected role.",
      "selectRole": "Please, select a role first."
    },
    "order": {
      "history": "Order history"
    },
    "page": {
      "pageTitle": "Logistic platform"
    },
    "partnership": {
      "errors": {
        "contractorNumber": {
          "tooShort": "The number of the contractor should consist of at least three characters."
        },
        "subcontractorCode": {
          "blank": "Please enter subcontractor code"
        }
      }
    },
    "product": {
      "errors": {
        "symbol": {
          "blank": "Please, provide symbol"
        },
        "totalGrossTonnage": {
          "blank": "Please, provide total gross tonnage"
        }
      }
    },
    "resetPasswordWindow": {
      "enteredPasswordsIsNotIdentical": "Entered passwords must be identical.",
      "header": "Changing the password to the system",
      "info": "Reset your password is required by the policy of the system.",
      "info2": "The new password must be different from the previously entered password and should contain 8 characters (digits, small and capital letters)."
    },
    "resources": {
      "cannotChangeClientMessage": {
        "basic": "You cannot choose {company} as client due to invalid resource dates:",
        "hint": "Update resource dates and choose {company} as client again."
      },
      "dateRange": "Date range",
      "dateStatuses": {
        "INVALID": "invalid",
        "RED": "red",
        "VALID": "valid",
        "YELLOW": "yellow",
        "label": "status"
      },
      "hideInactiveDrivers": "Hide inactive drivers",
      "hideIntegratedDrivers": "Hide drivers of integrated carriers",
      "hideIntegratedTrucks": "Hide trucks of integrated carriers",
      "hideOutOfCommission": "Hide {vehicleType} out of commission",
      "of": {
        "CARRIER": "carrier",
        "CISTERN": "cistern",
        "CONTAINER": "container",
        "DRIVER": "driver",
        "TRAILER": "trailer",
        "TRUCK": "truck",
        "UNDERCARRIAGE": "undercarriage",
        "VEHICLE": "vehicle"
      },
      "showAll": "Show additionally out of commission {vehicleType}",
      "showAllDrivers": "Show additionally inactive drivers",
      "showIntegratedDrivers": "Show drivers of integrated carriers",
      "showIntegratedTrucks": "Show trucks of integrated carriers",
      "updateDateExceedIntervalWarning": {
        "basic": "Differences between previous and newly added dates exceeds set intervals:",
        "confirm": "Are you sure you want to save changes?",
        "details": "{controlledDateType} - difference exceeds interval {interval}"
      }
    },
    "roleGroup": {
      "ADMIN": {
        "desc": "User with all possible privileges. Has access to all tabs. Can create and edit users and companies.",
        "label": "Admin"
      },
      "CARRIER": {
        "desc": "Carrier. Can create an advice for many suppliers \\\\ recipients.",
        "label": "Carrier"
      },
      "CHOOSE_COMPANY": {
        "desc": "Choose company and role, please."
      },
      "CLIENT": {
        "desc": "Client. Can create orders.",
        "label": "Client"
      },
      "FORWARDER": {
        "desc": "Can create and edit deliveries, services and time windows.",
        "label": "Forwarder"
      },
      "GUARD": {
        "desc": "Can neither create nor edit deliveries and services.",
        "label": "Guard"
      },
      "MANAGER": {
        "desc": "Manager has the same authorities as forwarder, additionally he can approve and delete external cost order",
        "label": "Manager"
      },
      "MECHANIC": {
        "desc": "Can receive services only - without access to the system. ",
        "label": "Mechanic"
      },
      "OBSERVER": {
        "desc": "This user can only see deliveries.",
        "label": "Observer"
      },
      "SUPPLIER": {
        "desc": "Can create deliveries only for his own company. Do not see time windows of other suppliers \\\\ recipients.",
        "label": "Supplier \\\\ Recipient"
      },
      "TECHNICAL_EMPLOYEE": {
        "desc": "Can create and edit services",
        "label": "Service departmen worker"
      },
      "TRADESMAN": {
        "desc": "Tradesman.",
        "label": "Tradesman"
      },
      "WAREHOUSEMAN": {
        "desc": "This user can access deliveries and schedule, but can not create or update deliveries.",
        "label": "Warehouse"
      },
      "WASH": {
        "desc": "Wash.",
        "label": "Wash"
      },
      "label": "Role",
      "name": "Name",
      "placeholder": "Select a role",
      "selectCompany": "Select company"
    },
    "route": {
      "label": "Route"
    },
    "schedule": {
      "ADVICED": "Adviced",
      "ASSIGNED": "Assigned",
      "EXTERNAL": "External companies",
      "NOTADVICED": "Not adviced",
      "NOTASSIGNED": "Not assigned",
      "NOTPLANNED": "Not planned",
      "PLANNED": "Planned",
      "areYouSure": "Are you sure?",
      "cantPlaceAdvice": "Unfortunately you can't place this advice here. At the moment this truck is used in other transport",
      "wasDropped": " was dropped ",
      "wasResized": " was resized ",
      "week": "Week"
    },
    "service": {
      "arrival": "Arrival",
      "company": "Company",
      "contractorCompanyPlaceholder": "Company name",
      "costs": "Costs",
      "createTitle": "Plan service",
      "departure": "Departure",
      "description": "Description of service",
      "documents": "Documents",
      "errors": {
        "mileage": {
          "blank": "Please set mileage",
          "notAnInteger": "Mileage should be an integer"
        },
        "serviceType": {
          "blank": "Please set service type"
        },
        "workload": {
          "incorrectNumber": "Incorrect number"
        }
      },
      "finishedJobs": "Finished jobs",
      "history": "History of services",
      "id": "Service number",
      "internalWork": "Internal work",
      "mechanic": "Mechanic",
      "mileage": "Mileage [km]",
      "noJobs": "No work planned",
      "owner": "Owner",
      "registrationNumber": "Registration number",
      "requestedJobs": "Requested jobs",
      "selectCompany": "Select company",
      "selectDate": "Select date",
      "selectMechanic": "Select mechanic",
      "selectOwner": "Select owner",
      "selectType": "Select service type",
      "selectVehicle": "Select vehicle",
      "servicePlace": "Place of service",
      "serviceType": "Service type",
      "startDate": "Service start date",
      "statuses": "Statuses",
      "stopDate": "Service stop date",
      "technicalEmployee": "Service departmen worker",
      "title": "Service",
      "vehicle": "Vehicle",
      "workload": "Workload [h]"
    },
    "service-type": {
      "errors": {
        "name": {
          "blank": "Service type name is required"
        }
      }
    },
    "services": {
      "add": "Plan service",
      "label": "Services"
    },
    "settings": {
      "backToSettings": "Back to settings",
      "desc": {
        "ARCHIVING_AFTER_DELETE_JOB_INTERVAL": "How much time after removal we should transfer the transport to the archive.",
        "ARCHIVING_JOB_INTERVAL": "How long after the confirmation of the final status we should move the transport to the archive.",
        "AUTOMATIC_LOAD_MORE": "Automatic transports fetching after reaching end of table",
        "AVERAGE_TRUCK_SPEED_IN_KMPH": "Average truck speed (in km/h)",
        "CHECKPOINT_APPROVED_AFTER_SELECTING_CARRIER": "Checkpoint approved after selecting the carrier",
        "CHECKPOINT_CAN_BE_APPROVED_AFTER_SELECTING_MECHANIC": "Checkpoint approved after selecting a mechanic",
        "CHECKPOINT_CAN_BE_APPROVED_AFTER_SELECTING_SERVICE_PLACE": "Checkpoint can be approved after selecting service place",
        "CHECKPOINT_CAN_BE_APPROVED_AFTER_SELECTING_WASH": "Checkpoint which can be approved after selecting the wash",
        "CHECKPOINT_THAT_APPROVES_ORDER": "The name of the checkpoint after which the order will be confirmed and the mail will be sent to the client",
        "DAYS_TO_RESET_PASSWORD": "How often, in days, should user see a prompt to change a password.",
        "DEFAULT_WORKLOAD_TIME_IN_HOURS": "Default hourly workload",
        "DELETE_COST_IN_SAP_ERROR_EMAIL": "Email to send information about next failure during deleting cost in SAP",
        "DELETE_TRANSPORT_INTERVAL": "Up to what point in relation to the estimated date of unloading it should be possible to remove transport.",
        "DOCUMENTS_MONITORING_INTERVAL": "The size of the delay in adding the documents, after which the mail will be sent to the customer.",
        "EXTERNAL_COST_ORDERS_FEATURE_ENABLED": "Enabled external cost confirmation feature",
        "FILE_TERMS_OF_CONDITIONS_ACCEPTED": "Link to terms of service.",
        "INVITATION_EXPIRATION_INTERVAL": "The period of validity of the invitation.",
        "LOGO_ON_LOGIN_PAGE": "Link to the logo that is displayed on the login page",
        "MINIMAL_INTERMODAL_EXPECTED_MARGIN": "Minimal intermodal expected margin",
        "MINIMAL_INTERNATIONAL_EXPECTED_MARGIN": "Minimal international expected margin",
        "MINIMAL_NATIONAL_EXPECTED_MARGIN": "Minimal national expected margin",
        "NAME_TERMS_OF_CONDITIONS_ACCEPTED": "Name of service terms.",
        "POLL_INTERVAL": "Refresh rate of the table (in milliseconds)",
        "RESET_PASSWORD_IS_DISABLED": "Disable obligatory password reset.",
        "SAP_COMMUNICATION_ENABLED": "SAP communication enabled",
        "SCHEDULER_POLLING_FREQUENCY": "Refresh rate of the schedule (in milliseconds)",
        "SHOW_TERMS_OF_CONDITIONS_ACCEPTED": "A parameter specifying the visibility of the service acceptance terms in the advice form.",
        "STATUS_POLL_INTERVAL": "Frequency of polling for locator status (in minutes)",
        "WASHING_TIME_IN_HOURS": "Washing time (in hours)",
        "XLS_TOP_ROW_ENABLED": "Turn header for XLS reports."
      },
      "edit": "Edit settings",
      "emails": {
        "label": "Notifications",
        "lateDocuments": "delay in adding documents"
      },
      "label": "Settings",
      "serviceTypes": {
        "desc": "Service type",
        "label": "Edit service types",
        "tableHeaderLabel": "Service type"
      },
      "transportTypes": {
        "header": "Transport type settings",
        "label": "Transport types settings",
        "permissions": "Permissions",
        "permissionsSaveSuccess": "We successfully saved permissions settings.",
        "workflow": "Workflow"
      },
      "updateSuccessMessage": "Settings have been updated."
    },
    "statuses": {
      "deleted": "Deleted",
      "finished": "Completed",
      "inProgress": "In progress",
      "initial": "Advice",
      "planned": "Planned",
      "ready": "Ready",
      "toPlan": "To plan",
      "waitingForConfirmation": "Waiting for confirmation of"
    },
    "statute": {
      "accept": {
        "disabled.title": "Please check the acceptance statement.",
        "header": "Drogi użytkowniku",
        "inTouch": "I understand, we will be in touch.",
        "informed": "I understand, I feel informed.",
        "label": "I understand, I agree.",
        "manual": "I have read, understand and accept the Safety Instructions.",
        "paragraph_1": "1. LOGINTEGRA Spółka z ograniczoną odpowiedzialnością z siedzibą w Gdańsku, ul. Lęborska 3b, wpisaną do Rejestru Przedsiębiorców prowadzonego przez Sąd Rejonowy Gdańsk - Północ w Gdańsku, VII Wydział Gospodarczy Krajowego Rejestru Sądowego pod numerem KRS 0000426238, NIP 584-272-80-64, REGON 221707056 jest administratorem Twoich danych osobowych, o których mowa w RODO, znajdujących się w tym Systemie. Dane kontaktowe administratora są następujące: tel.: +48 58 535 78 06, e-mail: info@logintegra.com. Dane te mogą być udostępnione innym użytkownikom zaproszonym do Systemu lub tym, od których zaproszenie zaakceptowali Państwo, a także innym podmiotom opisanym w poniższej informacji. ",
        "paragraph_10": "10. W przypadku jakichkolwiek pytań, wątpliwości lub prośby dotyczące przetwarzania Twoich danych, najłatwiej kontaktować się z nami pisząc na adres e-mail: info@logintegra.com",
        "paragraph_2": "2. Twoje Dane osobowe będą przetwarzane, wyłącznie w celu wykonania umów zawartych pomiędzy Administratorem i innymi Użytkownikami, realizacji obowiązków i praw wiążących się z zawartymi umowami, realizacji poszczególnych funkcjonalności Systemu, oraz w celu realizacji obowiązków wynikających z przepisów prawa, a także w zakresie w jakim wyrazisz zgodę na przetwarzanie Twoich danych. ",
        "paragraph_3": "3. System, którego jesteś użytkownikiem służy do współpracy pomiędzy przedsiębiorstwami Użytkowników w ramach obsługi procesów logistycznych przedsiębiorstw Użytkowników, w związku z tym dane osobowe mogą być widoczne dla innych Użytkowników Systemu lub mogą być im wysyłane lub przekazywane. Twoje dane osobowe mogą również być przekazywane w innych przypadkach, przewidzianych przepisami prawa, w szczególności: ",
        "paragraph_3_1": "organom administracji skarbowej - w zakresie w jakim przekazanie danych osobowych stanowi obowiązek wynikający z przepisów podatkowych;",
        "paragraph_3_2": "organom administracji publicznej, w tym m.in. policji, sądom, prokuraturze, organom realizującym zadania z zakresu ubezpieczeń społecznych, w zakresie w jakim przekazanie danych osobowych stanowi obowiązek wynikający z przepisów prawa,",
        "paragraph_3_3": "zaufanym podwykonawcom, którzy wykonują w naszym imieniu obsługę pomocy technicznej lub świadczą usługi hostingowe;",
        "paragraph_3_4": "podmiotom świadczącym usługi księgowe, podatkowe oraz prawne",
        "paragraph_4": "4. Twoje dane osobowe będą przetwarzane przez okres niezbędny dla realizacji celów określonych w pkt 2 lub do czasu wycofania zgody. Niektóre kategorie danych mogą być przechowywane dłużej, jeśli obowiązek taki wynika z przepisów prawa, np. dane zawarte w wystawionych fakturach przechowywane będą przez administratora do czasu upływu ustawowych terminów przechowywania faktur dla celów podatkowych.",
        "paragraph_5": "5. Podanie przez Ciebie danych osobowych jest dobrowolne, ale korzystanie z systemu, z uwagi na jego specyfikę, wymaga podania pewnych kategorii danych. Jeśli nie podasz prawdziwych danych osobowych wymaganych w formularzu rejestracyjnym lub wycofasz zgodę na ich przetwarzanie, użytwanie Systemu nie będzie możliwe.",
        "paragraph_6": "6. Masz prawo do:",
        "paragraph_6_1": "dostępu do treści swoich danych;",
        "paragraph_6_2": "sprostowania swoich danych,",
        "paragraph_6_3": "usunięcia lub ograniczenia przetwarzania swoich danych,",
        "paragraph_6_4": "wniesienia sprzeciwu wobec przetwarzania swoich danych",
        "paragraph_6_5": "przenoszenia danych;",
        "paragraph_6_6": "cofnięcia zgody na przetwarzanie danych w dowolnym momencie, które pozostaje bez wpływu na zgodność z prawem przetwarzania, którego dokonano na podstawie zgody, przed jej cofnięciem.",
        "paragraph_6_bottom": "Ponadto masz również prawo do wniesienia skargi do organu nadzorczego, gdy uznasz, że przetwarzanie Twoich danych osobowych narusza przepisy prawa. ",
        "paragraph_7": "7. Szczegółowe kwestie związane z ochroną danych są regulowane w naszych dokumentach:",
        "paragraph_7_1": "Polityce przetwarzania danych;",
        "paragraph_7_2": "Instrukcji bezpieczeństwa,",
        "paragraph_7_3": "Regulaminie używania Systemu.",
        "paragraph_7_bottom": "Aby uzyskać dostęp do dokumentów, kliknij na ich nazwę.",
        "paragraph_8": "8. Nasz System używa plików cookies (ciasteczek).",
        "paragraph_9": "9. Pamiętaj, że w systemie przetwarzane są dane osobowe. Wprowadzając do systemu dane osób trzecich. np. kierowców musisz zadbać o spełnienie obowiązków ciążących na administratorze danych osobowych określonych w RODO. Obowiązki te obejmują między innymi poinformowanie osoby, której dane są przetwarzane o:",
        "paragraph_9_1": "tożsamości administratora i jego danych kontaktowych;",
        "paragraph_9_2": "celach przetwarzania danych osobowych, oraz podstawie prawnej przetwarzania;",
        "paragraph_9_3": "informacjach o odbiorcach danych osobowych lub o kategoriach odbiorców, jeżeli istnieją;",
        "paragraph_9_4": "okresie, przez który dane osobowe będą przechowywane;",
        "paragraph_9_5": "o prawie do żądania od administratora dostępu do danych osobowych dotyczących osoby, której dane dotyczą, ich sprostowania, usunięcia lub ograniczenia przetwarzania lub o prawie do wniesienia sprzeciwu wobec przetwarzania, a także o prawie do przenoszenia danych;",
        "paragraph_9_6": "jeżeli przetwarzanie odbywa się na podstawie zgody osoby - informacji o prawie do cofnięcia zgody w dowolnym momencie bez wpływu na zgodność z prawem przetwarzania, którego dokonano na podstawie zgody przed jej cofnięciem;",
        "paragraph_9_7": "prawie wniesienia skargi do organu nadzorczego;",
        "paragraph_9_8": "tym, czy podanie danych osobowych jest wymogiem ustawowym lub umownym lub warunkiem zawarcia umowy oraz czy osoba, której dane dotyczą, jest zobowiązana do ich podania i jakie są ewentualne konsekwencje niepodania danych.",
        "paragraph_9_bottom": "Pamiętaj, aby zadbać o spełnienie obowiązków administratora. Jeśli dowiesz się o możliwym naruszeniu zasad przetwarzania danych osobowych - niezwłocznie nas poinformuj.",
        "policy": "I have read, understand and accept the Data Processing Policy.",
        "statute": "I have read, understand and accept the Terms of Use of the System.",
        "subHeader": "W związku z tym, że wchodzą w życie przepisy rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie danych), opublikowanego w dzienniku urzędowym Unii Europejskiej seria L z 2016 r. Nr 119, str. 1. Dla zachowania przejrzystości treści będziemy to  rozporządzenie nazywać skrótowo RODO. Wykonując obowiązki nałożone przez RODO na administratorów i przetwarzających dane osobowe, chcielibyśmy poinformować Cię o kilku istotnych kwestiach z tym związanych. Prosimy o uważne zapoznanie się z poniższą informacją:"
      },
      "label": "Terms",
      "label.ablative": "Terms",
      "reject": {
        "button": "Cancel (this account will be blocked)",
        "confirmation": "Are you sure? Your account will be blocked."
      }
    },
    "step": {
      "errors": {
        "address": {
          "blank": "Please, provide an address"
        },
        "company": {
          "blank": "Please, provide a client"
        },
        "deliveryDateFrom": {
          "blank": "Please, provide the date of loading / unloading",
          "blankLeaveFrom": "Please set start date of the leave",
          "blankServiceFrom": "Please set a date for vehicle's service"
        },
        "deliveryDateTo": {
          "blankLeaveFrom": "Please set start date of the leave",
          "blankLeaveTo": "Please set end date of the leave",
          "blankServiceTo": "Please set a date to take vehicle from service",
          "wrongTimeWindow": "Incorrect time window: the end of loading/unloading must take place after loading/unloading has begun"
        },
        "distance": {
          "incorrectNumber": "Please provide correct distance"
        }
      },
      "exportClearance": "Export clearance",
      "from": "From:",
      "importClearance": "Import clearance",
      "load": {
        "date": "Load date"
      },
      "to": "To:",
      "unload": {
        "date": "Unload date"
      }
    },
    "table": {
      "errorInfo": "Something went wrong, check the integration tab",
      "errors": {
        "alreadyAssigned": "Cannot change resource assignment to the route.",
        "conflicts": "Conflicts occurred.",
        "generic": "An error occurred.",
        "routeStatus": "Cannot change route binding to resources due to route status.",
        "unexpectedException": "An error occurred while creating a route in the locator system. Try again in a few minutes or contact us at <a href='mailto:info@logintegra.com'>info@logintegra.com</a>."
      },
      "flAutomaticTimeShiftInfo": "Automatic transport rescheduling at AddSecure",
      "flTrackerId": "Request to AddSecure",
      "loadMore": "Load more data",
      "loading": "Loading",
      "select": "Select",
      "synchronizationInProgress": "Syncing with AddSecure... Please wait",
      "transports": {
        "showAll": "All",
        "showMine": "My transports"
      },
      "waitingForConnection": "Connecting now"
    },
    "tabs": {
      "companies": {
        "CARRIER": "Carriers",
        "CLIENT": "Clients",
        "SERVICE": "Service",
        "WASH": "Washes",
        "label": "Contractors"
      },
      "externalCostOrders": "Orders",
      "invitations": "Invitations",
      "order": "Order",
      "orders": {
        "archive": "Archive",
        "grouping": "Grouping",
        "label": "Orders",
        "list": "List"
      },
      "plan": "Plan",
      "resources": {
        "CONTAINER": "Containers",
        "DRIVER": "Drivers",
        "TRAILER": "Trailers",
        "TRUCK": "Trucks",
        "label": "Resources",
        "reviewSchedule": "Service/review schedule",
        "reviewScheduleArchive": "Archive of service/review schedule",
        "scheduleRepairsAndReviews": "Schedule of repairs / reviews"
      },
      "review": "Review",
      "schedule": "Schedule",
      "service-inspection": "Service/inspection",
      "services": "Schedule of reviews and repairs",
      "settings": "Settings",
      "users": "Users"
    },
    "task": {
      "beforeConfirmation": {
        "colorBeforeWas": "and its table row and time window has color",
        "stateBeforeWas": "Before confirmation of this stage, transport was in state"
      },
      "checkpointName": "Checkpoint name",
      "defaultStagePrefix": "Confirmed",
      "errors": {
        "checkpointName": {
          "blank": "Please, provide a checkpoint name."
        },
        "expectedDateIntervalModulo": {
          "incorrectNumber": "Incorrect number"
        },
        "expectedDateReference": {
          "blank": "Please, provide a reference"
        },
        "stepType": {
          "blank": "Please, provide a step type"
        }
      },
      "expectedDateMode": {
        "exact": "at",
        "onDay": "on the day of",
        "reference": "not later than"
      },
      "expectedDateReference": "Reference",
      "group": {
        "0": "Preparation",
        "1": "Loads and unloads",
        "2": "Ending"
      },
      "label": "Stage",
      "markIfWasDelayed": {
        "disabled": {
          "label": "Mark delay",
          "title": "Click the icon to receive a red frame after confirming this time after time."
        },
        "enabled": {
          "label": "Mark delay",
          "title": "In the case of confirmation of the stage after time, the time window will receive a red frame. Click the icon to change the settings."
        }
      },
      "reference": {
        "after": {
          "0": "",
          "earliestStepStart": "start of first load/unload",
          "executionDate": "service execution date",
          "latestStepStop": "end of first load/unload",
          "stepStart": "start of",
          "stepStop": "end of",
          "transportCreated": "transport creation"
        },
        "before": {
          "earliestStepStart": "start of first load/unload",
          "executionDate": "service execution date",
          "latestStepStop": "end of first load/unload",
          "stepStart": "start of",
          "stepStop": "end of",
          "transportCreated": "transport creation"
        }
      },
      "shouldBeConfirmedBy": "should be confirmed by",
      "sign": "Before/After",
      "stageName": "Stage name",
      "stepType": {
        "load": "load",
        "placeholder": "Action type",
        "service": "service",
        "unload": "unload"
      },
      "tracked": {
        "disabled": {
          "label": "",
          "title": "Expected time of confirmation of the stage will only be pictorial information - in the case of non-approval we do not change the color of the line or the window and we will not send notifications about the delay. Click on the icon to change the settings."
        },
        "enabled": {
          "label": "Supervision of punctuality",
          "title": "If not approved on time, the transport enters 'late' state - row in the table and the time window will change to red and will be send emails with information about the delay. Click on the icon to change the settings."
        }
      },
      "unit": "Unit"
    },
    "tasks": {
      "empty": "There is no tasks here."
    },
    "trailer": {
      "errors": {
        "axisType": {
          "tooShort": "The axis type should consist of at least three characters."
        },
        "capacity": {
          "blank": "Please, provide a capacity"
        },
        "flTrackerId": {
          "notANumber": "Number must contain only digits"
        },
        "model": {
          "tooShort": "The model should consist of at least three characters."
        },
        "owner": {
          "blank": "Please, select an owner"
        },
        "registrationNumber": {
          "blank": "Provide a registration number, please.",
          "tooLong": "The registration number should consist of maximum 40 characters",
          "tooShort": "The registration number should consist of at least three characters."
        }
      }
    },
    "transaction": {
      "errors": {
        "carrierCompany": {
          "blank": "Please choose carrier company"
        },
        "currency": {
          "blank": "Please, provide a currency"
        },
        "currencyOfFreight": {
          "blank": "Please, provide a currency"
        },
        "currencyOfWash": {
          "blank": "Please, provide a currency"
        },
        "distanceToTheWash": {
          "blank": "Please, provide a distance to the wash"
        },
        "kilometersWithLoad": {
          "blank": "Please, provide a kilometers with load"
        },
        "priceOfFreight": {
          "incorrectNumber": "Please, provide a correct price of freight:"
        },
        "priceOfService": {
          "incorrectNumber": "Please, provide a correct price of service"
        },
        "priceOfWash": {
          "incorrectNumber": "Please, provide a correct cost of washing"
        }
      },
      "profitMargin": "Profit margin"
    },
    "transport": {
      "addNewCompany": {
        "assignmentCompany": "You want to assign this company as a transport contractor?",
        "cooperatingCompanies": "You already cooperate with a company of a similar name",
        "emailInfo": "An invitation for cooperation will be sent at given e-mail address.",
        "emailRequired": "If you want to send an invitation to cooperate, you must provide an email address",
        "existingCompany": "We already have a company with selected Taxpayer Identification Number in our database",
        "existingCompanyAndPartnership": "The company with Taxpayer Identification Number {taxIdNumber} is already registered in the system and belongs to your colleagues",
        "label": "Add new company.",
        "sendInvitation": "Send a cooperate invitation",
        "wrongEmail": "Entered address email is not correct"
      },
      "additionalData": "Additional data",
      "additionalDataPlaceholder": "Additional comments visible on order document (pdf)",
      "advice": "Advice",
      "adviceFor": "Company name",
      "arrivalDate": "Arrival date",
      "bigQuantityWarning": "Are you sure, you want to make advice for more than one trailer?",
      "canNotEditAfterProcessStarts": "You can only edit data of an advice you created and before the arrival of a driver.",
      "canNotEditBecauseOfOtherContractors": "You can not edit data of an advice with multiple contractors.",
      "carRegistrationNumber": "Car registration number",
      "carrier": {
        "chooseCarrier": "Choose carrier",
        "kilometersWithLoad": "Kilometers with load",
        "label": "Carrier",
        "pleaseChooseCarrier": "Please choose a carrier",
        "priceLowerThanMinimalMargin": "Set profit margin is lower than minimal permissible profit, equal to {minimalProfitMargin} {currencySymbol}",
        "priceLowerThanMinimalMarginAccept": "Do you want to create service with set price?",
        "priceOfFreight": "Freight"
      },
      "carrierCompany": "Carrier",
      "changeCarrierConfirm": "Are you sure you want to change carrier assigned to the transport? Due to changing carrier in already advised transport irreversible changes will occur:\n- advice data will be cleared\nand after saving form:\n- all external cost orders will be deleted,\n- all data related to notifications in Notification section will be removed,\n- chosen wash will be removed (if exists),\n- checkpoint 'wash' will be removed (if exists).\nNote: in case of external cost orders confirmed in SAP there will be necessity to remove them in SAP.",
      "checkpoints": {
        "confirmed": "Confirmed",
        "label": "Statuses",
        "planConfirm": "Planned date of confirmation",
        "realConfirm": "Real date of confirmation",
        "status": "Status"
      },
      "chooseForwarder": "Choose forwarder",
      "client": {
        "confirm": "Confirm",
        "label": "Client"
      },
      "clientCompany": "Client",
      "confirmBefore": "Confirm order before select",
      "contractors": {
        "add": {
          "title": "Add another contractor"
        },
        "delete": {
          "title": "Delete contractor from this transport"
        }
      },
      "createTitle": "Plan delivery",
      "createdBy": "Created by",
      "createdMessage": "Created {date} by {person} ({company})",
      "currency": "Currency",
      "dateOfAdvice": "Date of advice",
      "dateOfArchiving": "Date of archiving",
      "dateOfCollection": "Data of collection",
      "dateOfDeletion": "Date of deletion",
      "deleteTransport": "Are you sure you want to delete this transport?",
      "description": "Notes",
      "details": "Details of the delivery",
      "documentNumber": "Driver's document number",
      "documents": {
        "empty": "No documents have been added to this delivery yet.",
        "header": "Documents for transport {identifier}",
        "label": "Documents"
      },
      "downloadAsPDF": "Download the order document in PDF format",
      "driverIdentifier": "Driver's id",
      "driverLanguage": "Driver language",
      "driverMobilePhoneNumber": "Mobile phone number",
      "driverName": "Surname and name of driver",
      "earliestStepStart": "Load date",
      "erpIdentifier": "Client's order number",
      "errors": {
        "clientCompany": {
          "blank": "Please provide a client"
        },
        "contractors": {
          "notUnique": "Please provide a unique list of contractors"
        },
        "dateOfAdvice": {
          "blank": "Please, provide a date of advice",
          "isInPast": "You can not schedule deliveries in the past"
        },
        "erpIdentifier": {
          "blank": "Please, provide a client's order number"
        },
        "forwarder": {
          "blank": "Please choose forwarder"
        },
        "requiredCarType": {
          "blank": "Please provide a required car type"
        },
        "summaryQuantity": {
          "nonPositiveInteger": "Number of pallets must be integer greater than 0"
        },
        "summaryQuantityBoxes": {
          "nonPositiveInteger": "Number of boxes must be integer greater than 0",
          "summaryQuantityBlank": "Estimate how many pallets you will need to send the boxes and enter the amount - minimum 1 pallet"
        },
        "wash": {
          "blank": "Please choose a wash"
        }
      },
      "filterDate": {
        "max": {
          "placeholder": {
            "executionDate": "Max. execution date",
            "transportDate": "Max. date"
          },
          "title": {
            "executionDate": "Select maximum execution date.",
            "transportDate": "Select maximum date."
          }
        },
        "min": {
          "placeholder": {
            "executionDate": "Min. execution date",
            "transportDate": "Min. date"
          },
          "title": {
            "executionDate": "Select minimal execution date.",
            "transportDate": "Select minimal date."
          }
        },
        "mode": {
          "LOAD_DATE": "Load",
          "UNLOAD_DATE": "Unload"
        }
      },
      "forwarder": "Forwarder",
      "group": "Group",
      "grouping": {
        "INTERMODAL": "Transport of tankcontainers",
        "INTERNATIONAL": "Transport outside Poland",
        "NATIONAL": "Transport in Poland ",
        "assignedRoutes": "Assigned routes:",
        "dropHere": "Drop a route here...",
        "forwarders": "Forwarders:",
        "forwardersGroup": "Forwarders groups",
        "notAssignedRoutes": "Not assigned routes",
        "responsibleFor": "Responsible for:"
      },
      "integration": {
        "controllingNumber": "Controlling number",
        "dateCreated": "Created at",
        "flOrderNumber": "Route number",
        "label": "Integration",
        "message": "Message",
        "messages": {
          "ASSIGNED_RESOURCE_OCCUPANCY": "Route has been assigned to resources",
          "AUTOMATIC_TIME_SHIFT": "Automatic transport rescheduling",
          "CREATED_CONTROLLING_ORDER": "Controlling number was created",
          "CREATED_FRAMELOGIC_ROUTE": "Route has been created",
          "UPDATED_CONTROLLING_ORDER": "Controlling order was updated",
          "UPDATED_FRAMELOGIC_ROUTE": "Route has been edited"
        },
        "responseValue": "Response"
      },
      "latestStepStop": "Unload date",
      "load": "Load",
      "load-address": "Address (load)",
      "load-company": "Company (load)",
      "load-deliveryDateFrom": "Date \"from\" (load)",
      "load-deliveryDateTo": "Date \"to\" (load)",
      "load-distance": "Distance (load)",
      "load-documentIdentifier": "Document identifier (load)",
      "load-exportClearance": "Export clearance (load)",
      "load-importClearance": "Import clearance (load)",
      "load-quantity": "Quantity (load)",
      "load-volume": "Volume (load)",
      "load-weight": "Weight (load)",
      "logs": {
        "label": "History of changes"
      },
      "noFilesForOldBrowsers": "You can not add files in this view with Your browser. Please add file in table if necessary.",
      "otherRequirements": "Other requirements",
      "packs": {
        "boxes": "Boxes",
        "label": "Package",
        "pallets": "Pallets"
      },
      "personsGroup": "Group",
      "plan": "Plan",
      "priceOfService": "Price of the service",
      "products": {
        "label": "Cargo",
        "name": "Name",
        "symbol": "Symbol",
        "totalGrossTonnage": "Total gross tonnage",
        "totalNetTonnage": "Total net tonnage"
      },
      "quantity": "Quantity of load units",
      "remoteIdHeader": "Id in the customer's system",
      "requiredCarType": "Required car type",
      "saveTransportConfirm": "You have changed the loading or unloading. Are you sure you want to save the transport with the specified freight / kilometers with load?",
      "selectCountry": "Country",
      "selectSupplier": "Select supplier \\\\ recipient",
      "selectTransportType": "Select supply type",
      "service-address": "Address (service)",
      "service-company": "Company (service)",
      "service-deliveryDateFrom": "Date \"from\" (service)",
      "service-deliveryDateTo": "Date \"to\" (service)",
      "service-distance": "Distance (service)",
      "service-documentIdentifier": "Document identifier (service)",
      "service-exportClearance": "Export clearance (service)",
      "service-importClearance": "Import clearance (service)",
      "service-quantity": "Quantity (service)",
      "service-volume": "Volume (service)",
      "service-weight": "Weight (service)",
      "stoNumberHeader": "STO Number",
      "syncCheckpoints": "Perform synchronization of checkpoints with the remote {remoteId} order on the PCC Platform",
      "syncWithRemote": "Perform synchronization with the {remoteId} order on the PCC Platform",
      "termsOfConditionsAccepted": "I accept the ",
      "title": "Transport {ident}",
      "tradesman": {
        "label": "Tradesman"
      },
      "trailerRegistrationNumber": "Trailer registration number",
      "type": {
        "DELIVERY": "Delivery for PCC",
        "EXTERNAL_COST_ORDER": "External cost order",
        "EXTERNAL_DELIVERY": "External delivery for PCC",
        "EXTERNAL_MANUAL_TRANSPORT": "External transport",
        "EXTERNAL_TRANSPORT_ORDER": "External transport for PCC",
        "LEAVE": "Leave",
        "MANUAL_TRANSPORT": "Transport",
        "SERVICE": "Service/Review",
        "TRANSPORT": "Transport for PCC",
        "TRANSPORT_ORDER": "Order transport"
      },
      "unload": "Unload",
      "unload-address": "Address (unload)",
      "unload-company": "Company (unload)",
      "unload-deliveryDateFrom": "Date \"from\" (unload)",
      "unload-deliveryDateTo": "Date \"to\" (unload)",
      "unload-distance": "Distance (unload)",
      "unload-documentIdentifier": "Document identifier (unload)",
      "unload-exportClearance": "Export clearance (unload)",
      "unload-importClearance": "Import clearance (unload)",
      "unload-quantity": "Quantity (unload)",
      "unload-volume": "Volume (unload)",
      "unload-weight": "Weight (unload)",
      "volume": "Volume",
      "wash": {
        "chooseWash": "Choose wash",
        "choosingWashFailedAlert": "Choosing wash failed. Please try again and if the problem persists contact technical support.",
        "costOfWashing": "Cost of washing",
        "distanceInKm": "Distance in km",
        "label": "Wash"
      },
      "wash-address": "Address (wash)",
      "wash-company": "Company (wash)",
      "wash-deliveryDateFrom": "Date \"from\" (wash)",
      "wash-deliveryDateTo": "Date \"to\" (wash)",
      "wash-distance": "Distance (wash)",
      "wash-documentIdentifier": "Document identifier (wash)",
      "wash-exportClearance": "Export clearance (wash)",
      "wash-importClearance": "Import clearance (wash)",
      "wash-quantity": "Quantity (wash)",
      "wash-volume": "Volume (wash)",
      "wash-weight": "Weight (wash)",
      "weight": "Weight",
      "wzDocument": "WZ doc. numbers"
    },
    "truck": {
      "errors": {
        "carTypeGroup": {
          "blank": "Select transport vehicle type, please"
        },
        "euroStandard": {
          "blank": "Please, provide a euro standard"
        },
        "flTrackerId": {
          "notANumber": "Number must contain only digits"
        },
        "group": {
          "chooseGroup": "Provide a group, please"
        },
        "model": {
          "tooShort": "The model should consist of at least three characters."
        },
        "owner": {
          "blank": "Please, select an owner"
        },
        "registrationNumber": {
          "blank": "Provide a registration number, please.",
          "tooLong": "The registration number should consist of maximum 20 characters",
          "tooShort": "The registration number should consist of at least three characters."
        },
        "yearOfProduction": {
          "notANumber": "The year of production should consist only of numbers from the 1920-current year"
        }
      }
    },
    "user": {
      "accountCreatedBy": "Account created by",
      "activeNotifications": "Active notifications",
      "createUser": "Create user",
      "deleteConfirm": "Are you sure you want to delete this user?",
      "deletedMessage": "Deleted on {date} by {person} from the {company} company.",
      "editUser": "Edit user",
      "email": "Email",
      "groupName": "Group",
      "invitedBy": "Invited: {date} by {person}.",
      "list": "List of users",
      "mobilePhoneNumber": "Phone",
      "myAccount": "My account",
      "name": "Name and surname",
      "noActiveNotifications": "No active notifications",
      "roleDescription": "Description of role",
      "sapLogin": "SAP login",
      "selectRole": "Select role",
      "username": "Login"
    },
    "users": {
      "label": "Users"
    },
    "vehicle": {
      "add": {
        "CISTERN": "Add cistern",
        "UNDERCARRIAGE": "Add undercarriage"
      },
      "associatedContainer": "Container",
      "axisType": "Axis type",
      "bulwarks": "Bulwarks",
      "capacity": "Capacity",
      "carType": "Car type",
      "carTypeGroup": "Car type group",
      "card": {
        "CONTAINER": "Container card",
        "TRAILER": "Trailer card",
        "TRUCK": "Truck card"
      },
      "closestReview": "Closest service/review",
      "containerNumber": "Container number",
      "containersTrailer": "Trailer",
      "controllingNumber": "Controlling number",
      "costObject": "Cost object",
      "create": {
        "CISTERN": "Create cistern",
        "CONTAINER": "Create container",
        "TRUCK": "Create truck",
        "UNDERCARRIAGE": "Create undercarriage"
      },
      "deleteConfirm": "Are you sure, you want to delete this vehicle?",
      "deletedMessage": "We successfully deleted vehicle {vehicleRegistrationNumber}.",
      "description": "Description",
      "downAirConnection": "Down air connection",
      "driver": "Driver",
      "euroStandard": "Euro standard",
      "flTrackerId": "ID Number (tracker)",
      "group": "Group",
      "label": {
        "CARRIER": "Carrier",
        "CONTAINER": "Container",
        "TRAILER": "Trailer",
        "TRUCK": "Truck"
      },
      "model": "Model",
      "mpk": "MPK",
      "numberOfCompartments": "Number of compartments",
      "numberOfManholes": "Number of manholes",
      "outOfCommission": "Out of commission",
      "outOfCommissionHint": "Vehicle is out of commission",
      "owner": "Owner",
      "plural": {
        "CISTERN": "cisterns",
        "CONTAINER": "containers",
        "TRAILER": "trailers",
        "TRUCK": "trucks",
        "UNDERCARRIAGE": "undercarriages",
        "VEHICLE": "vehicles"
      },
      "registrationNumber": "Registration number",
      "route": "Route",
      "savedMessage": "We successfully saved vehicle {vehicleRegistrationNumber}.",
      "scheduleReview": "Schedule",
      "sideNumber": "Side number",
      "trailer": "Trailer",
      "trailerType": {
        "CISTERN": "Cistern",
        "UNDERCARRIAGE": "Undercarriage",
        "label": "Type"
      },
      "truck": "Truck",
      "validityDates": "Validity dates",
      "valveType": "Valve type",
      "yearOfProduction": "Year of production"
    },
    "warehouse": {
      "reserveFreePlace": "Reserve free place in the schedule on {initialStart}"
    },
    "washingStatus": {
      "warning": "The trailer has an unconfirmed washing status in at least two jobs. Before choosing a trailer, there may be a maximum of one order without a confirmed washing status for notification."
    },
    "workflows": {
      "addTask": "Add task",
      "label": "Workflow",
      "saveSuccessMessage": "We successfully saved workflow.",
      "updateDisclaimer": "Note: changes in time will only apply to NEW transports."
    },
    "wrongUrl": "No access or page you requested does not exist",
    "xls": {
      "mode": {
        "ROUTES": {
          "desc": "The report contains routes performed by drivers. Orders for which there is at least one unloading, the actual date of confirmation or the planned date of unloading (if there is no confirmed stage) are within the given time range, are taken into account.",
          "disabled": "The report of routes is available only in the case of filtering orders by the date of unloading.",
          "label": "Report of completed routes"
        },
        "TRANSPORTS": {
          "desc": "The orders report contains the details of the selected orders.",
          "label": "Orders report"
        },
        "select": "Select file version"
      },
      "title": "Excel parameters"
    }
  };
  _exports.default = _default;
});