define("apollo/pods/components/transports/transports-table/component", ["exports", "jquery", "apollo/config/environment", "apollo/mixins/persistence-mixin"], function (_exports, _jquery, _environment, _persistenceMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_persistenceMixin.default, {
    store: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    session: Ember.inject.service('session'),
    cookies: Ember.inject.service(),
    archive: null,
    withFinished: null,
    tempmax: 0,
    interval: 5,
    dataLoaded: false,
    searchParams: {},
    errors: null,
    page: 1,
    totalPages: 0,
    loadingMore: false,
    highlightedTransportId: null,
    transportTypes: Ember.computed({
      get: function get() {
        if (this._transportTypes) {
          return this._transportTypes;
        }

        return [];
      },
      set: function set(key, value) {
        return this._transportTypes = value;
      }
    }),
    transports: Ember.computed({
      get: function get() {
        if (this._transports) {
          return this._transports;
        }

        return [];
      },
      set: function set(key, value) {
        return this._transports = value;
      }
    }),
    transportsSortingObserver: Ember.observer('transports.@each.lastUpdated', function () {
      var sortFunction = this.get('sortFunction') ? this.get('sortFunction') : this.get('defaultSortFunction');

      if (!this.get('archive')) {
        this.get('transports').sort(sortFunction);
      }
    }),
    defaultSortFunction: function defaultSortFunction(a, b) {
      a = a.get('steps.firstObject.deliveryDateFrom');
      b = b.get('steps.firstObject.deliveryDateFrom');
      return a < b ? -1 : a > b ? 1 : 0;
    },
    hasMore: Ember.computed('page', 'totalPages', function () {
      return this.get('page') < this.get('totalPages');
    }),
    tableColumnNames: Ember.computed('transportTypes.@each.id', function () {
      var names = [];
      var transportTypes = this.get('transportTypes');

      if (!this.get('transportTypes')) {
        return names;
      }

      transportTypes.forEach(function (type) {
        type.get('tableColumns').forEach(function (column) {
          if (!names.isAny('name', column.get('name'))) {
            names.push(column.get('name'));
          }
        });
      });
      return names;
    }),
    pollRequested: Ember.observer('transportTypes.@each.forcedPollRequestTime', function () {
      var self = this;
      Ember.run.once(this, function () {
        if (self.get('transportTypes').findBy('forcedPollRequestTime')) {
          console.debug("Poll requested..");
          self.onPoll(false);
        }

        self.get('transportTypes').forEach(function (tt) {
          tt.set('forcedPollRequestTime', null);
        });
      });
    }),
    queryParams: Ember.computed('archive', 'withFinished', 'transportTypes.@each', 'filterMode', 'searchParams.groupId', 'searchParams.query', 'searchParams.max', 'searchParams.dateFilterMode', 'searchParams.filterMode', 'searchParams.start', 'searchParams.stop', 'page', 'searchParams.filters.@each.filterValue', function () {
      var params = {
        query: this.get('searchParams.query'),
        archived: this.get('archive'),
        withFinished: this.get('withFinished'),
        principalId: this.get('cookies').read('principalId'),
        page: this.get('page'),
        start: this.get('searchParams.start') ? moment(this.get('searchParams.start')).format() : '',
        stop: this.get('searchParams.stop') ? moment(this.get('searchParams.stop')).format() : '',
        dateFilterMode: this.get('searchParams.dateFilterMode'),
        filters: this.get('searchParams.filters'),
        carrierId: this.get('searchParams.carrierId'),
        clientId: this.get('searchParams.clientId'),
        washId: this.get('searchParams.washId'),
        truckId: this.get('searchParams.truckId'),
        trailerId: this.get('searchParams.trailerId'),
        containerId: this.get('searchParams.containerId'),
        driverId: this.get('searchParams.driverId'),
        transportTypesIds: this.get('searchParams.transportTypesIds'),
        groupId: this.get('searchParams.groupId'),
        max: this.get('searchParams.max'),
        assetType: this.get('searchParams.assetType')
      };
      var currentUser = this.get('sessionAccount').get('currentUser');

      if (currentUser.get('roleGroup.name') === 'TRADESMAN') {
        params.tradesmanId = currentUser.id;
        params.filterMode = this.get('searchParams.filterMode');
      }

      if (currentUser.get('roleGroup.name') === 'FORWARDER') {
        params.forwarderId = currentUser.id;
        params.filterMode = this.get('searchParams.filterMode');
      }

      this.notifyPropertyChange('tempmax');
      return params;
    }),
    transportsProxy: Ember.computed('tempmax', 'deliveryDateFromChange', 'queryParams', 'dataLoaded', 'transports.[]', function () {
      if (this.get('transports')) {
        return this.get('transports').slice(0, this.get('tempmax'));
      }
    }),
    //zmianna zmienia wartość po zmianie daty załadunku w stepsie i wywołuje transportsProxy
    deliveryDateFromChange: Ember.computed(function () {
      return false;
    }),
    setDeliveryDateFromChange: Ember.observer('steps.@each.deliveryDateFrom', function () {
      var self = this;
      self.set('deliveryDateFromChange', !self.set('deliveryDateFromChange'));
    }),
    initializeTransports: Ember.on('init', Ember.observer('transportTypes.@each', function () {
      var self = this;
      var searchParams = this.get('searchParams');
      self.setProperties({
        page: 1,
        'searchParams.max': 30
      });
      var list = self.get('transportsTabVisited') ? self.get('store').peekAll('transport').filter(function (t) {
        return (t.get('archived') === self.get('archive') && !t.get('deleted') || self.get('archive') && t.get('deleted')) && t.get('personsGroup.id') && self.get('transportTypes') && self.get('transportTypes').filterBy('id', t.get('transportType.id')).length > 0;
      }) : [];

      if (!self.get('alwaysReloadTransports')) {
        self.set('transportsTabVisited', true);
      }

      if ((searchParams.tradesmanId === null || searchParams.tradesmanId === undefined) && list.length > 0 && !self.get('searchParams.filters').length && (searchParams.query === null || searchParams.query === '') && !searchParams.filterMode) {
        self.set('transports', list);
        this.onPoll(false);
      } else {
        self.get('transports').clear();
        self.set('dataLoaded', false);
        self.searchTransports();
      }
    })),
    loadingInProgress: Ember.computed({
      get: function get() {
        if (this._loadingInProgress) {
          return this._loadingInProgress;
        }

        return false;
      },
      set: function set(key, value) {
        return this._loadingInProgress = value;
      }
    }),
    queryInputChanged: Ember.observer('searchParams.filters.@each.filterValue', 'searchParams.query', 'searchParams.start', 'searchParams.stop', 'searchParams.dateFilterMode', 'searchParams.filterMode', 'searchParams.groupId', function () {
      Ember.run.debounce(this, this.applyFilter, 500);
    }),
    applyFilter: function applyFilter() {
      this.setProperties({
        page: 1,
        transports: [],
        dataLoaded: false,
        tempmax: 0
      });
    },
    searchTransports: Ember.observer('dataLoaded', function () {
      if (this.get('dataLoaded') === true) {
        return;
      }

      var self = this;
      this.set('loadingInProgress', true);
      var timestamp = new Date().getTime();
      this.set('lastTransportRequest', timestamp);
      var queryParams = Object.assign({}, this.get('queryParams'));
      queryParams.timestamp = timestamp;
      var token = this.get('session.data.authenticated.token');

      _jquery.default.ajax({
        url: _environment.default.serverURL + '/transports/index',
        data: JSON.stringify(queryParams),
        type: 'POST',
        contentType: 'application/json',
        beforeSend: function beforeSend(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        }
      }).then(function (response) {
        if (!self.isDestroyed) {
          if (Number(response.meta.timestamp) < self.get('lastTransportRequest')) {
            console.log("Istnieje bardziej aktualne zapytanie do serwera, przerywamy..");
            return;
          }

          var totalPages = response.meta.totalPages;
          self.set('totalPages', totalPages);
          var count = response.meta.count;
          self.set('transportsCount', count);
          delete response.meta;
          self.get('store').pushPayload(response);

          if (response.transports.length !== 0) {
            response.transports.forEach(function (t) {
              var transport = self.get('store').peekRecord('transport', t.id);

              if (self.get('transportTypes').filterBy('id', transport.get('transportType.id')).length > 0) {
                self.get('transports').pushObject(transport);
              }
            });
            self.set('tempmax', self.get('tempmax') + self.get('interval'));
            self.notifyPropertyChange('tempmax');
          }

          self.set('loadingInProgress', false);
          self.set('loadingMore', false);
        }
      });
    }),
    didRender: function didRender() {
      var self = this;
      Ember.run.later(function () {
        if (!self.isDestroyed) {
          var interval = self.get('interval');

          if (self.get('tempmax') < self.get('transports.length')) {
            console.debug('loading');
            self.set('tempmax', self.get('tempmax') + interval);
          } else if (self.get('transports.length') > 0 && self.get('tempmax') >= self.get('transports.length')) {
            console.debug("Transports loading finished.");
            self.setProperties({
              dataLoaded: true,
              tempmax: self.get('transports.length')
            });
            self.resizeTableHeader();
          } else if (self.get('transports.length') > 0) {
            console.debug('Trwa ładowanie transportów, pozostało ' + self.get('transports.length'));
          } else if (self.get('transports.length') === 0) {
            self.set('dataLoaded', true);
          }
        }
      });
      var errorElement = document.querySelector('.alert-danger');

      if (errorElement) {
        errorElement.scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        });
      }
    },
    switchOffHighlight: function switchOffHighlight() {
      var highlightedTransportId = this.get('highlightedTransportId');

      if (highlightedTransportId) {
        var highlightedTransport = this.get('store').peekRecord('transport', highlightedTransportId);
        highlightedTransport.set('isHighlighted', false);
      }

      this.set('highlightedTransportId', null);
    },
    didDestroyElement: function didDestroyElement() {
      this.set('transports', null);
      this.switchOffHighlight();
    },
    initResizeObserver: Ember.on('init', function () {
      var self = this;
      (0, _jquery.default)(window).resize(function () {
        self.resizeTableHeader();
      });
    }),
    action: '',
    actions: {
      showModal: function showModal(template, transport) {
        this.showModal(template, transport);
      },
      confirm: function confirm(checkpoint) {
        this.confirm(checkpoint);
      },
      revoke: function revoke(checkpoint) {
        this.revoke(checkpoint);
      },
      markNotesAsReadOff: function markNotesAsReadOff(transport) {
        this.markNotesAsReadOff(transport);
      },
      addNote: function addNote(noteContent, transportId) {
        this.addNote(noteContent, transportId);
      },
      deleteFile: function deleteFile(file) {
        this.deleteFile(file);
      },
      fileUploadSuccess: function fileUploadSuccess(transportId) {
        this.fileUploadSuccess(transportId);
      },
      loadMore: function loadMore() {
        if (this.isDestroyed) {
          return;
        }

        if (this.get('hasMore') === false) {
          console.debug('nie ma więcej danych do załadowania');
        } else if (this.get('dataLoaded') === false) {
          console.debug('nie rozpoczynamy pobieranie nowych danych w trakcie wczytywaia');
        } else {
          this.setProperties({
            page: this.get('page') + 1,
            loadingMore: true,
            dataLoaded: false
          });
        }
      },
      updateHighlightedRow: function updateHighlightedRow(transportId) {
        var highlightedTransportId = this.get('highlightedTransportId');

        if (highlightedTransportId === transportId) {
          return;
        }

        this.switchOffHighlight();
        this.set('highlightedTransportId', transportId);
      },
      handleErrors: function handleErrors(errors) {
        this.set('errors', errors);
      },
      closeAlert: function closeAlert() {
        this.set('errors', null);
      }
    },
    onPoll: function onPoll() {
      var planNextRequest = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
      var self = this;

      try {
        var lastPollFormatted = moment(this.get('lastPoll')).format();
        var queryParams = {
          transportTypesIds: this.get('queryParams.transportTypesIds'),
          lastPoll: lastPollFormatted
        };
        var token = this.get('session.data.authenticated.token');

        _jquery.default.ajax({
          url: _environment.default.serverURL + '/transports/updates',
          data: queryParams,
          type: 'GET',
          beforeSend: function beforeSend(xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
          },
          success: function success(json) {
            if (self.isDestroyed || !json.transportIds.length) {
              return;
            }

            var transportIds = json.transportIds;
            var queryParams = Object.assign({}, self.get('queryParams'));
            queryParams.ids = transportIds;
            queryParams.page = 1;

            _jquery.default.ajax({
              url: _environment.default.serverURL + '/transports/index',
              data: JSON.stringify(queryParams),
              contentType: 'application/json; charset=utf-8',
              dataType: 'json',
              type: 'POST',
              beforeSend: function beforeSend(xhr) {
                xhr.setRequestHeader('Authorization', 'Bearer ' + token);
              }
            }).then(function (response) {
              delete response.meta;
              self.get('store').pushPayload(response);

              if (response.transports.length !== 0) {
                response.transports.forEach(function (t) {
                  var transport = self.get('store').peekRecord('transport', t.id);

                  if (self.get('transportTypes').filterBy('id', transport.get('transportType.id')).length > 0) {
                    self.refreshTransportInTable(transport);
                  }
                });
                self.set('tempmax', self.get('tempmax') + self.get('interval'));
                self.notifyPropertyChange('tempmax');
              }

              transportIds.filter(function (id) {
                return !response.transports.getEach("id").includes(id);
              }).forEach(function (id) {
                console.log("Usuwamy z tabeli transport ".concat(id, ".."));
                var transportToRemove = self.get('store').peekRecord('transport', id);

                if (transportToRemove) {
                  var transportWasVisible = self.get('transports').getEach('id').includes(transportToRemove.get('id'));
                  self.get('transports').removeObject(transportToRemove);

                  if (transportWasVisible) {
                    self.decrementProperty('transportsCount');
                  }
                }
              });
            });

            self.set('lastPoll', json.pollTime);
          },
          complete: function complete() {
            if (planNextRequest && !self.isDestroyed) {
              setTimeout(function () {
                return self.onPoll();
              }, self.get('pollInterval'));
            }
          }
        });
      } catch (e) {
        console.error(e);
      }
    },
    refreshTransportInTable: function refreshTransportInTable(loadedTransport) {
      if (this.get('transports').includes(loadedTransport)) {
        this.refreshTransportData(loadedTransport);
      } else if (loadedTransport.get('archived') === false && this.get('archive') !== true) {
        this.addTransportToTable(loadedTransport);
      }
    },
    refreshTransportData: function refreshTransportData(loadedTransport) {
      var transportId = loadedTransport.get('id');
      console.debug("Unloading checkpoints from transport ".concat(transportId, ".."));
      this.unloadDeletedRecords('transport', transportId, 'checkpoint');
      loadedTransport.notifyPropertyChange('lastUpdated');

      if (loadedTransport.get('archived')) {
        var transportWasVisible = self.get('transports').getEach('id').includes(loadedTransport.get('id'));
        this.get('transports').removeObject(loadedTransport);

        if (transportWasVisible) {
          this.decrementProperty('transportsCount');
        }
      }
    },
    addTransportToTable: function addTransportToTable(loadedTransport) {
      if (this.get('searchParams.filterMode') === "user" && loadedTransport.get('forwarder.id') !== this.get('sessionAccount.currentUser.id') || this.get('searchParams.filterMode') === "group" && loadedTransport.get('forwarder.userGroup.id') !== this.get('sessionAccount.currentUser.userGroup.id')) {
        console.log("W tabeli jest aktywny filtr po grupie lub kupcu - nie dodajemy transportu ".concat(loadedTransport.get('logString'), "."));
        return;
      }

      console.log("Dodajemy do tabeli transport ".concat(loadedTransport.get('logString'), ".."));
      this.get('transports').addObject(loadedTransport);
      this.incrementProperty('transportsCount');
    },
    handleError: function handleError(response, transportId) {
      var status = response.errors ? response.errors[0].status : undefined;

      if (status !== '403' && status !== '401') {
        console.log("Otrzymali\u015Bmy status ".concat(status, " przy pr\xF3bie od\u015Bwie\u017Cenia danych transportu ").concat(transportId, "."));
        console.error(response.errors);
      }

      var transportToRemove = this.get('transports').findBy('id', transportId.toString());

      if (transportToRemove) {
        this.get('transports').removeObject(transportToRemove);
        this.decrementProperty('transportsCount');
      }
    },
    resizeTableHeader: function resizeTableHeader() {
      var self = this;
      Ember.run.later(this, function () {
        if (self.isDestroyed) {
          return;
        }

        var cells = document.getElementsByClassName('transport-header-cell');

        for (var j = 0; j < cells.length; j++) {
          var name = cells[j].dataset.columnName;

          if (!name) {
            continue;
          }

          var el = document.getElementById("transport-column-".concat(name));

          if (!el) {
            continue;
          }

          var w = el.offsetWidth;
          var elements = document.querySelectorAll("[data-column-name=\"".concat(name, "\"]"));

          for (var i = 0; i < elements.length; i++) {
            elements[i].style.width = w + 'px';
          }
        }
      });
    },
    activatePolling: Ember.on('init', Ember.observer('archive', function () {
      var _this = this;

      if (!this.get('archive')) {
        setTimeout(function () {
          return _this.onPoll();
        }, this.get('pollInterval'));
      }
    })),
    lastPoll: Ember.computed({
      get: function get() {
        if (this._lastPoll) {
          return this._lastPoll;
        }

        return new Date();
      },
      set: function set(key, value) {
        return this._lastPoll = value;
      }
    }),
    pollInterval: Ember.computed(function () {
      return this.get('sessionAccount').getSettingValue('POLL_INTERVAL') || 1000;
    }).readOnly()
  });

  _exports.default = _default;
});