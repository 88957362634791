define("apollo/pods/components/create-advice-button/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    actions: {
      createAdvice: function createAdvice(transport) {
        var model = {
          transport: transport
        };
        model.transport.set('advice', this.get('store').createRecord('advice', {}));
        this.showModal('advice.modals.create-advice', model);
      }
    }
  });

  _exports.default = _default;
});