define("apollo/pods/external-cost/model", ["exports", "ember-data", "apollo/mixins/model-validator"], function (_exports, _emberData, _modelValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend(_modelValidator.default, {
    sessionAccount: Ember.inject.service('session-account'),
    intl: Ember.inject.service(),
    externalCostType: _emberData.default.belongsTo('external-cost-type', {
      async: true
    }),
    currency: _emberData.default.belongsTo('currency', {
      async: true
    }),
    company: _emberData.default.belongsTo('company', {
      async: true
    }),
    transport: _emberData.default.belongsTo('transport', {
      async: true
    }),
    externalCostOrder: _emberData.default.belongsTo('transport', {
      async: true
    }),
    price: _emberData.default.attr('number'),
    dateCreated: _emberData.default.attr('isodate'),
    isEnabled: _emberData.default.attr('boolean', {
      defaultValue: function defaultValue() {
        return true;
      }
    }),
    externalCostIntegrationNumber: _emberData.default.attr('number'),
    isExternalOrder: _emberData.default.attr('boolean'),
    pzNumber: _emberData.default.attr('string'),
    validations: {
      company: {
        presence: {
          message: 'blank'
        }
      },
      price: {
        presence: {
          message: 'blank'
        }
      },
      externalCostType: {
        presence: {
          message: 'blank'
        }
      },
      currency: {
        presence: {
          message: 'blank'
        }
      }
    },
    isWash: function isWash() {
      return this.get('externalCostType.name') === 'WASHING';
    },
    isAutomaticWashing: function isAutomaticWashing() {
      return this.get('externalCostType.name') === 'AUTOMATIC_WASHING';
    },
    isFreight: function isFreight() {
      return this.get('externalCostType.name') === 'FREIGHT';
    },
    hasExternalCategory: function hasExternalCategory() {
      return this.get('externalCostType.category') === 'EXTERNAL_COSTS';
    },
    canNotBeModified: Ember.computed('id', 'externalCostIntegrationNumber', function () {
      return this.get('externalCostIntegrationNumber');
    }),
    deletingOrderNotPossibleHint: Ember.computed('pzNumber', function () {
      if (!this.get('sessionAccount').hasRole('ROLE_CAN_DELETE_EXTERNAL_COST')) {
        return this.get('intl').t('external-cost.order.noAuthorityToDelete');
      } else if (this.get('pzNumber')) {
        return this.get('intl').t('external-cost.order.deletingNotPossibleConfirmedInSap');
      } else {
        return null;
      }
    })
  });

  _exports.default = _default;
});