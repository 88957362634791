define("apollo/authorizers/custom", ["exports", "ember-simple-auth/authorizers/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // the custom authorizer that authorizes requests against the custom server
  var _default = _base.default.extend({
    authorize: function authorize(sessionData, block) {
      block('Authorization', 'Bearer ' + sessionData.token);
    }
  });

  _exports.default = _default;
});