define("apollo/pods/transport/serializer", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.RESTSerializer.extend(_emberData.default.EmbeddedRecordsMixin, {
    attrs: {
      steps: {
        embedded: 'save'
      },
      advice: {
        embedded: 'save'
      },
      carrierCompany: {
        embedded: 'save'
      },
      transaction: {
        embedded: 'save'
      },
      products: {
        embedded: 'save'
      },
      externalCosts: {
        embedded: 'save'
      },
      additionalData: {
        embedded: 'save'
      }
    },
    serializeIntoHash: function serializeIntoHash(data, type, record, options) {
      var object = this.serialize(record, options);

      for (var key in object) {
        data[key] = object[key];
      }
    },

    /**
     * Ember-data ma bug, który powoduje powielanie rekordów po ich zapisaniu.
     * W przypadku `steps` akurat wystarczyło usuwać ostatni element z listy (por. `hasManyRecords.pop()`).
     *
     * Jedyną cechą charakterystyczną powielonych rekordów, którą zauważyłem, było to, że nie mają id
     * oraz mają `_bfsId > 0`.
     *
     * Można się zapoznać z wątkiem https://github.com/emberjs/data/issues/1829,
     * chociaż akurat nie było tam poniższego rozwiązania.
     * W ogóle chyba nie udało się znaleźć konkretnej łaty.
     *
     * @param records
     */
    removeDuplicatedRecords: function removeDuplicatedRecords(records) {
      try {
        records.filter(function (r) {
          return !r.id && r._internalModel._bfsId > 0;
        }).forEach(function (r) {
          console.debug("Removing duplicated has-many record of type ".concat(r.modelName, ".."));
          records.splice(records.indexOf(r), 1);
        });
      } catch (e) {
        console.error(e);
      }
    },
    serializeHasMany: function serializeHasMany(record, json, relationship) {
      var hasManyRecords, key;
      key = relationship.key;
      hasManyRecords = record.hasMany(key);

      if (hasManyRecords && this.attrs[key] && this.attrs[key].embedded === "save") {
        json[key] = [];
        this.removeDuplicatedRecords(hasManyRecords);
        hasManyRecords.forEach(function (item) {
          // use includeId: true if you want the id of each model on the hasMany relationship
          var data = item.attributes();

          if (key === "additionalData" && Ember.isBlank(data.comment)) {
            return;
          }

          data.id = item.id;

          if (relationship.options.deepEmbedded) {
            relationship.options.deepEmbedded.forEach(function (deepKey) {
              if (item.belongsTo(deepKey)) {
                var deepRecord = item.belongsTo(deepKey);

                if (deepKey === "company" && !deepRecord.attr('name')) {
                  return true;
                }

                data[deepKey] = deepRecord.attributes();
                data[deepKey].id = deepRecord.id;

                if (deepKey === "company") {
                  data[deepKey].country = deepRecord.belongsTo('country') ? {
                    id: deepRecord.belongsTo('country').id
                  } : null;
                } else if (deepKey === "service") {
                  data[deepKey].mechanic = deepRecord.belongsTo('mechanic') ? {
                    id: deepRecord.belongsTo('mechanic').id
                  } : null;
                  data[deepKey].serviceType = deepRecord.belongsTo('serviceType') ? {
                    id: deepRecord.belongsTo('serviceType').id
                  } : null;
                } else if (deepKey === "address") {
                  data[deepKey].country = deepRecord.belongsTo('country') ? {
                    id: deepRecord.belongsTo('country').id
                  } : null;
                }
              }
            });
          }

          if (item.modelName === "step") {
            if (data.deliveryDateFrom) {
              data.deliveryDateFrom = moment(data.deliveryDateFrom).format();
            }

            if (data.deliveryDateTo) {
              data.deliveryDateTo = moment(data.deliveryDateTo).format();
            }
          }

          json[key].push(data);
        });
      } else {
        this._super(record, json, relationship);
      }
    },
    serializeBelongsTo: function serializeBelongsTo(record, json, relationship) {
      var embeddedRecord, key;
      key = relationship.key;
      embeddedRecord = record.belongsTo(key);

      if (embeddedRecord && this.attrs[key] && this.attrs[key].embedded === "save") {
        if (key === "carrierCompany" && !embeddedRecord.attr('name')) {
          return true;
        }

        var data = embeddedRecord.attributes();
        data.id = embeddedRecord.id;

        if (relationship.options.deepEmbedded) {
          relationship.options.deepEmbedded.forEach(function (deepKey) {
            if (embeddedRecord.belongsTo(deepKey)) {
              data[deepKey] = {
                id: embeddedRecord.belongsTo(deepKey).id
              };
            }
          });
        }

        json[key] = data;
      } else {
        this._super(record, json, relationship);
      }
    }
  });

  _exports.default = _default;
});