define("apollo/pods/schedulers/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    cookies: Ember.inject.service(),
    model: function model(params) {
      var start = moment(params.date_string).startOf('week');
      var stop;
      var delta = params.delta;
      var transportTypeParam = params.transport_type;

      if (delta === 'month') {
        stop = moment(start).add(1, 'month');
      } else if (delta === '14days') {
        stop = moment(start).add(14, 'days');
      } else {
        stop = moment(start).add(1, 'week');
        delta = 'week';
      }

      console.log("Generating schedule for ".concat(start.format('YYYY-MM-DD'), " -- ").concat(stop.format('YYYY-MM-DD'), ".."));
      var transportsQueryParams = {
        start: start.format('YYYY-MM-DD'),
        stop: stop.format('YYYY-MM-DD'),
        transportTypesIds: Ember.A()
      };

      if (transportTypeParam.toUpperCase() === 'SERVICE') {
        var transportTypes = this.get('store').peekAll('transportType').filter(function (transportType) {
          return ['SERVICE'].includes(transportType.get('name'));
        });
        transportsQueryParams.transportTypesIds = transportTypes.getEach('id');
      } else if (transportTypeParam.toUpperCase() === 'TRANSPORT') {
        var _transportTypes = this.get('store').peekAll('transportType').filter(function (transportType) {
          return ['TRANSPORT', 'SERVICE', 'LEAVE', 'MANUAL_TRANSPORT', 'EXTERNAL_MANUAL_TRANSPORT', 'EXTERNAL_TRANSPORT_ORDER'].includes(transportType.get('name'));
        });

        transportsQueryParams.transportTypesIds = _transportTypes.getEach('id');
      }

      var partnershipType = this.get('store').peekAll('partnershipType').filterBy('name', 'SERVICE').get('firstObject');
      var reloadResources = true;

      if (this.controller) {
        reloadResources = transportTypeParam !== this.controller.get('model.transportTypeParam');
      }

      return Ember.RSVP.hash({
        transportTypesIds: transportsQueryParams.transportTypesIds,
        transports: this.get('store').query('transport', transportsQueryParams),
        companies: this.get('store').query('company', {
          principalCompanyId: this.get('cookies').read('principalId')
        }),
        trucks: this.get('store').findAll('truck'),
        trailers: this.get('store').findAll('trailer'),
        delta: delta,
        date: start,
        transportTypeParam: transportTypeParam,
        partnerships: this.get('store').findAll('partnership'),
        users: this.get('store').query('user', {
          partnershipTypeId: partnershipType.get('id')
        }),
        reloadResources: reloadResources
      });
    },
    actions: {
      refreshCurrentRoute: function refreshCurrentRoute() {
        console.log("Od\u015Bwie\u017Camy \u015Bcie\u017Ck\u0119 ".concat(this, ".."));
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});