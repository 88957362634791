define("apollo/pods/components/transports/transports-table/transport-header/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    classNames: ['transport-table-header'],
    intl: Ember.inject.service(),
    countLabel: Ember.computed('transportsCount', 'transportTypes', function () {
      var msgCode = this.findTranslationCode();
      return this.get('intl').t(msgCode, {
        count: this.get('transportsCount')
      });
    }),
    findTranslationCode: function findTranslationCode() {
      var transportTypeName = this.get('transportTypes.firstObject.name');

      switch (transportTypeName) {
        case "SERVICE":
          return "common.services";

        case "EXTERNAL_COST_ORDER":
          return "common.externalCostOrders";

        default:
          return "common.transports";
      }
    }
  });

  _exports.default = _default;
});