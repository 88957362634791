define("apollo/pods/transports/grouping/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    store: Ember.inject.service(),
    cookies: Ember.inject.service(),
    transportsGroupedByUserGroups: Ember.computed('model.transports.@each.personsGroup', function () {
      var userGroups = this.get('model.personsGroups');
      var transports = this.get('model.transports');
      var allTransportsAndUserGroups = [];
      transports.filter(function (t) {
        if (!t.get('archived')) {
          if (t.get('personsGroup.id')) {
            userGroups.filter(function (u) {
              if (u.get('name') === t.get('personsGroup.name')) {
                allTransportsAndUserGroups.push(new Map().set(u.get('name'), t));
              }
            });
          } else {
            allTransportsAndUserGroups.push(new Map().set('NOTASSIGNED', t));
          }
        }
      });
      return allTransportsAndUserGroups;
    }),
    forwarderGroupedByUserGroups: Ember.computed(function () {
      return [];
    }),
    setForwarderGroupedByUserGroups: Ember.observer('model.personsGroups', 'forwarders', function () {
      var self = this;
      var userGroups = this.get('model.personsGroups');
      self.get('forwarders').then(function (forwarders) {
        self.set('forwarderGroupedByUserGroups', []);
        userGroups.forEach(function (ug) {
          var maps = forwarders.filter(function (f) {
            return f.get('userGroup.name') === ug.get('name');
          });
          self.get('forwarderGroupedByUserGroups').push(new Map().set(ug.get('name'), maps));
        });
      });
    }),
    principalCompany: Ember.computed(function () {
      return this.get('store').peekRecord('company', this.get('cookies').read('principalId'));
    }),
    forwarders: Ember.computed('principalCompany.forwarders.@each.id', 'principalCompany.forwarders.length', function () {
      return this.get('principalCompany.forwarders');
    }),
    actions: {
      addRoute: function addRoute(item, dropType) {
        console.log(item);
        console.log(dropType);
        var userGroup = this.get('model.personsGroups').filter(function (g) {
          return g.get('name') === dropType;
        });
        var transport = this.get('store').peekRecord('transport', item);
        transport.set('personsGroup', userGroup.get('firstObject'));
        transport.set('isGrouping', true);
        transport.save();
        transport.set('isGrouping', false);
      }
    }
  });

  _exports.default = _default;
});