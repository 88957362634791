define("apollo/pods/components/transport/carrier-section/component", ["exports", "apollo/mixins/autocomplete-utils-mixin", "apollo/mixins/input-utils-mixin"], function (_exports, _autocompleteUtilsMixin, _inputUtilsMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_autocompleteUtilsMixin.default, _inputUtilsMixin.default, {
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    cookies: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    //obliczane tylko raz - przy pierwszej próbie zmiany przewoźnika
    persistentCarrierData: Ember.computed(function () {
      return {
        id: this.get('transport.carrierCompany.id'),
        freight: this.get('transport.transaction.priceOfFreight'),
        currency: this.get('transport.transaction.currencyOfFreight')
      };
    }),
    setCurrencies: Ember.on('init', function () {
      var self = this;
      var currencies = this.get('store').peekAll('currency');
      self.set('currencies', currencies.sortBy('idx'));
      self.get('transport.transaction').then(function (transaction) {
        if (!transaction.get('currencyOfFreight.id')) {
          transaction.set('currencyOfFreight', currencies.get('firstObject'));
        }
      });
    }),
    freightCanNotBeEdited: Ember.computed('transport.freightCost.externalCostIntegrationNumber', 'transport.advice.canNotBeEdited', 'transport.carrierCompany.id', function () {
      if (this.get('transport.advice.canNotBeEdited')) {
        return true;
      }

      if (this.get('transport.freightCost.externalCostIntegrationNumber') && this.get('persistentCarrierData').id === this.get('transport.carrierCompany.id')) {
        return true;
      }

      return false;
    }),
    canNotBeEdited: Ember.computed(function () {
      if (this.get('transport.advice.id') && !this.get('sessionAccount').hasRole('ROLE_CAN_CHANGE_CARRIER_AFTER_ADVISING_TRANSPORT')) {
        return true;
      }

      if (this.get("transport.advice.canNotBeEdited")) {
        return true;
      }

      var blockingCheckpoint = this.get('transport.checkpoints').find(function (cp) {
        return cp.get('task.blocksCarrierEdition');
      });

      if (blockingCheckpoint && this.get('transport.lastConfirmedCheckpoint.idx') >= blockingCheckpoint.get('idx')) {
        return true;
      }

      return this.get("transport.canNotBeEdited");
    }),
    actions: {
      setCarrier: function setCarrier(carrier) {
        var persistentCarrierData = this.get('persistentCarrierData');
        var persistentCarrierId = persistentCarrierData.id;
        var previousCarrierId = this.get('transport.carrierCompany.id');

        if (!previousCarrierId || !this.get('transport.advice.id') || Number(persistentCarrierId) === carrier.id || Number(previousCarrierId) === carrier.id) {
          this.setItem('transport.carrierCompany', 'company', carrier.id);
        } else {
          if (confirm(this.get('intl').t('transport.changeCarrierConfirm'))) {
            this.get('transport.advice').clearFormData ? this.get('transport.advice').clearFormData() : this.get('transport.advice.content').clearFormData();
            this.setItem('transport.carrierCompany', 'company', carrier.id);
          } else {
            this.setItem('transport.carrierCompany', 'company', previousCarrierId);
          }
        } //przywracamy zapamiętany fracht


        if (Number(persistentCarrierId) === carrier.id) {
          this.set('transport.transaction.priceOfFreight', persistentCarrierData.freight);
          this.set('transport.transaction.currencyOfFreight', persistentCarrierData.currency);
        }
      }
    }
  });

  _exports.default = _default;
});