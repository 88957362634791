define("apollo/helpers/has-access-to-column", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    sessionAccount: Ember.inject.service('session-account'),
    compute: function compute(params) {
      this.set('value', params[0]);
      this.set('transportTypeColumnNames', params[1]);
      return this.get('content');
    },
    valueDidChange: Ember.observer('value', 'transportTypeColumnNames', function () {
      var value = this.get('value');

      if (Ember.isEmpty(value)) {
        Ember.defineProperty(this, 'content', false);
        return;
      }

      var self = this;
      Ember.defineProperty(this, 'content', Ember.computed('value', 'transportTypeColumnNames', function () {
        var value = self.get('value');
        var sessionAccount = self.get('sessionAccount');
        var transportTypeColumnNames = self.get('transportTypeColumnNames');
        var transportTypeHasAccessToColumn = false;

        if (transportTypeColumnNames) {
          transportTypeHasAccessToColumn = transportTypeColumnNames.includes(value);
          return sessionAccount.get('columnNames').includes(value) && transportTypeHasAccessToColumn;
        }
      }));
    }),
    contentDidChange: Ember.observer('content', function () {
      this.recompute();
    })
  });

  _exports.default = _default;
});