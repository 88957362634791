define("apollo/mixins/persistence-mixin", ["exports", "@babel/runtime/helpers/esm/typeof", "jquery", "apollo/config/environment"], function (_exports, _typeof2, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    session: Ember.inject.service('session'),
    sessionAccount: Ember.inject.service('session-account'),

    /**
     * Ember-data nie potrafi automatycznie usunąć obiektów z relacji, jeśli te zostaną
     * usunięte po stronie serwera - musimy więc to zrobić ręcznie.
     * Poniższa metoda zapyta serwer o aktualne relacje dla podanego modelu i usunie te
     * obiektu, które już nie istnieją.
     *
     * Uwaga: serwer musi potrafić obsłużyć konkretne zapytanie ajax.
     *
     * @param modelName - nazwa modelu; np. 'transport'
     * @param modelId - id modelu
     * @param childModelName - nazwa modelu relacji zdefiniowana jako DS.hasMany; np. 'checkpoint'
     */
    unloadDeletedRecords: function unloadDeletedRecords(modelName, modelId, childModelName) {
      var self = this;
      return new Ember.RSVP.Promise(function (resolve) {
        var store = self.store;

        if (!store.peekRecord) {
          store = self.get('store');
        }

        var model = store.peekRecord(modelName, modelId);

        if (!model) {
          resolve();
        } // TODO: Jakaś mądrzejsza pluralizacja, jeśli będzie potrzeba.


        var relationship = childModelName + 's';
        model.get(relationship).then(function (currentRecords) {
          var token = self.get('session.data.authenticated.token');

          _jquery.default.ajax({
            url: _environment.default.serverURL + "/".concat(modelName, "s/") + modelId + "/".concat(relationship),
            // url może mieć postać np. 'host:port/transports/123456/checkpoints'
            type: 'GET',
            beforeSend: function beforeSend(xhr) {
              xhr.setRequestHeader('Authorization', 'Bearer ' + token);
            }
          }).always(function (json) {
            currentRecords.map(function (r) {
              return parseInt(r.id);
            }).forEach(function (currentId) {
              if (!json[relationship].filterBy('id', currentId).length) {
                var r = store.peekRecord('checkpoint', currentId);
                model.get(relationship).removeObject(r);
                r.unloadRecord();
                console.debug("Unloaded ".concat(childModelName, " #").concat(r.id, " from ").concat(modelName, " #").concat(modelId, "."));
              }
            });
            resolve();
          });
        });
      });
    },

    /**
     * Ember-data czasem wczytuje do sesji zduplikowane rekordy w przypadku relacji has-many.
     * Poniższa metoda usuwa nadmiarowe rekordy.
     *
     * @param parentModel
     * @param relationshipName
     */
    removeDuplicateRecords: function removeDuplicateRecords(parentModel, relationshipName) {
      var ids = [];
      var duplicatedModels = [];
      parentModel.get(relationshipName).forEach(function (m) {
        if (ids.includes(m.id)) {
          duplicatedModels.push(m);
        } else {
          ids.push(m.id);
        }
      });
      duplicatedModels.forEach(function (r) {
        console.debug("Deleted duplicated record: #".concat(r.id));
        parentModel.get(relationshipName).removeObject(r);
      });
    },
    //https://stackoverflow.com/questions/4465244/compare-2-json-objects
    deepEquals: function deepEquals(o1, o2) {
      var self = this;

      if (o1 === null || o2 === null) {
        return false;
      }

      var k1 = Object.keys(o1).sort();
      var k2 = Object.keys(o2).sort();
      var k1Filtered = k1.filter(function (e) {
        return e !== 'lastPoll';
      });

      if (k1Filtered.length !== k2.length) {
        return false;
      }

      return k1Filtered.map(function (keyPair) {
        if (keyPair === 'lastPoll') {
          return false;
        }

        if ((0, _typeof2.default)(o1[keyPair]) == "object" && (0, _typeof2.default)(o2[keyPair]) == "object") {
          return self.deepEquals(o1[keyPair], o2[keyPair]);
        } else {
          if (o1[keyPair] !== o2[keyPair]) {
            return false;
          }

          return o1[keyPair] === o2[keyPair];
        }
      });
    }
  });

  _exports.default = _default;
});