define("apollo/pods/components/transports/transports-table/load-more/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    sessionAccount: Ember.inject.service('session-account'),
    initLoadMore: Ember.on('init', function () {
      var self = this;

      if (this.get('sessionAccount').getSettingValue("AUTOMATIC_LOAD_MORE") === "false") {
        console.debug("Automatyczne dociąganie zamówień wyłączone - przerywamy...");
        return;
      }

      console.debug("Inicjujemy automatyczne dociąganie zamówień..");

      window.onscroll = function (ev) {
        if (self.get('dataLoaded') === false) {
          console.debug('jesteśmy w trakcie wczytywania, nie pobieramy kolejnych danych');
        } else if (window.innerHeight + window.pageYOffset >= $(document).height() - 2) {
          console.debug("scroll do końca strony, pobieramy kolejne zlecenia");
          self.loadMore();
        }
      };
    }),
    willDestroyElement: function willDestroyElement() {
      window.onscroll = null;
    },
    actions: {
      loadMore: function loadMore() {
        this.loadMore();
      }
    }
  });

  _exports.default = _default;
});