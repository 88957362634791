define("apollo/pods/components/drivers/drivers-list/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    init: function init() {
      this._super();

      var self = this;
      var drivers = this.get('company.drivers');
      var allTransportsAndDrivers = [];
      var filteredDrivers = [];

      if (self.get('transports.length') > 0) {
        self.get('transports').filter(function (t) {
          drivers.filter(function (d) {
            if (d.get('id') === t.get('advice.driver.id')) {
              allTransportsAndDrivers.push(new Map().set(d.get('id'), t));
            }
          });
        });
      }

      drivers.then(function (drivers) {
        drivers.filter(function (driver) {
          if (driver.get('enabled')) {
            filteredDrivers.push(driver);
          }
        });
        self.set('drivers', filteredDrivers);
      });
      self.set('transportsGroupedByDrivers', allTransportsAndDrivers);
    },
    queryParams: ['carrierId'],
    carrierId: Ember.computed(function () {
      return this.get('company.id');
    }),
    actions: {}
  });

  _exports.default = _default;
});