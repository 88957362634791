define("apollo/pods/users/create/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    sessionAccount: Ember.inject.service('session-account'),
    access: ['ROLE_CREATE_USER'],
    beforeModel: function beforeModel(transition) {
      if (!this.get('sessionAccount').hasAnyOfTheRoles(this.get('access'))) {
        transition.abort();
        return this.transitionTo('forbidden');
      }

      var currentUser = this.get('sessionAccount').get('currentUser');
    },
    model: function model() {
      var currentUser = this.get('sessionAccount').get('currentUser');
      return this.store.createRecord('user', {
        //company: this.store.createRecord('company'),
        roleGroup: currentUser.get('company').get('groups').get('firstObject')
      });
    }
  });

  _exports.default = _default;
});