define("apollo/pods/components/transport/steps-section/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    cookies: Ember.inject.service(),
    loadDeleteButtonIsActive: Ember.computed('transport.loadSteps.length', function () {
      var loadStepsLength = this.get('transport.loadSteps.length');
      return loadStepsLength > 1;
    }),
    unloadDeleteButtonIsActive: Ember.computed('transport.unloadSteps.length', function () {
      var unloadStepsLength = this.get('transport.unloadSteps.length');
      return unloadStepsLength > 1;
    }),
    actions: {
      addStepAt: function addStepAt(type, idx) {
        // TODO: [pawel] spr., czy type jest poprawny
        console.log("idx: ".concat(idx));
        var store = this.get('store');
        var transport = this.get('transport');
        idx = idx !== undefined ? idx : transport.get('steps.length'); // TODO: [pawel] poprawić, gdy będzie opcja dodawania stepów dowolnego typu (na razie zawsze jest to 'load')

        var address = store.createRecord('address', {});
        var step = this.get('store').createRecord('step', {
          stepTypeName: type,
          orderInTransport: idx,
          address: address,
          company: transport.get('clientCompany')
        }); // Musimy przesunąć niektóre stepy (np. po dodaniu załadunku, wszystkie rozładunki muszą zmienić kolejność)

        transport.get('steps').filter(function (s) {
          return s.get('orderInTransport') >= idx;
        }).forEach(function (s) {
          var currentIdx = s.get('orderInTransport');
          s.set('orderInTransport', currentIdx + 1);
        });
        console.log("Dodajemy step do transportu ".concat(transport.get('id'), " na miejscu ").concat(idx, ".."));
        transport.get('steps').pushObject(step);
      },
      deleteStep: function deleteStep(idx) {
        var transport = this.get('transport');
        var stepArray = transport.get('steps').filterBy('orderInTransport', idx);

        if (!stepArray) {
          return;
        }

        var step = stepArray.get('firstObject');
        transport.get('steps').removeObject(step);
        step.unloadRecord(); // Usuwamy ewentualne błędy dot. nieunikalnych dostawców

        var supplierNames = transport.get('supplierNames');

        if (supplierNames.length === supplierNames.uniq().length) {
          transport.get('errors').remove('contractors');
        } // Przeliczamy kolejność stepów w transporcie


        transport.get('steps').sortBy('orderInTransport').forEach(function (s, idx) {
          console.log("idx: ".concat(idx));
          s.set('orderInTransport', idx);
        });
      }
    }
  });

  _exports.default = _default;
});