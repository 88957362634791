define("apollo/pods/companies/create/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    cookies: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    access: ['ROLE_CREATE_COMPANY'],
    beforeModel: function beforeModel(transition) {
      if (!this.get('sessionAccount').hasAnyOfTheRoles(this.get('access'))) {
        transition.abort();
        this.transitionTo('forbidden');
      }
    },
    model: function model(params) {
      var countries = [];
      this.store.peekAll('country').sortBy('ibanSymbol').filter(function (item) {
        if (item.get('ibanSymbol') === 'PL') {
          countries.unshift(item);
        } else {
          countries.pushObject(item);
        }
      });
      var principalCompany = this.store.peekRecord('company', this.get('cookies').read('principalId'));
      var principalCountry = principalCompany.get('country');
      var company = this.store.createRecord('company', {
        country: principalCountry,
        enabled: true
      });
      var address = this.store.createRecord('address', {
        country: principalCountry,
        isHeadquarter: true
      });
      company.get('addresses').pushObject(address);
      var companyType = params.companyType.toString().toUpperCase();
      var partnership = this.store.createRecord('partnership', {
        principalCompany: principalCompany,
        integrated: false
      });
      company.get('partnerships').pushObject(partnership);
      return Ember.RSVP.hash({
        companyType: companyType,
        countries: countries,
        company: company
      });
    },
    setupController: function setupController(controller, model) {
      var companyType = model.companyType;
      var partnershipType = this.get('store').peekAll('partnershipType').findBy('name', companyType);
      model.company.set('partnershipType', partnershipType);
      model.company.get('partnershipWithPrincipal').set('partnershipType', partnershipType);
      controller.setProperties({
        model: model,
        isEditing: false
      });
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.set('successMessage', null);
      }
    },
    actions: {
      willTransition: function willTransition() {
        this.get('controller').setProperties({
          errors: [],
          attachments: [],
          successMessage: null
        });
      }
    }
  });

  _exports.default = _default;
});