define("apollo/pods/containers/index/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    sessionAccount: Ember.inject.service('session-account'),
    cookies: Ember.inject.service(),
    access: ['ROLE_RESOURCES_TAB'],
    queryParams: {
      query: {
        refreshModel: true
      },
      showAll: {
        refreshModel: true
      },
      from: {
        refreshModel: true
      },
      to: {
        refreshModel: true
      },
      dateStatus: {
        refreshModel: true
      },
      page: {
        refreshModel: true
      },
      max: {
        refreshModel: true
      }
    },
    beforeModel: function beforeModel(transition) {
      if (!this.get('sessionAccount').hasAnyOfTheRoles(this.get('access'))) {
        transition.abort();
        this.transitionTo('forbidden');
      }
    },
    model: function model(params) {
      params.ownerships = ['OWN', 'INTEGRATED'];
      params.sort = ['registration_number'];
      params.max = params.max || 25;
      params.page = params.page || 1;
      return this.store.query('container', params);
    },
    setupController: function setupController(controller, model) {
      this.get('controller').setProperties({
        model: model,
        vehicleType: 'CONTAINER',
        totalPages: model.get('meta.totalPages')
      });
    },
    actions: {
      willTransition: function willTransition(transition) {
        this.controllerFor('containers.index').setProperties({
          successMessage: null
        });

        if (transition.targetName !== 'containers.index') {
          this.get('controller').setProperties({
            query: '',
            queryInput: '',
            fromInput: null,
            from: null,
            toInput: null,
            to: null,
            showAll: false,
            dateStatusInput: null,
            dateStatus: null,
            page: 1,
            totalPages: 0
          });
        }
      }
    }
  });

  _exports.default = _default;
});