define("apollo/pods/components/transport/logs-section/component", ["exports", "jquery", "apollo/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    sessionAccount: Ember.inject.service('session-account'),
    store: Ember.inject.service(),
    actions: {
      toggleTransportLogs: function toggleTransportLogs(transport) {
        (0, _jquery.default)(".logs-div").toggle();
        this.send('getTransportLogs', transport);
      },
      getTransportLogs: function getTransportLogs(transport) {
        var self = this;
        var token = this.get('sessionAccount').get('token');

        _jquery.default.ajax({
          url: _environment.default.serverURL + '/transports/' + transport.get("id") + '/getTransportLogs',
          type: 'GET',
          beforeSend: function beforeSend(xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
          }
        }).then(function (json) {
          self.get('store').pushPayload(json);
          json.transportLogs.sortBy('dateCreated').reverse().forEach(function (transportLog) {
            var log = self.get('store').peekRecord('auditLog', transportLog.id);
            transport.get('transportLogs').pushObject(log);
          });
        });
      }
    }
  });

  _exports.default = _default;
});