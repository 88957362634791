define("apollo/pods/components/vehicles/container-form/component", ["exports", "apollo/mixins/vehicle-mixin"], function (_exports, _vehicleMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_vehicleMixin.default, {
    sessionAccount: Ember.inject.service('session-account'),
    dateFields: Ember.computed('', function () {
      return this.get('sessionAccount').get('resourceDateFormSettings').CONTAINER;
    }),
    actions: {}
  });

  _exports.default = _default;
});