define("apollo/pods/external-cost-type/model", ["exports", "ember-data", "apollo/mixins/model-validator"], function (_exports, _emberData, _modelValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend(_modelValidator.default, {
    intl: Ember.inject.service(),
    externalCosts: _emberData.default.hasMany('external-cost', {
      async: true
    }),
    name: _emberData.default.attr('string'),
    category: _emberData.default.attr('string'),
    validations: {
      name: {
        presence: {
          message: 'blank'
        }
      }
    },
    translatedName: Ember.computed('name', function () {
      var translationPath = 'external-cost.types.';
      return this.get('intl').t(translationPath + this.get('name'));
    }),
    externalCostTypeNameAndKey: Ember.computed('name', function () {
      return {
        name: this.get('intl').t('external-cost.types.' + this.get('name')).toString(),
        key: this.get('name')
      };
    })
  });

  _exports.default = _default;
});