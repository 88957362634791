define("apollo/pods/user-group/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    intl: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    name: _emberData.default.attr('string'),
    transports: _emberData.default.hasMany('transport', {
      async: true
    }),
    trucks: _emberData.default.hasMany('truck', {
      async: true
    }),
    company: _emberData.default.belongsTo('company', {
      async: true
    }),
    userGroupNameAndKey: Ember.computed('name', function () {
      return {
        name: this.get('intl').t('forwarderGroup.' + this.get('name')).toString(),
        key: this.get('name')
      };
    }),
    translatedName: Ember.computed('name', function () {
      return this.get('intl').t('forwarderGroup.' + this.get('name')).toString();
    }),
    sortedTrucks: Ember.computed('trucks.@each.sideNumber', function () {
      return this.get('trucks').filterBy('enabled').sort(function (a, b) {
        var sideNumberA = a.get('sideNumber') ? a.get('sideNumber').replace(/\s/g, '') : '';
        var sideNumberB = b.get('sideNumber') ? b.get('sideNumber').replace(/\s/g, '') : '';
        return sideNumberA.localeCompare(sideNumberB);
      });
    }),
    companiesWithTrucksInGroup: Ember.computed('sortedTrucks.@each.owner', function () {
      return this.get('sortedTrucks').filterBy('owner.id').getEach('owner').uniqBy('id');
    }),
    asResource: function asResource(children) {
      var currentUserGroupId = this.get('sessionAccount.currentUser.userGroup.id');
      var group = this;
      return {
        id: this.get('id'),
        title: this.get('intl').t('forwarderGroup.' + this.get('name')),
        children: children ? children : [],
        onDrop: function onDrop(context, transport) {
          transport.cancelAdvice();
          transport.cancelCarrier();
          transport.set('personsGroup', group);
          transport.save().then(function () {
            context.unloadDeletedRecords('transport', transport.id, 'checkpoint');
          });
        },
        isMainInGroup: true,
        shouldBeCollapsed: currentUserGroupId && group.get('id') !== currentUserGroupId
      };
    }
  });

  _exports.default = _default;
});