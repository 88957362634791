define("apollo/pods/companies/index/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    sessionAccount: Ember.inject.service('session-account'),
    cookies: Ember.inject.service(),
    access: ['ROLE_COMPANIES_TAB'],
    queryParams: {
      query: {
        refreshModel: true
      },
      page: {
        refreshModel: true
      },
      max: {
        refreshModel: true
      }
    },
    beforeModel: function beforeModel(transition) {
      if (!this.get('sessionAccount').hasAnyOfTheRoles(this.get('access'))) {
        transition.abort();
        this.transitionTo('forbidden');
      }
    },
    model: function model(params) {
      var companyType = params.companyType.toString().toUpperCase();
      params.max = params.max || 25;
      params.page = params.page || 1;
      params.principalCompanyId = this.get('cookies').read('principalId');
      var companies = this.store.query('company', params);
      return Ember.RSVP.hash({
        companyType: companyType,
        companies: companies
      });
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      this.get('controller').setProperties({
        companies: model.companies,
        companyType: model.companyType,
        totalPages: model.companies.get('meta.totalPages')
      });
    },
    actions: {
      willTransition: function willTransition(transition) {
        this.get('controller').setProperties({
          info: null,
          errors: null,
          successMessage: null
        });

        if (transition.targetName !== 'companies.index') {
          this.get('controller').setProperties({
            queryInput: '',
            filterActive: false,
            page: 1,
            totalPages: 0
          });
        }

        return true;
      }
    }
  });

  _exports.default = _default;
});