define("apollo/pods/notification/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    intl: Ember.inject.service(),
    roleGroups: _emberData.default.hasMany('roleGroup', {
      async: true
    }),
    task: _emberData.default.belongsTo('task', {
      async: true
    }),
    type: _emberData.default.attr('string'),
    name: _emberData.default.attr('string'),
    active: _emberData.default.attr('boolean'),
    sentAutomatically: _emberData.default.attr('boolean'),
    visibleInTransportTable: _emberData.default.attr('boolean'),
    idx: _emberData.default.attr('number'),
    isOfTypeSMS: Ember.computed('type', function () {
      return this.get('type') === 'SMS';
    }),
    label: Ember.computed('name', 'isOfTypeSMS', function () {
      var prefix = this.get('isOfTypeSMS') ? 'SMS ' : '';
      return prefix + this.get('intl').t("notification.".concat(this.get('name'), ".label.short"));
    }),
    nameAndKey: Ember.computed('name', function () {
      var label = this.get('intl').t("notification.".concat(this.get('name'), ".unsent"));
      return {
        name: label,
        key: this.get('name')
      };
    })
  });

  _exports.default = _default;
});