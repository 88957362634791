define("apollo/routes/application", ["exports", "jquery", "ember-simple-auth/mixins/application-route-mixin", "apollo/config/environment"], function (_exports, _jquery, _applicationRouteMixin, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_applicationRouteMixin.default, {
    sessionAccount: Ember.inject.service('session-account'),
    freshWidget: Ember.inject.service('fresh-widget'),
    intl: Ember.inject.service(),
    cookies: Ember.inject.service(),
    beforeModel: function beforeModel() {
      if (this.get('beforeModelFired')) {
        console.log("beforeModel already fired; returning..");
        return true;
      }

      this.set('beforeModelFired', true);
      var language;

      try {
        language = (window.navigator.languages || [window.navigator.language || window.navigator.userLanguage])[0].split("-")[0];
      } catch (e) {
        language = (navigator.language || navigator.browserLanguage).split('-')[0];
      }

      var availableLocales = ['pl', 'en'];

      if (_jquery.default.inArray(language, availableLocales) < 0) {
        language = 'en';
      }

      this.set('locale', language);
      this.set('intl.locale', language);

      if (this.get('session.isAuthenticated') && !this.get('sessionAccount.currentUser')) {
        return this._loadCurrentUser();
      }
    },
    afterModel: function afterModel() {
      this.loadSupportWidget();
    },
    sessionAuthenticated: function sessionAuthenticated() {
      console.log("Session has been authenticated.");

      this._super.apply(this, arguments);

      var self = this;

      this._loadCurrentUser().then(function (loggedUser) {
        if (!loggedUser.get('resetPasswordIsDisabled') && new Date(loggedUser.get('resetPasswordDate')) < new Date()) {
          self.get('sessionAccount').set('passwordExpired', true);
          self.transitionTo('reset-password');
        } else {
          loggedUser.get('company').then(function () {
            self.send('afterLogin');
          });
        }
      });
    },
    loadSupportWidget: function loadSupportWidget() {
      this.get('freshWidget').loadWidget();
    },
    actions: {
      afterLogin: function afterLogin() {
        var loggedUser = this.get('sessionAccount').get('currentUser');

        if (!loggedUser.get('statuteAccepted')) {
          var username = loggedUser.get('username');
          console.log("User ".concat(username, " did not accept Terms; redirecting to review route.."));
          this.transitionTo('terms.review');
        } else {
          var typesCanBeSeenByRoleGroup = this.get('sessionAccount.currentUser.transportTypes').filterBy('canBeSeenByRoleGroup');

          if (typesCanBeSeenByRoleGroup.length === 1 && typesCanBeSeenByRoleGroup.filterBy('name', 'SERVICE').length) {
            this.transitionTo('services');
          } else {
            this.transitionTo('transports');
          }
        }
      },
      showModal: function showModal(name, model) {
        this.render(name, {
          into: 'application',
          outlet: 'modal',
          model: model
        });
      },
      removeModal: function removeModal() {
        (0, _jquery.default)('.modal').hide();
        (0, _jquery.default)(".fade").hide();
        (0, _jquery.default)("body").removeClass("modal-open"); // Za https://github.com/emberjs/ember-inspector/issues/625.

        this.render('modals/empty', {
          outlet: 'modal',
          into: 'application',
          controller: 'application'
        });
      },
      error: function error(_error) {
        var status = _error.errors ? _error.errors[0].status : null;

        if (status === 0) {
          console.log('Sorry, but we\'re having trouble connecting to the server. This problem is usually the result of a broken Internet connection. You can try refreshing this page.');
        } else if (status === "403" || status === "401" || status === "404") {
          this.transitionTo('forbidden');
        }
      },
      goBack: function goBack() {
        window.history.back();
      }
    },
    redirect: function redirect(model, transition) {
      var unauthenticatedRoutes = _environment.default['ember-simple-auth'].unauthenticatedRoutes;

      if (!unauthenticatedRoutes.includes(transition.targetName) && !this.get('session.isAuthenticated')) {
        console.log("User is unauthenticated - redirecting to auth route (and save transition to ".concat(transition.targetName, ") .."));
        this.set('session.attemptedTransition', transition);
        var principalId = this.get('cookies').read('principalId');
        this.replaceWith('auth.login', {
          queryParams: {
            principalId: principalId ? principalId : ''
          }
        });
      } else {
        console.log("session.isAuthenticated");
      }
    },
    _loadCurrentUser: function _loadCurrentUser() {
      if (!this.get('session.isAuthenticated')) {
        return;
      }

      return this.get('sessionAccount').loadCurrentUser();
    },
    setupController: function setupController(controller) {
      var html = (0, _jquery.default)('html');
      controller.set('browserUnsupported', html.is('.unsupported'));
      controller.set('browserOld', html.is('.old'));
    }
  });

  _exports.default = _default;
});