define("apollo/pods/trucks/index/controller", ["exports", "apollo/mixins/vehicle-mixin", "apollo/mixins/resource-mixin", "apollo/mixins/modal-mixin"], function (_exports, _vehicleMixin, _resourceMixin, _modalMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_vehicleMixin.default, _resourceMixin.default, _modalMixin.default, {
    queryParams: ['query', 'showAll', 'from', 'to', 'dateStatus', 'page', 'max', 'ownerships'],
    showAll: false,
    ownerships: ["OWN"],
    cookies: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    showIntegratedTrucksFilterLabel: Ember.computed('ownerships.[]', function () {
      var integratedCarriersIncluded = this.get('ownerships').includes("INTEGRATED");
      return this.get('intl').t(integratedCarriersIncluded ? 'resources.hideIntegratedTrucks' : 'resources.showIntegratedTrucks');
    }),
    trucks: Ember.computed('model', function () {
      return this.get('model');
    }),
    dateColumns: Ember.computed('', function () {
      return this.get('sessionAccount').get('resourceDateFormSettings').TRUCK;
    }),
    actions: {
      showDetails: function showDetails(vehicleId) {
        if (this.get('sessionAccount').hasRole('ROLE_CREATE_VEHICLE')) {
          this.transitionToRoute('trucks.edit', vehicleId);
        }
      }
    }
  });

  _exports.default = _default;
});