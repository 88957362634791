define("apollo/pods/components/routes-dropzone/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "z3YE5sES",
    "block": "{\"symbols\":[\"t\"],\"statements\":[[7,\"h4\",true],[10,\"class\",\"text-center\"],[8],[7,\"span\",true],[10,\"class\",\"fa fa-plus\"],[10,\"aria-hidden\",\"true\"],[8],[9],[0,\" \"],[1,[28,\"t\",[\"transport.grouping.dropHere\"],null],false],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"row transport-grouping-row\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-xs-12\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"transportsGroupedByUserGroups\"]]],null,{\"statements\":[[4,\"if\",[[28,\"find-group\",[[24,[\"pg\",\"name\"]],[23,1,[]]],null]],null,{\"statements\":[[0,\"                \"],[1,[28,\"transport-grouping\",null,[[\"transportMap\",\"transport-id\"],[[23,1,[]],[28,\"find-transport-id\",[[23,1,[]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null],[0,\"    \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/routes-dropzone/template.hbs"
    }
  });

  _exports.default = _default;
});