define("apollo/pods/services/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QXoj/YSC",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"container-fluid\"],[8],[0,\"\\n    \"],[1,[28,\"transports/search-transports\",null,[[\"archive\",\"searchParams\",\"transportTypes\"],[false,[24,[\"searchParams\"]],[24,[\"allAvailableTransportTypes\"]]]]],false],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"clearfix\"],[8],[9],[0,\"\\n    \"],[1,[28,\"transports/transports-table\",null,[[\"transportTypes\",\"archive\",\"searchParams\",\"showModal\",\"fileUploadSuccess\",\"deleteFile\",\"confirm\",\"revoke\",\"markNotesAsReadOff\",\"addNote\"],[[24,[\"allAvailableTransportTypes\"]],false,[24,[\"searchParams\"]],[28,\"action\",[[23,0,[]],\"showModal\"],null],[28,\"action\",[[23,0,[]],\"fileUploadSuccess\"],null],[28,\"action\",[[23,0,[]],\"deleteFile\"],null],[28,\"action\",[[23,0,[]],\"confirm\"],null],[28,\"action\",[[23,0,[]],\"revoke\"],null],[28,\"action\",[[23,0,[]],\"markNotesAsReadOff\"],null],[28,\"action\",[[23,0,[]],\"addNote\"],null]]]],false],[0,\"\\n\\n    \"],[1,[28,\"outlet\",[\"modal\"],null],false],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/services/index/template.hbs"
    }
  });

  _exports.default = _default;
});