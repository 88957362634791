define("apollo/helpers/odd-even", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.oddEven = oddEven;
  _exports.default = void 0;

  function oddEven(number) {
    if (number % 2) {
      return 'odd';
    } else {
      return 'even';
    }
  }

  var _default = Ember.Helper.helper(oddEven);

  _exports.default = _default;
});