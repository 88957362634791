define("apollo/pods/components/create-leave-button/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    cookies: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    leaveTransportType: Ember.computed(function () {
      return this.get('store').peekAll('transport-type').filterBy('name', 'LEAVE').get('firstObject');
    }),
    principalCompany: Ember.computed(function () {
      return this.get('store').peekRecord('company', this.get('cookies').read('principalId'));
    }),
    driverLeave: Ember.computed(function () {
      return this.get('leave');
    }),
    actions: {
      openLeaveModal: function openLeaveModal() {
        var leave = this.get('driverLeave');

        if (!this.get('driverLeave.id')) {
          var advice = this.get('store').createRecord('advice', {
            driver: this.get('driver')
          });
          leave = this.get('store').createRecord('transport', {
            transportType: this.get('leaveTransportType'),
            advice: advice,
            principalCompany: this.get('principalCompany'),
            carrierCompany: this.get('driver.company')
          });
          var step = this.get('store').createRecord('step', {
            stepTypeName: 'leave',
            transport: leave
          });
        }

        this.showModal('leaves.modals.create-leave', leave);
      }
    }
  });

  _exports.default = _default;
});