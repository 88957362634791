define("apollo/pods/components/button-with-confirmation/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'span',
    type: 'button',
    click: function click() {
      var text = this.get('text') || this.get('intl').t('common.confirmMessage');

      if (confirm(text)) {
        this.get('onConfirm')();
      }
    }
  });

  _exports.default = _default;
});