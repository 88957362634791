define("apollo/pods/register/index/controller", ["exports", "jquery", "apollo/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service('session'),
    loginController: Ember.inject.controller('auth/login'),
    intl: Ember.inject.service(),
    actions: {
      register: function register() {
        var self = this;
        var user = this.get('model.user');
        var company = this.get('model.company');
        var data = user.serialize();
        data.company = company.serialize();

        _jquery.default.ajax({
          url: _environment.default.serverURL + "/register",
          type: 'POST',
          data: JSON.stringify(data),
          contentType: 'application/json'
        }).then(function (jsonResponse) {
          if (jsonResponse.errors) {
            self.set('errors', jsonResponse.errors);
            return;
          }

          self.setProperties({
            inProgress: false,
            errors: []
          });
          var credentials = {
            identification: jsonResponse.user.username,
            password: user.get('password')
          };
          self.get('session').authenticate('authenticator:custom', credentials).then(null, function (error) {
            if (error !== undefined) {
              console.error("error: " + error);
            }
          });
        }, function (response) {
          self.set('errors', response.errors);
        });
      }
    }
  });

  _exports.default = _default;
});