define("apollo/pods/partnership/model", ["exports", "ember-data", "apollo/mixins/model-validator"], function (_exports, _emberData, _modelValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend(_modelValidator.default, {
    STATUS: {
      NO_INVITATION: "NO_INVITATION",
      WAITING: "WAITING",
      ACCEPTED: "ACCEPTED",
      REJECTED: "REJECTED"
    },
    principalCompany: _emberData.default.belongsTo('company', {
      async: true
    }),
    contractorCompany: _emberData.default.belongsTo('company', {
      async: true,
      inverse: 'partnerships'
    }),
    invitation: _emberData.default.belongsTo('invitation', {
      async: true
    }),
    partnershipType: _emberData.default.belongsTo('partnershipType', {
      async: true
    }),
    contractorNumber: _emberData.default.attr('string'),
    subcontractorCode: _emberData.default.attr('string'),
    integrated: _emberData.default.attr('boolean', {
      defaultValue: function defaultValue() {
        return false;
      }
    }),
    intermodalCarrier: _emberData.default.attr('boolean', {
      defaultValue: function defaultValue() {
        return false;
      }
    }),
    onlyAsCustomerOfService: _emberData.default.attr('boolean'),
    status: _emberData.default.attr('string'),
    validations: {
      contractorNumber: {
        custom: [{
          validation: function validation(key, value, model) {
            if (model.get('partnershipType.name') === 'SERVICE' || model.get('partnershipType.name') !== 'SERVICE' && value && value.length >= 3) {
              return true;
            }
          },
          message: 'tooShort'
        }]
      },
      subcontractorCode: {
        custom: [{
          validation: function validation(key, value, model) {
            if (model.get('partnershipType.name') !== 'CARRIER') {
              return true;
            } else if (model.get('partnershipType.name') === 'CARRIER' && value) {
              return true;
            }
          },
          message: 'blank'
        }]
      }
    }
  });

  _exports.default = _default;
});