define("apollo/pods/wash/modals/choose-wash/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wkEamx8T",
    "block": "{\"symbols\":[],\"statements\":[[4,\"custom-modal\",null,[[\"title\",\"close\"],[[24,[\"title\"]],[28,\"action\",[[23,0,[]],\"removeModal\"],null]]],{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"form-horizontal\"],[8],[0,\"\\n\\n        \"],[1,[28,\"wash-form\",null,[[\"washStep\",\"transaction\",\"transport\"],[[24,[\"washStepCopy\"]],[24,[\"transactionCopy\"]],[24,[\"model\",\"transport\"]]]]],false],[0,\"\\n\\n        \"],[7,\"div\",true],[10,\"class\",\"text-right margin-top-big\"],[8],[0,\"\\n\"],[4,\"unless\",[[24,[\"canNotEdit\"]]],null,{\"statements\":[[0,\"                \"],[7,\"button\",false],[12,\"class\",\"btn btn-primary\"],[12,\"data-button-name\",\"SAVE\"],[12,\"type\",\"submit\"],[3,\"action\",[[23,0,[]],\"chooseWash\",[24,[\"model\"]]]],[8],[0,\"\\n                    \"],[1,[28,\"t\",[\"common.save\"],null],false],[0,\"\\n                \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n            \"],[7,\"button\",false],[12,\"class\",\"btn btn-default\"],[12,\"data-dismiss\",\"modal\"],[12,\"data-button-name\",\"CANCEL\"],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],\"cancel\"]],[8],[0,\"\\n                \"],[1,[28,\"t\",[\"common.cancel\"],null],false],[0,\"\\n            \"],[9],[0,\"\\n        \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/wash/modals/choose-wash/template.hbs"
    }
  });

  _exports.default = _default;
});