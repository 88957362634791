define("apollo/pods/components/transport-modal/component", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      ok: function ok() {
        (0, _jquery.default)('.modal').modal('hide');
        this.ok();
      }
    },
    show: Ember.on('didInsertElement', function () {
      var modal = (0, _jquery.default)('.modal');
      modal.modal({
        backdrop: 'static'
      }).on('hidden.bs.modal', function () {
        this.close();
      }.bind(this));
      modal.modal({
        backdrop: 'static'
      }).on('shown.bs.modal', function () {
        (0, _jquery.default)('.focus-input').focus();
        (0, _jquery.default)('input').change(function () {
          (0, _jquery.default)(this).val((0, _jquery.default)(this).val().trim());
        });
      }.bind(this));
    })
  });

  _exports.default = _default;
});