define("apollo/pods/components/transports/transports-table/transport-filters/filter/component", ["exports", "jquery", "apollo/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    FILTERS_WITH_TRANSLATED_LABELS: ['checkpoint', 'personsGroup', 'notification', 'externalCostType', 'task_confirmed'],
    store: Ember.inject.service(),
    cookies: Ember.inject.service(),
    intl: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    tagName: '',
    searchField: '',
    searchParams: {},
    //rzeczywista zmienna propoagowana po całym systemie
    filterValue: '',
    //zmienna działająca tylko w ramach komponentu
    filterKey: '',
    alternativeFilterKey: '',
    selectOptions: Ember.A(),
    selectedOption: '',
    setParams: Ember.observer('filterValue', function () {
      var self = this;
      Ember.run.once(this, function () {
        if (!this.get('filterValue')) {
          this.set('filterValue', '');
        }

        var Filter = Ember.Object.extend({
          filterKey: '',
          filterValue: ''
        });
        var value = this.get('filterValue');

        if (this.get('filterType') === 'date' && this.get('filterValue')) {
          value = moment(this.get('filterValue')).format('YYYY-MM-DD');
        }

        var filter = Filter.create({
          filterKey: this.get('filterKey'),
          alternativeFilterKey: this.get('alternativeFilterKey'),
          filterValue: value,
          filterType: this.get('filterType')
        });
        var existingFilters = this.get('searchParams.filters').filter(function (f) {
          if (f.filterKey === filter.get('filterKey')) {
            if (filter.get('filterValue') === '') {
              self.get('searchParams.filters').popObject(filter);
            } else {
              f.set('filterValue', filter.get('filterValue'));
            }
          }

          return f.filterKey === filter.get('filterKey');
        });

        if (existingFilters.length === 0 && filter.get('filterValue') !== '') {
          self.get('searchParams.filters').pushObject(filter);
        }
      });
    }),
    clearFilters: Ember.observer('searchParams.filters.length', function () {
      Ember.run.once(this, function () {
        if (this.get('searchParams.filters').length === 0) {
          this.set('filterValue', '');
          this.set('selectedOption', '');
        }
      });
    }),
    noMatchesMessage: Ember.computed('loading', function () {
      return this.get('intl').t("common.".concat(this.get('loading') ? 'loading' : 'noMatches'));
    }),
    needsTranslation: Ember.computed('selectOptionsType', function () {
      return this.get('FILTERS_WITH_TRANSLATED_LABELS').includes(this.get('selectOptionsType'));
    }),
    translateOptions: function translateOptions(options) {
      var self = this;
      return options.map(function (item) {
        return {
          key: item.key,
          name: self.get('intl').t("filterNames.".concat(item.key))
        };
      });
    },
    actions: {
      setSelectOptions: function setSelectOptions() {
        if (this.get('filterType') !== "select") {
          return;
        }

        this.get('selectOptions').clear();
        this.set('loading', true);
        var token = this.get('sessionAccount').get('token');
        var data = Object.assign({}, this.get('queryParams'));
        data.filterKey = this.get('filterKey');
        data.alternativeFilterKey = this.get('alternativeFilterKey');
        data.selectFilterNameKey = this.get('selectFilterNameKey');
        var self = this;

        _jquery.default.ajax({
          url: _environment.default.serverURL + '/filter',
          type: 'POST',
          data: JSON.stringify(data),
          contentType: 'application/json',
          beforeSend: function beforeSend(xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
          },
          complete: function complete() {
            self.set('loading', false);
            self.set('optionsLoaded', true);
          }
        }).then(function (items) {
          self.set('searchField', 'name');
          var options = items.options.filter(function (o) {
            return o.key;
          });

          if (self.get('needsTranslation')) {
            options = self.translateOptions(options);
          }

          self.get('selectOptions').pushObjects(options.sortBy('name'));
        }, function (response) {
          self.set('errors', response.errors);
        });
      },
      setSelectedOptionAndFilterValue: function setSelectedOptionAndFilterValue(selectValue) {
        this.set('filterValue', selectValue ? selectValue.key : null);
        this.set('selectedOption', selectValue);
      },
      changeDate: function changeDate(dateToChange, newDate) {
        this.set(dateToChange, newDate);
      }
    }
  });

  _exports.default = _default;
});