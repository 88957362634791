define("apollo/pods/leaves/modals/create-leave/controller", ["exports", "jquery", "apollo/config/environment", "apollo/mixins/modal-mixin", "apollo/mixins/persistence-mixin"], function (_exports, _jquery, _environment, _modalMixin, _persistenceMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_persistenceMixin.default, _modalMixin.default, {
    intl: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    store: Ember.inject.service(),
    cookies: Ember.inject.service(),
    createTransportController: Ember.inject.controller('transports/modals/create-transport'),
    errors: [],
    title: Ember.computed('model.archived', function () {
      if (this.get('model.id')) {
        return this.get('intl').t('leave.title', this.get('model.internalIndex'));
      }

      return this.get('intl').t('leave.createTitle');
    }),
    actions: {
      save: function save() {
        var self = this;
        var leave = self.get('model');
        var driver = leave.get('advice.driver');

        if (!this.customValidate(leave)) {
          return;
        }

        leave.save().then(function () {
          self.removeDuplicateRecords(leave, 'steps');
          self.removeDuplicateRecords(leave, 'checkpoints');
          var stepsCopy = leave.get('steps').slice();
          stepsCopy.forEach(function (step) {
            if (step !== undefined && !step.get('id')) {
              leave.get('steps').removeObject(step);
              step.unloadRecord();
            }
          });
          self.send('removeModal');
          driver.get('leaves').pushObject(leave);
          driver.reload();
        });
      },
      cancel: function cancel() {
        this.setProperties({
          errors: []
        });
        this.get('createTransportController').rollbackTransportChanges(this.get('model'));
        (0, _jquery.default)('.modal').modal('hide');
      },
      deleteLeave: function deleteLeave() {
        var self = this;
        (0, _jquery.default)(".delete-progress").show();
        var token = this.get('sessionAccount').get('token');

        _jquery.default.ajax({
          type: "PUT",
          url: _environment.default.serverURL + '/transports/' + this.get('model.id') + "/delete",
          beforeSend: function beforeSend(xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
          }
        }).then(function () {
          self.setProperties({
            errors: []
          });
          (0, _jquery.default)('.modal').modal('hide');
          self.get('model').set('deleted', true);
        });
      }
    },
    customValidate: function customValidate(leave) {
      var validate = true;

      if (!leave.get('steps.firstObject.deliveryDateFrom')) {
        leave.get('steps.firstObject.errors').add('deliveryDateFrom', 'blankLeaveFrom');
        validate = false;
      }

      if (!leave.get('steps.firstObject.deliveryDateTo')) {
        leave.get('steps.firstObject.errors').add('deliveryDateTo', 'blankLeaveTo');
        validate = false;
      }

      return validate;
    }
  });

  _exports.default = _default;
});