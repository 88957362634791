define("apollo/pods/auth/login/controller", ["exports", "jquery", "ember-simple-auth/mixins/unauthenticated-route-mixin", "apollo/config/environment"], function (_exports, _jquery, _unauthenticatedRouteMixin, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_unauthenticatedRouteMixin.default, {
    session: Ember.inject.service('session'),
    intl: Ember.inject.service(),
    cookies: Ember.inject.service(),
    queryParams: ['principalId'],
    principalId: null,
    logo: '',
    setLogo: Ember.observer('principalId', function () {
      var self = this;
      var pathname = window.location.pathname.replace('/', '');

      if (this.get('alias')) {
        pathname = this.get('alias');
      }

      if (pathname || self.get('principalId')) {
        _jquery.default.ajax({
          url: _environment.default.serverURL + '/loginPage',
          type: 'GET',
          contentType: 'application/json; charset=utf-8',
          dataType: 'json',
          data: {
            companyAlias: pathname,
            principalId: self.get('principalId')
          }
        }).then(function (resp) {
          if (resp.errors) {
            console.error(resp.errors);
          } else {
            self.set('logo', resp.logo);
          }
        });
      }
    }),
    showLoginAs: Ember.computed(function () {
      return _environment.default.environment !== 'production';
    }),
    actions: {
      authenticate: function authenticate() {
        var self = this;
        self.set("errorMessage", null);
        var credentials = this.getProperties('identification', 'password');

        if (credentials.identification) {
          credentials.identification = credentials.identification.trim();
        }

        if (credentials.password) {
          credentials.password = credentials.password.trim();
        }

        if (!credentials.identification || !credentials.password) {
          var message = this.get('intl').t('auth.noLoginAndPassword');
          this.set('errorMessage', message);
          return;
        }

        (0, _jquery.default)(".login-progress").show();
        this.get('session').authenticate('authenticator:custom', credentials).then(null, function (error) {
          if (error !== undefined) {
            console.error("error: " + error);
          }

          self.set("password", null);
          (0, _jquery.default)(".login-progress").hide();
          (0, _jquery.default)(".password").focus();
          var message;

          if (error === "Unauthorized") {
            message = self.get('intl').t('auth.invalidLoginOrPassword');
          } else {
            message = self.get('intl').t('auth.serverError');
          }

          self.set('errorMessage', message);
        });
      },
      loginAs: function loginAs(username) {
        this.setProperties({
          identification: username,
          password: "aA123456"
        });
        this.send("authenticate");
      }
    }
  });

  _exports.default = _default;
});